import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, Form, Input, Select, Switch } from 'antd'
import {
  PlusOutlined,
  MinusCircleOutlined,
  CloseOutlined,
  CheckOutlined,
} from '@ant-design/icons'
import StyledTooltip from '../shared/StyledTooltip'
import showConfirm from '../shared/DeleteConfirm'

const { TextArea } = Input
const FormItem = Form.Item

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 20,
      offset: 4,
    },
  },
}

const EditHero = ({
  visible,
  onCancel,
  editRecurrent,
  onUpdate,
  onDelete,
  setEditRecurrent,
}) => {
  const [form] = Form.useForm()

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />
  }

  return (
    <Modal
      open={visible}
      title="Редактировать рекуррентный платеж"
      okText="Редактировать"
      cancelText="Закрыть"
      onCancel={onCancel}
      footer={[
        <Button
          danger
          style={{ float: 'left' }}
          onClick={() => {
            showConfirm(() => {
              onDelete()
            })
          }}
        >
          Удалить
        </Button>,
        <Button key="submit" onClick={onCancel}>
          Отмена
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields()
                onUpdate(values)
              })
              .catch((info) => {
                console.log('Validate Failed:', info)
              })
          }}
        >
          Сохранить
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        initialValues={{
          name: editRecurrent?.name,
          monthPayments: editRecurrent?.monthPayments?.map((monthPayment) => {
            return monthPayment?.amount
          }),
          freezingCount: editRecurrent?.freezingCount,
          freezingDays: editRecurrent?.freezingDays,
          isActive: editRecurrent?.isActive,
        }}
      >
        <FormItem
          key="name"
          label={getTooltip('Название', 'Название')}
          name="name"
          rules={[
            {
              required: true,
              message: 'Укажите название',
            },
          ]}
        >
          <Input placeholder="Название" />
        </FormItem>
        <FormItem
          key="freezingCount"
          label={getTooltip('Кол-во заморозок', 'Кол-во заморозок')}
          name="freezingCount"
          rules={[
            {
              required: true,
              message: 'Укажите кол-во заморозок',
            },
          ]}
        >
          <Input placeholder="Кол-во заморозок" type="number" />
        </FormItem>
        <FormItem
          key="freezingDays"
          label={getTooltip('Кол-во заморозок', 'Кол-во заморозок')}
          name="freezingDays"
          rules={[
            {
              required: true,
              message: 'Укажите кол-во заморозок',
            },
          ]}
        >
          <Input placeholder="Кол-во заморозок" type="number" />
        </FormItem>
        <FormItem
          key="isActive"
          label={getTooltip('Активировать', 'Активировать')}
          name="isActive"
        >
          <Switch
            defaultChecked={editRecurrent?.isActive}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            placeholder="Активировать"
          />
        </FormItem>
        <Form.List
          name="monthPayments"
          rules={[
            {
              validator: async (_, monthPayments) => {
                if (!monthPayments || monthPayments.length < 1) {
                  return Promise.reject(new Error('At least 2 passengers'))
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  {...formItemLayoutWithOutLabel}
                  label={index === 0 ? 'Месячные платежи' : ''}
                  required={false}
                  key={field.key}
                >
                  <Form.Item
                    {...field}
                    validateTrigger={['onChange', 'onBlur']}
                    noStyle
                  >
                    <Input
                      type="number"
                      placeholder={`Цена ${index + 1} месяца`}
                      style={{
                        width: '60%',
                      }}
                    />
                  </Form.Item>
                  {fields.length > 1 ? (
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  ) : null}
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  // style={{
                  //   width: '60%'
                  // }}
                  icon={<PlusOutlined />}
                >
                  Добавить месяц
                </Button>

                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  )
}

EditHero.propTypes = {
  editHero: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    countSales: PropTypes.number,
    tickets: PropTypes.number,
    backgroundURL: PropTypes.string,
    identifier: PropTypes.string,
    sex: PropTypes.string,
    history: PropTypes.string,
    avatarURL: PropTypes.string,
    bwAvatarURL: PropTypes.string,
    imageURL: PropTypes.string,
    heroLevels: PropTypes.shape(),
  }).isRequired,
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  levels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default EditHero
