import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Input, Select, Button } from 'antd'
import { removeTypenameField } from '../../utils/apollo'

const ContentContainer = styled.div`
  width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const StyledButton = styled(Button)`
  width: 250px;
  margin-top: 20px;
`

const HurdleValuesModal = ({ handleUpdateHurdle, hurdle }) => {
  const [hurdleValues, setHurdleValues] = useState({
    hurdleType: '',
    male: {
      bodyFatComparisonValue: 0,
      changeOverValue: 0,
      changeBelowValue: 0,
      requirementPass: 0
    },
    female: {
      bodyFatComparisonValue: 0,
      changeOverValue: 0,
      changeBelowValue: 0,
      requirementPass: 0
    }
  })

  useEffect(() => {
    if (hurdle) {
      setHurdleValues(removeTypenameField(hurdle))
    }
  }, [hurdle])

  const handleInputChange = (value, field, nestedField) => {
    if (nestedField) {
      setHurdleValues({
        ...hurdleValues,
        [field]: {
          ...hurdleValues[field],
          [nestedField]: parseFloat(value)
        }
      })
    } else {
      // the case with hurdle type
      setHurdleValues({
        ...hurdleValues,
        [field]: value
      })
    }
  }

  const fields = [
    'bodyFatComparisonValue',
    'changeOverValue',
    'changeBelowValue',
    'requirementPass'
  ]

  const hurdleOptions = ['onlyBodyTest', 'noHurdle', 'regularHurdle']

  const onUpdateClick = () => {
    handleUpdateHurdle({
      hurdle: hurdleValues
    })
  }

  return (
    <ContentContainer>
      <h2>Hurdle type</h2>
      <Select
        value={hurdleValues.hurdleType}
        onChange={value => handleInputChange(value, 'hurdleType')}
      >
        {hurdleOptions.map(hurdleOption => (
          <Select.Option value={hurdleOption}>{hurdleOption}</Select.Option>
        ))}
      </Select>

      {hurdleValues.hurdleType === 'regularHurdle' && (
        <>
          <h2>Male specifications</h2>
          {fields.map(field => (
            <>
              <h3> {field} </h3>
              <Input
                type="number"
                onChange={e => handleInputChange(e.target.value, 'male', field)}
                value={hurdleValues.male[field]}
                placeholder={field}
              />
            </>
          ))}
        </>
      )}
      {hurdleValues.hurdleType === 'regularHurdle' && (
        <>
          <h2>Female specifications</h2>
          {fields.map(field => (
            <>
              <h3> {field} </h3>
              <Input
                type="number"
                onChange={e =>
                  handleInputChange(e.target.value, 'female', field)
                }
                value={hurdleValues.female[field]}
                placeholder={field}
              />
            </>
          ))}
        </>
      )}
      <StyledButton onClick={onUpdateClick} type="primary">
        Обновить
      </StyledButton>
    </ContentContainer>
    // </Modal>
  )
}

HurdleValuesModal.propTypes = {
  hurdle: PropTypes.shape({}).isRequired,
  handleUpdateHurdle: PropTypes.func.isRequired
}

export default HurdleValuesModal
