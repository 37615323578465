// don't forget to change corresponding file in the server directory
// it is also called programSetTypes.js

export default [
  '',
  'fullBody',
  'upperBody',
  'legs',
  'functional',
  'gluteLab',
  'push',
  'pull',
  'armBlast',
  'endGame',
  'assessment',
  'education',
  'guest',
  'strength',
  'streetWorkout',
  'bootcamp'
]
