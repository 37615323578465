import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { gql, useLazyQuery } from '@apollo/client'
import { Modal, Form, Select } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

const FormItem = Form.Item

const Header = styled.div`
  font-family: 'GraphikLCGMedium';
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 32px;
`
const CHECK_EVENT = gql`
  query checkWeekAtEvent(
    $startDate: DateTime
    $endDate: DateTime
    $clubId: ID
  ) {
    checkWeekAtEvent(startDate: $startDate, endDate: $endDate, clubId: $clubId)
  }
`

const WarningContainer = styled.div`
  background: rgba(255, 230, 230, 1);
  border-radius: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 8px;
`

const WarningTitle = styled.div`
  font-family: 'GraphikLCGSemibold';
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 8px;
`

const WarningContent = styled.div`
  font-family: 'GraphikLCGMedium';
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
`

const enumerateWeeksBetweenDates = (startDate, endDate) => {
  let date = []
  let startTime = startDate
  while (dayjs(startTime) <= dayjs(endDate)) {
    date.push({
      startDate: dayjs(startTime).startOf('week'),
      endDate: dayjs(startTime).endOf('week'),
    })
    startTime = dayjs(startTime).add(1, 'week')
  }
  return date
}

const CopyEventsModal = ({
  visible,
  onCancel,
  title,
  editEvent,
  events,
  currentClub,
  addMultipleEventsClick,
}) => {
  const [form] = Form.useForm()
  const [weeks, setWeeks] = React.useState(
    enumerateWeeksBetweenDates(
      dayjs().add(1, 'week').startOf('week'),
      dayjs().add(5, 'week').endOf('week')
    )
  )
  const [isCompleted, seIsCompleted] = React.useState(false)

  const [checkEvent, { data, loading, error }] = useLazyQuery(CHECK_EVENT, {
    fetchPolicy: 'no-cache',
  })

  React.useEffect(() => {
    if (data && !loading && !error) {
      seIsCompleted(data.checkWeekAtEvent)
    }
  }, [data, loading, error])

  return (
    <Modal
      open={visible}
      title={title}
      footer={null}
      //   okText="Создать"
      //   cancelText="Отмена"
      onCancel={() => {
        form.resetFields()
        onCancel()
      }}
    >
      <Header>Укажите неделю</Header>
      <Form form={form} layout="vertical">
        <FormItem
          key="week"
          label={
            <>
              <div
                style={{
                  fontFamily: 'GraphikLCGMedium',
                  fontSize: 16,
                  color: 'rgba(0, 0, 0, 0.5)',
                }}
              >
                Неделя
              </div>
            </>
          }
          name="week"
          rules={[
            {
              required: true,
              message: `Пожалуйста, выберите неделю`,
            },
          ]}
        >
          <Select
            placeholder="Неделя"
            onChange={(value) => {
              console.log('value', value)
              checkEvent({
                variables: {
                  startDate: dayjs(weeks[parseInt(value)].startDate).startOf(
                    'week'
                  ),
                  endDate: dayjs(weeks[parseInt(value)].startDate).endOf(
                    'week'
                  ),
                  clubId: currentClub.id,
                },
              })
            }}
          >
            {weeks.map((week, index) => (
              <Select.Option key={index} value={index}>
                {dayjs(week.startDate).format('Do MMMM')} -{' '}
                {dayjs(week.endDate).format('Do MMMM')}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
        {!isCompleted && !!data && (
          <WarningContainer>
            <WarningTitle>Внимание!</WarningTitle>
            <WarningContent>
              Вы не можете копировать на неделю, если в ней уже есть
              опубликованные занятия.
            </WarningContent>
          </WarningContainer>
        )}
        <FormItem>
          <div
            style={{
              marginBottom: 8,
            }}
          >
            <button
              style={{
                width: '100%',
                color: 'white',
                borderRadius: 8,
                paddingTop: 16,
                paddingBottom: 16,
                fontFamily: 'GraphikLCGMedium',
                fontSize: 14,
                opacity: isCompleted ? 1 : 0.25,
                backgroundColor: 'rgba(111, 44, 255, 1)',
                border: 'none',
                cursor: 'pointer',
              }}
              onClick={() => {
                form
                  .validateFields()
                  .then((values) => {
                    form.resetFields()
                    addMultipleEventsClick(
                      events.map((event) => {
                        return {
                          startTime: dayjs(event?.startTime).add(
                            parseInt(values.week) + 1,
                            'week'
                          ),
                          endTime: dayjs(event?.startTime)
                            .add(parseInt(values.week) + 1, 'week')
                            .add(1, 'hours'),
                          trainer: event?.trainer?.id,
                          programSet: event?.programSet?.id,
                          zone: event?.programSet?.zone?.id,
                          club: currentClub?.id,
                          status: 'preview',
                          limitStation: event?.limitStation
                            ? parseInt(event?.limitStation)
                            : null,
                        }
                      })
                    )
                    onCancel()
                  })
                  .catch((info) => {
                    // eslint-disable-next-line no-console
                    console.log('Validate Failed:', info)
                  })
              }}
            >
              {loading ? <LoadingOutlined /> : 'Подтверждаю'}
            </button>
          </div>
        </FormItem>
      </Form>
    </Modal>
  )
}

CopyEventsModal.propTypes = {
  previewEvents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default CopyEventsModal
