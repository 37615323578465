import { Modal, DatePicker } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useAuth } from '../../context/useAuth';
import datePickerLocale from 'antd/es/date-picker/locale/ru_RU';
import { gql, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'

const Container = styled.div`
  background-color: white;
  border-radius: 8px;
`;

const Title = styled.p`
  color: rgba(0, 0, 0, 1);
  font-family: 'GraphikLCGMedium';
  font-size: 24px;
  line-height: 32px;
  text-align: center;
`;

const Section = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SubSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SubText = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-family: 'GraphikLCGMedium';
  font-size: 14px;
  line-height: 16px;
`;

const Name = styled.p`
  color: rgba(0, 0, 0, 1);
  font-family: 'GraphikLCGRegular';
  font-size: 14px;
  line-height: 16px;
`;

const EndDate = styled.p`
  color: rgba(0, 0, 0, 1);
  font-family: 'GraphikLCGRegular';
  font-size: 24px;
  line-height: 32px;
`;

const ButtonOk = styled.button`
  width: 100%;
  background-color: rgba(111, 44, 255, 1);
  border-radius: 8px;
  border: none;
  height: 56px;
  color: rgba(255, 255, 255, 1);
  font-family: 'GraphikLCGMedium';
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
  &:hover {
    transition: all 0.3s;
    border: 1px solid rgba(111, 44, 255, 1);
    background-color: white;
    color: rgba(111, 44, 255, 1);
  }
`;

const ButtonOkAbsent = styled.button`
  width: 100%;
  background-color: rgba(111, 44, 255, 0.25);
  border-radius: 8px;
  border: none;
  height: 56px;
  color: rgba(255, 255, 255, 0.25);
  font-family: 'GraphikLCGMedium';
  font-size: 18px;
  line-height: 24px;
`;

const ButtonCancel = styled.button`
  margin-top: 8px;
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: 56px;
  color: rgba(0, 0, 0, 1);
  font-family: 'GraphikLCGMedium';
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
`;

const UPDATE_TIME_USERHEROPASS = gql`
  mutation updateTimeUserHeroPass($id: ID!, $input: UpdateTimeHeroPassInput) {
    updateTimeUserHeroPass(id: $id, input: $input) {
      id
      status
    }
  }
`

const ChangingHPDate = ({
  changeHRModal,
  setChangeHRModal,
  selectUser,
  userCurrentHP,
  marathons,
  refetchUserCurrentHP,
}) => {
  const { user } = useAuth();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [updateUserHeroPass] = useMutation(UPDATE_TIME_USERHEROPASS, {
    onCompleted() {
      refetchUserCurrentHP()
    }
  });

  const handleStartDateChange = (date) => {
    if(date){
      const jsDate = date.toDate();
      setStartDate(jsDate);
      const end = new Date(jsDate);
      const currentHPStartDate = new Date(userCurrentHP.startTime);
      const CurrentHPEndDate = new Date(userCurrentHP.endTime);
      const diffInMs = CurrentHPEndDate - currentHPStartDate;
      const timeDifference = diffInMs / (1000 * 60 * 60 * 24);
      end.setDate(end.getDate() + timeDifference)
      setEndDate(end);
      console.log(diffInMs, timeDifference)
    }
    else{
      setStartDate(null)
      setEndDate(null)
    }
  };

  const handleUpdate = () => {
    const sortedMarathons = marathons ? [...marathons].sort((a, b) => {
      const dateA = new Date(a.marathonEvent.endTime);
      const dateB = new Date(b.marathonEvent.endTime);
      return dateB - dateA;
    }) : [];
    const latestMarathonEndTime = sortedMarathons.length > 0 ? new Date(sortedMarathons[0]?.marathonEvent.endTime) : null;
    const userStartTime = userCurrentHP?.startTime ? new Date(userCurrentHP.startTime) : null;

    if(userStartTime < latestMarathonEndTime){
      toast.error('Есть прошедший марафон')
      setChangeHRModal(false);
      return; 
    }

    const id = userCurrentHP?.id;
    const input = {
      startTime: new Date(startDate).toISOString(),
      endTime: new Date(endDate).toISOString(),
    };

    updateUserHeroPass({ variables: { id, input } })
      .then(response => {
        console.log('Update successful', response);
      })
      .catch(error => {
        console.error('Update error', error);
      });
    toast.success('Hero`s Pass успешно изменен')
    setChangeHRModal(false);
  };

  return (
    <Modal
      open={changeHRModal}
      centered
      footer={null}
      width={368}
      onCancel={() => {
        setChangeHRModal(false)
        setStartDate(null);
        setEndDate(null);
      }}
    >
      <Container>
        <Title>Поменять дату абонемента</Title>
        <Section
          style={{
            marginTop: 32,
            marginBottom: 8,
          }}
        >
          <SubText>Имя:</SubText>
          <Name>{selectUser?.nickname}</Name>
        </Section>
        <Section style={{ marginBottom: 16 }}>
          <SubText>Администратор:</SubText>
          <Name>{user?.nickname}</Name>
        </Section>
        <SubSection>
          <SubText>Дата начала</SubText>
          <DatePicker
            locale={datePickerLocale}
            placeholder="Выберите дату начала"
            format="DD.MM.YYYY"
            onChange={handleStartDateChange}
            style={{
              width: '100%',
              color: 'black',
              borderRadius: 8,
            }}
            disabledDate={(currentDate) => currentDate < new Date().setHours(0, 0, 0, 0)}
          />
          <SubText style={{ marginTop: 16 }}>Дата окончания</SubText>
          <EndDate>{endDate ? dayjs(endDate).format('DD.MM.YYYY'): ''}</EndDate>
        </SubSection>
        {startDate !== null  ? (
          <ButtonOk onClick={handleUpdate}>
            Подтверждаю
          </ButtonOk>       
        ) : (
          <ButtonOkAbsent>
            Подтверждаю
          </ButtonOkAbsent>
        )}
        <ButtonCancel onClick={() => setChangeHRModal(false)}>
          Отмена
        </ButtonCancel>
      </Container>
    </Modal>
  );
};

export default ChangingHPDate;
