import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Table, Button } from 'antd'
import { Link } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons'

import CreateForm from '../shared/CreateForm'

const Levels = ({ levels, addLevelClick }) => {
  const [modalVisible, setModalVisible] = useState(false)

  const columns = [
    {
      title: 'Уровень',
      dataIndex: 'degree'
    },
    {
      title: 'Название',
      dataIndex: 'name'
    },
    {
      title: 'Очки за занятие',
      dataIndex: 'attendancePoints'
    },
    {
      title: 'Макс.кол-во очков',
      dataIndex: 'maxPoints'
    },
    {
      title: 'Идеальная неделя',
      dataIndex: 'visitsForPerfectWeek'
    },
    {
      title: 'Бонусные очки',
      dataIndex: 'perfectWeekPoints'
    },
    {
      title: 'Штрафные очки за непосещения',
      dataIndex: 'punishmentPoints'
    },
    {
      title: 'Очки за ввод веса на упражнение',
      dataIndex: 'enterWeightBonusPoints'
    },
    {
      title: 'Действие',
      key: 'action',
      render: (text, item) => (
        <span>
          <Link to={`/levels/${item.id}`}>Редактировать</Link>
        </span>
      )
    }
  ]

  const fields = [
    {
      key: 'name',
      label: 'Название уровня',
      tooltipText: 'Название уровня'
    },
    {
      key: 'degree',
      label: 'Уровень',
      number: true
    },
    {
      key: 'attendancePoints',
      label: 'Очки за занятие',
      number: true,
      tooltipText: 'Кол-во очков получаемых за занятие на этом уровне'
    },
    {
      key: 'maxPoints',
      label: 'Максимальное количество очков',
      number: true,
      tooltipText: 'Требуемое кол-во очков для перехода на следующий уровень'
    },
    {
      key: 'visitsForPerfectWeek',
      label: 'Количество посещений для "Идеальной недели"',
      number: true,
      tooltipText:
        'Требуемое кол-во посещений в неделю для выполнения “Идеальной недели” '
    },
    {
      key: 'perfectWeekPoints',
      label: 'Количество бонусных очков за "Идеальную неделю"',
      number: true,
      tooltipText:
        'Кол-во бонусных очков получаемых за выполнение “Идеальной недели” '
    },
    {
      key: 'punishmentPoints',
      label: 'Количество штрафных очков за пропуски',
      number: true,
      tooltipText: 'Количество штрафных очков за пропущение больше двух недель'
    },
    {
      key: 'enterWeightBonusPoints',
      label: 'Количество очков за ввод веса во время упражнения',
      number: true,
      tooltipText: 'Количество бонусных очков за ввод веса'
    }
  ]

  const handleCreate = values => {
    addLevelClick(values)
    setModalVisible(false)
  }

  return (
    <>
      <Table
        dataSource={levels}
        columns={columns}
        title={() => (
          <div>
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новый уровень
            </Button>
          </div>
        )}
      />
      <CreateForm
        title="Добавить новый уровень"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onCreate={handleCreate}
        fields={fields}
      />
    </>
  )
}

Levels.propTypes = {
  levels: PropTypes.shape({
    id: PropTypes.string.isRequired,

    name: PropTypes.string,
    maxPoints: PropTypes.number,
    attendancePoints: PropTypes.number,
    perfectWeekPoints: PropTypes.number,
    punishmentPoints: PropTypes.number,
    enterWeightBonusPoints: PropTypes.number,
    visitsForPerfectWeek: PropTypes.number
  }).isRequired,
  addLevelClick: PropTypes.func.isRequired
}

export default Levels
