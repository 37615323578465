import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import axios from 'axios'

import { DatePicker, Modal, Form, Input, Select, Checkbox, Button } from 'antd'

import showConfirm from '../shared/DeleteConfirm'
import StyledTooltip from './StyledTooltip'

const { TextArea } = Input
const FormItem = Form.Item

const DetailsIcon = styled.img`
  width: 32px;
  height: 32px;
`
const InputContainer = styled.div`
  justify-content: space-between;
  display: flex;
`

const EditForm = ({
  title,
  visible,
  width,
  onCancel,
  onUpdate,
  fields,
  onDelete,
  setImages,
  images,
  setSecondImage,
  secondImage,
}) => {
  const [form] = Form.useForm()
  const [image, setImage] = React.useState(null)
  const [checkBoxes, setCheckBoxes] = React.useState({})

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />
  }

  React.useEffect(() => {
    const startingCheckboxes = {}
    fields.forEach((field) => {
      if (field.checkbox) {
        console.log({
          field,
        })
        startingCheckboxes[field.key] = field.value
      }
    })
    setCheckBoxes(startingCheckboxes)
  }, [])

  const handleCheckboxClick = (fieldName, checked) => {
    console.log('fieldName', fieldName, checked)

    console.log(checkBoxes)
    setCheckBoxes({ ...checkBoxes, [fieldName]: checked })
  }

  const handleImages = (event) => {
    setImages(event.target.value)
  }

  const handleSecondImages = (event) => {
    setSecondImage(event.target.value)
  }

  return (
    <>
      <Modal
        open={visible}
        title={title}
        okText="Create"
        onCancel={onCancel}
        width={width}
        footer={[
          <Button
            danger
            style={{ float: 'left' }}
            onClick={() => {
              showConfirm(() => {
                onDelete()
              })
            }}
          >
            Удалить
          </Button>,
          <Button onClick={onCancel} htmlType="reset">
            Отмена
          </Button>,
          <Button
            key="submit"
            htmlType="submit"
            type="primary"
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  console.log('after validation')
                  form.resetFields()
                  console.log('sending update', { values, checkBoxes })
                  onUpdate({ ...values, ...checkBoxes })
                  // onUpdate(values)
                  console.log('image before axios', image)
                  if (image) {
                    delete values.imageURL
                    // axios
                    //   .post('/api/uploadImageByFile', image, {})
                    //   .then(res => {
                    //     console.log('res', res)
                    //     if (res.data && res.data.file) {
                    //       onUpdate({ ...values, imageURL: res.data.file.url })
                    //     }
                    //   })
                  } else {
                    console.log('calling it inside else clause')
                    onUpdate({ ...values, ...checkBoxes })
                  }
                })
                .catch((info) => {
                  // eslint-disable-next-line no-console
                  console.log('Validate Failed:', info)
                })
            }}
          >
            Сохранить
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          {fields.map((field) => (
            <FormItem
              key={field.label}
              label={
                field.tooltipText
                  ? getTooltip(field.label, field?.tooltipText)
                  : field.label
              }
              name={field.key}
              rules={[
                {
                  required: !field.isNotRequired,
                  message: `Please give a name to a ${field.label}`,
                },
              ]}
              initialValue={field.value}
            >
              {
                /* eslint-disable */
                field.options ? (
                  <Select
                    mode={field.mode || 'single'}
                    placeholder="Choose type"
                    showSearch
                  >
                    {field.options.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.labelImg && (
                          <DetailsIcon src={option.labelImg} />
                        )}
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                ) : field.image ? (
                  <Input
                    type="file"
                    name="image"
                    onChange={(event) => {
                      if (event.target.files[0]) {
                        const data = new FormData()
                        data.append('image', event.target.files[0])
                        setImage(data)
                      }
                    }}
                  />
                ) : field.checkbox ? (
                  <Checkbox
                    onChange={(e) =>
                      handleCheckboxClick(field.key, e.target.checked)
                    }
                    checked={checkBoxes[field.key]}
                  />
                ) : field.number ? (
                  <Input type="number" />
                ) : field.inputType === 'date' ? (
                  <DatePicker />
                ) : field.textArea ? (
                  <TextArea
                    row={4}
                    style={{
                      height: 400,
                    }}
                  />
                ) : field.customImages ? (
                  <InputContainer>
                    <Input
                      placeholder={field.label}
                      value={images}
                      onChange={handleImages}
                    />
                    <Input
                      placeholder={field.label}
                      value={secondImage}
                      onChange={handleSecondImages}
                    />
                  </InputContainer>
                ) : (
                  <Input placeholder={field.label} />
                )
                /* eslint-enable */
              }
            </FormItem>
          ))}
        </Form>
      </Modal>
    </>
  )
}

EditForm.propTypes = {
  dataToDisplay: PropTypes.shape({}),
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onDelete: PropTypes.func.isRequired,
}

EditForm.defaultProps = {
  dataToDisplay: {},
}

export default EditForm
