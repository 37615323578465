import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Layout, Menu, Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'
import { InfoCircleOutlined, OrderedListOutlined } from '@ant-design/icons'

import Stations from '../stations/Stations.contationer'
import EditForm from '../shared/EditForm'

const { Header, Sider, Content } = Layout

const StyledBreadcrumb = styled(Breadcrumb)`
  margin: 16px 0;
`

const StyledHeader = styled(Header)`
  background: #fff;
  border-bottom: 1px solid #d9d9d9;
  padding: 0px 24px;
`

const StyledMenu = styled(Menu)`
  height: 100%;
  padding-top: 16px;
`
const StyledLayout = styled(Layout)`
  padding: 0 24px 24px;
  background: #fff;
`

const StyledContent = styled(Content)`
  padding: 24px;
  margin: 0px;
  min-height: 280px;
`

const HeaderTitle = styled.div`
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #262626;
  margin-bottom: 24px;
`

const Zone = ({ zone, club, updateClick, deleteClick }) => {
  const [currentTab, setCurrentTab] = useState(1)
  const fields = [
    {
      key: 'name',
      label: 'Название',
      value: zone.name,
      isRequired: true,
      tooltipText: 'Название зоны',
    },
    {
      key: 'group',
      label: 'Кол-во групп станций',
      value: zone.group,
      number: true,
      isRequired: true,
      tooltipText: 'Количество групп станций',
    },
    {
      key: 'width',
      label: 'Ширина карты',
      value: zone?.width,
      number: true,
      isRequired: true,
      tooltipText: 'Ширина карты',
    },
    {
      key: 'height',
      label: 'Высота карты',
      value: zone?.height,
      number: true,
      isRequired: true,
      tooltipText: 'Высота карты',
    },
  ]
  const handleTabChange = (activeKey) => {
    setCurrentTab(activeKey)
  }

  return (
    <Layout>
      <StyledBreadcrumb>
        <Breadcrumb.Item>
          <Link to="/clubs/">Клубы</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/clubs/${club?.id}/`}>{club?.name}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{zone?.name}</Breadcrumb.Item>
      </StyledBreadcrumb>
      <StyledHeader>
        <h2>Редактирование зоны</h2>
      </StyledHeader>
      <Layout>
        <Sider>
          <StyledMenu mode="inline" defaultSelectedKeys={['1']}>
            <Menu.Item
              icon={<InfoCircleOutlined />}
              key="1"
              onClick={() => handleTabChange(1)}
            >
              Основные
            </Menu.Item>
            <Menu.Item
              icon={<OrderedListOutlined />}
              key="2"
              onClick={() => handleTabChange(2)}
            >
              Список станций
            </Menu.Item>
          </StyledMenu>
        </Sider>
        <StyledLayout>
          <StyledContent className="site-layout-background">
            {currentTab === 1 && (
              <>
                <HeaderTitle>Основные</HeaderTitle>
                <EditForm
                  fields={fields}
                  onUpdateClick={updateClick}
                  onDeleteClick={deleteClick}
                />
              </>
            )}
            {currentTab === 2 && (
              <>
                <Stations zoneId={zone.id} />
              </>
            )}
          </StyledContent>
        </StyledLayout>
      </Layout>
    </Layout>
  )
}
Zone.propTypes = {
  zone: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    group: PropTypes.number,
  }).isRequired,
  club: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  deleteClick: PropTypes.func.isRequired,
  updateClick: PropTypes.func.isRequired,
}
export default Zone
