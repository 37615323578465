import React, { useState, useEffect } from 'react'
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import Loading from '../shared/Loading'
import withMainLayout from '../../hocs/withMainLayout'
import Faq from './Faq'

const GET_CATEGORIES = gql`
query FaqCategories {
    faqCategories {
        id
        name
        iconUrl
    }
}
`
const CREATE_CATEGORY = gql `
mutation CreateFaqCategory($input: FaqCategoryInput) {
  createFaqCategory(input: $input) {
      id
      name
      iconUrl
      created_at
      updated_at
  }
}
`
const DELETE_CATEGORY = gql `
mutation DeleteFaqCategory($id: ID!) {
    deleteFaqCategory(id: $id)
}
`
const UPDATE_CATEGORY = gql `
mutation UpdateFaqCategory($id: ID!, $input: UpdateFaqCategoryInput) {
  updateFaqCategory(id: $id, input: $input) {
      id
      name
      iconUrl
      created_at
      updated_at
  }
}
`

const FaqContainer = () => {
  const [categories, setCategories] = useState(null)


  const {data: dataCategories, loading: loadingCategories, error: errorCategories, refetch} = useQuery(GET_CATEGORIES)

  const [addCategory] = useMutation(CREATE_CATEGORY,{
    fetchPolicy: "no-cache",
    onCompleted() {
      toast.success("Категория успешно добавлена")
      refetch()
    }
  })
  const [deleteCategory] = useMutation(DELETE_CATEGORY,{
    fetchPolicy: "no-cache",
    onCompleted() {
      toast.success("Категория успешно удалена")
      refetch()
    }
  })
  const [updateCategory] = useMutation(UPDATE_CATEGORY, {
    fetchPolicy: "no-cache",
    onCompleted() {
      toast.success("Категория успешно изменена")
      refetch()
    }
  })

  useEffect(() => {
    if (dataCategories && !loadingCategories && !errorCategories) {
      setCategories(dataCategories.faqCategories)
    }
  }, [dataCategories, loadingCategories, errorCategories])

  const handleAddCategory = (values) => {
    addCategory({ variables: { input: values} })
  }
  const handleDeleteCategory = (id) => {
    deleteCategory({ variables:{ id: id } })
  }
  const handleUpdateCategory = (id, value) => {
    updateCategory({ variables: {id: id, input: value} })
  }

  if (loadingCategories) {
    return <Loading />
  }

  return (
    <>
      <Faq 
        categories={categories} 
        handleAddCategory={handleAddCategory} 
        handleDeleteCategory={handleDeleteCategory}
        handleUpdateCategory={handleUpdateCategory}
       />
    </>
  )
}

export default withMainLayout(FaqContainer)
