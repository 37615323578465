import React, { useState } from 'react'
import styled from 'styled-components'
// import { makeStyles } from "@material-ui/core/styles";
import { MenuItem } from '@mui/material'
import { FormControl } from '@mui/material'
import { Select } from '@mui/material'
import { Input } from 'antd'

// import ArrowDown from '../../../assets/ArrowDown';
// import OpenLogo from '../../../assets/open.svg'

const Header = styled.div`
  display: flex;
  alignitems: center;
  flexdirection: row;
`
const Search = styled.div`
  display: flex;
  alignitems: center;
  flexdirection: row;
  margin-right: 8px;
  margin-left: auto;
`

const Options = styled.div`
  display: right;
  gap: 2%;
`

const StyledInput = styled(Input)`
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.04);
  height: 40px;
  width: 240px;
  margin-right: 8px;
`

const ConversionClientsHeader = ({ search, setSearch, setFilter }) => {
  // const useStyles = makeStyles((theme) => ({
  //   formControl: {
  //     margin: theme.spacing(1),
  //     minWidth: 165,
  //     border: "1px solid rgba(0, 0, 0, 0.1)",
  //     borderRadius: 8,
  //     backgroundColor: "transparent",
  //     padding: 4
  //   }
  // }));

  // const classes = useStyles();
  const [select, setSelect] = React.useState('')
  const [free, setFree] = React.useState('')

  const handleChange = (event) => {
    setSelect(event.target.value)
  }

  return (
    <Header
      style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}
    >
      <FormControl
      //  className={classes.formControl}
      >
        <Select
          MenuProps={{
            disableScrollLock: true,
          }}
          value={select}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          disableUnderline
          style={{
            paddingLeft: 10,
            fontFamily: 'GraphikLCGMedium',
            fontSize: 14,
          }}
        >
          <MenuItem
            style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
            value=""
            onClick={() => setFilter(0)}
          >
            Все клиенты
          </MenuItem>
          <MenuItem
            style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
            value={10}
            onClick={() => setFilter(1)}
          >
            С абонементом{' '}
          </MenuItem>
          <MenuItem
            style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
            value={20}
            onClick={() => setFilter(2)}
          >
            Без абонемента{' '}
          </MenuItem>
        </Select>
      </FormControl>
      <Search>
        <StyledInput
          onChange={(e) => {
            setSearch(e.target.value.toLowerCase())
          }}
          placeholder="Поиск"
        />
      </Search>
      <Options></Options>
    </Header>
  )
}

export default ConversionClientsHeader
