import React, { useState } from 'react'
import styled from 'styled-components'

import { Table, Button } from 'antd'
import { Link } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons'

import CreateForm from '../shared/CreateForm'

const StyledImg = styled.img`
  width: 50px;
  height: 50px;
`

const TREASURE_CHEST_TYPES = [
  {
    value: 'common',
    label: 'common'
  },
  {
    value: 'uncommon',
    label: 'uncommon'
  },
  {
    value: 'legendary',
    label: 'legendary'
  }
]

const TreasureChests = ({ treasureChests, handleAddChest }) => {
  const [modalVisible, setModalVisible] = useState(false)

  const columns = [
    {
      title: 'Название',
      dataIndex: 'name'
    },
    {
      title: 'Цена',
      dataIndex: 'price'
    },
    {
      title: 'Тип сундука',
      dataIndex: 'type'
    },
    {
      title: 'Иконка',
      dataIndex: 'imageURL',
      render: item => {
        return <StyledImg src={item} alt="No Icon" />
      }
    },
    {
      title: 'Действие',
      key: 'action',
      render: (text, item) => (
        <span>
          <Link to={`/treasureChests/${item.id}`}>Редактировать</Link>
        </span>
      )
    }
  ]

  const fields = [
    {
      key: 'name',
      label: 'Название сундука',
      tooltipText: 'Название сундука'
    },
    {
      key: 'price',
      label: 'Цена за сундук',
      tooltipText: 'Цена за сундук в гантелях',
      number: true
    },
    {
      key: 'imageURL',
      label: 'Иконка сундука',
      tooltipText: 'Иконка сундука для приложения',
      image: true
    },
    {
      key: 'type',
      label: 'Тип сундука',
      type: 'select',
      options: TREASURE_CHEST_TYPES,
      tooltipText: 'Тип сундука: обычный, необычный, легендарный'
    }
  ]

  const handleCreate = values => {
    setModalVisible(false)
    handleAddChest(values)
  }

  return (
    <>
      <Table
        dataSource={treasureChests}
        columns={columns}
        title={() => (
          <div>
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новый сундук
            </Button>
          </div>
        )}
      />
      <CreateForm
        title="Добавить новый сундук"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onCreate={handleCreate}
        fields={fields}
      />
    </>
  )
}

export default TreasureChests
