import React, { useState, useEffect } from 'react'
import { Table, Input, Descriptions } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

import styled from 'styled-components'

import PropTypes from 'prop-types'
import dayjs from 'dayjs'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const StyledDescriptions = styled(Descriptions)`
  width: 300px;
`

const SalesUsers = ({
  dataUsers,
  usersCnt,
  setPage,
  page,
  searchPhoneNumber,
  setSearchPhoneNumber,
}) => {
  const [searchNickname, setSearchNickname] = useState(null)

  const utmFields = [
    'utmSource',
    'utmCampaign',
    'utmMedium',
    'contactId',
    'leadId',
  ]

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      width: '15%',
    },
    {
      title: 'Никнейм',
      dataIndex: 'nickname',
      width: '10%',
    },
    {
      title: 'Номер телефона',
      dataIndex: 'phoneNumber',
    },
    {
      title: 'Дата создания',
      dataIndex: 'created_at',
      render: (text) => (
        <span>{dayjs(text).format('Do MMMM, YYYY, HH:mm')}</span>
      ),
    },
    {
      title: 'Установил приложение?',
      render: (text, item) => <span> {item.hasRegistered ? 'Да' : 'Нет'}</span>,
    },
    {
      title: 'utm',
      render: (_, user) => {
        return (
          <StyledDescriptions column={1}>
            {utmFields?.map((field) =>
              user[field] ? (
                <Descriptions.Item label={field}>
                  {user[field]}
                </Descriptions.Item>
              ) : null
            )}
          </StyledDescriptions>
        )
      },
    },
    {
      title: 'Подробнее',
      width: '20%',
      render: (text, item) => (
        <span>
          <Link to={`/users/${item.id}`}>Подробная информация</Link>
        </span>
      ),
    },
  ]

  return (
    <>
      <Table
        dataSource={dataUsers}
        columns={columns}
        rowKey={(item) => item.id}
        pagination={{
          defaultPageSize: 10,
          total: usersCnt,
          current: page,
          onChange: (page, pageSize) => {
            setPage(page)
          },
          showSizeChanger: false,
        }}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Никнейм/Email/Номер телефона"
              onChange={(e) => {
                setSearchPhoneNumber(e.target.value.toLowerCase())
              }}
            />
          </StyledHeaderContainer>
        )}
      />
    </>
  )
}

SalesUsers.propTypes = {
  dataUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default SalesUsers
