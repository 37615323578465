import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import Zone from './Zone'
import Loading from '../shared/Loading'
import { useLoading } from '../../context/useLoading'
import withMainLayout from '../../hocs/withMainLayout'

const GET_DATA = gql`
  query getData($id: ID!, $clubId: ID!) {
    zone(id: $id) {
      id
      name
      group
      width
      height
      stations {
        id
      }
    }
    club(id: $clubId) {
      id
      name
    }
  }
`
const DELETE_ZONE = gql`
  mutation deleteZone($id: ID!) {
    deleteZone(id: $id)
  }
`

const UPDATE_ZONE = gql`
  mutation updateZone($id: ID!, $input: ZoneInput) {
    updateZone(id: $id, input: $input) {
      id
      name
      group
      width
      height
    }
  }
`
const UPDATE_CLUB = gql`
  mutation updateClub($id: ID!, $input: ClubInput) {
    updateClub(id: $id, input: $input) {
      id
      zones {
        id
        name
        group
        width
        height
      }
    }
  }
`

const GET_ZONES = gql`
  query getZones($id: ID!) {
    club(id: $id) {
      id
      zones {
        id
        name
        group
        width
        height
      }
    }
  }
`
const ZoneContainer = (props) => {
  const { id: clubId, zoneId } = useParams()
  const navigate = useNavigate()
  const { showLoading, hideLoading } = useLoading()
  const [zoneData, setZoneData] = useState(null)
  const [clubData, setClubData] = useState(null)
  const { data, loading, error } = useQuery(GET_DATA, {
    variables: { id: zoneId, clubId },
  })
  const [updateZone] = useMutation(UPDATE_ZONE, {
    onCompleted() {
      hideLoading()
      toast.success('Зона успешно обновлена')
    },
  })
  const [updateClub] = useMutation(UPDATE_CLUB)

  const [deleteZone] = useMutation(DELETE_ZONE, {
    update(cache, { data: { deleteZone: id } }) {
      let { club } = cache.readQuery({
        query: GET_ZONES,
        variables: { id: clubId },
      })
      club = {
        id: club.id,
        zones: club.zones.filter((dataZone) => dataZone.id !== id),
      }
      const zones =
        club && club.zones ? club.zones.map((dataZone) => dataZone.id) : []

      updateClub({ variables: { id: club.id, input: { zones } } })
      cache.writeQuery({
        query: GET_ZONES,
        variables: { id: clubId },
        data: {
          club,
        },
      })
      hideLoading()
      toast.success('Зона успешно удален')
      navigate(`/clubs/${clubId}`)
    },
  })

  useEffect(() => {
    if (data && data.zone && data.club) {
      setZoneData(data.zone)
      setClubData(data.club)
    }
  }, [data, loading, error])

  if (loading)
    return (
      <div>
        <Loading />
      </div>
    )

  const handleUpdateClick = (values) => {
    const dataZone = {
      name: values.name,
      group: parseInt(values.group, 10),
      width: parseInt(values.width, 10),
      height: parseInt(values.height, 10),
    }
    showLoading()
    updateZone({ variables: { id: zoneId, input: dataZone } })
  }

  const handleDeleteClick = () => {
    showLoading()
    deleteZone({ variables: { id: zoneId } })
  }
  return (
    <>
      {data && zoneData && clubData && (
        <Zone
          zone={zoneData}
          club={clubData}
          deleteClick={handleDeleteClick}
          updateClick={handleUpdateClick}
        />
      )}
    </>
  )
}

ZoneContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      zoneId: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

export default withMainLayout(ZoneContainer)
