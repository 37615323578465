import React from 'react'
import { gql, useQuery } from '@apollo/client'

import UserPhotos from './UserPhotos'
import WithMainLayout from '../../hocs/withMainLayout'
import Loading from '../shared/Loading'

const GET_USERS = gql`
  query getUsers {
    usersWithContest {
      id
      username
      nickname
      email
      level {
        id
        name
      }
      sex
    }
  }
`

const UserPhotosContainer = () => {
  const [allUsers, setAllUsers] = React.useState([])
  const { data, loading, error } = useQuery(GET_USERS)

  React.useEffect(() => {
    if (data && !error && !loading) {
      setAllUsers(data.usersWithContest)
    } else {
      console.log('error', error)
    }
  }, [data, loading, error])

  if (loading) {
    return <Loading />
  }

  return <UserPhotos users={allUsers} />
}

export default WithMainLayout(UserPhotosContainer)
