import React, { useState, useEffect } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import Loading from '../shared/Loading'
import withMainLayout from '../../hocs/withMainLayout'
import SupportCategories from './SupportCategories'

const GET_CATEGORIES = gql`
query requestCategories {
	requestCategories {
			_id
			name
	}
}
`
const CREATE_CATEGORY = gql `
mutation createRequestCategory($input: RequestCategoryInput) {
  createRequestCategory(input: $input) {
      _id
      name
  }
}
`

const UPDATE_CATEGORY = gql `
mutation updateRequestCategory($id: ID!, $input: RequestCategoryInput) {
  updateRequestCategory(id: $id, input: $input) {
      id
  }
}
`

const SupportCategoriesContainer = () => {
  const [categories, setCategories] = useState(null)

  const {data: dataCategories, loading: loadingCategories, error: errorCategories, refetch} = useQuery(GET_CATEGORIES)

  const [addCategory] = useMutation(CREATE_CATEGORY,{
    fetchPolicy: "no-cache",
    onCompleted() {
      toast.success("Категория успешно добавлена")
      refetch()
    }
  })

  const [updateCategory] = useMutation(UPDATE_CATEGORY, {
    fetchPolicy: "no-cache",
    onCompleted() {
      toast.success("Категория успешно изменена")
      refetch()
    }
  })

  useEffect(() => {
    if (dataCategories && !loadingCategories && !errorCategories) {
      setCategories(dataCategories?.requestCategories)
    }
  }, [dataCategories, loadingCategories, errorCategories])

  const handleAddCategory = (values) => {
    addCategory({ variables: { input: values} })
  }
  const handleDeleteCategory = (id) => {
    updateCategory({ variables:{ id: id, input: { isDeleted: true } } })
  }
  const handleUpdateCategory = (id, value) => {
    updateCategory({ variables: {id: id, input: value} })
  }

  if (loadingCategories) {
    return <Loading />
  }

  return (
    <>
      <SupportCategories
        categories={categories} 
        handleAddCategory={handleAddCategory} 
        handleDeleteCategory={handleDeleteCategory}
        handleUpdateCategory={handleUpdateCategory}
       />
    </>
  )
}

export default withMainLayout(SupportCategoriesContainer)