import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Button, Descriptions } from 'antd'
import EditForm from '../shared/EditFormModal'

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledDescriptions = styled(Descriptions)`
  width: 400px;
`

const LevelGeneral = ({ level, handleUpdateLevel }) => {
  const [editLevel, setEditLevel] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)

  useEffect(() => {
    if (level) {
      setEditLevel(level)
    }
  }, [level])

  const fields = [
    {
      label: 'Уровень',
      fieldName: 'degree'
    },
    {
      label: 'Название',
      fieldName: 'name'
    },
    {
      label: 'Очки за занятие',
      fieldName: 'attendancePoints'
    },
    {
      label: 'Макс.кол-во очков',
      fieldName: 'maxPoints'
    },
    {
      label: 'Идеальная неделя',
      fieldName: 'visitsForPerfectWeek'
    },
    {
      label: 'Бонусные очки за идеальную неделю',
      fieldName: 'perfectWeekPoints'
    },
    {
      label: 'Штрафные очки за непосещения',
      fieldName: 'punishmentPoints'
    },
    {
      label: 'Очки за ввод веса на упражнение',
      fieldName: 'enterWeightBonusPoints'
    }
  ]

  const handleEditClick = () => {
    setModalVisible(true)
  }

  const editFields = [
    {
      key: 'name',
      label: 'Название уровня',
      value: editLevel && editLevel.name,
      isRequired: true,
      tooltipText: 'Название уровня'
    },
    {
      key: 'degree',
      label: 'Уровень',
      value: editLevel && editLevel.degree,
      number: true,
      isRequired: true
    },
    {
      key: 'attendancePoints',
      label: 'Очки за занятие',
      value: editLevel && editLevel.attendancePoints,
      number: true,
      isRequired: true,
      tooltipText: 'Кол-во очков получаемых за занятие на этом уровне'
    },
    {
      key: 'maxPoints',
      label: 'Максимальное количество очков',
      value: editLevel && editLevel.maxPoints,
      number: true,
      isRequired: true,
      tooltipText: 'Требуемое кол-во очков для перехода на следующий уровень'
    },
    {
      key: 'visitsForPerfectWeek',
      label: 'Количество посещений для "Идеальной недели"',
      value: editLevel && editLevel.visitsForPerfectWeek,
      number: true,
      isRequired: true,
      tooltipText:
        'Требуемое кол-во посещений в неделю для выполнения “Идеальной недели” '
    },
    {
      key: 'perfectWeekPoints',
      label: 'Количество бонусных очков за "Идеальную неделю"',
      value: editLevel && editLevel.perfectWeekPoints,
      number: true,
      isRequired: true,
      tooltipText:
        'Кол-во бонусных очков получаемых за выполнение “Идеальной недели” '
    },
    {
      key: 'punishmentPoints',
      label: 'Количество штрафных очков за пропуски',
      value: editLevel && editLevel.punishmentPoints,
      number: true,
      tooltipText: 'Количество штрафных очков за пропущение больше двух недель'
    },
    {
      key: 'enterWeightBonusPoints',
      label: 'Количество очков за ввод веса во время упражнения',
      value: editLevel && editLevel.enterWeightBonusPoints,
      number: true,
      tooltipText: 'Количество бонусных очков за ввод веса'
    }
  ]

  const handleUpdateClick = values => {
    handleUpdateLevel(values)
    setModalVisible(false)
  }

  return (
    <MainContainer>
      <StyledDescriptions
        title="Описание уровня"
        bordered
        column={1}
        size="small"
        labelStyle={{ width: '200px' }}
        extra={<Button onClick={handleEditClick}>Редактировать</Button>}
      >
        {fields.map(field => (
          <Descriptions.Item label={field.label}>
            {level[field.fieldName]}
          </Descriptions.Item>
        ))}
      </StyledDescriptions>
      {modalVisible && editFields && (
        <EditForm
          title="Редактировать уровень"
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          onUpdate={handleUpdateClick}
          fields={editFields}
        />
      )}
    </MainContainer>
  )
}

LevelGeneral.propTypes = {
  level: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    maxPoints: PropTypes.number,
    attendancePoints: PropTypes.number,
    perfectWeekPoints: PropTypes.number,
    punishmentPoints: PropTypes.number,
    enterWeightBonusPoints: PropTypes.number,
    visitsForPerfectWeek: PropTypes.number
  }).isRequired,
  handleUpdateLevel: PropTypes.func.isRequired
}

export default LevelGeneral
