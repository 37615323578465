import React from 'react'
import PropTypes from 'prop-types'
import { DatePicker, Form, Input, Select, Checkbox, Button } from 'antd'

import showConfirm from '../shared/DeleteConfirm'
import StyledTooltip from './StyledTooltip'

const { TextArea } = Input
const FormItem = Form.Item

const EditForm = ({ onUpdateClick, fields, onDeleteClick }) => {
  const [form] = Form.useForm()

  const onFinish = (values) => {
    onUpdateClick(values)
  }

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />
  }

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        style={{
          maxWidth: 600,
        }}
      >
        {fields.map((field) => (
          <FormItem
            key={field.label}
            label={
              field.tooltipText
                ? getTooltip(field.label, field?.tooltipText)
                : field.label
            }
            name={field.key}
            rules={[
              {
                required: !field.isNotRequired,
                message: `Please give a name to a ${field.label}`,
              },
            ]}
            initialValue={field.value}
          >
            {
              /* eslint-disable */
              field.options ? (
                <Select
                  mode={field.mode || 'single'}
                  placeholder="Choose type"
                  showSearch
                >
                  {field.options.map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              ) : field.checkbox ? (
                <Checkbox />
              ) : field.number ? (
                <Input type="number" />
              ) : field.inputType === 'date' ? (
                <DatePicker />
              ) : field.textArea ? (
                <TextArea row={4} />
              ) : (
                <Input placeholder={field.label} />
              )
              /* eslint-enable */
            }
          </FormItem>
        ))}
        <Form.Item style={{ textAlign: 'right' }}>
          <Button
            danger
            style={{ float: 'left' }}
            onClick={() => {
              showConfirm(() => {
                onDeleteClick()
              })
            }}
          >
            Удалить
          </Button>
          <Button type="primary" htmlType="submit">
            Сохранить изменения
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

EditForm.propTypes = {
  dataToDisplay: PropTypes.shape({}),
  // title: PropTypes.string.isRequired,
  // visible: PropTypes.bool.isRequired,
  // onCancel: PropTypes.func.isRequired,
  // onCreate: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onUpdateClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
}

EditForm.defaultProps = {
  dataToDisplay: {},
}

export default EditForm
