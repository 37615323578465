import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Table, Button, Modal, Form, Select, Input } from 'antd'
import styled from 'styled-components'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

import StyledTooltip from '../shared/StyledTooltip'
import PROGRAM_SET_TYPES from '../../utils/programSetTypes'

const FormItem = Form.Item

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const ProgramSets = ({ programSets, clubs, levels, addProgramSetClick }) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [zones, setZones] = useState(null)
  const [form] = Form.useForm()

  const [data, setData] = useState([])
  const [searchInput, setSearchInput] = useState('')

  useEffect(() => {
    if (programSets) {
      let descriptiveProgramSets = programSets.map((programSet) => {
        const clubName = programSet?.club?.name
        const zoneName = programSet?.zone?.name
        return {
          ...programSet,
          clubName,
          zoneName,
        }
      })
      if (searchInput) {
        descriptiveProgramSets = descriptiveProgramSets.filter((programSet) => {
          return programSet.name?.toLowerCase().includes(searchInput)
        })
      }
      setData(descriptiveProgramSets)
    }
  }, [programSets, searchInput])

  const columns = [
    {
      title: 'Программа',
      dataIndex: 'name',
    },
    {
      title: 'Клуб',
      dataIndex: 'clubName',
    },
    {
      title: 'Зона',
      dataIndex: 'zoneName',
    },
    {
      title: 'Действие',
      key: 'action',
      render: (text, item) => (
        <span>
          <Link to={`/programSets/${item.id}`}>Редактировать</Link>
        </span>
      ),
    },
  ]

  const handleCreate = (values) => {
    addProgramSetClick(values)
    setModalVisible(false)
  }

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />
  }

  return (
    <>
      <Table
        dataSource={data}
        columns={columns}
        rowKey={(item) => item.id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Поиск"
              onChange={(e) => {
                setSearchInput(e.target.value.toLowerCase())
              }}
            />
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined />
              Новая программа
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новая Программа"
        okText="Создать"
        cancelText="Закрыть"
        onCancel={() => setModalVisible(false)}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields()

              handleCreate(values)
            })
            .catch((info) => {
              // eslint-disable-next-line no-console
              console.log('Validate Failed:', info)
            })
        }}
      >
        <Form form={form} layout="vertical">
          <FormItem
            key="name"
            label={getTooltip('Название программы', 'Название программы')}
            name="name"
            rules={[
              {
                required: true,
                message: `Пожалуйста, напишите название программы`,
              },
            ]}
          >
            <Input placeholder="Название" />
          </FormItem>
          <FormItem
            key="shortDescription"
            label={getTooltip(
              'Краткое описание программы',
              'Краткое описание программы'
            )}
            name="shortDescription"
            rules={[
              {
                required: true,
                message: `Пожалуйста, напишите Краткое описание программы`,
              },
            ]}
          >
            <Input placeholder="Краткое описание" />
          </FormItem>
          <FormItem
            key="levels"
            label={getTooltip(
              'Список уровней',
              'Список уровней в которые будет включена эта программа'
            )}
            name="levels"
            rules={[
              {
                required: true,
                message: `Пожалуйста, выберите классы`,
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Выберите классы"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {levels.map((level) => (
                <Select.Option key={level.id} value={level.id}>
                  {level.name}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
          <FormItem
            key="club"
            label={getTooltip(
              'Клуб',
              'Клуб в котором будет проходить данная программа'
            )}
            name="club"
            rules={[
              {
                required: true,
                message: `Пожалуйста, выберите клуб`,
              },
            ]}
          >
            <Select
              placeholder="Выберите клуб"
              showSearch
              onChange={(value) => {
                const club = clubs.find((clubData) => clubData.id === value)
                setZones(club.zones)
              }}
            >
              {clubs.map((club) => (
                <Select.Option key={club.id} value={club.id}>
                  {club.name}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
          <FormItem
            key="zone"
            label={getTooltip(
              'Зона',
              'Зона в которой будет проходить данная программа'
            )}
            name="zone"
            rules={[
              {
                required: true,
                message: `Пожалуйста, выберите зону`,
              },
            ]}
          >
            <Select
              mode="single"
              placeholder="Выберите зону"
              showSearch
              disabled={!zones}
            >
              {zones &&
                zones.map((zone) => (
                  <Select.Option key={zone.id} value={zone.id}>
                    {zone.name}
                  </Select.Option>
                ))}
            </Select>
          </FormItem>
          <FormItem
            key="type"
            label={getTooltip('Тип программы', 'Тип программы')}
            name="type"
            rules={[
              {
                required: true,
                message: `Пожалуйста, выберите тип`,
              },
            ]}
          >
            <Select mode="single" placeholder="Выберите тип" showSearch>
              {PROGRAM_SET_TYPES.map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
        </Form>
      </Modal>
    </>
  )
}

ProgramSets.propTypes = {
  programSets: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      videoUrl: PropTypes.string,
    }).isRequired
  ).isRequired,
  clubs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  levels: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  addProgramSetClick: PropTypes.func.isRequired,
}

export default ProgramSets
