import React, { useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {
  Layout,
  Table,
  Modal,
  Form,
  Button,
  Divider,
  Input,
  DatePicker,
} from 'antd'
import { removeTypenameField } from '../../utils/apollo'

import showConfirm from '../shared/DeleteConfirm'
import StyledTooltip from '../shared/StyledTooltip'

const { Content } = Layout

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledLayout = styled(Layout)`
  background: #fff;
`
const StyledTable = styled(Table)`
  margin-top: 24px;
`

const StyledButton = styled(Button)`
  width: 250px;
`

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`

const StyledFieldContainer = styled.div`
  margin-bottom: 20px;
`

const StyledHeaderText = styled.h3`
  font-weight: 600;
`

const emptyCurrentStage = {
  startTime: dayjs(),
  endTime: dayjs(),
  votingStartTime: dayjs(),
  votingEndTime: dayjs(),
  stageNumber: 1,
}

const ContestStages = ({
  contest,
  handleAddStage,
  handleDeleteStage,
  handleUpdateStage,
}) => {
  dayjs.locale('ru')
  const { stages } = contest
  const [currentStage, setCurrentStage] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)
  const [modalMode, setModalMode] = useState('edit')

  const columns = [
    {
      title: 'Номер стадии',
      dataIndex: 'stageNumber',
    },
    {
      title: 'Начало стадии',
      render: (text, item) => {
        const eventDate = dayjs(item?.startTime).format('Do MMMM, YYYY')
        return <span>{eventDate}</span>
      },
      width: '30%',
    },
    {
      title: 'Начало голосования',
      render: (text, item) => {
        const eventDate = dayjs(item?.votingStartTime).format('Do MMMM, YYYY')
        return <span>{eventDate}</span>
      },
      width: '20%',
    },
    {
      title: 'Конец голосования',
      render: (text, item) => {
        const eventDate = dayjs(item?.votingEndTime).format('Do MMMM, YYYY')
        return <span>{eventDate}</span>
      },
      width: '20%',
    },
    {
      title: 'Конец стадии',
      render: (text, item) => {
        const eventDate = dayjs(item?.endTime).format('Do MMMM, YYYY')
        return <span>{eventDate}</span>
      },
      width: '20%',
    },
    {
      title: 'Действие',
      key: 'action',
      render: (text, item) => {
        return (
          <span>
            {/* eslint-disable */}

            <Link
              onClick={() => {
                setCurrentStage(item)
                setModalVisible(true)
                setModalMode('edit')
              }}
            >
              Редактировать
            </Link>
          </span>
        )
      },
    },
  ]

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />
  }

  const handleInputChange = (value, field) => {
    setCurrentStage({
      ...currentStage,
      [field]: value,
    })
  }

  const parseValues = (values) => {
    const { stageNumber, startTime, endTime, votingStartTime, votingEndTime } =
      values
    return {
      stageNumber: parseInt(stageNumber, 10),
      startTime,
      endTime,
      votingStartTime,
      votingEndTime,
    }
  }

  return (
    <MainContainer>
      <StyledLayout>
        <h2>Список стадии</h2>

        <Content>
          <StyledTable
            dataSource={stages}
            columns={columns}
            rowKey={(item) => item?.id}
            pagination={false}
            title={() => (
              <StyledButton
                type="primary"
                onClick={() => {
                  setCurrentStage(emptyCurrentStage)
                  setModalMode('create')
                  setModalVisible(true)
                }}
              >
                Добавить стадию
              </StyledButton>
            )}
          />
          <Modal
            open={modalVisible}
            title={currentStage?.name}
            okText={modalMode === 'edit' ? 'Сохранить' : 'Создать'}
            cancelText="Отмена"
            onCancel={() => {
              setModalVisible(false)
              setModalMode('edit')
              setCurrentStage(null)
            }}
            onOk={() => {
              if (modalMode === 'edit') {
                handleUpdateStage({
                  id: currentStage.id,
                  values: parseValues(removeTypenameField(currentStage)),
                })
                setModalVisible(false)
              } else {
                handleAddStage({
                  values: parseValues(currentStage),
                })
                setModalVisible(false)
              }
            }}
          >
            <StyledHeaderText>
              Стадия {currentStage?.stageNumber}
            </StyledHeaderText>
            <Divider type="horizontal" />
            <Form layout="vertical">
              <StyledFieldContainer>
                <label>
                  {getTooltip('Номер стадии', 'Номер этапа конкурса: 1, 2, 3')}
                </label>
                <Input
                  type="number"
                  value={currentStage?.stageNumber}
                  onChange={(e) =>
                    handleInputChange(e.target.value, 'stageNumber')
                  }
                />
              </StyledFieldContainer>
              <StyledFieldContainer>
                <label>
                  {getTooltip(
                    'Начало стадии',
                    'Дата когда начинается этап конкурса'
                  )}
                </label>
                <StyledDatePicker
                  showTime
                  placeholder="startTime"
                  value={dayjs(currentStage?.startTime)}
                  onChange={(value) =>
                    handleInputChange(value?.format(), 'startTime')
                  }
                />
              </StyledFieldContainer>
              <StyledFieldContainer>
                <label>
                  {getTooltip(
                    'Начало голосования',
                    'Дата когда начинается голосование'
                  )}
                </label>
                <StyledDatePicker
                  showTime
                  placeholder="votingStartTime"
                  // value={dayjs(currentStage?.votingStartTime)}
                  value={dayjs(currentStage?.votingStartTime)}
                  onChange={(value) =>
                    handleInputChange(value?.format(), 'votingStartTime')
                  }
                />
              </StyledFieldContainer>
              <StyledFieldContainer>
                <label>
                  {getTooltip(
                    'Конец голосования',
                    'Дата когда заканчивается голосование'
                  )}
                </label>
                <StyledDatePicker
                  showTime
                  placeholder="votingEndTime"
                  value={dayjs(currentStage?.votingEndTime)}
                  onChange={(value) =>
                    handleInputChange(value?.format(), 'votingEndTime')
                  }
                />
              </StyledFieldContainer>
              <StyledFieldContainer>
                <label>
                  {getTooltip(
                    'Конец стадии',
                    'Дата когда заканчивается стадие'
                  )}
                </label>
                <StyledDatePicker
                  showTime
                  placeholder="endTime"
                  value={dayjs(currentStage?.endTime)}
                  onChange={(value) =>
                    handleInputChange(value?.format(), 'endTime')
                  }
                />
              </StyledFieldContainer>
            </Form>
            {modalMode === 'edit' && (
              <StyledButton
                danger
                onClick={() => {
                  showConfirm(() => {
                    handleDeleteStage(currentStage?.id)
                    setModalVisible(false)
                  })
                }}
              >
                Удалить стадию
              </StyledButton>
            )}
          </Modal>
        </Content>
      </StyledLayout>
    </MainContainer>
  )
}

ContestStages.propTypes = {
  contest: PropTypes.shape({
    stages: PropTypes.arrayOf(
      PropTypes.shape({
        stageNumber: PropTypes.number,
        startTime: PropTypes.string,
        endTime: PropTypes.string,
        votingStartTime: PropTypes.string,
        votingEndTime: PropTypes.string,
      })
    ),
  }).isRequired,
  handleAddStage: PropTypes.func.isRequired,
  handleDeleteStage: PropTypes.func.isRequired,
  handleUpdateStage: PropTypes.func.isRequired,
}

export default ContestStages
