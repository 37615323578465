import React, { useState } from 'react'

import styled from 'styled-components'

import PropTypes from 'prop-types'
import { Table, Button, Divider, Modal, Select, Input } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import PROGRAM_SET_TYPES from '../../utils/programSetTypes'

import showConfirm from '../shared/DeleteConfirm'

const StyledSelect = styled(Select)`
  width: 320px;
`
const StyledSelectClub = styled(Select)`
  width: 160px;
  margin-left: 20px;
`;

const emptyTask = {
  name: '',
  type: '',
  typeOfClass: '',
  typeOfClassMultiple: [],
  visits: 0,
  countReads: 0,
  club: ''
}

const TASK_TYPES = [
  {
    value: 'visits',
    label: 'Визиты',
  },
  {
    value: 'visitsToClass',
    label: 'Визиты за класс',
  },
  {
    value: 'visitsToClassMultiple',
    label: 'Визиты на один из классов',
  },
  {
    value: 'lecture',
    label: 'Прочитать лекции',
  },
]

const TASK_CLASS = PROGRAM_SET_TYPES.map((programSetType) => ({
  value: programSetType,
  label: programSetType,
}))

const MarathonTasks = ({
  tasks,
  handleAddTask,
  handleDeleteTask,
  handleUpdateTask,
  setChoosedClub,
  choosedClub,
  clubs,
  setClubs
}) => {
  const [display, setDisplay] = useState('create')
  const [editTask, setEditTask] = useState(emptyTask)
  const [modalVisible, setModalVisible] = useState(false)

  const filteredByClub = tasks.filter(item => item.club?.id === choosedClub.id)

  const columns = [
    {
      title: 'Название',
      dataIndex: 'name',
    },
    {
      title: 'Тип',
      dataIndex: 'type',
    },
    {
      title: 'Класс',
      dataIndex: 'typeOfClass',
    },
    {
      title: 'Визиты',
      dataIndex: 'visits',
    },
    {
      title: 'Действие',
      key: 'action',
      render: (text, item) => (
        <span>
          <Button
            type="link"
            onClick={() => {
              setDisplay('edit')
              setEditTask(item)
              setModalVisible(true)
            }}
          >
            Редактировать
          </Button>
        </span>
      ),
    },
  ]

  const onCreate = () => {
    handleAddTask(editTask)
    setModalVisible(false)
  }

  const onUpdate = () => {
    if (editTask?.id) {
      handleUpdateTask(editTask?.id, editTask)
      setModalVisible(false)
    }
  }

  const onDelete = () => {
    showConfirm(() => {
      if (editTask?.id) {
        handleDeleteTask(editTask?.id)
        setModalVisible(false)
      }
    })
  }

  const modalText = display === 'create' ? 'Создать' : 'Редактировать'

  const modalFunction = display === 'create' ? onCreate : onUpdate

  const onCancel = () => {
    setModalVisible(false)
  }

  const handleInputChange = (field, value) => {
    const newEditTask = {
      ...editTask,
      [field]: value,
    }

    setEditTask(newEditTask)
  }

  const handleChangeClub = (value) => {
    const selectedClub = clubs.find(club => club.id === value);
    setChoosedClub(selectedClub);
  };

  return (
    <>
      <Table
        dataSource={filteredByClub}
        columns={columns}
        rowKey={(item) => item?.id}
        title={() => (
          <div>
            <Button
              type="primary"
              onClick={() => {
                setEditTask(emptyTask)
                setModalVisible(true)
                setDisplay('create')
              }}
            >
              <PlusOutlined /> Новое задание
            </Button>
            <StyledSelectClub
              onChange={(item) => handleChangeClub(item)}
              value={choosedClub?.name}
            >
              {clubs.map((club) => (
                <Select.Option value={club.id} key={club.id}>
                  {club.name}
                </Select.Option>
              ))}
            </StyledSelectClub>
          </div>
        )}
      />
      <Modal
        open={modalVisible}
        title={modalText}
        onCancel={onCancel}
        footer={[
          <Button
            danger
            style={{
              float: 'left',
              visibility: display === 'create' && 'hidden',
            }}
            onClick={onDelete}
            key="deleteButton"
          >
            Удалить
          </Button>,
          <Button onClick={onCancel} key="cancelButton">
            Отмена
          </Button>,
          <Button key="submitButton" type="primary" onClick={modalFunction}>
            {modalText}
          </Button>,
        ]}
      >
        <h3> Название задание </h3>
        <Input
          onChange={(e) => handleInputChange('name', e.target.value)}
          value={editTask?.name}
        />
        <Divider />
        <h3> Клуб </h3>
        <StyledSelect
          onChange={(value) => handleInputChange('club', value)}
          value={editTask?.club}
        >
          {clubs.map((taskType) => (
            <Select.Option value={taskType.id} key={taskType.id}>
              {taskType.name}
            </Select.Option>
          ))}
        </StyledSelect>
        <h3> Тип награды </h3>
        <StyledSelect
          onChange={(value) => handleInputChange('type', value)}
          value={editTask?.type}
        >
          {TASK_TYPES.map((taskType) => (
            <Select.Option value={taskType.value} key={taskType.value}>
              {taskType.label}
            </Select.Option>
          ))}
        </StyledSelect>
        <Divider />
        {editTask?.type === 'visitsToClass' && (
          <>
            <h3> Класс который надо посетить </h3>
            <StyledSelect
              onChange={(value) => handleInputChange('typeOfClass', value)}
              value={editTask?.typeOfClass}
            >
              {TASK_CLASS.map((classType) => (
                <Select.Option value={classType.value} key={classType.value}>
                  {classType.label}
                </Select.Option>
              ))}
            </StyledSelect>
            <Divider />
          </>
        )}
        {editTask?.type === 'visitsToClassMultiple' && (
          <>
            <h3> Классы для выбора </h3>
            <StyledSelect
              onChange={(value) =>
                handleInputChange('typeOfClassMultiple', value)
              }
              value={editTask?.typeOfClassMultiple}
              mode="multiple"
            >
              {TASK_CLASS.map((classType) => (
                <Select.Option value={classType.value} key={classType.value}>
                  {classType.label}
                </Select.Option>
              ))}
            </StyledSelect>
            <Divider />
          </>
        )}
        {editTask?.type === 'lecture' && (
          <>
            <h3> Количество лекции для прочтения </h3>
            <Input
              type="number"
              onChange={(e) => handleInputChange('countReads', e.target.value)}
              value={editTask?.countReads}
            />
            <Divider />
          </>
        )}
        {editTask?.type !== 'lecture' && (
          <>
            <h3> Количество визитов </h3>
            <Input
              type="number"
              onChange={(e) => handleInputChange('visits', e.target.value)}
              value={editTask?.visits}
            />
          </>
        )}
      </Modal>
    </>
  )
}

MarathonTasks.propTypes = {
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleAddTask: PropTypes.func.isRequired,
  handleDeleteTask: PropTypes.func.isRequired,
  handleUpdateTask: PropTypes.func.isRequired,
}

export default MarathonTasks
