import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import styled from 'styled-components'

import { Table } from 'antd'

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const AllUserPayments = ({ userPayments, total, setPage, page }) => {
  dayjs.locale('ru')
  const columns = [
    {
      title: 'Клиент',
      width: '15%',
      render: (text, item) => (
        <span>
          <Link to={`/users/${item?.user?.id}`}>{item?.user?.nickname}</Link>
        </span>
      ),
    },
    {
      title: 'Пакет',
      width: '25%',
      render: (_, item) => {
        let text = ''
        if (item?.payment) {
          text = `Покупка ${item?.payment?.countClasses} билетов ${
            item?.payment?.ticketType === 'regular'
              ? 'для клиентов Invictus'
              : 'для всех'
          }`
        }
        if (item?.heroPass) {
          text = `Покупка HeroPass "${item?.heroPass?.name}"`
        }
        if (item?.marathonEvent && item?.type !== 'addedFromHeroPass') {
          text = `Покупка Марафона "${
            item?.marathonEvent?.marathon?.name
          }" c ${dayjs(item?.marathonEvent?.startTime).format(
            'Do MMMM'
          )} до ${dayjs(item?.marathonEvent?.endTime).format('Do MMMM, YYYY')}`
        }
        if (item?.type === 'addedFromHeroPass') {
          text = `Приобретение Марафона через HeroPass "${
            item?.marathonEvent?.marathon?.name
          }" c ${dayjs(item?.marathonEvent?.startTime).format(
            'Do MMMM'
          )} до ${dayjs(item?.marathonEvent?.endTime).format('Do MMMM, YYYY')}`
        }
        return <span>{text}</span>
      },
    },
    {
      title: 'Количество билетов',
      render: (text, item) => {
        let tickets = '0'
        if (item?.payment) {
          tickets = `${item?.payment?.countClasses}`
        }
        if (item?.heroPass) {
          tickets = 'HeroPass'
        }
        if (item?.marathonEvent) {
          tickets = `${item?.marathonEvent?.marathon?.countClasses}`
        }
        return <span>{tickets} </span>
      },
      width: '20%',
    },
    {
      title: 'Цена',
      render: (text, item) => {
        let cost = 0
        if (item?.payment) {
          cost = item?.payment?.cost
        }
        if (item?.heroPass) {
          cost = item?.heroPass?.price
        }
        if (item?.marathonEvent && item?.type !== 'addedFromHeroPass') {
          cost = item?.marathonEvent?.marathon?.price
        }
        return (
          <span>
            {item?.type === 'addedFromHeroPass' ? 'Через HeroPass' : cost}
          </span>
        )
      },
    },
    // {
    //   title: 'Цена',
    //   dataIndex: 'cost',
    //   width: '20%'
    // },
    // {
    //   title: 'Тип билета',
    //   dataIndex: 'ticketType',
    //   width: '20%',
    //   render: text => <span> {text === 'regular' ? 'ЧК' : 'Не ЧК'} </span>
    // },
    {
      title: 'Дата',
      dataIndex: 'created_at',
      render: (text) => <span> {dayjs(text).format('Do MMMM, YYYY')}</span>,
    },
  ]

  return (
    <MainContainer>
      <Table
        dataSource={userPayments}
        columns={columns}
        rowKey={(item) => item.id}
        pagination={{
          defaultPageSize: 10,
          total: total,
          current: page,
          onChange: (page, pageSize) => {
            setPage(page)
          },
          showSizeChanger: false,
        }}
      />
    </MainContainer>
  )
}

AllUserPayments.propTypes = {
  allUserPayments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      userNickname: PropTypes.string,
      countClasses: PropTypes.number,
      cost: PropTypes.number,
      ticketType: PropTypes.string,
    })
  ).isRequired,
}

export default AllUserPayments
