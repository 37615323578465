import React from 'react'
import { Layout, Menu, Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { InfoCircleOutlined, DropboxOutlined } from '@ant-design/icons'
import TreasureChestGeneral from './TreasureChestGeneral'
import TreasureChestRewards from './TreasureChestRewards'

const { Sider, Content } = Layout

const StyledBreadcrumb = styled(Breadcrumb)`
  margin: 16px 0;
`

const StyledHeader = styled.div`
  background: #fff;
  border-bottom: 1px solid #d9d9d9;
  padding: 16px 24px 0;
  display: flex;
  justify-content: space-between;
`

const StyledMenu = styled(Menu)`
  height: 100%;
  padding-top: 16px;
`

const StyledLayout = styled(Layout)`
  padding: 0 24px 24px;
  background: #fff;
`

const StyledContent = styled(Content)`
  padding: 24px;
  margin: 0px;
  min-height: 280px;
`

const TreasureChest = ({
  treasureChest,
  handleUpdateChest,
  handleDeleteChest,
  handleAddReward,
  handleDeleteReward,
  handleUpdateReward,
  treasureChests,
  epicHeroes
}) => {
  const [currentTab, setCurrentTab] = React.useState(1)

  const handleTabChange = activeKey => {
    setCurrentTab(activeKey)
  }

  return (
    <Layout>
      <StyledBreadcrumb>
        <Breadcrumb.Item>
          <Link to="/treasureChests" href="/">
            Сундуки
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item> {treasureChest?.name}</Breadcrumb.Item>
      </StyledBreadcrumb>

      <StyledHeader>
        <h2>Сундук</h2>
      </StyledHeader>

      <Layout>
        <Sider>
          <StyledMenu mode="inline" defaultSelectedKeys={['1']}>
            <Menu.Item
              icon={<InfoCircleOutlined />}
              key="1"
              onClick={() => handleTabChange(1)}
            >
              Основные
            </Menu.Item>
            <Menu.Item
              icon={<DropboxOutlined />}
              key="2"
              onClick={() => handleTabChange(2)}
            >
              Награды
            </Menu.Item>
          </StyledMenu>
        </Sider>
        <StyledLayout>
          <StyledContent className="site-layout-background">
            {currentTab === 1 && (
              <TreasureChestGeneral
                {...{
                  treasureChest,
                  handleUpdateChest,
                  handleDeleteChest
                }}
              />
            )}
            {currentTab === 2 && (
              <TreasureChestRewards
                {...{
                  treasureChests,
                  epicHeroes,
                  handleAddReward,
                  handleDeleteReward,
                  handleUpdateReward,
                  rewards: treasureChest?.rewards
                }}
              />
            )}
          </StyledContent>
        </StyledLayout>
      </Layout>
    </Layout>
  )
}

TreasureChest.propTypes = {
  treasureChest: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    rewards: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired
      })
    ).isRequired
  }).isRequired,
  treasureChests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  epicHeroes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  handleUpdateChest: PropTypes.func.isRequired,
  handleDeleteChest: PropTypes.func.isRequired,
  handleAddReward: PropTypes.func.isRequired,
  handleDeleteReward: PropTypes.func.isRequired,
  handleUpdateReward: PropTypes.func.isRequired
}

export default TreasureChest
