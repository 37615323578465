import React from 'react'
import { Table, Button, Divider } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import EditForm from '../shared/EditFormModal'
import CreateForm from '../shared/CreateForm'

const AutoNotifications = ({
  autoNotifications,
  deleteClick,
  addClick,
  updateClick
}) => {
  const [display, setDisplay] = React.useState('default')
  const [editValue, setEditValue] = React.useState(null)
  const [modalVisible, setModalVisible] = React.useState(false)

  const columns = [
    {
      title: 'Тип автоматического уведомления',
      dataIndex: 'cause'
    },
    {
      title: 'Тип',
      dataIndex: 'type'
    },
    {
      title: 'Заголовок',
      dataIndex: 'heading'
    },
    {
      title: 'Сообщение',
      dataIndex: 'message'
    },
    {
      title: 'Статус',
      render: (_, item) => {
        if (item.isActive) {
          return 'Активен'
        }
        return 'Неактивен'
      }
    },
    {
      title: 'Действие',
      key: 'action',
      render: (text, item) => (
        <span>
          <Button
            type="link"
            onClick={() => {
              setDisplay('edit')
              setEditValue(item)
            }}
          >
            Редактировать
          </Button>
          <Divider type="vertical" />
        </span>
      )
    }
  ]

  const fields = [
    {
      key: 'cause',
      label: 'Тип автоматического уведомления',
      tooltipText: 'Выберите тип автоматического сообщения',
      options: [
        { value: 'levelUp', label: 'levelUp' },
        { value: 'receivedAward', label: 'receivedAward' },
        { value: 'ticket', label: 'ticket' },
        { value: 'longTimeNoSee', label: 'longTimeNoSee' },
        { value: 'finishEvent', label: 'finishEvent' },
        { value: 'eventSoon', label: 'eventSoon' }
      ]
    },
    {
      key: 'type',
      label: 'Тип нотификации',
      options: [
        { value: 'broadcast', label: 'broadcast' },
        { value: 'onlyUsers', label: 'onlyUsers' },
        { value: 'onlyCertainLevel', label: 'onlyCertainLevel' }
      ],
      tooltipText: 'Тип нотификации'
    },
    {
      key: 'heading',
      label: 'Заголовок',
      tooltipText: 'Заголовок уведомления'
    },
    {
      key: 'message',
      label: 'Сообщение',
      tooltipText: 'Сообщение для уведомления'
    },
    {
      key: 'isActive',
      label: 'Статус уведомления ',
      checkbox: true,
      isNotRequired: true,
      tooltipText: 'Статус уведомления(активен/неактивен)'
    }
  ]

  const editFields = [
    {
      key: 'cause',
      label: 'Тип автоматического уведомления',
      tooltipText: 'Выберите тип автоматического сообщения',
      value: editValue && editValue.cause,
      options: [
        { value: 'levelUp', label: 'levelUp' },
        { value: 'receivedAward', label: 'receivedAward' },
        { value: 'ticket', label: 'ticket' },
        { value: 'longTimeNoSee', label: 'longTimeNoSee' },
        { value: 'finishEvent', label: 'finishEvent' },
        { value: 'eventSoon', label: 'eventSoon' }
      ]
    },
    {
      key: 'type',
      label: 'Тип нотификации',
      value: editValue && editValue.type,
      options: [
        { value: 'broadcast', label: 'broadcast' },
        { value: 'onlyUsers', label: 'onlyUsers' },
        { value: 'onlyCertainLevel', label: 'onlyCertainLevel' }
      ],
      tooltipText: 'Тип автоматического уведомления'
    },
    {
      key: 'heading',
      label: 'Заголовок',
      tooltipText: 'Заголовок автоматического уведомления',
      value: editValue && editValue.heading
    },
    {
      key: 'message',
      label: 'Сообщение',
      tooltipText: 'Сообщение для автоматического уведомления',
      value: editValue && editValue.message
    },
    {
      key: 'isActive',
      label: 'Статус уведомления ',
      checkbox: true,
      isNotRequired: true,
      tooltipText: 'Статус автоматического уведомления(активен/неактивен)',
      value: editValue && editValue.isActive
    }
  ]

  const handleCreate = values => {
    addClick(values)
    setModalVisible(false)
  }

  const handleUpdate = values => {
    updateClick(editValue.id, values)
    setDisplay('default')
    setEditValue(null)
  }

  const handleDelete = () => {
    deleteClick(editValue.id)
    setDisplay('default')
    setEditValue(null)
  }

  return (
    <>
      <Table
        dataSource={autoNotifications}
        columns={columns}
        title={() => (
          <div>
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новое автоматическое уведомление
            </Button>
          </div>
        )}
      />
      <CreateForm
        title="Добавить новое автоматическое уведомление"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onCreate={handleCreate}
        fields={fields}
      />
      {display === 'edit' && editValue && (
        <EditForm
          title="Редактировать автоматическое уведомление"
          visible={display === 'edit'}
          onCancel={() => setDisplay('default')}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          fields={editFields}
        />
      )}
    </>
  )
}

export default AutoNotifications
