import React, { useState } from 'react'

import styled from 'styled-components'
import { Link } from 'react-router-dom'

import dayjs from 'dayjs'

import PropTypes from 'prop-types'
import {
  Table,
  Button,
  Divider,
  Modal,
  Select,
  Form,
  Layout,
  DatePicker,
} from 'antd'

import { removeTypenameField } from '../../utils/apollo'
import showConfirm from '../shared/DeleteConfirm'
import StyledTooltip from '../shared/StyledTooltip'

const { Content } = Layout

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledLayout = styled(Layout)`
  background: #fff;
`
const StyledTable = styled(Table)`
  margin-top: 24px;
`

const StyledSelect = styled(Select)`
  width: 320px;
`

const StyledButton = styled(Button)`
  width: 250px;
`

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`

const StyledFieldContainer = styled.div`
  margin-bottom: 20px;
`

const StyledHeaderText = styled.h3`
  font-weight: 600;
`

const emptyMarathonEvent = {
  trainer: null,
  startTime: dayjs(),
  endTime: dayjs(),
}

const MarathonEvents = ({
  marathonEvents,
  marathonEventsCnt,
  page,
  setPage,
  marathonId,
  handleAddMarathonEvent,
  handleUpdateMarathonEvent,
  handleDeleteMarathonEvent,
  trainers,
}) => {
  dayjs.locale('ru')

  const [editMarathonEvent, setEditMarathonEvent] = useState(null)
  const [modalMode, setModalMode] = useState('create')
  const [modalVisible, setModalVisible] = useState(false)

  const columns = [
    {
      title: 'Начало марафона',
      render: (_, item) => {
        const eventDate = dayjs(item?.startTime).format('Do MMMM, YYYY')
        return <span>{eventDate}</span>
      },
      width: '30%',
    },
    {
      title: 'Конец марафона',
      render: (_, item) => {
        const eventDate = dayjs(item?.endTime).format('Do MMMM, YYYY')
        return <span>{eventDate}</span>
      },
      width: '30%',
    },
    {
      title: 'Руководитель',
      render: (_, item) => {
        const trainerName = item?.trainer?.nickname
        return <span> {trainerName}</span>
      },
    },
    {
      title: 'Действие',
      key: 'action',
      render: (text, item) => {
        return (
          <span>
            {/* eslint-disable */}

            <Link
              to={`/marathonEvent/${marathonId}/${item.id}`}
              // onClick={() => {
              //   setEditMarathonEvent({ ...item, trainer: item?.trainer?.id })
              //   setModalVisible(true)
              //   setModalMode('edit')
              // }}
            >
              Редактировать
            </Link>
          </span>
        )
      },
    },
  ]

  const handleInputChange = (field, value) => {
    setEditMarathonEvent({
      ...editMarathonEvent,
      [field]: value,
    })
  }

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />
  }

  return (
    <MainContainer>
      <StyledLayout>
        <h2> Марафоны </h2>
        <Content>
          <StyledTable
            dataSource={marathonEvents}
            columns={columns}
            rowKey={(item) => item?.id}
            pagination={{
              defaultPageSize: 10,
              total: marathonEventsCnt,
              current: page,
              onChange: (page, pageSize) => {
                setPage(page)
              },
              showSizeChanger: false,
            }}
            title={() => (
              <StyledButton
                type="primary"
                onClick={() => {
                  setEditMarathonEvent(emptyMarathonEvent)
                  setModalMode('create')
                  setModalVisible(true)
                }}
              >
                Добавить марафон
              </StyledButton>
            )}
          />
          <Modal
            open={modalVisible}
            title="Marathon event"
            okText={modalMode === 'edit' ? 'Сохранить' : 'Создать'}
            cancelText="Отмена"
            onCancel={() => {
              setModalVisible(false)
              setModalMode('edit')
              setEditMarathonEvent(null)
            }}
            onOk={() => {
              if (modalMode === 'edit') {
                console.log('values', removeTypenameField(editMarathonEvent))
                handleUpdateMarathonEvent({
                  id: editMarathonEvent.id,
                  values: removeTypenameField(editMarathonEvent),
                })
                setModalVisible(false)
              } else {
                console.log('editMarathonEvent', editMarathonEvent)
                handleAddMarathonEvent({
                  values: editMarathonEvent,
                })
                setModalVisible(false)
              }
            }}
          >
            <StyledHeaderText> Марафон </StyledHeaderText>
            <Divider type="horizontal" />
            <Form layout="vertical">
              <StyledFieldContainer>
                <label>
                  {getTooltip(
                    'Начало марафона',
                    'Дата когда начинается марафон'
                  )}
                </label>
                <StyledDatePicker
                  showTime
                  placeholder="marathon event start"
                  value={dayjs(editMarathonEvent?.startTime)}
                  onChange={(value) =>
                    handleInputChange('startTime', value?.format())
                  }
                />
              </StyledFieldContainer>
              <StyledFieldContainer>
                <label>
                  {getTooltip(
                    'Конец марафона',
                    'Дата когда заканчивается марафон'
                  )}
                </label>
                <StyledDatePicker
                  showTime
                  placeholder="marathon event end"
                  value={dayjs(editMarathonEvent?.endTime)}
                  onChange={(value) =>
                    handleInputChange('endTime', value?.format())
                  }
                />
              </StyledFieldContainer>
              <StyledFieldContainer>
                <label>
                  {getTooltip('Руководитель марафона', 'Назначенный тренер')}
                </label>
                <StyledSelect
                  value={editMarathonEvent?.trainer}
                  onChange={(value) => handleInputChange('trainer', value)}
                >
                  {trainers.map((trainer) => (
                    <Select.Option value={trainer?.id} key={trainer?.id}>
                      {trainer?.nickname}
                    </Select.Option>
                  ))}
                </StyledSelect>
              </StyledFieldContainer>
            </Form>
            {/* {modalMode === 'edit' && (
              <StyledButton
                danger
                onClick={() => {
                  showConfirm(() => {
                    console.log('deleting', editMarathonEvent?.id)
                    handleDeleteMarathonEvent({ id: editMarathonEvent?.id })
                    setModalVisible(false)
                  })
                }}
              >
                Удалить стадию
              </StyledButton>
            )} */}
          </Modal>
        </Content>
      </StyledLayout>
    </MainContainer>
  )
}

MarathonEvents.propTypes = {
  marathonEvents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      startTime: PropTypes.string,
      endTime: PropTypes.string,
    })
  ).isRequired,
  handleAddMarathonEvent: PropTypes.func.isRequired,
  handleUpdateMarathonEvent: PropTypes.func.isRequired,
  handleDeleteMarathonEvent: PropTypes.func.isRequired,
  trainers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      nickname: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default MarathonEvents
