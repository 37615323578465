import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Table, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import CreateSeason from './CreateSeason'

const Seasons = ({ seasons, addSeasonClick }) => {
  const [modalVisible, setModalVisible] = useState(false)

  const columns = [
    {
      title: 'Имя',
      dataIndex: 'name'
    },
    {
      title: 'Описание',
      dataIndex: 'description'
    },
    {
      title: 'Действие',
      key: 'action',
      render: item => (
        <span>
          <Link to={`/seasons/${item.id}`}>Редактировать</Link>
        </span>
      )
    }
  ]

  const handleCreate = values => {
    addSeasonClick(values)
    setModalVisible(false)
  }

  return (
    <>
      <Table
        dataSource={seasons}
        columns={columns}
        title={() => (
          <div>
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новый Cезон
            </Button>
          </div>
        )}
      />
      <CreateSeason
        title="Добавить новый сезон"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onCreate={handleCreate}
      />
    </>
  )
}

Seasons.propTypes = {
  seasons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  addSeasonClick: PropTypes.func.isRequired
}

export default Seasons
