import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useParams } from 'react-router-dom'

import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import MarathonEvent from './MarathonEvent'
import Loading from '../shared/Loading'
import { useLoading } from '../../context/useLoading'
import withMainLayout from '../../hocs/withMainLayout'

const GET_MARATHON_EVENT = gql`
  query marathonEvent($id: ID!, $marathonId: ID!) {
    marathonEvent(id: $id) {
      id
      startTime
      endTime
      trainer {
        id
        nickname
      }
    }
    userMarathonEvents(marathonEventId: $id) {
      id
      user {
        id
        nickname
        phoneNumber
        email
      }
      created_at
    }
    marathon(id: $marathonId) {
      id
      name
    }
    usersByRole(role: "trainer") {
      id
      role
      nickname
    }
  }
`

const UPDATE_MARATHON_EVENT = gql`
  mutation updateMarathonEvent($id: ID!, $input: MarathonEventInput) {
    updateMarathonEvent(id: $id, input: $input) {
      id
    }
  }
`

const DELETE_MARATHON_EVENT = gql`
  mutation deleteMarathonEvent($id: ID!) {
    deleteMarathonEvent(id: $id)
  }
`
const GET_USERS = gql`
  query find($query: UsersQuery, $pagination: PaginationQuery) {
    findUsers(queryUsers: $query, pagination: $pagination) {
      users {
        id
        nickname
        email
        phoneNumber
      }
      total
      limit
      page
      pages
    }
  }
`
const PARTICIPATE_USER = gql`
  mutation participateInMarathonEvent($userId: ID!, $marathonEventId: ID!, $clubId: ID!) {
    participateInMarathonEvent(
      userId: $userId
      marathonEventId: $marathonEventId
      clubId: $clubId
    ) {
      id
    }
  }
`
const GET_CLUBS = gql`
  query clubs {
    clubs {
      id
      name
    }
  }
  `

const MarathonEventContainer = () => {
  const { marathonEventId, marathonId } = useParams()
  const [trainers, setTrainers] = useState([])
  const { showLoading, hideLoading } = useLoading()
  const navigate = useNavigate()
  const [marathonEvent, setMarathonEvent] = useState(null)
  const [userMarathonEvents, setUserMarathonEvents] = useState(null)
  const [marathon, setMarathon] = useState(null)
  const [users, setUsers] = useState([])
  const [clubs, setClubs] = useState()

  const [
    getUsers,
    {
      loading: loadingUsers,
      error: errorUsers,
      data: dataUsers,
      refetch: refetchUsers,
    },
  ] = useLazyQuery(GET_USERS)
  const { data, loading, error, refetch } = useQuery(GET_MARATHON_EVENT, {
    variables: {
      id: marathonEventId,
      marathonId,
    },
  })
  const { data: clubsData, loading: clubsLoading } = useQuery(GET_CLUBS)

  useEffect(() => {
    if (
      data &&
      data.marathonEvent &&
      data.usersByRole &&
      data.marathon &&
      data.userMarathonEvents
    ) {
      setMarathonEvent(data.marathonEvent)
      setMarathon(data.marathon)
      setTrainers(data.usersByRole)
      setUserMarathonEvents(data.userMarathonEvents)
    } else if (error) {
      console.log('error', error)
    }
  }, [data, loading, error])

  useEffect(() => {
    if (dataUsers && dataUsers.findUsers) {
      setUsers(dataUsers.findUsers.users)
    } else if (errorUsers) {
      console.log(`error `, errorUsers)
    }
  }, [dataUsers, loadingUsers, errorUsers])

  React.useEffect(() => {
    if (clubsData && !clubsLoading) {
      setClubs(clubsData.clubs)
    }
  }, [clubsData, clubsLoading])
  

  const [updateMarathonEvent] = useMutation(UPDATE_MARATHON_EVENT, {
    onCompleted() {
      hideLoading()
      refetch()
      toast.success('Marathon event updated')
    },
  })

  const handleUpdateMarathonEvent = (values) => {
    showLoading()
    updateMarathonEvent({
      variables: {
        id: marathonEventId,
        input: values,
      },
    })
  }

  const [deleteMarathonEvent] = useMutation(DELETE_MARATHON_EVENT, {
    onCompleted() {
      hideLoading()
      navigate(`/marathons/${marathonId}`)
      toast.success('Marathon event deleted')
    },
  })

  const handleDeleteMarathonEvent = ({ id }) => {
    showLoading()
    deleteMarathonEvent({
      variables: {
        id,
      },
    })
  }

  const [participateUser] = useMutation(PARTICIPATE_USER, {
    onCompleted() {
      hideLoading()
      refetch()
      toast.success('User added')
    },
    onError(error) {
      toast.error(`${error.message}`)
    },
  })

  const handleParticipateUser = (selectedUser, selectedClub) => {
    showLoading()
    participateUser({
      variables: {
        userId: selectedUser,
        marathonEventId,
        clubId: selectedClub
      },
    })
  }
  if (!marathonEvent) {
    return <Loading />
  }

  return (
    <MarathonEvent
      {...{
        marathon,
        marathonEvent,
        userMarathonEvents,
        handleUpdateMarathonEvent,
        handleDeleteMarathonEvent,
        handleParticipateUser,
        trainers,
        getUsers,
        users,
        refetchUsers,
        clubs
      }}
    />
  )
}

MarathonEventContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

export default withMainLayout(MarathonEventContainer)
