import React, { useState, useEffect } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import NewsModals from './NewsModals'
import Loading from '../shared/Loading'
import { useLoading } from '../../context/useLoading'
import withMainLayout from '../../hocs/withMainLayout'

const GET_DATA = gql`
  query getData {
    newsModals {
      id
      title
			imageUrl
      startTime
      endTime
    }
  }
`

const GET_NEWSMODAL = gql`
  query getNewsModals {
    newsModals {
			id
      title
			imageUrl
      startTime
      endTime
    }
  }
`

const DELETE_PROGRAM = gql`
  mutation deleteNewsModal($id: ID!) {
    deleteNewsModal(id: $id)
  }
`

const ADD_PROGRAM = gql`
  mutation addNewsModal($input: NewsModalInput) {
    addNewsModal(input: $input) {
			id
      title
			imageUrl
      startTime
      endTime
    }
  }
`
const UPDATE_PROGRAM = gql`
  mutation updateNewsModal($id: ID!, $input: NewsModalInput) {
    updateNewsModal(id: $id, input: $input) {
			id
      title
			imageUrl
      startTime
      endTime
    }
  }
`

const NewsModalsContainer = () => {
  const [dataNewsModals, setDataNewsModals] = useState(null)
  const { showLoading, hideLoading } = useLoading()

  const { data, loading, error } = useQuery(GET_DATA)

  const [updateNewsModal] = useMutation(UPDATE_PROGRAM, {
    onCompleted: () => {
      hideLoading()
    }
  })

  const [deleteNewsModal] = useMutation(DELETE_PROGRAM, {
    update(cache, { data: { deleteNewsModal: id } }) {
      const { newsModals } = cache.readQuery({ query: GET_NEWSMODAL })
      cache.writeQuery({
        query: GET_NEWSMODAL,
        data: {
          newsModals: newsModals.filter(newsModal => newsModal.id !== id)
        }
      })
      hideLoading()
      toast.success('Новость успешно удален')
    }
  })

  const [addNewsModal] = useMutation(ADD_PROGRAM, {
    update(cache, { data: { addNewsModal: newsModal } }) {
      const { newsModals } = cache.readQuery({ query: GET_NEWSMODAL })
      cache.writeQuery({
        query: GET_NEWSMODAL,
        data: { newsModals: newsModals.concat([newsModal]) }
      })
      hideLoading()
      toast.success('Новость успешно создан')
    }
  })

  useEffect(() => {
    if (data && !loading && !error) {
      setDataNewsModals(data.newsModals)
    }
  }, [data, loading, error])

  const handleDeleteNewsModal = newsModalId => {
    showLoading()
    deleteNewsModal({ variables: { id: newsModalId } })
  }

  const handleAddNewsModal = values => {
    showLoading()
    addNewsModal({
      variables: {
        input: values
      }
    })
  }

  const handleUpdateNewsModal = (newsModalId, values) => {
    showLoading()
    updateNewsModal({ variables: { id: newsModalId, input: values } })
  }

  if (loading && dataNewsModals ) {
    return <Loading />
  }

  return (
    <>
      {dataNewsModals && (
        <NewsModals
          dataNewsModals={dataNewsModals}
          deleteNewsModalClick={handleDeleteNewsModal}
          addNewsModalClick={handleAddNewsModal}
          updateNewsModalClick={handleUpdateNewsModal}
        />
      )}
    </>
  )
}

export default withMainLayout(NewsModalsContainer)
