import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import Chat from '../../images/svg/ChatIcon';
import EyeIcon from '../../images/svg/EyeIcon';
import PhoneIcon from '../../images/svg/PhoneIcon';

const ModalStyled = styled.div`
	position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`
const ModalContent = styled.div`
	background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
	height: 234px;
`
const ButtonStatus = styled.div`
  background-color: #0000001A;
  border-radius: 8px;
  align-items: center;
  padding: 4px 4px 0px 4px;
  cursor: pointer;
`
const Column = styled.div`
	flex-direction: column;
`
const NameEvent = styled.div`
	font-size: 12px;
	font-family: 'GraphikLCGMedium';
`
const Name = styled.div`
	font-size: 10px;	
`
const Row = styled.div`
	display: flex;
`
const StyledInput = styled.input`
	margin-top: 16px;
	width: 258px;
	border-radius: 8px;
	border: 1px solid #0000001A;
	padding-top: 8px;
	padding-bottom: 48px;
`
const ButtonSave = styled.div`
	cursor: pointer;
	background-color: #6F2CFF;
	font-size: 12px;
	border-radius: 8px;
	color: #fff;
	margin-top: 16px;
	align-items: center;
	display: flex;
	justify-content: center;
	padding: 8px 0px 8px 0px;
`
const ButtonClose = styled.div`
	cursor: pointer;
	background-color: #E6E6E6;
	font-size: 12px;
	border-radius: 8px;
	color: black;
	margin-top: 8px;
	align-items: center;
	display: flex;
	justify-content: center;
	padding: 8px 0px 8px 0px;
`

const Modal = ({ 
	type, 
	comment, 
	setComment, 
	setIsModalOpen, 
	setSelectedUser, 
	selectedUser,
	handleChangeStatus,
	handleAddComment,
	user
 }) => {
	const handleComment = (event) => {
    setComment(event.target.value)
  }
	const onClick = () => {
		handleChangeStatus({
			userId: selectedUser?.user?._id, 
			values: {
				[type]: {
					status: !selectedUser[type]?.status,
					sales: selectedUser[type]?.status ? null : user.id, 
					comment: comment
				}
			}
		});
	}
	
	return (
			<ModalStyled>
				<ModalContent>
				<Row>
				<div style={{paddingRight: 8}}>
					{type === 'meetSales' ? 
					<ButtonStatus>
						<EyeIcon opacity={1} />
					</ButtonStatus> : type === 'writeSales' ? 
					<ButtonStatus>
						<Chat /> 
					</ButtonStatus> : 
					<ButtonStatus>
						<PhoneIcon />
					</ButtonStatus>
					}
				</div>
				<Column>
					<NameEvent>
					{type === 'meetSales' ? "Встретился в живую" : type === 'writeSales' ? 'Написал в мессендже' : 'Связался через звонок'}
					</NameEvent>
					<Name>
						Рысалды
					</Name>
				</Column>
					</Row>
					<StyledInput placeholder={'Заметка'} onChange={handleComment} value={comment} />
					<ButtonSave onClick={onClick}>Сохранить</ButtonSave>
					<ButtonClose onClick={()=> {
						setIsModalOpen(false)
						setSelectedUser(null)
					}}>Закрыть</ButtonClose>
				</ModalContent>
			</ModalStyled>
	)
}
export default Modal;