import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { Layout, Menu, Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'
import { InfoCircleOutlined, GatewayOutlined } from '@ant-design/icons'

import EditForm from '../shared/EditForm'
import WeeklyTasks from './WeeklyTasks.container'

const { Header, Sider, Content } = Layout

const StyledBreadcrumb = styled(Breadcrumb)`
  margin: 16px 0;
`

const StyledHeader = styled(Header)`
  background: #fff;
  border-bottom: 1px solid #d9d9d9;
  padding: 0px 24px;
`

const StyledMenu = styled(Menu)`
  height: 100%;
  padding-top: 16px;
`
const StyledLayout = styled(Layout)`
  padding: 0 24px 24px;
  background: #fff;
`

const StyledContent = styled(Content)`
  padding: 24px;
  margin: 0px;
  min-height: 280px;
`
const HeaderTitle = styled.div`
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #262626;
  margin-bottom: 24px;
`

const Season = ({ season, updateClick, deleteClick }) => {
  const [currentTab, setCurrentTab] = useState(1)
  const fields = [
    {
      key: 'name',
      label: 'Название сезона',
      value: season.name,
      isRequired: true,
      tooltipText: 'Название сезона',
    },
    {
      key: 'description',
      label: 'Описание сезона',
      value: season.description,
      isRequired: true,
      tooltipText: 'Описание сезона',
    },
    {
      key: 'subheading',
      label: 'Слоган сезона',
      value: season.subheading,
      textArea: true,
      isRequired: true,
      tooltipText: 'Слоган сезона',
    },
    {
      key: 'bannerURL',
      label: 'Баннер сезона',
      value: season.bannerURL,
      isRequired: true,
      tooltipText: 'Баннер сезона',
    },
    {
      key: 'attendancePoints',
      label: 'Баллы',
      value: season.attendancePoints,
      isRequired: true,
      number: true,
      tooltipText: 'Баллы за визит в этом сезона',
    },
    {
      key: 'perfectWeekPoints',
      label: 'Количество бонусных очков за "Идеальную неделю"',
      value: season.perfectWeekPoints,
      isRequired: true,
      number: true,
      tooltipText:
        'Кол-во бонусных очков получаемых за выполнение “Идеальной недели”',
    },
    {
      key: 'visitsForPerfectWeek',
      label: 'Количество посещений для "Идеальной недели" клана',
      value: season.visitsForPerfectWeek,
      isRequired: true,
      number: true,
      tooltipText:
        'Требуемое кол-во посещений в неделю для выполнения “Идеальной недели” клана',
    },
    {
      key: 'startTime',
      label: 'Начало сезона',
      value: dayjs(season.startTime),
      isRequired: true,
      inputType: 'date',
      tooltipText: 'Начало сезона',
    },
    {
      key: 'endTime',
      label: 'Конец сезона',
      value: dayjs(season.endTime),
      isRequired: true,
      inputType: 'date',
      tooltipText: 'Конец сезона',
    },
  ]
  const handleTabChange = (activeKey) => {
    setCurrentTab(activeKey)
  }

  return (
    <Layout>
      <StyledBreadcrumb>
        <Breadcrumb.Item>
          <Link to="/clubs">Сезоны</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{season?.name}</Breadcrumb.Item>
      </StyledBreadcrumb>
      <StyledHeader>
        <h2>Редактирование Сезона</h2>
      </StyledHeader>
      <Layout>
        <Sider>
          <StyledMenu mode="inline" defaultSelectedKeys={['1']}>
            <Menu.Item
              icon={<InfoCircleOutlined />}
              key="1"
              onClick={() => handleTabChange(1)}
            >
              Основные
            </Menu.Item>
            <Menu.Item
              icon={<GatewayOutlined />}
              key="2"
              onClick={() => handleTabChange(2)}
            >
              Недельные задачи
            </Menu.Item>
          </StyledMenu>
        </Sider>
        <StyledLayout>
          <StyledContent className="site-layout-background">
            {currentTab === 1 && (
              <>
                <HeaderTitle>Основные</HeaderTitle>
                <EditForm
                  fields={fields}
                  onUpdateClick={updateClick}
                  onDeleteClick={deleteClick}
                />
              </>
            )}
            {currentTab === 2 && <WeeklyTasks seasonId={season.id} />}
          </StyledContent>
        </StyledLayout>
      </Layout>
    </Layout>
  )
}

Season.propTypes = {
  season: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    subheading: PropTypes.string,
    bannerURL: PropTypes.string,
    attendancePoints: PropTypes.number,
    perfectWeekPoints: PropTypes.number,
    visitsForPerfectWeek: PropTypes.number,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
  }).isRequired,
  updateClick: PropTypes.func.isRequired,
  deleteClick: PropTypes.func.isRequired,
}
export default Season
