import React from 'react'
import PropTypes from 'prop-types'
import { gql, useQuery } from '@apollo/client'

import { Table } from 'antd'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import 'dayjs/locale/ru'

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const GET_DATA = gql`
  query getData($userId: ID!) {
    userInventory(userId: $userId) {
      id
      items {
        id
        type
        created_at
        updated_at
        usedDate
        ... on TreasureChestItem {
          id
          treasureChest {
            id
            name
          }
          droppedReward {
            id
            type
            name
            changeValue
            ... on RewardTreasureChest {
              id
              name
            }
            ... on RewardEpicHero {
              id
              epicHero {
                id
                name
              }
            }
          }
          isUsed
        }
        ... on EpicHeroItem {
          id
          epicHero {
            id
            name
          }
        }
      }
    }
  }
`

const UserInventoryItems = ({ userId }) => {
  const [userInventoryItems, setUserInventoryItems] = React.useState([])
  const { data, error, loading, refetch } = useQuery(GET_DATA, {
    variables: { userId: userId },
    fetchPolicy: 'no-cache',
  })

  React.useEffect(() => {
    if (data && data.userInventory && !loading) {
      setUserInventoryItems(data?.userInventory?.items)
    } else if (error) {
      console.log(`error `, error)
    }
  }, [data, loading, error])

  const getItemName = (item) => {
    switch (item.type) {
      case 'treasureChest':
        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link to={`/treasureChests/${item?.treasureChest?.id}`}>
            {`Сундук: ${item?.treasureChest?.name}`}
          </Link>
        )

      case 'epicHero':
        return `Герой ${item?.epicHero?.name}`
      default:
        return ''
    }
  }

  const getRewardName = (reward) => {
    if (!reward) {
      return ''
    }

    switch (reward.type) {
      case 'dumbbell':
        return `Гантели: ${reward.changeValue}`

      case 'ticket':
        return `Билеты: ${reward.changeValue}`

      case 'guestTicket':
        return `Гостевые билеты: ${reward.changeValue}`

      case 'treasureChest':
        return reward.name

      case 'epicHero':
        return reward.name

      default:
        return ''
    }
  }

  const columns = [
    {
      title: 'Предмет',
      width: '20%',
      render: (_, item) => {
        return <span> {getItemName(item)}</span>
      },
    },
    {
      title: 'Дата получения предмета',
      render: (_, item) => {
        return (
          <span> {dayjs(item?.created_at).format('Do MMMM HH:mm, YYYY')}</span>
        )
      },
    },
    {
      title: 'Использован?',
      dataIndex: 'isUsed',
      render: (text) => {
        if (text) {
          return 'Да'
        }
        return 'Нет'
      },
    },
    {
      title: 'Выпало',
      width: '30%',
      render: (_, item) => {
        return <span> {getRewardName(item?.droppedReward)}</span>
      },
    },
    {
      title: 'Дата использования предмета',
      render: (_, item) => {
        if (item.isUsed) {
          return (
            <span>
              {' '}
              {dayjs(item?.usedDate || item?.updated_at).format(
                'Do MMMM HH:mm, YYYY'
              )}
            </span>
          )
        }
        return ''
      },
    },
  ]

  return (
    <MainContainer>
      <h2> Инвентарь </h2>
      <Table
        dataSource={userInventoryItems}
        columns={columns}
        loading={loading}
        rowKey={(item) => item.id}
      />
    </MainContainer>
  )
}

UserInventoryItems.propTypes = {
  userInventoryItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string,
    })
  ).isRequired,
}

export default UserInventoryItems
