import { Button, Modal } from 'antd'
import React from 'react'
import styled from 'styled-components'

const ButtonOk = styled.button`
  width: 100%;
  background-color: rgba(111, 44, 255, 1);
  border-radius: 8px;
  border: none;
  height: 56px;
  color: rgba(255, 255, 255, 1);
  font-family: 'GraphikLCGMedium';
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
  &:hover {
    transition: all 0.3s;
    border: 1px solid rgba(111, 44, 255, 1);
    background-color: white;
    color: rgba(111, 44, 255, 1);
  }
`
const Input = styled.input`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 100%;
  padding: 10px;
  font-family: 'GraphikLCGMedium';
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 8px;
  &:focus {
    outline: none;
    border: 1px solid #6f2cff;
  }
`

const CommentModal = ({
  modalComment,
  setModalComment,
  comment,
  setComment,
  HandleAddNote,
  idUserMarathonEvent,
}) => {
  const handleComment = (event) => {
    setComment(event.target.value)
  }

  return (
    <Modal
      open={modalComment}
      centered
      footer={null}
      width={368}
      onCancel={() => setModalComment(false)}
    >
      <div style={{ paddingBottom: 10 }}>Введите комментарий</div>
      <Input
        type="text"
        style={{ height: 80 }}
        value={comment}
        onChange={handleComment}
        placeholder={'Комментарий'}
      />
      <ButtonOk
        onClick={() => {
          {
            HandleAddNote({ id: idUserMarathonEvent, values: comment })
            setModalComment(false)
          }
        }}
      >
        Подтверждаю
      </ButtonOk>
    </Modal>
  )
}

export default CommentModal
