import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Layout, Menu, Breadcrumb, Form, Input, Button } from 'antd'
import { Link } from 'react-router-dom'
import { InfoCircleOutlined, GatewayOutlined } from '@ant-design/icons'

import showConfirm from '../shared/DeleteConfirm'
import StyledTooltip from '../shared/StyledTooltip'
import EditForm from '../shared/EditForm'
import Zones from '../zones/Zones.container'
const FormItem = Form.Item

const { Header, Sider, Content } = Layout

const StyledBreadcrumb = styled(Breadcrumb)`
  margin: 16px 0;
`

const StyledHeader = styled(Header)`
  background: #fff;
  border-bottom: 1px solid #d9d9d9;
  padding: 0px 24px;
`

const StyledMenu = styled(Menu)`
  height: 100%;
  padding-top: 16px;
`
const StyledLayout = styled(Layout)`
  padding: 0 24px 24px;
  background: #fff;
`

const StyledContent = styled(Content)`
  padding: 24px;
  margin: 0px;
  min-height: 280px;
`
const HeaderTitle = styled.div`
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #262626;
  margin-bottom: 24px;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Club = ({ club, updateClick, deleteClick }) => {
  const [form] = Form.useForm()
  const [currentTab, setCurrentTab] = useState(1)

  const handleTabChange = (activeKey) => {
    setCurrentTab(activeKey)
  }

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />
  }

  const onFinish = (values) => {
    const { locksCount } = values
    updateClick({
      ...values,
      locksCount: {
        male: parseInt(locksCount?.male),
        female: parseInt(locksCount?.female),
      },
    })
  }

  const onDelete = () => {
    showConfirm(() => {})
  }

  return (
    <Layout>
      <StyledBreadcrumb>
        <Breadcrumb.Item>
          <Link to="/clubs">Клубы</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{club?.name}</Breadcrumb.Item>
      </StyledBreadcrumb>
      <StyledHeader>
        <h2>Редактирование клуба</h2>
      </StyledHeader>
      <Layout>
        <Sider>
          <StyledMenu mode="inline" defaultSelectedKeys={['1']}>
            <Menu.Item
              icon={<InfoCircleOutlined />}
              key="1"
              onClick={() => handleTabChange(1)}
            >
              Основные
            </Menu.Item>
            <Menu.Item
              icon={<GatewayOutlined />}
              key="2"
              onClick={() => handleTabChange(2)}
            >
              Зоны
            </Menu.Item>
          </StyledMenu>
        </Sider>
        <StyledLayout>
          <StyledContent className="site-layout-background">
            {currentTab === 1 && (
              <>
                <HeaderTitle>Основные</HeaderTitle>
                <Form
                  form={form}
                  layout="vertical"
                  hideRequiredMark
                  onFinish={onFinish}
                >
                  <FormItem
                    key="name"
                    label={getTooltip('Название', 'Название клуба')}
                    name="name"
                    initialValue={club?.name}
                  >
                    <Input placeholder="Название" />
                  </FormItem>
                  <FormItem
                    key="country"
                    label={getTooltip(
                      'Страна',
                      'Страна в которой находится клуб'
                    )}
                    initialValue={club?.country}
                    name="country"
                  >
                    <Input placeholder="Страна" />
                  </FormItem>
                  <FormItem
                    key="city"
                    label={getTooltip(
                      'Город',
                      'Город в которой находится клуб'
                    )}
                    initialValue={club?.city}
                    name="city"
                  >
                    <Input placeholder="Город" />
                  </FormItem>
                  <FormItem
                    key="tunnel"
                    initialValue={club?.tunnel}
                    rules={[
                      {
                        required: true,
                        message: 'Укажите туннель',
                      },
                    ]}
                    label={getTooltip('Туннель', 'Туннель для шкафчика')}
                    name="tunnel"
                  >
                    <Input placeholder="Туннель" />
                  </FormItem>
                  <FormItem
                    label={getTooltip('Кол-во шкафчиков', 'Кол-во шкафчиков')}
                  >
                    <Input.Group compact>
                      <Form.Item
                        initialValue={club?.locksCount?.male}
                        name={['locksCount', 'male']}
                        noStyle
                        rules={[
                          {
                            required: true,
                            message: 'Укажите кол-во мужских шкафчиков',
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          style={{
                            width: '50%',
                          }}
                          placeholder="Мужской"
                        />
                      </Form.Item>
                      <Form.Item
                        name={['locksCount', 'female']}
                        initialValue={club?.locksCount?.female}
                        noStyle
                        rules={[
                          {
                            required: true,
                            message: 'Укажите кол-во женских шкафчиков',
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          style={{
                            width: '50%',
                          }}
                          placeholder="Женский"
                        />
                      </Form.Item>
                    </Input.Group>
                  </FormItem>
                  <Form.Item>
                    <ButtonContainer>
                      <Button danger onClick={onDelete}>
                        Удалить
                      </Button>
                      <Button type="primary" htmlType="submit">
                        Сохранить
                      </Button>
                    </ButtonContainer>
                  </Form.Item>
                </Form>
              </>
            )}
            {currentTab === 2 && <Zones clubId={club.id} />}
          </StyledContent>
        </StyledLayout>
      </Layout>
    </Layout>
  )
}
Club.propTypes = {
  club: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
  updateClick: PropTypes.func.isRequired,
  deleteClick: PropTypes.func.isRequired,
}
export default Club
