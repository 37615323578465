import React from 'react'
import { gql, useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import TrainerEvent from './TrainerEvent'
import Loading from '../../shared/Loading'
import withMainLayout from '../../../hocs/withMainLayout'

const GET_EVENT = gql`
  query event($id: ID!) {
    event(id: $id) {
      id
      startTime
      endTime
      trainer {
        id
        role
        nickname
      }
      reviews {
        id
        value
        comment
      }
      programSet {
        id
        name
        type
        programs {
          id
          name
          level {
            degree
          }
        }
        zone {
          group
        }
        club {
          id
          name
        }
        stationExercises {
          id
          station {
            id
            number
          }
          exercise {
            id
            name
          }
        }
      }
    }
  }
`

const GET_BOOKINGS = gql`
  query bookingsByEvent($input: BookingFilterInput) {
    bookingsByEvent(input: $input) {
      id
      user {
        id
        nickname
      }
      identifier
      event {
        id
      }
      group
      exercise {
        name
      }
      station {
        icon
      }
    }
  }
`

const TrainerEventContainer = () => {
  const { id: eventId } = useParams()
  const [event, setEvent] = React.useState({})
  const [stations, setStations] = React.useState([])
  const [reviews, setReviews] = React.useState([])
  const [objectIdentifier, setObjectIdentifier] = React.useState({})

  const { data, loading } = useQuery(GET_EVENT, {
    variables: {
      id: eventId,
    },
  })

  const { data: bookingsData, loading: bookingsLoading } = useQuery(
    GET_BOOKINGS,
    {
      variables: {
        input: { event: eventId },
      },
    }
  )

  React.useEffect(() => {
    if (bookingsData && !bookingsLoading) {
      const obj = {}
      for (let i = 0; i < bookingsData.bookingsByEvent.length; i += 1) {
        const { identifier } = bookingsData.bookingsByEvent[i]
        const { nickname, id: userId } = bookingsData.bookingsByEvent[i].user
        obj[identifier] = { nickname, userId }
        setObjectIdentifier(obj)
      }
    }
  }, [bookingsData])

  React.useEffect(() => {
    if (data && !loading) {
      setEvent(data.event)
      setReviews(data.event.reviews)
    }
  }, [data, loading])

  React.useEffect(() => {
    const arr = []
    for (let j = 0; j < event.programSet?.stationExercises.length; j += 1) {
      for (let i = 0; i < event.programSet?.zone.group; i += 1) {
        arr.push({
          ...event.programSet?.stationExercises[j],
          identifier: `${
            event.programSet?.stationExercises[j].station.number
          }${String.fromCharCode(65 + i)}`,
          zoneNumber: i + 1,
        })
      }
    }
    setStations(arr)
  }, [event])

  const stationBookings = stations.map((item) => {
    return {
      ...item,
      eventId: item.id,
      id: `${item.id}${item.identifier}`,
      station: item.identifier,
      stationName: item.exercise.name,
      nickname: objectIdentifier[item.identifier]?.nickname || 'Свободно',
      userId: objectIdentifier[item.identifier]?.userId,
      stationId: item.station.id,
      exerciseId: item.exercise.id,
    }
  })

  if (loading || bookingsLoading) {
    return <Loading />
  }

  return (
    <TrainerEvent
      event={event}
      stationBookings={stationBookings}
      reviews={reviews}
    />
  )
}

TrainerEventContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
}

export default withMainLayout(TrainerEventContainer)
