import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import AutoNotifications from './AutoNotifications'
import Loading from '../shared/Loading'
import { useLoading } from '../../context/useLoading'
import withMainLayout from '../../hocs/withMainLayout'

const GET_AUTO_NOTIFICATIONS = gql`
  query autoNotifications {
    autoNotifications {
      id
      cause
      type
      heading
      message
      isActive
    }
  }
`

const DELETE_AUTO_NOTIFICATION = gql`
  mutation deleteAutoNotification($id: ID!) {
    deleteAutoNotification(id: $id)
  }
`

const UPDATE_AUTO_NOTIFICATION = gql`
  mutation updateAutoNotification($id: ID!, $input: AutoNotificationInput) {
    updateAutoNotification(id: $id, input: $input) {
      id
      cause
      isActive
    }
  }
`

const ADD_AUTO_NOTIFICATION = gql`
  mutation addAutoNotifition($input: AutoNotificationInput) {
    addAutoNotification(input: $input) {
      id
      type
      heading
      message
      cause
    }
  }
`

const AutoNotificationsContainer = () => {
  const [dataAutoNotifications, setDataAutoNotifications] = React.useState(null)
  const { showLoading, hideLoading } = useLoading()

  const { data, loading, error } = useQuery(GET_AUTO_NOTIFICATIONS)

  const [updateAutoNotification] = useMutation(UPDATE_AUTO_NOTIFICATION)

  const [deleteAutoNotification] = useMutation(DELETE_AUTO_NOTIFICATION, {
    update(cache, { data: { deleteAutoNotification: id } }) {
      const { autoNotifications } = cache.readQuery({
        query: GET_AUTO_NOTIFICATIONS
      })
      cache.writeQuery({
        query: GET_AUTO_NOTIFICATIONS,
        data: {
          autoNotifications: autoNotifications.filter(
            autoNotification => autoNotification.id !== id
          )
        }
      })
      hideLoading()
      toast.success('Автоматическое уведомление успешно удалено')
    }
  })

  const [addAutoNotification] = useMutation(ADD_AUTO_NOTIFICATION, {
    update(cache, { data: { addAutoNotification: autoNotification } }) {
      const { autoNotifications } = cache.readQuery({
        query: GET_AUTO_NOTIFICATIONS
      })
      cache.writeQuery({
        query: GET_AUTO_NOTIFICATIONS,
        data: {
          autoNotifications: autoNotifications.concat([autoNotification])
        }
      })
      hideLoading()
      toast.success('Автоматическая нотификация успешно создана')
    }
  })

  React.useEffect(() => {
    if (data && !loading && !error) {
      setDataAutoNotifications(data.autoNotifications)
    }
  }, [data, loading, error])

  const handleDeleteAutoNotification = autoNotificationId => {
    showLoading()
    deleteAutoNotification({ variables: { id: autoNotificationId } })
  }

  const handleUpdateAutoNotification = (id, values) => {
    updateAutoNotification({ variables: { id, input: values } })
  }

  const handleAddAutoNotification = values => {
    showLoading()
    addAutoNotification({
      variables: {
        input: values
      }
    })
  }

  if (loading && !dataAutoNotifications) {
    return <Loading />
  }

  return (
    <AutoNotifications
      autoNotifications={dataAutoNotifications}
      deleteClick={handleDeleteAutoNotification}
      addClick={handleAddAutoNotification}
      updateClick={handleUpdateAutoNotification}
    />
  )
}

export default withMainLayout(AutoNotificationsContainer)
