import React from 'react'
import { gql, useQuery, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'

import Seasons from './Seasons'
import withMainLayout from '../../hocs/withMainLayout'
import Loading from '../shared/Loading'
import { useLoading } from '../../context/useLoading'

const GET_SEASONS = gql`
  query getSeasons {
    seasons {
      id
      name
      description
      subheading
      bannerURL
      startTime
      endTime
    }
  }
`

const ADD_SEASON = gql`
  mutation addSeason($input: SeasonInput) {
    addSeason(input: $input) {
      id
      name
      description
      subheading
      bannerURL
      startTime
      endTime
    }
  }
`

const SeasonsContainer = () => {
  const [dataSeasons, setDataSeasons] = React.useState(null)
  const { showLoading, hideLoading } = useLoading()
  const { data, loading, error } = useQuery(GET_SEASONS)

  React.useEffect(() => {
    if (data && !loading && !error) {
      setDataSeasons(data.seasons)
    }
  }, [data, loading, error])

  const [addSeason] = useMutation(ADD_SEASON, {
    update(cache, { data: { addSeason: season } }) {
      const { seasons } = cache.readQuery({ query: GET_SEASONS })
      cache.writeQuery({
        query: GET_SEASONS,
        data: { seasons: seasons.concat([season]) }
      })
      hideLoading()
      toast.success('Событие успешно создано')
    }
  })

  const handleAddSeason = values => {
    showLoading()
    const dataSeason = {
      visitsForPerfectWeek: parseInt(values.visitsForPerfectWeek, 10),
      perfectWeekPoints: parseInt(values.perfectWeekPoints, 10),
      attendancePoints: parseInt(values.attendancePoints, 10)
    }
    delete values.visitsForPerfectWeek
    delete values.perfectWeekPoints
    delete values.attendancePoints
    addSeason({
      variables: {
        input: { ...values, ...dataSeason }
      }
    })
  }

  if (loading && !dataSeasons) {
    return <Loading />
  }

  return <Seasons seasons={dataSeasons} addSeasonClick={handleAddSeason} />
}

export default withMainLayout(SeasonsContainer)
