import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {
  Breadcrumb,
  Layout,
  Table,
  Modal,
  Form,
  Select,
  Button,
  Divider,
  Input,
} from 'antd'

// import {
//   sortableContainer,
//   sortableElement,
//   sortableHandle
// } from 'react-sortable-hoc'
import {
  MenuOutlined,
  PlusOutlined,
  SearchOutlined,
  CopyOutlined,
  DeleteOutlined,
} from '@ant-design/icons'

import icons from '../shared/icons'

import IntervalsTable from './IntervalsTable'
import showConfirm from '../shared/DeleteConfirm'
import StyledTooltip from '../shared/StyledTooltip'

const { Content } = Layout

const FormItem = Form.Item

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledLayout = styled(Layout)`
  background: #fff;
`
const StyledTable = styled(Table)`
  margin-top: 24px;
`

const DetailsIcon = styled.img`
  width: 32px;
  height: 32px;
`

const StyledSelect = styled(Select)`
  width: 100%;
  margin-bottom: 24px;
  margin-top: 18px;
`

const StyledHeaderText = styled.h3`
  font-weight: 600;
`

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
`

const ButtonStyled = styled(Button)`
  border: none;
  background: none;
`

const ExpandedRowStyled = styled.div`
  display: flex;
  justify-content: space-between;
`
const ExpandedRowTitle = styled.div``

const ExpandedRowAction = styled.div``

// const DragHandle = sortableHandle(() => (
//   <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
// ));

const ProgramSetBotcamp = ({ programSet, exercises, onUpdate }) => {
  const [form] = Form.useForm()

  const [modalVisible, setModalVisible] = useState(false)
  const [currentRound, setCurrentRound] = useState(null)
  const [modalMode, setModalMode] = useState('edit')
  const [currentStation, setCurrentStation] = useState(null)
  const [currentExercise, setCurrentExercise] = useState(null)
  const [descriptiveStations, setDescriptiveStations] = useState([])
  const [descriptiveRounds, setDescriptiveRounds] = useState([])

  const [visibleCreateRound, setVisibleCreateRound] = useState(false)
  const [visibleCreateRest, setVisibleCreateRest] = useState(false)
  const [currentRoundId, setCurrentRoundId] = useState(null)
  const [currentStationIndex, setCurrentStationIndex] = useState(null)
  const [visibleEditInterval, setVisibleEditInterval] = useState(false)
  const [editInterval, setEditInterval] = useState(null)
  const [station, setStation] = useState(null)
  const stations = programSet?.zone?.stations
  const { bootcampRounds } = programSet

  useEffect(() => {
    const descriptiveRoundsNew = bootcampRounds.map((bootcampRound) => {
      return {
        ...bootcampRound,
        stations: stations,
      }
    })
  }, [bootcampRounds])

  // const SortableItem = sortableElement(props => <tr {...props} />)

  // const SortableContainer = sortableContainer(props => <tbody {...props} />)

  // const onSortEnd = ({ oldIndex, newIndex }) => {
  //   const descriptiveStationsNew = descriptiveStations
  //   if (oldIndex !== newIndex) {
  //     const newData = arrayMove(
  //       [].concat(descriptiveStationsNew),
  //       oldIndex,
  //       newIndex
  //     ).filter(el => !!el)

  //     setDescriptiveStations(newData)
  //     // updateStationExercisesOrder(newData)
  //   }
  // }

  // const DraggableContainer = (props) => (
  //   <SortableContainer
  //     useDragHandle
  //     disableAutoscroll
  //     helperClass="row-dragging"
  //     // onSortEnd={onSortEnd}
  //     {...props}
  //   />
  // );

  // const DraggableBodyRow = ({ className, style, ...restProps }) => {
  //   const dataSource = descriptiveStations;
  //   // function findIndex base on Table rowKey props and should always be a right array index
  //   const index = dataSource.findIndex(
  //     (x) => x.id === restProps["data-row-key"]
  //   );
  //   return <SortableItem index={index} {...restProps} />;
  // };

  const onCreateRound = (value) => {
    let bootcampRoundsOld = []
    if (programSet.bootcampRounds.length > 0) {
      bootcampRoundsOld = programSet.bootcampRounds.map((bootcampRound) => {
        const stationDetailsOld = bootcampRound.stationDetails.map(
          (stationDetail) => {
            const intervalExercisesOld = stationDetail.intervalExercises.map(
              (intervalExercise) => {
                return {
                  exercise: intervalExercise?.exercise?.id,
                  isUnlimited: intervalExercise?.isUnlimited,
                  beginnerSpeed: intervalExercise?.beginnerSpeed,
                  intermediateSpeed: intervalExercise?.intermediateSpeed,
                  athleteSpeed: intervalExercise?.athleteSpeed,
                  proSpeed: intervalExercise?.proSpeed,
                  reps: intervalExercise?.reps,
                  isRest: intervalExercise?.isRest,
                }
              }
            )
            return {
              station: stationDetail.station?.id,
              intervalExercises: intervalExercisesOld,
            }
          }
        )
        return {
          intervalCount: bootcampRound.intervalCount,
          intervalDuration: bootcampRound.intervalDuration,
          isRest: bootcampRound.isRest,
          stationDetails: stationDetailsOld,
        }
      })
    }
    const intervalCount = parseInt(value.intervalCount)
    const intervalDuration = parseInt(value.intervalDuration)
    const stationDetails = stations.map((stationData) => {
      let i = 0
      let intervalExercisesNew = []
      while (i < intervalCount) {
        intervalExercisesNew.push({
          beginnerSpeed: 0,
          intermediateSpeed: 0,
          athleteSpeed: 0,
          proSpeed: 0,
          isRest: true,
          exercise: null,
          reps: 0,
          isRest: true,
        })

        i = i + 1
      }
      return {
        station: stationData?.id,
        intervalExercises: intervalExercisesNew,
      }
    })
    const bootcampRoundsNew = {
      intervalCount: intervalCount,
      intervalDuration: intervalDuration,
      isRest: false,
      stationDetails: stationDetails,
    }
    onUpdate({
      bootcampRounds:
        programSet.bootcampRounds.length > 0
          ? bootcampRoundsOld.concat([bootcampRoundsNew])
          : programSet.bootcampRounds.concat([bootcampRoundsNew]),
    })
  }

  const onCreateRest = (value) => {
    let bootcampRoundsOld = []
    if (programSet.bootcampRounds.length > 0) {
      bootcampRoundsOld = programSet.bootcampRounds.map((bootcampRound) => {
        const stationDetailsOld = bootcampRound.stationDetails.map(
          (stationDetail) => {
            const intervalExercisesOld = stationDetail.intervalExercises.map(
              (intervalExercise) => {
                return {
                  exercise: intervalExercise?.exercise?.id,
                  isUnlimited: intervalExercise?.isUnlimited,
                  beginnerSpeed: intervalExercise?.beginnerSpeed,
                  intermediateSpeed: intervalExercise?.intermediateSpeed,
                  athleteSpeed: intervalExercise?.athleteSpeed,
                  proSpeed: intervalExercise?.proSpeed,
                  reps: intervalExercise?.reps,
                  isRest: intervalExercise?.isRest,
                }
              }
            )
            return {
              station: stationDetail.station?.id,
              intervalExercises: intervalExercisesOld,
            }
          }
        )
        return {
          intervalCount: bootcampRound.intervalCount,
          intervalDuration: bootcampRound.intervalDuration,
          isRest: bootcampRound.isRest,
          stationDetails: stationDetailsOld,
        }
      })
    }
    const intervalDuration = parseInt(value.intervalDuration)
    const bootcampRoundsNew = {
      intervalDuration: intervalDuration,
      isRest: true,
    }

    onUpdate({
      bootcampRounds:
        programSet.bootcampRounds.length > 0
          ? bootcampRoundsOld.concat([bootcampRoundsNew])
          : programSet.bootcampRounds.concat([bootcampRoundsNew]),
    })
  }

  const onCreateExercise = ({ newArray }) => {
    let bootcampRoundsOld = []
    bootcampRoundsOld = programSet.bootcampRounds.map((bootcampRound) => {
      if (bootcampRound.id === currentRoundId) {
        const stationDetailsOld = bootcampRound.stationDetails.map(
          (stationDetail) => {
            let oldIntervalFilter = []
            oldIntervalFilter = stationDetail.intervalExercises.map((el) => {
              return {
                exercise: el?.exercise?.name ? el.exercise?.id : el.exercise,
                isUnlimited: el?.isUnlimited,
                beginnerSpeed: parseInt(el?.beginnerSpeed),
                intermediateSpeed: parseInt(el?.intermediateSpeed),
                athleteSpeed: parseInt(el?.athleteSpeed),
                proSpeed: parseInt(el?.proSpeed),
                reps: parseInt(el?.reps),
                isRest: el?.isRest,
              }
            })

            if (stationDetail.station.id === currentStationIndex) {
              return {
                station: stationDetail.station?.id,
                intervalExercises: newArray,
              }
            } else {
              return {
                station: stationDetail.station?.id,
                intervalExercises: oldIntervalFilter,
              }
            }
          }
        )
        return {
          intervalCount: bootcampRound.intervalCount,
          intervalDuration: bootcampRound.intervalDuration,
          isRest: bootcampRound.isRest,
          stationDetails: stationDetailsOld,
        }
      } else {
        const stationDetails = bootcampRound.stationDetails.map(
          (stationDetail) => {
            let oldIntervalFilter = []
            oldIntervalFilter = stationDetail.intervalExercises.map((el) => {
              return {
                exercise: el?.exercise?.name ? el.exercise?.id : el.exercise,
                isUnlimited: el?.isUnlimited,
                beginnerSpeed: parseInt(el?.beginnerSpeed),
                intermediateSpeed: parseInt(el?.intermediateSpeed),
                athleteSpeed: parseInt(el?.athleteSpeed),
                proSpeed: parseInt(el?.proSpeed),
                reps: parseInt(el?.reps),
                isRest: el?.isRest,
              }
            })
            return {
              station: stationDetail.station?.id,
              intervalExercises: oldIntervalFilter,
            }
          }
        )
        return {
          intervalCount: bootcampRound.intervalCount,
          intervalDuration: bootcampRound.intervalDuration,
          isRest: bootcampRound.isRest,
          stationDetails: stationDetails,
        }
      }
    })

    onUpdate({
      bootcampRounds: bootcampRoundsOld,
    })
    setEditInterval(null)
    setVisibleEditInterval(false)
    setCurrentStationIndex(null)
    setCurrentRoundId(null)
  }

  const columns = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      // render: () => <DragHandle />
    },
    Table.EXPAND_COLUMN,
    {
      title: 'Раунд',
      render: (item, __, index) => {
        return `Раунд ${index + 1}`
      },
    },
    {
      title: 'Количество интервалов',
      dataIndex: 'intervalCount',
    },
    {
      title: 'Длительность интервала',
      dataIndex: 'intervalDuration',
    },
    {
      title: 'Общая продолжительность',
      render: (item, __, index) => {
        return Math.floor((item.intervalCount * item.intervalDuration) / 60)
      },
    },
    {
      title: 'Действие',
      key: 'action',
      render: (item, __, index) => {
        if (item.isPresent) {
          return (
            <span>
              {/* eslint-disable */}

              <Button
                type="link"
                onClick={() => {
                  setModalVisible(true)
                  setModalMode('edit')
                }}
              >
                Редактировать
              </Button>
            </span>
          )
        }
        return (
          <Buttons>
            <ButtonStyled
              type="default"
              onClick={() => console.log('CopyOutlined')}
            >
              <CopyOutlined style={{ fontSize: '18px' }} />
            </ButtonStyled>
            <ButtonStyled
              type="default"
              onClick={() => {
                console.log('index', index)
                let bootcampRoundsOld = []
                if (programSet.bootcampRounds.length > 0) {
                  bootcampRoundsOld = programSet.bootcampRounds.map(
                    (bootcampRound) => {
                      const stationDetailsOld =
                        bootcampRound.stationDetails.map((stationDetail) => {
                          const intervalExercisesOld =
                            stationDetail.intervalExercises.map(
                              (intervalExercise) => {
                                return {
                                  exercise: intervalExercise?.exercise?.id,
                                  isUnlimited: intervalExercise?.isUnlimited,
                                  beginnerSpeed:
                                    intervalExercise?.beginnerSpeed,
                                  intermediateSpeed:
                                    intervalExercise?.intermediateSpeed,
                                  athleteSpeed: intervalExercise?.athleteSpeed,
                                  proSpeed: intervalExercise?.proSpeed,
                                  reps: intervalExercise?.reps,
                                  isRest: intervalExercise?.isRest,
                                }
                              }
                            )
                          return {
                            station: stationDetail.station?.id,
                            intervalExercises: intervalExercisesOld,
                          }
                        })
                      return {
                        intervalCount: bootcampRound.intervalCount,
                        intervalDuration: bootcampRound.intervalDuration,
                        isRest: bootcampRound.isRest,
                        stationDetails: stationDetailsOld,
                      }
                    }
                  )
                }
                onUpdate({
                  bootcampRounds:
                    programSet.bootcampRounds.length > 0
                      ? bootcampRoundsOld.filter(
                          (_, bootcampRoundIndex) =>
                            bootcampRoundIndex !== index
                        )
                      : programSet.bootcampRounds.filter(
                          (_, bootcampRoundIndex) =>
                            bootcampRoundIndex !== index
                        ),
                })
              }}
            >
              <DeleteOutlined style={{ fontSize: '18px' }} />
            </ButtonStyled>
          </Buttons>
        )
      },
    },
  ]

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />
  }

  return (
    <MainContainer>
      <StyledLayout>
        <Content>
          <StyledTable
            dataSource={bootcampRounds}
            columns={columns}
            rowKey={(_, index) => index}
            pagination={false}
            // components={{
            //   body: {
            //     wrapper: DraggableContainer,
            //     row: DraggableBodyRow
            //   }
            // }}
            title={() => (
              <StyledHeaderContainer>
                <h2>План занятия</h2>
                <div>
                  <Button
                    type="default"
                    onClick={() => setVisibleCreateRound(true)}
                  >
                    <PlusOutlined /> Добавить раунд
                  </Button>
                  <Button
                    type="default"
                    onClick={() => setVisibleCreateRest(true)}
                    style={{ marginLeft: 14 }}
                  >
                    <PlusOutlined /> Добавить отдых
                  </Button>
                </div>
              </StyledHeaderContainer>
            )}
            expandable={{
              onExpand: (expanded, record) => {
                if (expanded) {
                  const index = programSet.bootcampRounds.find(
                    (el) => el.id === record.id
                  )
                  setCurrentRoundId(index?.id)
                }
              },
              expandedRowRender: (record, index) => {
                const asd = record?.stationDetails.map((stationDetail) => {
                  return (
                    <ExpandedRowStyled>
                      <ExpandedRowTitle>
                        {stationDetail?.station.name}
                      </ExpandedRowTitle>
                      <ExpandedRowAction>
                        <Button
                          type="link"
                          onClick={() => {
                            setCurrentStationIndex(stationDetail.station.id)
                            setVisibleEditInterval(true)
                            setEditInterval(stationDetail?.intervalExercises)
                            setStation(stationDetail.station)
                            console.log(
                              'stationDetail.station',
                              stationDetail.station
                            )
                          }}
                        >
                          Настроить
                        </Button>
                      </ExpandedRowAction>
                    </ExpandedRowStyled>
                  )
                })
                return asd
              },
              rowExpandable: (record) => !record.isRest,
            }}
          />
          <Modal
            open={visibleCreateRound}
            title="Создать раунд"
            okText="Создать"
            cancelText="Закрыть"
            onCancel={() => setVisibleCreateRound(false)}
            onOk={() => {
              form
                .validateFields()
                .then((values) => {
                  form.resetFields()
                  onCreateRound(values)
                })
                .catch((info) => {
                  // eslint-disable-next-line no-console
                  console.log('Validate Failed:', info)
                })
            }}
          >
            <Form form={form} layout="vertical">
              <FormItem
                key="intervalCount"
                label={getTooltip(
                  'Количество интервалов',
                  'Количество интервалов'
                )}
                name="intervalCount"
              >
                <Input placeholder="Количество интервалов" />
              </FormItem>
              <FormItem
                key="intervalDuration"
                label={getTooltip(
                  'Длительность интервала(c)',
                  'Длительность интервала(c)'
                )}
                name="intervalDuration"
              >
                <Input placeholder="Длительность интервала(c)" />
              </FormItem>
            </Form>
          </Modal>
          <Modal
            open={visibleCreateRest}
            title="Создать отдых"
            okText="Создать"
            cancelText="Закрыть"
            onCancel={() => setVisibleCreateRest(false)}
            onOk={() => {
              form
                .validateFields()
                .then((values) => {
                  form.resetFields()
                  onCreateRest(values)
                })
                .catch((info) => {
                  // eslint-disable-next-line no-console
                  console.log('Validate Failed:', info)
                })
            }}
          >
            <Form form={form} layout="vertical">
              <FormItem
                key="intervalDuration"
                label={getTooltip(
                  'Длительность интервала(c)',
                  'Длительность интервала(c)'
                )}
                name="intervalDuration"
              >
                <Input placeholder="Длительность интервала(c)" />
              </FormItem>
            </Form>
          </Modal>
          {visibleEditInterval && (
            <Modal
              open={visibleEditInterval}
              title="Выбрать упражнения"
              okText="Создать"
              cancelText="Закрыть"
              onCancel={() => setVisibleEditInterval(false)}
              onOk={() => {
                let newArray = []
                newArray = editInterval.map((el) => {
                  return {
                    exercise: el.exercise?.name ? el.exercise?.id : el.exercise,
                    isUnlimited: el.isUnlimited,
                    beginnerSpeed: parseInt(el.beginnerSpeed),
                    intermediateSpeed: parseInt(el.intermediateSpeed),
                    athleteSpeed: parseInt(el.athleteSpeed),
                    proSpeed: parseInt(el.proSpeed),
                    reps: parseInt(el.reps),
                    isRest: el.isRest,
                  }
                })
                onCreateExercise({ newArray })
              }}
              width={1000}
            >
              <IntervalsTable
                exercises={exercises}
                station={station}
                dataSource={editInterval}
                editInterval={editInterval}
                setEditInterval={setEditInterval}
              />
            </Modal>
          )}
        </Content>
      </StyledLayout>
    </MainContainer>
  )
}

ProgramSetBotcamp.propTypes = {
  programSet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    club: PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
    zone: PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      stations: PropTypes.arrayOf(
        PropTypes.shape({
          number: PropTypes.number.isRequired,
          icon: PropTypes.string.isRequired,
        })
      ),
    }),
    levels: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  exercises: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default ProgramSetBotcamp
