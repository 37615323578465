import React from 'react'
import { Form, Input, Select, AutoComplete, Card, Button, Space } from 'antd'
import {
  PlusOutlined,
  MinusCircleOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'

const { Option } = AutoComplete

const UserForm = ({
  form,
  nickname,
  email,
  withPassword,
  sex,
  phoneNumber,
  hero,
  heroPathLevels,
  sharedUser,
  birthday,
  level,
  role,
  levels,
  heroes,
  users,
  searchNickname,
  setSearchNickname,
  clubs,
  userClub,
  isDeleted,
  isGuestClient,
  guest
}) => {
  const [userRole, setUserRole] = React.useState(role)
  const FormItem = Form.Item
  const data = [
    {id: 0, value: true, name: 'Да'}, 
    {id: 1, value: false, name: 'Нет'}
  ]

  return (
    <Form form={form} layout="vertical" hideRequiredMark>
      <FormItem
        key="nickname"
        label="Никнейм пользователя"
        name="nickname"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите Никнейм`,
          },
        ]}
        initialValue={nickname}
      >
        <Input placeholder="Никнейм" />
      </FormItem>
      <FormItem
        key="email"
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите email`,
          },
        ]}
        initialValue={email}
      >
        <Input placeholder="Email" type="email" />
      </FormItem>
      {/* <FormItem
        key="userAvatarURL"
        label="UserAvatarURL"
        name="userAvatarURL"
        initialValue={userAvatarURL}
      >
        <Input placeholder="userAvatarURL" />
      </FormItem> */}
      {withPassword && (
        <FormItem
          key="password"
          label="Пароль"
          name="password"
          rules={[
            {
              required: true,
              message: `Пожалуйста, введите пароль`,
            },
          ]}
        >
          <Input placeholder="Пароль" type="password" />
        </FormItem>
      )}
      <FormItem
        key="sex"
        label="Пол"
        name="sex"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите пол`,
          },
        ]}
        initialValue={sex}
      >
        <Select placeholder="Выберите пол" mode="single">
          <Select.Option key="male" value="male">
            Мужской
          </Select.Option>
          <Select.Option key="female" value="female">
            Женский
          </Select.Option>
        </Select>
      </FormItem>
      <FormItem
        key="club"
        label="Клуб"
        name="club"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите клуб`,
          },
        ]}
        initialValue={userClub}
      >
        <Select placeholder="Выберите клуб" mode="single">
        {clubs.map((obj => (
          <Select.Option key={obj.id} value={obj.id}>
            {obj.name}
          </Select.Option>
        )))
        }
        </Select>
      </FormItem>
      <FormItem
        key="role"
        label="Роль"
        name="role"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите роль`,
          },
        ]}
        initialValue={role}
      >
        <Select
          placeholder="Выберите роль"
          mode="single"
          onChange={(value) => setUserRole(value)}
        >
          <Select.Option key="admin" value="admin">
            admin
          </Select.Option>
          <Select.Option key="user" value="user">
            user
          </Select.Option>
          <Select.Option key="doctor" value="doctor">
            doctor
          </Select.Option>
          <Select.Option key="trainer" value="trainer">
            trainer
          </Select.Option>
          <Select.Option key="photographer" value="photographer">
            photographer
          </Select.Option>
          <Select.Option key="reception" value="reception">
            reception
          </Select.Option>
          <Select.Option key="salesperson" value="salesperson">
            salesperson
          </Select.Option>
          <Select.Option key="schedule" value="schedule">
            schedule
          </Select.Option>
          <Select.Option key="accountant" value="accountant">
            accountant
          </Select.Option>
          <Select.Option key="invictusSub" value="invictusSub">
            invictusSub
          </Select.Option>
          <Select.Option key="headReception" value="headReception">
            headReception
          </Select.Option>
        </Select>
      </FormItem>
      {userRole === 'user' && (
        <>
          <FormItem
            key="level"
            label="Уровень"
            name="level"
            rules={[
              {
                required: true,
                message: `Пожалуйста, введите уровень`,
              },
            ]}
            initialValue={level}
          >
            <Select placeholder="Выберите уровень" mode="single">
              {levels.map((singleLevel) => (
                <Select.Option key={singleLevel?.name} value={singleLevel?.id}>
                  {singleLevel?.name}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
          <FormItem
            key="hero"
            label="Персонаж"
            name="hero"
            rules={[
              {
                required: true,
                message: `Пожалуйста, выберите персонажа`,
              },
            ]}
            initialValue={hero}
          >
            <Select placeholder="Выберите персонажа" mode="single">
              {heroes.map((singleHero) => (
                <Select.Option
                  key={singleHero.identifier}
                  value={singleHero.id}
                >
                  {singleHero.name}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
        </>
      )}

      <FormItem
        key="phoneNumber"
        label="Номер"
        name="phoneNumber"
        rules={[
          {
            required: false,
          },
        ]}
        initialValue={phoneNumber}
      >
        <Input placeholder="Номер телефона" type="tel" />
      </FormItem>
      <FormItem
        key="birthday"
        label="День Рождения"
        name="birthday"
        initialValue={
          birthday
            ? dayjs(birthday).format('YYYY-MM-DD')
            : dayjs().format('YYYY-MM-DD')
        }
      >
        <Input type="date" />
      </FormItem>
      <FormItem
        key="sharedUser"
        label="Код друга"
        name="sharedUser"
        initialValue={sharedUser}
      >
        <Select
          showSearch
          placeholder="Введите Код друга"
          mode="single"
          defaultActiveFirstOption={false}
          filterOption={false}
          notFoundContent={null}
          suffixIcon={null}
          onSearch={(value) => setSearchNickname(value)}
        >
          {users?.map((user) => (
            <Select.Option key={user?.id} value={user?.id}>
              {user?.nickname} | {user?.phoneNumber} | {user?.shortCode}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
      <FormItem
        key="isDeleted"
        label="Удален"
        name="isDeleted"
        initialValue={isDeleted === true ? 'Да' : 'Нет'}
      >
        <Select placeholder="Выберите удален ли" mode="single">
        {data.map((obj => (
          <Select.Option key={obj.id} value={obj.value}>
            {obj.name}
          </Select.Option>
        )))
        }
        </Select>
      </FormItem>
      <FormItem
        key="isGuestClient"
        label="Гость"
        name="isGuestClient"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите является ли гостем`,
          },
        ]}
        initialValue={guest}
      >
        <Select placeholder="Выберите является ли гостем" mode="single">
        {data.map((obj => (
          <Select.Option key={obj.id} value={obj.value}>
            {obj.name}
          </Select.Option>
        )))
        }
        </Select>
      </FormItem>
      <Form.List
        key="heroPathLevels"
        name="heroPathLevels"
        label="Уровень Марафона"
        initialValue={heroPathLevels}
      >
        {(fields) => {
          {/* console.log('fields', form.getFieldsValue()) */}
          return (
            <>
              {fields.map((field, index) => {
                {/* console.log('field', heroPathLevels[field.key]) */}
                return (
                  <Space
                    direction="horizontal"
                    size={16}
                    style={{ marginRight: index % 2 === 0 ? 8 : 0 }}
                  >
                    <Card
                      title={`${heroPathLevels[
                        field.key
                      ]?.identifier?.toUpperCase()}`}
                      key={field.key}
                      style={{ width: '232px' }}
                    >
                      <Form.Item
                        style={{ display: 'none' }}
                        name={[field.name, 'identifier']}
                        rules={[
                          {
                            required: true,
                            message: 'Укажите идентификатор',
                          },
                        ]}
                      >
                        <Input placeholder="идентификатор" />
                      </Form.Item>
                      <Form.Item
                        name={[field.name, 'degree']}
                        rules={[
                          {
                            required: true,
                            message: 'Укажите уровень',
                          },
                        ]}
                      >
                        <Input placeholder="Уровень" />
                      </Form.Item>
                    </Card>
                  </Space>
                )
              })}
            </>
          )
        }}
      </Form.List>
    </Form>
  )
}

UserForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  nickname: PropTypes.string,
  email: PropTypes.string,
  withPassword: PropTypes.bool,
  sex: PropTypes.string,
  phoneNumber: PropTypes.string,
  hero: PropTypes.string,
  birthday: PropTypes.string,
  level: PropTypes.string,
  role: PropTypes.string,
  userAvatarURL: PropTypes.string,
  levels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
    })
  ).isRequired,
  heroes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      identifier: PropTypes.string,
    })
  ).isRequired,
  trainers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      nickname: PropTypes.string,
    })
  ).isRequired,
  trainer: PropTypes.string,
}

UserForm.defaultProps = {
  nickname: '',
  email: '',
  withPassword: false,
  sex: '',
  phoneNumber: '',
  hero: '',
  birthday: '',
  userAvatarURL: '',
  level: '',
  role: '',
  trainer: '',
}

export default UserForm
