import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Table, Button, Divider, Modal, Select, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import PROGRAM_SET_TYPES from '../../utils/programSetTypes';
import showConfirm from '../shared/DeleteConfirm';

const StyledSelect = styled(Select)`
  width: 320px;
`;
const StyledSelectClub = styled(Select)`
  width: 160px;
  margin-left: 20px;
`;
const StyledButton = styled(Button)`
  background-color: 'red';
  font-size: 12px;
  margin-left: 10px;
  padding-left: 10px;
`;

const emptyTask = {
  week: 1,
  trainings: [
    {
      types: []
    }
  ],
  club: ''
};

const TASK_TYPES = [
  {
    value: 'visitsToClass',
    label: 'Визиты за класс',
  },
  {
    value: 'visitsToClassMultiple',
    label: 'Визиты на один из классов',
  }
];

const TASK_CLASS = PROGRAM_SET_TYPES.map((programSetType) => ({
  value: programSetType,
  label: programSetType,
}));

const RecommendPage = ({
  tasks,
  handleDeleteTask,
  clubs,
  setClubs,
  setChoosedClub,
  choosedClub,
  handleUpdatePlanMarathon
}) => {
  const [display, setDisplay] = useState('create');
  const [editTask, setEditTask] = useState(emptyTask);
  const [modalVisible, setModalVisible] = useState(false);
  const [taskType, setTaskType] = useState();
  const [filteredTasks, setFilteredTasks] = useState()
  const [weekOfUpdatePlan, setWeekOfUpdatePlan] = useState('')
  const [clubOfUpdatePlan, setClubOfUpdatePlan] = useState('')

  React.useEffect(() => {
    const filteredTasks = tasks.filter(item => item.club.id === choosedClub.id);
    const dataSource = filteredTasks.flatMap(item => item.trainings.map(training => ({
      ...training,
      week: item.week,
      trainings: [{ types: [] }],
      club: item.club.id,
    })));
    setFilteredTasks(dataSource);
  }, [choosedClub, tasks]);

  const columns = [
    {
      title: 'Название',
      dataIndex: 'types',
      render: types => types.map(type => type.shortDescription).join(', '),
    },
    {
      title: 'Тип',
      dataIndex: 'types',
      render: types => types.map(type => type.type).join(', '),
    },
    {
      title: 'Неделя',
      dataIndex: 'week',
    },
    {
      title: 'Действие',
      key: 'action',
      render: (text, item) => (
        <span>
          <Button
            type="link"
            onClick={() => {
              setDisplay('edit');
              setClubOfUpdatePlan(item.club)
              setWeekOfUpdatePlan(item.week)
              setEditTask(item);
              setModalVisible(true);
            }}
          >
            Редактировать
          </Button>
        </span>
      ),
    },
  ];

  const onCreate = () => {
    handleUpdatePlanMarathon({ tasks, newTask: editTask });
    setModalVisible(false)  
  };

  const updateTasksAndCleanEditTask = (tasks, editTask) => {
    const propertiesToDelete = ['__typename', 'types'];
    const cleanedEditTask = { ...editTask };
    propertiesToDelete.forEach(prop => delete cleanedEditTask[prop]);
    const typeOfEditTask = editTask?.types[0].type;
  
    let updateApplied = false;
  
    const updatedTasks = tasks.map(task => {
      if (task.week === weekOfUpdatePlan && task.club.id === clubOfUpdatePlan && !updateApplied) {
        const updatedTrainings = task.trainings.map(training => {
          if (!updateApplied) {
            const filteredTypes = training.types.filter(each => each.type !== typeOfEditTask);
            if (filteredTypes.length !== training.types.length) {
              updateApplied = true;
            }
            return { ...training, types: filteredTypes };
          }
          return training;
        }).filter(training => training.types.length > 0);
        return { ...task, trainings: updatedTrainings };
      }
      return task;
    });
  
    return { updatedTasks, cleanedEditTask };
  };
    
  const onUpdate = () => {
    if (editTask?.week) {
      const { updatedTasks, cleanedEditTask } = updateTasksAndCleanEditTask(tasks, editTask);
      handleUpdatePlanMarathon({ tasks: updatedTasks, newTask: cleanedEditTask });
      setModalVisible(false);
    } else {
      console.log('No week found for update');
    }
  };

  const onDelete = () => {
    showConfirm(() => {
      if (editTask?.week) {
        const { updatedTasks, cleanedEditTask } = updateTasksAndCleanEditTask(tasks, editTask);
        handleUpdatePlanMarathon({ tasks: updatedTasks, newTask: null });
        setModalVisible(false);
      } else {
        console.log('No week found for update');
      }
    });
  };

  const modalText = display === 'create' ? 'Создать' : 'Редактировать';

  const modalFunction = display === 'create' ? onCreate : onUpdate;

  const onCancel = () => {
    setModalVisible(false);
  };

  const handleInputChange = (field, value, index) => {
    let newEditTask = { ...editTask };
    if (field === 'trainings' && index !== undefined) {
      newEditTask.trainings[index].types = value.map(v => ({ type: v }));
    } else if (field === 'week') {
      newEditTask[field] = parseInt(value, 10);
    } else {
      newEditTask[field] = value;
    }
    setEditTask(newEditTask);
  };

  const handleChangeTaskType = (value) => {
    setTaskType(value);
  };

  const addTrainingSelect = () => {
    setEditTask(prevState => ({
      ...prevState,
      trainings: [
        ...prevState.trainings,
        {
          types: []
        }
      ]
    }));
  };

  const handleChangeClub = (value) => {
    const selectedClub = clubs.find(club => club.id === value);
    setChoosedClub(selectedClub);
  };
  
  return (
    <>
      <Table
        dataSource={filteredTasks}
        columns={columns}
        rowKey={(item) => item?.id}
        title={() => (
          <div>
            <Button
              type="primary"
              onClick={() => {
                setEditTask(emptyTask);
                setModalVisible(true);
                setDisplay('create');
              }}
            >
              <PlusOutlined /> Новое задание
            </Button>
            <StyledSelectClub
              onChange={(item) => handleChangeClub(item)}
              value={choosedClub?.name}
            >
              {clubs.map((club) => (
                <Select.Option value={club.id} key={club.id}>
                  {club.name}
                </Select.Option>
              ))}
            </StyledSelectClub>
          </div>
        )}
      />
      <Modal
        open={modalVisible}
        title={modalText}
        onCancel={onCancel}
        footer={[
          <Button
            danger
            style={{
              float: 'left',
              visibility: display === 'create' ? 'hidden' : 'visible',
            }}
            onClick={onDelete}
            key="deleteButton"
          >
            Удалить
          </Button>,
          <Button onClick={onCancel} key="cancelButton">
            Отмена
          </Button>,
          <Button key="submitButton" type="primary" onClick={modalFunction}>
            {modalText}
          </Button>,
        ]}
      >
        <h3> Неделя </h3>
        <Input
          onChange={(e) => handleInputChange('week', e.target.value)}
          value={editTask?.week}
        />
        <h3> Клуб </h3>
        <StyledSelect
          onChange={(value) => handleInputChange('club', value)}
          value={editTask?.club}
        >
          {clubs.map((taskType) => (
            <Select.Option value={taskType.id} key={taskType.id}>
              {taskType.name}
            </Select.Option>
          ))}
        </StyledSelect>
        <h3> Тип посещения </h3>
        <StyledSelect
          onChange={(value) => handleChangeTaskType(value)}
          value={taskType}
        >
          {TASK_TYPES.map((taskType) => (
            <Select.Option value={taskType.value} key={taskType.value}>
              {taskType.label}
            </Select.Option>
          ))}
        </StyledSelect>
        <Divider />
        {taskType === 'visitsToClass' && (
          <>
            <h3> Тренировки на всю неделю </h3>
            {editTask.trainings.map((training, index) => (
              <StyledSelect
                key={index}
                onChange={(value) => handleInputChange('trainings', [value], index)}
                value={training.types.length ? training.types[0].type : ''}
                style={{ marginBottom: '10px' }}
              >
                {TASK_CLASS.map((classType) => (
                  <Select.Option value={classType.value} key={classType.value}>
                    {classType.label}
                  </Select.Option>
                ))}
              </StyledSelect>
            ))}
          </>
        )}
        {taskType === 'visitsToClassMultiple' && (
          <>
            <h3> Тренировки на всю неделю </h3>
            {editTask.trainings.map((training, index) => (
              <StyledSelect
                key={index}
                onChange={(value) => handleInputChange('trainings', value, index)}
                value={training.types.map(typeObj => typeObj.type)}
                mode="multiple"
                style={{ marginBottom: '10px' }}
              >
                {TASK_CLASS.map((classType) => (
                  <Select.Option value={classType.value} key={classType.value}>
                    {classType.label}
                  </Select.Option>
                ))}
              </StyledSelect>
            ))}
            <Divider />
          </>
        )}
        <StyledButton onClick={addTrainingSelect}>
          Добавить ещё
        </StyledButton>
        <Divider />
      </Modal>
    </>
  );
};

RecommendPage.propTypes = {
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleUpdatePlanMarathon: PropTypes.func.isRequired,
  handleDeleteTask: PropTypes.func.isRequired,
  handleUpdateTask: PropTypes.func.isRequired,
};

export default RecommendPage;
