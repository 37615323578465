import React from 'react'
import InputMask from 'react-input-mask'
import styled from 'styled-components'


const StyledInput = styled.input`
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 100%;
    padding: 10px;
    font-family: 'GraphikLCGMedium';
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 8px;
    &:focus {
        outline: none;
        box-shadow: 0px 0px 2px #6f2cff;
    }
`;

function MaskInput(props) {
  const { mask, value, onChange, ...otherProps } = props

  return (
      <StyledInput          
        type="text" 
        id="phone"
        placeholder="8-___-___-__-__"
        maxLength={11}
        onChange={onChange}
      />
  )
}

export default MaskInput