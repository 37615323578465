import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { toast } from 'react-toastify';

import Card from './Card';
import withMainLayout from '../../hocs/withMainLayout';
import Loading from '../shared/Loading';
import { useLoading } from '../../context/useLoading';
import { useAuth } from '../../context/useAuth';
import UserInfo from './UserInfo';
import Modal from './Modal';

const CHANGE_STATUS = gql`
  mutation salesStatus($userId: ID, $input: StatusInput){
    salesStatus(userId: $userId, input: $input){
      updated_at
    }
  }
`;

const HEROS_WEEK_USER_INFO = gql`
  query userHistoryHeroWeek($userId: ID) {
    userHistoryHeroWeek(userId: $userId) {
      commentSales
      day
      goal
      experienceInGym
      user {
        id
        firstName
        nickname
        email
        phoneNumber
      }
      events {
        type
        startTime
        programSetName
      }
    }
  }
`;

const ADD_COMMENT = gql`
  mutation CommentSalesHeroWeek($userId: ID, $input: SalesCommentInput) {
    commentSalesHeroWeek(userId: $userId, input: $input) {
      id
    }
  }
`;

const Wrapper = styled.div`
  border-radius: 8px;
  flex: 1;
  background-color: #fff;
`;

const HerosWeekContainer = () => {
  const { user } = useAuth();
  const { showLoading, hideLoading } = useLoading();
  const [showInfo, setShowInfo] = useState(false);
  const [users, setUsers] = useState(null);
  const [usersInfo, setUsersInfo] = useState(null);
  const [newChanges, setNewChanges] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserZone, setSelectedUserZone] = useState(null);
  const [showInput, setShowInput] = useState(false);
  const [comment, setComment] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [type, setType] = useState(null)
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [refreshing, setRefreshing] = React.useState(false);

  const fetchData = useCallback(() => {
    const params = new URLSearchParams();
    // params.append('page', page);
    // params.append('limit', limit);
  
    fetch('https://admin.herosjourney.kz/notify/api/userHeroWeekHistories', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: params.toString()
    })
    .then(response => response.json())
    .then(data => {
      setUsers(data);
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  }, [newChanges]);
  
  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, [fetchData]);

  const [changeStatus] = useMutation(CHANGE_STATUS, {
    onCompleted: () => {
      hideLoading();
      toast.success('Статус успешно изменен');
    },
    onError: (error) => {
      hideLoading();
      toast.error(`Ошибка: ${error.message}`);
    }
  });

  const [addComment] = useMutation(ADD_COMMENT, {
    onCompleted: () => {
      hideLoading();
      toast.success('Примечание успешно добавлено');
    },
    onError: (error) => {
      hideLoading();
      toast.error(`Ошибка: ${error.message}`);
    }
  });

  const [getUserInfo, {
    loading: loadingUserInfo,
    error: errorUserInfo,
    data: dataUserInfo
  }] = useLazyQuery(HEROS_WEEK_USER_INFO);

  useEffect(() => {
    if (dataUserInfo && dataUserInfo.userHistoryHeroWeek) {
      setUsersInfo(dataUserInfo.userHistoryHeroWeek);
    }
  }, [dataUserInfo, loadingUserInfo, errorUserInfo]);

  const handleChangeStatus = ({userId, values}) => {
    showLoading();
    changeStatus({
      variables: {
        userId: userId,
        input: values
      }
    });
  };

  const handleAddComment = ({userId, values}) => {
    showLoading();
    addComment({
      variables: {
        userId: userId,
        input: values
      }
    });
    handleGetUserInfo(userId);
  };

  const handleGetUserInfo = (userId) => {
    getUserInfo({
      variables: {
        userId: userId
      }
    });
  };
  
  if (!user) {
    return <Loading />;
  }

  return (
    <Wrapper>
      {users ? (
        <>
          <Card 
            data={users} 
            handleChangeStatus={handleChangeStatus} 
            user={user} 
            setShowInfo={setShowInfo} 
            handleGetUserInfo={handleGetUserInfo}
            setNewChanges={setNewChanges}
            newChanges={newChanges}
            setSelectedUser={setSelectedUser}
            setSelectedUserZone={setSelectedUserZone}
            setShowInput={setShowInput}
            setComment={setComment}
            setPage={setPage}
            page={page}
            setIsModalOpen={setIsModalOpen}
            setType={setType}
          />
          <UserInfo 
            showInfo={showInfo}
            setShowInfo={setShowInfo} 
            usersInfo={usersInfo}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            setSelectedUserZone={setSelectedUserZone}
            selectedUserZone={selectedUserZone}
            loadingUserInfo={loadingUserInfo}
            setShowInput={setShowInput}
            showInput={showInput}
            handleAddComment={handleAddComment}
            setComment={setComment}
            comment={comment}
          />
          {isModalOpen && 
            <Modal 
              type={type} 
              comment={comment} 
              setComment={setComment} 
              setIsModalOpen={setIsModalOpen}
              handleChangeStatus={handleChangeStatus}
              handleAddComment={handleAddComment}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              user={user}
            />}
        </>
      ) : <Loading />}
    </Wrapper>
  );
};

export default withMainLayout(HerosWeekContainer);
