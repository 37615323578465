import React from 'react'
import { Table } from 'antd'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const TrainerReviews = ({ reviews }) => {
  dayjs.locale('ru')

  let totalSum = 0
  let totalCnt = 0
  const dataSource = reviews.map((review) => {
    const { event, programSet } = review

    const eventDate = dayjs(event.startTime).format('Do MMMM, YYYY')
    const eventStartTime = dayjs(event.startTime).format('HH:mm')
    const eventEndTime = dayjs(event.endTime).format('HH:mm')
    const eventDateString = `${eventDate} | ${eventStartTime}-${eventEndTime}`
    totalSum += review?.value || 0
    totalCnt += 1
    const programSetName = programSet?.name
    return {
      ...review,
      eventDateString,
      programSetName,
    }
  })

  const averageScore = totalCnt
    ? Math.floor(100 * (totalSum / totalCnt)) / 100
    : 0

  const columns = [
    {
      title: 'Оценка',
      dataIndex: 'value',
      width: '10%',
      render: (item) => {
        return (
          <span>
            {item && (
              <>
                {item}
                <span role="img" aria-label="star">
                  ⭐
                </span>
              </>
            )}
          </span>
        )
      },
    },
    {
      title: 'Комментарий',
      dataIndex: 'comment',
      width: '50%',
    },
    {
      title: 'Название программы',
      dataIndex: 'programSetName',
      width: '20%',
    },
    {
      title: 'Дата и время события',
      dataIndex: 'eventDateString',
      width: '20%',
    },
  ]

  return (
    <MainContainer>
      <h2> Средняя оценка: {averageScore}</h2>
      <Table
        dataSource={dataSource}
        rowKey={(item) => item.id}
        columns={columns}
      />
    </MainContainer>
  )
}

TrainerReviews.propTypes = {
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.number,
      comment: PropTypes.string,
      event: PropTypes.shape({
        startTime: PropTypes.string,
        endTime: PropTypes.string,
      }),
      programSet: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ).isRequired,
}

export default TrainerReviews
