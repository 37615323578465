import React from 'react'
import { Layout, Menu, Breadcrumb, Table } from 'antd'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { TeamOutlined, StarOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'

const { Sider, Content } = Layout

const StyledBreadcrumb = styled(Breadcrumb)`
  margin: 16px 0;
`

const StyledHeader = styled.div`
  background: #fff;
  border-bottom: 1px solid #d9d9d9;
  padding: 16px 24px 0;
  display: flex;
  justify-content: space-between;
`

const StyledMenu = styled(Menu)`
  height: 100%;
  padding-top: 16px;
`

const StyledLayout = styled(Layout)`
  padding: 0 24px 24px;
  background: #fff;
`

const StyledContent = styled(Content)`
  padding: 24px;
  margin: 0px;
  min-height: 280px;
`

const TrainerEvent = ({ event, stationBookings, reviews }) => {
  const [currentTab, setCurrentTab] = React.useState(1)

  const handleTabChange = (activeKey) => {
    setCurrentTab(activeKey)
  }

  const bookingColumns = [
    {
      title: 'Станция',
      dataIndex: 'station',
      width: '10%',
    },
    {
      title: 'Название станции',
      dataIndex: 'stationName',
      width: '60%',
    },
    {
      title: 'Пользователь',
      dataIndex: 'nickname',
    },
  ]

  const reviewColumns = [
    {
      title: 'Оценка',
      dataIndex: 'value',
      width: '10%',
      render: (item) => {
        return (
          <span>
            {item && (
              <>
                {item}
                <span role="img" aria-label="star">
                  ⭐
                </span>
              </>
            )}
          </span>
        )
      },
    },
    {
      title: 'Комментарий',
      dataIndex: 'comment',
    },
  ]

  const eventDate = dayjs(event.startTime).format('DD.MM.YY')
  const eventStartTime = dayjs(event.startTime).format('HH:mm')
  const eventEndTime = dayjs(event.endTime).format('HH:mm')

  return (
    <Layout>
      <StyledBreadcrumb>
        <Breadcrumb.Item>
          <Link to="/trainerEvents" href="/">
            События
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {`${event.programSet?.name} | ${eventDate} | ${eventStartTime}-${eventEndTime}`}
        </Breadcrumb.Item>
      </StyledBreadcrumb>

      <StyledHeader>
        <h2>
          {`${event.programSet?.name} | ${eventDate} | ${eventStartTime}-${eventEndTime}`}
        </h2>
      </StyledHeader>

      <Layout>
        <Sider>
          <StyledMenu mode="inline" defaultSelectedKeys={['1']}>
            <Menu.Item
              icon={<TeamOutlined />}
              key="1"
              onClick={() => handleTabChange(1)}
            >
              Участники
            </Menu.Item>
            <Menu.Item
              icon={<StarOutlined />}
              key="2"
              onClick={() => handleTabChange(2)}
            >
              Оценки
            </Menu.Item>
          </StyledMenu>
        </Sider>
        <StyledLayout>
          <StyledContent className="site-layout-background">
            {currentTab === 1 && (
              <Table
                dataSource={stationBookings}
                rowKey={(item) => item.id + item.identifier}
                columns={bookingColumns}
              />
            )}
            {currentTab === 2 && (
              <Table
                dataSource={reviews}
                rowKey={(item) => item.id}
                columns={reviewColumns}
              />
            )}
          </StyledContent>
        </StyledLayout>
      </Layout>
    </Layout>
  )
}

TrainerEvent.propTypes = {
  event: PropTypes.shape({
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    programSet: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  stationBookings: PropTypes.arrayOf(
    PropTypes.shape({
      station: PropTypes.string,
      stationName: PropTypes.string,
      nickname: PropTypes.string,
    })
  ).isRequired,
}

export default TrainerEvent
