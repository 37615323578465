import React from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import styled from 'styled-components'

import ConversionBody from './ConversionBody'
import withMainLayout from '../../hocs/withMainLayout'
import Loading from '../shared/Loading'

const GET_MARATHON_EVENTS = gql`
  query usersMarathonHerroPassEvent($marathonId: ID, $query: PaginationQuery) {
    usersMarathonHerroPassEvent(marathonId: $marathonId, query: $query) {
      marathonEvents {
        id
        startTime
        endTime
        hpBuyCount
        clientsCount
      }
      total
      page
    }
  }
`

const Wrapper = styled.div`
  margin: 2%;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  flex: 1;
  background-color: #fff;
`

const ConverionContainer = () => {
  const [marathonEvents, setMarathonEvents] = React.useState(null)
  const [page, setPage] = React.useState(1)

  const [getMarahonEvents, { loading, error, data }] =
    useLazyQuery(GET_MARATHON_EVENTS)

  React.useEffect(() => {
    getMarahonEvents({
      variables: {
        marathonId: '614974f10163567b03eb6926',
        query: {
          page: page,
          limit: 18,
        },
      },
    })
  }, [getMarahonEvents, page])

  React.useEffect(() => {
    if (data && data?.usersMarathonHerroPassEvent && !loading) {
      setMarathonEvents(data?.usersMarathonHerroPassEvent)
    }
  }, [data, loading, error])

  if (
    loading ||
    !marathonEvents?.marathonEvents ||
    marathonEvents?.marathonEvents?.length === 0
  ) {
    return <Loading />
  }

  return (
    <Wrapper>
      <ConversionBody
        marathonEvents={marathonEvents}
        page={page}
        setPage={setPage}
      />
    </Wrapper>
  )
}

export default withMainLayout(ConverionContainer)
