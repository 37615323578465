import { gql } from '@apollo/client'

export const GET_USERS = gql`
  query find($query: UsersQuery, $pagination: PaginationQuery) {
    findUsers(queryUsers: $query, pagination: $pagination) {
      users {
        id
        firstName
        username
        nickname
        phoneNumber
        email
        tickets
        utmSource
        utmCampaign
        utmMedium
        contactId
        leadId
        created_at
        club {
                id
            }
        level {
          name
        }
        hero {
          id
          name
        }
      }
      total
      limit
      page
      pages
    }
  }
`
export const GET_ACTIVE_USER_MARATHON = gql`
  query getActiveMarathon($userId: ID) {
    getActiveMarathon(userId: $userId) {
      id
      marathon {
        id
        name
        assessmentClasses
      }
      startTime
      endTime
    }
    userMarathonEventByUser(userId: $userId) {
      ticketsLeft
      marathonVisits
      marathonMissed
      assessmentVisit
    }
  }
`

export const GET_USER_MARATHONS = gql`
  query userMarathonEventsByUser($userId: ID) {
    userMarathonEventsByUser(userId: $userId) {
      id
      ticketsLeft
      marathonVisits
      marathonMissed
      assessmentVisit
      marathon {
        id
        name
      }
      marathonEvent {
        id
        startTime
        endTime
      }
    }
  }
`
export const GET_MARATHON = gql`
  query marathon($id: ID!) {
    marathon(id: $id) {
      id
      name
      price
      marathonEvents {
        id
        startTime
        endTime
      }
    }
  }
`
export const HERO_PASSES = gql`
  query heroPasses {
    heroPasses {
      id
      name
      price
    }
  }
`
export const PAY_BY_ORDER = gql`
  mutation payByOrder(
    $orderInput: OrderInput
    $userInput: UserInput
    $userId: ID
  ) {
    payByOrder(
      orderInput: $orderInput
      userInput: $userInput
      userId: $userId
    ) {
      status
      message
    }
  }
`

export const CHANGE_CLUB = gql`
  mutation changeClub(
    $changeInput: ChangeInput
    $userId: ID!
  ) {
    changeClub(
      changeInput: $changeInput
      userId: $userId
    ) {
      status
      message
    }
  }
`

export const REREG = gql`
  query CalculateTransfer($userId: ID!, $clubId: ID!) {
    calculateTransfer(
      userId: $userId, 
      clubId: $clubId
    )
  }
`

export const REFUND = gql`
  query calculateRefund($userId: ID!) {
    calculateRefund(
      userId: $userId
    ) {
      fullPrice
      fine
      usedAmount
      refundAmount
    }
  }
`

export const GET_CLUBS = gql`
  query clubs {
    clubs {
      id
      name
    }
  }
`

export const REFUND_PAYMENT = gql`
  mutation refundPayment(
    $userId: ID!
    $userHeroPassId: ID!
    $imageFile: FileUpload!
    $clubId: ID
    $salesmanId: ID
    $receptionistId: ID
    $refundAmount: Float
    $changeRefundAmount: Float
    $comment: String
    $refundLetterTime: DateTime
    $paymentMethod: String,
    $paidAmount: Float,
    $cashAmount: Float,
    $cardAmount: Float,
  ) {
    refundPayment(
      userId: $userId
      userHeroPassId: $userHeroPassId
      imageFile: $imageFile
      clubId: $clubId
      salesmanId: $salesmanId
      receptionistId: $receptionistId
      refundAmount: $refundAmount
      changeRefundAmount: $changeRefundAmount
      comment: $comment
      refundLetterTime: $refundLetterTime
      paymentMethod: $paymentMethod
      paidAmount: $paidAmount
      cashAmount: $cashAmount
      cardAmount: $cardAmount
    ) {
      status
      message
    }
  }
`

export const PAYMENTS = gql`
  query payments {
    payments {
      name
      id
      isActive
      cost
    }
  }
`

export const CURRENT_USER_HP = gql`
  query currentUserHeroPass($userId: ID) {
    currentUserHeroPass(userId: $userId) {
      id
      startTime
      heroPass {
        name
        duration
        price
        isActive
      }
      endTime
      fromUser
      toUser
      updated_at
      created_at
      status
    }
  }
`
export const USER_PAYMENTS = gql`
  query userPayments($userId: ID!) {
    userPayments(userId: $userId) {
      created_at
      cost
      paidAmount
      paymentMethod
      checkId
      checkLink
      salesman {
        username
      }
      payment {
        id
        name
      }
      heroPass {
        name
        id
      }
      userHeroPass {
        id
      }
      marathonEvent {
        id
        marathon {
          name
        }
      }
    }
  }
`
export const USERS_BY_ROLE = gql`
  query salesByRole {
    salesByRole {
      id
      username
      nickname
      role
      club {
        id
        name
      }
    }
  }
`
export const GET_USERS_WITH_HEROPASS = gql`
  query activeUserHeroPasses(
    $pagination: PaginationQuery
    $queryUsers: UsersQuery
    $heroPassId: ID
    $month: Int
    $year: Int
  ) {
    activeUserHeroPasses(
      pagination: $pagination
      queryUsers: $queryUsers
      heroPassId: $heroPassId
      month: $month
      year: $year
    ) {
      userHeroPasses {
        id
        startTime
        endTime
        heroPass {
          id
          name
        }
        note {
          noteText
          createdAt
        }
        user {
          firstName
          phoneNumber
          id
        }
      }
      total
      limit
    }
  }
`

export const ADD_NOTE = gql`
  mutation addUserHeroPassNote($id: ID!, $input: UserHeroPassNoteInput) {
    addUserHeroPassNote(id: $id, input: $input) {
      id
    }
  }
`

export const ADD_RECURRENT_NOTE = gql`
  mutation addRecurrentPaymentNote(
    $id: ID!
    $input: RecurrentPaymentNoteInput
  ) {
    addRecurrentPaymentNote(id: $id, input: $input) {
      id
    }
  }
`

export const GET_RECURRENT_USERS = gql`
  query usersRecurrentPayments(
    $pagination: PaginationQuery
    $queryUsers: UsersQuery
  ) {
    usersRecurrentPayments(pagination: $pagination, queryUsers: $queryUsers) {
      total
      recurrentPayments {
        id
        isFreezing
        isPaiedAll
        totalAmount
        currentManthNumber
        recurrentPayment {
          name
          totalAmount
          isActive
        }
        userHeroPass {
          id
        }
        monthPaymentsProgress {
          amount
          paymentDate
        }
        user {
          id
          firstName
          phoneNumber
          nickname
        }
        note {
          noteText
          createdAt
        }
      }
    }
  }
`
export const TRANSFER_NOT_USED_MARATHON = gql`
  mutation transferWithNotUsedMarathon(
    $userId: ID
    $marathon: ID
    $marathonEventId: ID
  ) {
    transferWithNotUsedMarathon(
      userId: $userId
      marathon: $marathon
      marathonEventId: $marathonEventId
    ) {
      status
      message
    }
  }
`
export const GET_MARATHON_EVENTS = gql`
  query marathon($id: ID!) {
    marathon(id: $id) {
      marathonEvents {
        id
        startTime
        endTime
      }
    }
  }
`
export const GET_USERS_WITH_GUEST_TICKETS = gql`
  query userGuestTicketWithPaginate(
    $query: PaginationQuery
    $queryUsers: UsersQuery
  ) {
    userGuestTicketWithPaginate(query: $query, queryUsers: $queryUsers) {
      total
      userGuestTicket {
        id
        guestTickets
        ticketsLeft
        created_at
        expiryDate
        userRecipient {
          username
          phoneNumber
          firstName
        }
      }
    }
  }
`
