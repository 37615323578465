import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, Form, Input, Select, Checkbox, Space, Card } from 'antd'
import styled from 'styled-components'

import StyledTooltip from '../shared/StyledTooltip'
import showConfirm from '../shared/DeleteConfirm'
import iconImages from '../shared/icons'

const DetailsIcon = styled.img`
  width: 32px;
  height: 32px;
`

const FormItem = Form.Item

const icons = [
  {
    value: 'HALF_RACK_FULL',
    label: 'HALF_RACK_FULL',
    labelImg: iconImages.HALF_RACK_FULL,
  },
  {
    value: 'HIP_THRUST',
    label: 'HIP_THRUST',
    labelImg: iconImages.HIP_THRUST,
  },
  {
    value: 'LAT_MACHINE_CONVERGENT',
    label: 'LAT_MACHINE_CONVERGENT',
    labelImg: iconImages.LAT_MACHINE_CONVERGENT,
  },
  {
    value: 'LEG_PRESS_45',
    label: 'LEG_PRESS_45',
    labelImg: iconImages.LEG_PRESS_45,
  },
  {
    value: 'POWER_RUNNER',
    label: 'POWER_RUNNER',
    labelImg: iconImages.POWER_RUNNER,
  },
  {
    value: 'SQUAT_LUNGE',
    label: 'SQUAT_LUNGE',
    labelImg: iconImages.SQUAT_LUNGE,
  },
  {
    value: 'SUPER_VERTICAL_CHEST_PRESS',
    label: 'SUPER_VERTICAL_CHEST_PRESS',
    labelImg: iconImages.SUPER_VERTICAL_CHEST_PRESS,
  },
  {
    value: 'SUPER_DELTOID_PRESS',
    label: 'SUPER_DELTOID_PRESS',
    labelImg: iconImages.SUPER_DELTOID_PRESS,
  },
  {
    value: 'SUPER_INCLINED_BENCH_PRESS',
    label: 'SUPER_INCLINED_BENCH_PRESS',
    labelImg: iconImages.SUPER_INCLINED_BENCH_PRESS,
  },
  {
    value: 'SUPER_ROWING',
    label: 'SUPER_ROWING',
    labelImg: iconImages.SUPER_ROWING,
  },
  {
    value: 'ABDOMINAL_CRUNCH',
    label: 'ABDOMINAL_CRUNCH',
    labelImg: iconImages.ABDOMINAL_CRUNCH,
  },
  {
    value: 'ABDUCTOR_MACHINE',
    label: 'ABDUCTOR_MACHINE',
    labelImg: iconImages.ABDUCTOR_MACHINE,
  },
  {
    value: 'ADJUSTABLE_CABLE_STATION_WITH_BAR',
    label: 'ADJUSTABLE_CABLE_STATION_WITH_BAR',
    labelImg: iconImages.ADJUSTABLE_CABLE_STATION_WITH_BAR,
  },
  {
    value: 'CALF_MACHINE',
    label: 'CALF_MACHINE',
    labelImg: iconImages.CALF_MACHINE,
  },
  {
    value: 'CURLING_MACHINE',
    label: 'CURLING_MACHINE',
    labelImg: iconImages.CURLING_MACHINE,
  },
  {
    value: 'HORIZONTAL_LEG_PRESS',
    label: 'HORIZONTAL_LEG_PRESS',
    labelImg: iconImages.HORIZONTAL_LEG_PRESS,
  },
  {
    value: 'JUNGLE_MACHINE_HLP',
    label: 'JUNGLE_MACHINE_HLP',
    labelImg: iconImages.JUNGLE_MACHINE_HLP,
  },
  {
    value: 'LEG_CURLING',
    label: 'LEG_CURLING',
    labelImg: iconImages.LEG_CURLING,
  },
  {
    value: 'LEG_EXTENSION',
    label: 'LEG_EXTENSION',
    labelImg: iconImages.LEG_EXTENSION,
  },
  {
    value: 'MASTER_GLUTEUS_PLUS',
    label: 'MASTER_GLUTEUS_PLUS',
    labelImg: iconImages.MASTER_GLUTEUS_PLUS,
  },
  {
    value: 'PECK_BACK',
    label: 'PECK_BACK',
    labelImg: iconImages.PECK_BACK,
  },
  {
    value: 'TRICEPS_MACHINE',
    label: 'TRICEPS_MACHINE',
    labelImg: iconImages.TRICEPS_MACHINE,
  },
  {
    value: 'FULLY_ADJUSTABLE_BENCH',
    label: 'FULLY_ADJUSTABLE_BENCH',
    labelImg: iconImages.FULLY_ADJUSTABLE_BENCH,
  },
  {
    value: 'BENCH_PRESS',
    label: 'BENCH_PRESS',
    labelImg: iconImages.BENCH_PRESS,
  },
  {
    value: 'ADJUSTABLE_BACK_EXTENSION',
    label: 'ADJUSTABLE_BACK_EXTENSION',
    labelImg: iconImages.ADJUSTABLE_BACK_EXTENSION,
  },
  {
    value: 'TREAD_MILL',
    label: 'TREAD_MILL',
    labelImg: iconImages.TREAD_MILL,
  },
  {
    value: 'PRESS_STOMACH',
    label: 'PRESS_STOMACH',
    labelImg: iconImages.PRESS_STOMACH,
  },
]

function toLetters(num) {
  const mod = num % 26
  let pow = (num / 26) | 0
  const out = mod ? String.fromCharCode(64 + mod) : (--pow, 'Z')
  return pow ? toLetters(pow) + out : out
}

const EditStation = ({
  visible,
  onCancel,
  title,
  editStation,
  onUpdate,
  onDelete,
  onCreate,
  isNew,
  group,
}) => {
  const [form] = Form.useForm()
  const [isCardio, setIsCardio] = React.useState(editStation?.isCardio)

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />
  }

  return (
    <Modal
      open={visible}
      title={title}
      okText="Создать"
      cancelText="Закрыть"
      onCancel={onCancel}
      footer={
        !isNew
          ? [
              <Button
                danger
                style={{ float: 'left' }}
                onClick={() => {
                  showConfirm(() => {
                    onDelete()
                  })
                }}
              >
                Удалить
              </Button>,
              <Button key="submit" onClick={onCancel}>
                Отмена
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={() => {
                  form
                    .validateFields()
                    .then((values) => {
                      form.resetFields()
                      onUpdate({ ...values, isCardio })
                    })
                    .catch((info) => {
                      console.log('Validate Failed:', info)
                    })
                }}
              >
                Сохранить
              </Button>,
            ]
          : [
              <Button
                key="submit"
                type="primary"
                onClick={() => {
                  form
                    .validateFields()
                    .then((values) => {
                      form.resetFields()
                      onCreate({ ...values, isCardio })
                    })
                    .catch((info) => {
                      console.log('Validate Failed:', info)
                    })
                }}
              >
                Сохранить
              </Button>,
            ]
      }
    >
      <Form form={form} layout="vertical">
        <FormItem
          key="number"
          label={getTooltip('Номер ', 'Номер станции')}
          name="number"
          rules={[
            {
              required: true,
              message: `Пожалуйста, укажите номер`,
            },
          ]}
          initialValue={editStation?.number}
        >
          <Input placeholder="Номер" type="number" />
        </FormItem>
        <FormItem
          key="name"
          label={getTooltip('Название ', 'Название станции')}
          name="name"
          initialValue={editStation?.name}
        >
          <Input placeholder="Название станции" />
        </FormItem>
        <FormItem
          key="icon"
          label={getTooltip('Иконка ', 'Иконка станции')}
          name="icon"
          rules={[
            {
              required: true,
              message: `Пожалуйста, выберите иконку`,
            },
          ]}
          initialValue={editStation?.icon}
        >
          <Select placeholder="Иконка">
            {icons?.map((icon) => (
              <Select.Option key={icon.value} value={icon.value}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <DetailsIcon src={icon.labelImg} />
                  <div>{icon.label}</div>
                </div>
              </Select.Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          key="isCardio"
          label={getTooltip('Кардио тренажер? ', 'Этот тренажер для кардио?')}
          name="isCardio"
        >
          <Checkbox
            onChange={(e) => setIsCardio(e.target.checked)}
            checked={isCardio}
          />
        </FormItem>
        <Form.List
          key="positions"
          name="positions"
          label="Позиция Станции"
          initialValue={
            (editStation?.positions?.length > 0 && editStation?.positions) ||
            Array.from({ length: group }, (_, index) => {
              return {}
            })
          }
        >
          {(fields) => {
            return (
              <>
                {fields.map((field, index) => {
                  return (
                    <Space
                      direction="horizontal"
                      size={16}
                      style={{
                        marginRight: index % 2 === 0 ? 8 : 0,
                        marginTop: 8,
                      }}
                    >
                      <Card
                        title={`Группа: ${toLetters(index + 1)}`}
                        key={field}
                        style={{ width: '232px' }}
                      >
                        <Form.Item
                          name={[field?.name, 'height']}
                          rules={[
                            {
                              required: true,
                              message: 'Укажите позицию по Y-осьи',
                            },
                          ]}
                        >
                          <Input
                            type="number"
                            placeholder="Позиция по Y-осьи"
                          />
                        </Form.Item>
                        <Form.Item
                          name={[field?.name, 'width']}
                          rules={[
                            {
                              required: true,
                              message: 'Укажите позицию по x-осьи',
                            },
                          ]}
                        >
                          <Input
                            type="number"
                            placeholder="Позиция по x-осьи"
                          />
                        </Form.Item>
                      </Card>
                    </Space>
                  )
                })}
              </>
            )
          }}
        </Form.List>
      </Form>
    </Modal>
  )
}

EditStation.propTypes = {
  editStation: PropTypes.shape({
    name: PropTypes.string,
    number: PropTypes.number,
    icon: PropTypes.string,
    isCardio: PropTypes.bool,
  }).isRequired,
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default EditStation
