import React, { useState, useEffect } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import HeroPasses from './HeroPasses'
import Loading from '../shared/Loading'
import { useLoading } from '../../context/useLoading'
import withMainLayout from '../../hocs/withMainLayout'

const GET_HEROPASS = gql`
  query getHeroPass {
    heroPasses {
      id
      name
      description
      imageURL
      price
      isActive
    }
  }
`

const DELETE_HEROPASS = gql`
  mutation deleteHeroPass($id: ID!) {
    deleteHeroPass(id: $id)
  }
`

const ADD_HEROPASS = gql`
  mutation addHeroPass($input: HeroPassInput) {
    addHeroPass(input: $input) {
      id
      name
      description
      imageURL
      price
      isActive
    }
  }
`

const UPDATE_HEROPASS = gql`
  mutation updateHeroPass($id: ID!, $input: HeroPassInput) {
    updateHeroPass(id: $id, input: $input) {
      id
      name
      description
      imageURL
      price
      isActive
    }
  }
`

const HeroPassesContainer = () => {
  const [dataHeroPasses, setDataHeroPasses] = useState(null)
  const { showLoading, hideLoading } = useLoading()

  const { data, loading, error, refetch } = useQuery(GET_HEROPASS, {
    fetchPolicy: 'no-cache'
  })

  const [addHeroPass] = useMutation(ADD_HEROPASS, {
    onCompleted() {
      refetch()
      hideLoading()
      toast.success('Цена успешно создана')
    }
  })

  useEffect(() => {
    if (data && !loading && !error) {
      setDataHeroPasses(data.heroPasses)
    }
  }, [data, loading, error])

  const handleAddHeroPass = values => {
    showLoading()
    addHeroPass({
      variables: {
        input: {
          ...values,
          price: parseInt(values.price, 10),
          duration: parseInt(values.price, 10)
        }
      }
    })
  }

  if (loading && !dataHeroPasses) {
    return <Loading />
  }

  return (
    <HeroPasses
      heroPasses={dataHeroPasses}
      addHeroPassClick={handleAddHeroPass}
    />
  )
}

export default withMainLayout(HeroPassesContainer)
