import React from 'react'
import { Table, Button, Divider } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import EditForm from '../shared/EditFormModal'
import CreateForm from '../shared/CreateForm'

const CoefficientCompress = ({
  coefficients,
  deleteClick,
  addClick,
  updateClick
}) => {
  const [display, setDisplay] = React.useState('default')
  const [editValue, setEditValue] = React.useState(null)
  const [modalVisible, setModalVisible] = React.useState(false)

  const columns = [
    {
      title: 'Тип',
      dataIndex: 'type'
    },
    {
      title: 'Коэффициент сжатия',
      dataIndex: 'coefficient'
    },
    {
      title: 'Действие',
      key: 'action',
      render: (text, item) => (
        <span>
          <Button
            type="link"
            onClick={() => {
              setDisplay('edit')
              setEditValue(item)
            }}
          >
            Редактировать
          </Button>
          <Divider type="vertical" />
        </span>
      )
    }
  ]

  const fields = [
    {
      key: 'type',
      label:
        'Выберите ОС для которой хотите добавить сжатие фотографии для телефона',
      options: [
        { value: 'ios', label: 'ios' },
        { value: 'android', label: 'android' },
        { value: 'common', label: 'common' }
      ],
      tooltipText: 'IOS/ANDROID/COMMON'
    },
    {
      key: 'coefficient',
      label: 'Коэффициент сжатия от 1 до 100',
      tooltipText: 'Коэффициент',
      number: true
    }
  ]

  const editFields = [
    {
      key: 'type',
      label:
        'Выберите ОС для которой хотите изменить сжатие фотографии для телефона',
      value: editValue && editValue.type,
      options: [
        { value: 'ios', label: 'ios' },
        { value: 'android', label: 'android' },
        { value: 'common', label: 'common' }
      ],
      tooltipText: 'IOS/ANDROID/COMMON'
    },
    {
      key: 'coefficient',
      label: 'Коэффициент сжатия от 1 до 100',
      tooltipText: 'Коэффициент',
      number: true,
      value: editValue && editValue.coefficient
    }
  ]

  const handleCreate = values => {
    addClick(values)
    setModalVisible(false)
  }

  const handleUpdate = values => {
    updateClick(editValue.id, values)
    setDisplay('default')
    setEditValue(null)
  }

  const handleDelete = () => {
    deleteClick(editValue.id)
    setDisplay('default')
    setEditValue(null)
  }

  return (
    <>
      <Table
        dataSource={coefficients}
        columns={columns}
        title={() => (
          <div>
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новый коэффициент сжатия фотографии
            </Button>
          </div>
        )}
      />
      <CreateForm
        title="Добавить новый коэффициент сжатия фотографии"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onCreate={handleCreate}
        fields={fields}
      />
      {display === 'edit' && editValue && (
        <EditForm
          title="Редактировать коэффициент сжатия фотографии"
          visible={display === 'edit'}
          onCancel={() => setDisplay('default')}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          fields={editFields}
        />
      )}
    </>
  )
}

export default CoefficientCompress
