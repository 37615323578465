import React from 'react';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import Notifications from './Notifications';
import withMainLayout from '../../hocs/withMainLayout';
import Loading from '../shared/Loading';

const GET_NOTIFICATION = gql`
    query GetNotifications($query: PaginationQuery) {
        notifications(query: $query) {
            notifications {
                id
                message
                heading
                type
                created_at
            }
            total
            page
            limit
            pages
        }
    }
`;

const GET_CLUBS = gql`
    query GetClubs {
        clubs {
            id
            name
        }
    }
`;

const ADD_NOTIFICATION = gql`
    mutation addNotifications($input: NotificationInput!) {
        addNotifications(input: $input) {
            status
            message
        }
    }
`;

const GET_USERS = gql`
    query find($query: UsersQuery, $pagination: PaginationQuery) {
        findUsers(queryUsers: $query, pagination: $pagination) {
            users {
                id
                email
                phoneNumber
            }
            total
            limit
            page
            pages
        }
    }
`;


const columns = [
  {
    title: 'Заголовок',
    dataIndex: 'heading',
    width: '30%',
  },
  {
    title: 'Текст',
    dataIndex: 'message',
    width: '50%',
  },
  {
    title: 'Дата отправки',
    dataIndex: 'created_at',
    width: '20%',
  },
];

const NotificationsContainer = () => {
  const [notifications, setNotifications] = React.useState([]);
  const [heading, setHeading] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [type, setType] = React.useState('');
  const [isVisible, setIsVisible] = React.useState(false);

  const [clubs, setClubs] = React.useState([]);
  const [selectedClub, setSelectedClub] = React.useState(null);

  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [totalPages, setTotalPages] = React.useState(1);
  const [totalItems, setTotalItems] = React.useState(0);

  const [recipientType, setRecipientType] = React.useState('club'); // default to 'club' or 'users'
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [users, setUsers] = React.useState([]);

  const { data, loading, error, refetch } = useQuery(GET_NOTIFICATION, {
    variables: {
      query: {
        page,
        limit,
      },
    },
  });

  const { data: clubsData, loading: clubsLoading, error: clubsError } = useQuery(GET_CLUBS);

  const [addNotification, { loading: addNotificationLoading }] = useMutation(ADD_NOTIFICATION, {
    onCompleted: () => {
      setHeading('');
      setMessage('');
      setType('');
      setSelectedClub(null);
      toast.success('Уведомление создано успешно');
      setIsVisible(false);
      setSelectedUsers([]);
      refetch();
    },
    onError: () => {
      toast.error('Ошибка при создании уведомления');
    },
  });

  const [fetchUsers, { loading: usersLoading, data: usersData }] = useLazyQuery(GET_USERS);


  React.useEffect(() => {
    if (!loading && data && !error) {
      const formattedNotifications = data.notifications.notifications.map((item) => ({
        ...item,
        created_at: dayjs(item.created_at).format('DD MMMM YYYY | HH:mm'),
      }));
      setNotifications(formattedNotifications);
      setTotalItems(data.notifications.total);
      setTotalPages(data.notifications.pages);
    }
  }, [data, loading, error]);

  React.useEffect(() => {
    if (!clubsLoading && clubsData && !clubsError) {
      setClubs(clubsData.clubs);
    }
  }, [clubsData, clubsLoading, clubsError]);

  const handleUserSearch = (searchValue) => {
    fetchUsers({
      variables: {
        query: {
          phoneNumber: searchValue,
        },
        pagination: {
          limit: 10,
          page: 1,
        },
      },
    });
  };

  React.useEffect(() => {
    if (usersData && usersData.findUsers) {
      setUsers(usersData.findUsers.users);
    }
  }, [usersData]);

  const handleSendNotification = () => {
    if (heading && message && type) {
      const input = {
        heading,
        message,
        type,
      };

      if (recipientType === 'club' && selectedClub) {
        input.club = selectedClub;
      } else if (recipientType === 'users' && selectedUsers.length > 0) {
        input.users = selectedUsers;
      } else {
        toast.error('Пожалуйста, выберите клуб или пользователей.');
        return;
      }

      addNotification({
        variables: {
          input,
        },
      });
    } else {
      toast.error('Поля не должны быть пустыми');
    }
  };

  const handlePageChange = (newPage, newLimit) => {
    setPage(newPage);
    setLimit(newLimit);
    refetch({ page: newPage });
  };

  if (loading || addNotificationLoading || clubsLoading) {
    return <Loading />;
  }

  if (error || clubsError) {
    toast.error('Error loading data.');
    return null;
  }

  return (
    <Notifications
      heading={heading}
      message={message}
      type={type}
      notifications={notifications}
      columns={columns}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      setMessage={setMessage}
      setHeading={setHeading}
      setType={setType}
      handleSendNotification={handleSendNotification}
      pagination={{ page, limit, total: totalItems }}
      onPageChange={handlePageChange}
      clubs={clubs}
      selectedClub={selectedClub}
      setSelectedClub={setSelectedClub}
      recipientType={recipientType}
      setRecipientType={setRecipientType}
      selectedUsers={selectedUsers}
      setSelectedUsers={setSelectedUsers}
      users={users}
      handleUserSearch={handleUserSearch}
      usersLoading={usersLoading}
    />
  );
};

export default withMainLayout(NotificationsContainer);
