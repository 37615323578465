import React from 'react'
import PropTypes from 'prop-types'
import { gql, useLazyQuery } from '@apollo/client'

import { Table } from 'antd'
import styled from 'styled-components'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

const ColorfulText = styled.div`
  color: ${(p) => p.color};
`

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const GET_DATA = gql`
  query getData($userId: ID, $query: PaginationQuery) {
    pointHistoryWithPagination(userId: $userId, query: $query) {
      userPoints {
        id
        changeValue
        type
        event {
          id
          programSet {
            id
            name
          }
        }
        created_at
      }

      total
      limit
      page
      pages
    }
  }
`

const UserPoints = ({ userId }) => {
  const [userPoints, setUserPoints] = React.useState([])
  const [total, setTotal] = React.useState(0)
  const [page, setPage] = React.useState(1)
  dayjs.locale('ru')

  const [getData, { data, error, loading, refetch }] = useLazyQuery(GET_DATA, {
    fetchPolicy: 'no-cache',
  })

  React.useEffect(() => {
    getData({
      variables: {
        userId: userId,
        query: {
          page: page,
          limit: 10,
        },
      },
    })
  }, [])

  React.useEffect(() => {
    refetch({
      userId: userId,
      query: {
        page: page,
        limit: 10,
      },
    })
  }, [page])

  React.useEffect(() => {
    if (data && data.pointHistoryWithPagination && !loading) {
      setUserPoints(data.pointHistoryWithPagination.userPoints)
      setTotal(data.pointHistoryWithPagination.total)
    } else if (error) {
      console.log(`error `, error)
    }
  }, [data, loading, error])

  const renderChangeValue = (changeValue) => {
    if (changeValue > 0) {
      return <ColorfulText color="#00c000"> +{changeValue}</ColorfulText>
    } else if (changeValue < 0) {
      return <ColorfulText color="#FF4D4F"> {changeValue}</ColorfulText>
    }
    return <ColorfulText color="#000000"> {changeValue}</ColorfulText>
  }

  const renderDescription = (item) => {
    if (item.type === 'perfectWeek') {
      return <span> Бонус за идеальную неделю </span>
    }
    if (item.type === 'penalty') {
      return <span> Штраф за непосещение</span>
    }
    return <span> {item?.event?.programSet?.name} </span>
  }

  const userPointColumns = [
    {
      title: 'Очки',
      width: '15%',
      dataIndex: 'changeValue',
      render: (text, item) => renderChangeValue(item?.changeValue),
    },
    {
      title: 'Дата',
      width: '20%',
      render: (text, item) => {
        const dateReceived = dayjs(item.created_at).format('Do MMMM, YYYY')
        return <span>{dateReceived} </span>
      },
    },
    {
      title: 'Примечание',
      render: (text, item) => renderDescription(item),
    },
  ]

  return (
    <MainContainer>
      <h2> Очки</h2>
      <Table
        dataSource={userPoints}
        columns={userPointColumns}
        rowKey={(item) => item.id}
        loading={loading}
        pagination={{
          defaultPageSize: 10,
          total: total,
          current: page,
          onChange: (page, pageSize) => {
            setPage(page)
          },
          showSizeChanger: false,
        }}
      />
    </MainContainer>
  )
}

UserPoints.propTypes = {
  userPoints: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      changeValue: PropTypes.number,
      created_at: PropTypes.string,
      type: PropTypes.string,
    })
  ).isRequired,
}

export default UserPoints
