import React, { useState, useEffect } from 'react'
import { gql, useQuery, useLazyQuery } from '@apollo/client'

import withMainLayout from '../../hocs/withMainLayout'
import { useAuth } from '../../context/useAuth'
import SalesPayments from './SalesPayments'
import SalesPaymentsInfo from './SalesPaymentsInfo'
const GET_DATA = gql`
  query salesByPaginate(
    $query: PaginationQuery
    $queryType: String
    $salesmanId: ID,
    $clubId: ID
  ) {
    salesByPaginate(
      query: $query
      queryType: $queryType
      salesmanId: $salesmanId,
      clubId: $clubId
    ) {
      sales {
        id
        type
        checkLink
        checkId
        discount
        paidAmount
        paymentMethod
        user {
          id
          nickname
          phoneNumber
        }
        heroPass {
          id
          name
          price
        }
        marathonEvent {
          id
          startTime
          endTime
          marathon {
            id
            name
            price
            countClasses
          }
        }
        payment {
          id
          name
          cost
          countClasses
          ticketType
        }
        created_at
      }
      total
      limit
      page
      pages
    }
  }
`

const SalesPaymentsContainer = () => {
  const [salesPayments, setSalesPayments] = useState([])
  const [sale, setSale] = useState()
  const [total, setTotal] = React.useState(0)
  const [page, setPage] = React.useState(1)
  const [showInfo, setShowInfo] = React.useState(false)
  const [filter, setFilter] = React.useState(null)

  const { user } = useAuth()

  const [getData, { data, loading, error }] = useLazyQuery(GET_DATA)

  React.useEffect(() => {
    if (user) {
      getData({
        variables: {
          salesmanId: filter === 'added' || filter === 'salesman' ? null : user?.id,
          clubId: user?.club?.id,
          query: {
            page: page,
            limit: 10,
          },
          queryType: filter,
        },
      })
    }
  }, [user, filter, page])

  useEffect(() => {
    if (data && !loading) {
      setSalesPayments(data.salesByPaginate.sales)
      setTotal(data.salesByPaginate.total)
    }
  }, [data, loading, error])

  return (
    <>
      <SalesPayments
        salesPayments={salesPayments}
        setShowInfo={setShowInfo}
        total={total}
        page={page}
        setPage={setPage}
        setSale={setSale}
        setFilter={setFilter}
        filter={filter}
        loading={loading}
        user={user}
      />
      <SalesPaymentsInfo
        setShowInfo={setShowInfo}
        showInfo={showInfo}
        sale={sale}
      />
    </>
  )
}

export default withMainLayout(SalesPaymentsContainer)
