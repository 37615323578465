import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Table, Button, Form, Modal, Divider, Input, Switch } from 'antd'
import { Link } from 'react-router-dom'
import {
  PlusOutlined,
  MinusCircleOutlined,
  CloseOutlined,
  CheckOutlined,
} from '@ant-design/icons'

import CreateForm from '../shared/CreateForm'
import showConfirm from '../shared/DeleteConfirm'
import StyledTooltip from '../shared/StyledTooltip'
import EditRecurrent from './EditRecurrent'

const FormItem = Form.Item
const Header = styled.div`
  display: flex;
  justify-content: space-between;
`
const HeaderTitle = styled.div`
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #262626;
`

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 4,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 20,
    },
  },
}
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 20,
      offset: 4,
    },
  },
}
const Recurrents = ({
  recurrents,
  heroPassId,
  addRecurrentClick,
  updateRecurrentClick,
  deleteRecurrentClick,
}) => {
  const [form] = Form.useForm()
  const [editForm] = Form.useForm()
  const [display, setDisplay] = useState('default')
  const [editRecurrent, setEditRecurrent] = useState(null)

  const columns = [
    {
      title: 'Название',
      dataIndex: 'name',
    },
    {
      title: 'Активен',
      render: (text, item) => (
        <span style={{ color: item?.isActive ? 'green' : 'red' }}>
          {item?.isActive ? 'Активен' : 'Неактивен'}
        </span>
      ),
    },
    {
      title: 'Действие',
      key: 'action',
      render: (text, item) => (
        <span>
          <Button
            type="link"
            onClick={() => {
              setDisplay('edit')
              setEditRecurrent(item)
            }}
          >
            Редактировать
          </Button>
          <Divider type="vertical" />
        </span>
      ),
    },
  ]

  const handleCreate = (values) => {
    addRecurrentClick(values)
    setDisplay('default')
  }

  const handleUpdate = (values) => {
    updateRecurrentClick(editRecurrent.id, values)
    setDisplay('default')
    setEditRecurrent(null)
  }

  const handleDelete = () => {
    deleteRecurrentClick(editRecurrent.id)
    setDisplay('default')
    setEditRecurrent(null)
  }

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />
  }

  return (
    <>
      <Table
        dataSource={recurrents}
        columns={columns}
        title={() => (
          <Header>
            <HeaderTitle>Станции</HeaderTitle>
            <Button
              type="primary"
              onClick={() => {
                setDisplay('add')
              }}
            >
              <PlusOutlined /> Новый рекуррентный платеж
            </Button>
          </Header>
        )}
      />
      {display === 'add' && (
        <Modal
          open={display === 'add'}
          title="Создать рекуррентный платеж"
          okText="Создать"
          cancelText="Закрыть"
          onCancel={() => {
            form.resetFields()
            setDisplay('default')
            setEditRecurrent(null)
          }}
          footer={[
            <Button
              key="submit"
              onClick={() => {
                form.resetFields()
                setDisplay('default')
                setEditRecurrent(null)
              }}
            >
              Отмена
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                form
                  .validateFields()
                  .then((values) => {
                    form.resetFields()
                    addRecurrentClick(values)
                    setDisplay('default')
                  })
                  .catch((info) => {
                    console.log('Validate Failed:', info)
                  })
              }}
            >
              Сохранить
            </Button>,
          ]}
        >
          <Form form={form} layout="vertical" hideRequiredMark>
            <FormItem
              key="name"
              label={getTooltip('Название', 'Название')}
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Укажите название',
                },
              ]}
            >
              <Input placeholder="Название" />
            </FormItem>
            <FormItem
              key="freezingCount"
              label={getTooltip('Кол-во заморозок', 'Кол-во заморозок')}
              name="freezingCount"
              rules={[
                {
                  required: true,
                  message: 'Укажите кол-во заморозок',
                },
              ]}
            >
              <Input placeholder="Кол-во заморозок" type="number" />
            </FormItem>
            <FormItem
              key="freezingDays"
              label={getTooltip(
                'Кол-во дней заморозок',
                'Кол-во дней заморозок'
              )}
              name="freezingDays"
              rules={[
                {
                  required: true,
                  message: 'Укажите кол-во дней заморозок',
                },
              ]}
            >
              <Input placeholder="Кол-во дней заморозок" type="number" />
            </FormItem>
            <Form.List
              name="monthPayments"
              rules={[
                {
                  validator: async (_, monthPayments) => {
                    if (!monthPayments || monthPayments.length < 1) {
                      return Promise.reject(new Error('At least 2 passengers'))
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item
                      {...formItemLayoutWithOutLabel}
                      label={index === 0 ? 'Месячные платежи' : ''}
                      required={false}
                      key={field.key}
                    >
                      <Form.Item
                        {...field}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message:
                              "Please input passenger's name or delete this field.",
                          },
                        ]}
                        noStyle
                      >
                        <Input
                          type="number"
                          placeholder={`Цена ${index + 1} месяца`}
                          style={{
                            width: '60%',
                          }}
                        />
                      </Form.Item>
                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => remove(field.name)}
                        />
                      ) : null}
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      // style={{
                      //   width: '60%'
                      // }}
                      icon={<PlusOutlined />}
                    >
                      Добавить месяц
                    </Button>

                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
        </Modal>
      )}
      {display === 'edit' && editRecurrent && (
        <EditRecurrent
          title="Редактировать рекуррентный платеж"
          visible={display === 'edit'}
          onCancel={() => {
            setDisplay('default')
            setEditRecurrent(null)
          }}
          onUpdate={handleUpdate}
          editRecurrent={editRecurrent}
          onDelete={handleDelete}
        />
      )}
    </>
  )
}

Recurrents.propTypes = {
  recurrents: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  heroPassId: PropTypes.string.isRequired,
  addRecurrentClick: PropTypes.func.isRequired,
}

export default Recurrents
