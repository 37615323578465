import { Button, Modal } from 'antd'
import React from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'

const ButtonOk = styled.button`
  width: 100%;
  background-color: rgba(111, 44, 255, 1);
  border-radius: 8px;
  border: none;
  height: 56px;
  color: rgba(255, 255, 255, 1);
  font-family: 'GraphikLCGMedium';
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
  &:hover {
    transition: all 0.3s;
    border: 1px solid rgba(111, 44, 255, 1);
    background-color: white;
    color: rgba(111, 44, 255, 1);
  }
`
const Input = styled.input`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 100%;
  padding: 10px;
  font-family: 'GraphikLCGMedium';
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 8px;
  &:focus {
    outline: none;
    border: 1px solid #6f2cff;
  }
`

const TransferModal = ({
  setVisible,
  visible,
  selectUser,
  activeMarathon,
  handleTransferNotUsedMarathon,
  marathonToTransfer,
}) => {
  return (
    <Modal
      open={visible}
      centered
      footer={null}
      width={368}
      onCancel={() => setVisible(false)}
    >
      <div style={{ paddingBottom: 10, fontFamily: 'GraphikLCGMedium' }}>
        Вы подтверждаете перенос марафона на{' '}
        {dayjs(marathonToTransfer?.startTime).format('DD.MM.YYYY')}?{' '}
      </div>
      <ButtonOk
        onClick={() => {
          {
            handleTransferNotUsedMarathon({
              marathonEventId: marathonToTransfer.id,
              marathonId: activeMarathon.marathon.id,
              id: selectUser.id,
            })
            setVisible(false)
          }
        }}
      >
        Подтверждаю
      </ButtonOk>
    </Modal>
  )
}

export default TransferModal
