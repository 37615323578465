import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Layout, Menu, Breadcrumb, Button, Select, Form, Input } from 'antd'
import { Link } from 'react-router-dom'
import { InfoCircleOutlined, GatewayOutlined } from '@ant-design/icons'
import EmailEditor from 'react-email-editor'

import StyledTooltip from '../shared/StyledTooltip'
import showConfirm from '../shared/DeleteConfirm'

const FormItem = Form.Item

const { Header, Sider, Content } = Layout

const StyledBreadcrumb = styled(Breadcrumb)`
  margin: 16px 0;
`

const StyledHeader = styled(Header)`
  background: #fff;
  border-bottom: 1px solid #d9d9d9;
  padding: 0px 24px;
`

const StyledMenu = styled(Menu)`
  height: 100%;
  padding-top: 16px;
`
const StyledLayout = styled(Layout)`
  padding: 0 24px 24px;
  background: #fff;
`

const StyledContent = styled(Content)`
  padding: 24px;
  margin: 0px;
  min-height: 280px;
`
const HeaderTitle = styled.div`
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #262626;
  margin-bottom: 24px;
`

const Message = ({
  message,
  allUsers,
  allLevels,
  updateClick,
  deleteClick,
  refetchUsers
}) => {
  const [currentTab, setCurrentTab] = useState(1)
  const [type, setType] = useState(message.type)
  const [title, setTitle] = useState(message.title)
  const [level, setLevel] = useState(message.level)
  const [users, setUsers] = useState(message.users.map(user => user.id))

  const handleTabChange = activeKey => {
    setCurrentTab(activeKey)
  }

  const emailEditorRef = React.useRef(null)

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml(data => {
      const { design, html } = data
      const templateJson = JSON.stringify(design)
      updateClick({ html, json: templateJson })
    })
  }

  const onLoad = () => {
    const templateJson = JSON.parse(message.json)
    if (emailEditorRef.current) {
      emailEditorRef.current.editor.loadDesign(templateJson)
    }
  }

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />
  }

  const onFinish = values => {
    updateClick(values)
  }

  const handleSearch = value => {
    refetchUsers({
      query: {
        phoneNumber: value
      },
      pagination: {
        page: 1,
        limit: 20
      }
    })
  }

  return (
    <Layout>
      <StyledBreadcrumb>
        <Breadcrumb.Item>
          <Link to="/messages">Сообщения</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{message?.title}</Breadcrumb.Item>
      </StyledBreadcrumb>
      <StyledHeader>
        <h2>Редактирование сообщения</h2>
      </StyledHeader>
      <Layout>
        <Sider>
          <StyledMenu mode="inline" defaultSelectedKeys={['1']}>
            <Menu.Item
              icon={<InfoCircleOutlined />}
              key="1"
              onClick={() => handleTabChange(1)}
            >
              Основные
            </Menu.Item>
            <Menu.Item
              icon={<GatewayOutlined />}
              key="2"
              onClick={() => handleTabChange(2)}
            >
              Текст Сообщения
            </Menu.Item>
          </StyledMenu>
        </Sider>
        <StyledLayout>
          <StyledContent className="site-layout-background">
            {currentTab === 1 && (
              <>
                <HeaderTitle>Основные</HeaderTitle>
                <Form layout="vertical" hideRequiredMark onFinish={onFinish}>
                  <FormItem
                    key="title"
                    label={getTooltip('Заголовок', 'Заголовок сообщения')}
                    name="title"
                    initialValue={message.title}
                  >
                    <Input
                      placeholder="Заголовок"
                      onChange={value => {
                        setTitle(value.target.value)
                      }}
                    />
                  </FormItem>
                  <FormItem
                    key="type"
                    label={getTooltip('Тип', 'Тип сообщения')}
                    name="type"
                    initialValue={message.type}
                  >
                    <Select
                      placeholder="Тип сообщения"
                      showSearch
                      onChange={value => setType(value)}
                    >
                      <Select.Option key="broadcast" value="broadcast">
                        broadcast
                      </Select.Option>
                      <Select.Option key="onlyUsers" value="onlyUsers">
                        onlyUsers
                      </Select.Option>
                      <Select.Option
                        key="onlyCertainLevel"
                        value="onlyCertainLevel"
                      >
                        onlyCertainLevel
                      </Select.Option>
                    </Select>
                  </FormItem>
                  {type === 'onlyUsers' && (
                    <FormItem
                      key="users"
                      label={getTooltip(
                        'Пользователи',
                        'Пользователи которые получать сообщения'
                      )}
                      name="users"
                      initialValue={message.users.map(user => user.id)}
                    >
                      <Select
                        filterOption={false}
                        mode="multiple"
                        placeholder="Пользователи"
                        showSearch
                        onChange={value => {
                          setUsers(value)
                        }}
                      >
                        {allUsers &&
                          allUsers.map(user => (
                            <Select.Option key={user.id} value={user.id}>
                              {user.email} | {user.phoneNumber}
                            </Select.Option>
                          ))}
                      </Select>
                    </FormItem>
                  )}
                  {type === 'onlyCertainLevel' && (
                    <FormItem
                      key="level"
                      label={getTooltip(
                        'Уровень для которого предназначено это сообщение',
                        'Уровень для которого предназначено это сообщение'
                      )}
                      name="level"
                      initialValue={message.level?.id}
                    >
                      <Select
                        placeholder="Уровень"
                        onChange={value => {
                          setLevel(value)
                        }}
                      >
                        {allLevels &&
                          allLevels.map(level => (
                            <Select.Option key={level.id} value={level.id}>
                              {level.name} | degree: {level.degree}
                            </Select.Option>
                          ))}
                      </Select>
                    </FormItem>
                  )}
                  <Form.Item style={{ textAlign: 'right' }}>
                    <Button
                      danger
                      style={{ float: 'left' }}
                      onClick={() => {
                        showConfirm(() => {
                          deleteClick()
                        })
                      }}
                    >
                      Удалить
                    </Button>
                    <Button type="primary" htmlType="submit">
                      Сохранить изменения
                    </Button>
                  </Form.Item>
                </Form>
              </>
            )}
            {currentTab === 2 && (
              <>
                <EmailEditor ref={emailEditorRef} onLoad={onLoad} />
                <Button
                  type="primary"
                  onClick={exportHtml}
                  style={{ marginTop: 24 }}
                >
                  Сохранить
                </Button>
              </>
            )}
          </StyledContent>
        </StyledLayout>
      </Layout>
    </Layout>
  )
}
Message.propTypes = {
  message: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
    html: PropTypes.string,
    json: PropTypes.string
  }).isRequired,
  allUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      email: PropTypes.string
    })
  ).isRequired,
  allLevels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      degree: PropTypes.number
    })
  ).isRequired,
  updateClick: PropTypes.func.isRequired,
  deleteClick: PropTypes.func.isRequired
}
export default Message
