import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Modal, Form, DatePicker, Space, Card, Input } from 'antd'
import datePickerLocale from 'antd/es/date-picker/locale/ru_RU'
import dayjs from 'dayjs'

import StyledTooltip from '../shared/StyledTooltip'

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const UpdateMarathonLevelModal = ({
  modalVisible,
  userMarathonEvent,
  handleUpdateUser,
  onCancel,
}) => {
  const [form] = Form.useForm()
  const [values, setValues] = React.useState({})

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />
  }

  const handleOkClick = () => {
    handleUpdateUser({ values })
    onCancel()
    onCancel(false)
  }

  const getTitle = ({ task }) => {
    switch (task?.type) {
      case 'visits':
        return `Посетиить: ${task?.visits}`
      case 'visitsToClass':
        return `${task?.typeOfClass?.toUpperCase()}: ${task.visits}`
      case 'visitsToClassMultiple':
        return `${task?.typeOfClassMultiple?.map((typeOfClass, index) => {
          return typeOfClass?.toUpperCase()
        })}: ${task.visits}`
      case 'lecture':
        return `Прочитать лекции: ${task.countReads}`
      default:
        return ''
    }
  }

  return (
    <Modal
      open={modalVisible}
      title="Редактировать прогресс"
      onCancel={() => {
        form.resetFields()
        onCancel()
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields()
            const { basicTime, tasksProgress } = values

            handleUpdateUser({
              id: userMarathonEvent?.id,
              basicTime: basicTime,
              tasksProgress: tasksProgress,
            })
            onCancel()
          })
          .catch((info) => {
            console.log('Validate Failed:', info)
          })
      }}
      cancelText="Отмена"
    >
      <MainContainer>
        <Form form={form} layout="vertical">
          <Form.Item
            key="basicTime"
            name="basicTime"
            label={getTooltip(
              'Стартовое время',
              'C какого времени считать прогресс'
            )}
            initialValue={
              userMarathonEvent?.basicTime
                ? dayjs(userMarathonEvent?.basicTime)
                : dayjs(userMarathonEvent?.marathonEvent?.startTime)
            }
          >
            <DatePicker
              locale={datePickerLocale}
              placeholder="Выберите дату начала"
              format="DD.MM.YYYY"
              style={{
                width: '100%',
                color: 'black',
              }}
            />
          </Form.Item>
          <Form.List
            key="tasksProgress"
            name="tasksProgress"
            label="Задания"
            initialValue={userMarathonEvent?.tasksProgress.map(
              (taskProgress) => {
                return {
                  task: taskProgress?.task,
                  completedCnt: taskProgress?.completedCnt,
                  taskId: taskProgress?.task?.id,
                }
              }
            )}
          >
            {(fields) => {
              return (
                <>
                  {fields.map((field, index) => {
                    const title = getTitle({
                      task: userMarathonEvent?.tasksProgress[field.key].task,
                    })
                    return (
                      <Space
                        direction="horizontal"
                        size={16}
                        style={{ marginRight: index % 2 === 0 ? 8 : 0 }}
                      >
                        <Card
                          title={`${title}`}
                          key={field.key}
                          style={{ width: '232px' }}
                        >
                          <Form.Item
                            style={{ display: 'none' }}
                            name={[field.name, 'taskId']}
                          >
                            <Input placeholder="Занаие" />
                          </Form.Item>
                          <Form.Item
                            name={[field.name, 'completedCnt']}
                            rules={[
                              {
                                required: true,
                                message: 'Укажите количество',
                              },
                            ]}
                          >
                            <Input placeholder="Количество" />
                          </Form.Item>
                        </Card>
                      </Space>
                    )
                  })}
                </>
              )
            }}
          </Form.List>
        </Form>
      </MainContainer>
    </Modal>
  )
}

export default UpdateMarathonLevelModal
