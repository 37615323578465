import React from 'react'
import styled from 'styled-components'
import { Form, Button, DatePicker, Select } from 'antd'
import dayjs from 'dayjs'

import showConfirm from '../shared/DeleteConfirm'
import StyledTooltip from '../shared/StyledTooltip'

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`

const FormItem = Form.Item

const MarathonEventGeneral = ({
  marathonEvent,
  trainers,
  handleUpdateMarathonEvent,
  handleDeleteMarathonEvent,
}) => {
  const [form] = Form.useForm()
  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />
  }
  return (
    <MainContainer>
      <h2> Основные </h2>
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        onFinish={handleUpdateMarathonEvent}
      >
        <FormItem
          key="startTime"
          label={getTooltip('Начало марафона', 'Дата когда начинается марафон')}
          name="startTime"
          initialValue={dayjs(marathonEvent?.startTime)}
        >
          <StyledDatePicker showTime placeholder="marathon event start" />
        </FormItem>

        <FormItem
          key="endTime"
          label={getTooltip(
            'Конец марафона',
            'Дата когда заканчивается марафон'
          )}
          name="endTime"
          rules={[
            {
              required: true,
              message: `Пожалуйста, укажите дату окончания марафона`,
            },
          ]}
          initialValue={dayjs(marathonEvent?.endTime)}
        >
          <StyledDatePicker showTime placeholder="marathon event end" />
        </FormItem>

        <FormItem
          key="trainer"
          label="Клуб"
          name="trainer"
          rules={[
            {
              required: true,
              message: `Пожалуйста, выберите тренера`,
            },
          ]}
          initialValue={marathonEvent?.trainer?.id}
        >
          <Select placeholder="Выберите клуб" showSearch>
            {trainers.map((trainer) => (
              <Select.Option key={trainer.id} value={trainer.id}>
                {trainer.nickname}
              </Select.Option>
            ))}
          </Select>
        </FormItem>

        <FormItem>
          <ButtonContainer>
            {/* <Button
              danger
              onClick={() => {
                showConfirm(() => {
                  handleDeleteMarathonEvent()
                })
              }}
            >
              Удалить
            </Button> */}
            <Button type="primary" htmlType="submit">
              Сохранить изменения
            </Button>
          </ButtonContainer>
        </FormItem>
      </Form>
    </MainContainer>
  )
}

export default MarathonEventGeneral
