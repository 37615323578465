import {
  AlertOutlined,
  BlockOutlined,
  CalendarOutlined,
  CodeSandboxOutlined,
  CrownOutlined,
  DashboardOutlined,
  DingtalkOutlined,
  DollarCircleOutlined,
  DollarOutlined,
  DropboxOutlined,
  GiftOutlined,
  HeartOutlined,
  InsertRowAboveOutlined,
  LogoutOutlined,
  MessageOutlined,
  MoneyCollectOutlined,
  NodeIndexOutlined,
  NotificationOutlined,
  PicCenterOutlined,
  PictureOutlined,
  ProfileOutlined,
  RadarChartOutlined,
  RobotOutlined,
  RocketOutlined,
  SettingOutlined,
  ShopOutlined,
  StarOutlined,
  TeamOutlined,
  ThunderboltOutlined,
  TrophyOutlined,
  UserOutlined
} from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'

import Loading from '../pages/shared/Loading'
import { Menu } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { useAuth } from '../context/useAuth'

// import ConversionClientsLogo from '../../src/images/ConversionClientsLogo.svg'

function MainMenu({ currentUrl }) {
  const { user, logout } = useAuth()
  const navigate = useNavigate()

  const adminMenu = [
    { link: '/events', name: 'События', icon: CalendarOutlined },
    { link: '/users', name: 'Пользователи', icon: TeamOutlined },
    { link: '/programSets', name: 'Программы', icon: ProfileOutlined },
    { link: '/exercises', name: 'Упражнения', icon: ThunderboltOutlined },
    { link: '/levels', name: 'Уровни', icon: CrownOutlined },
    { link: '/heros', name: 'Персонажи', icon: UserOutlined },
    { link: '/clubs', name: 'Клубы', icon: ShopOutlined },
    { link: '/payments', name: 'Пакеты', icon: DollarCircleOutlined },
    {
      link: '/allUserPayments',
      name: 'Платежи клиентов',
      icon: MoneyCollectOutlined,
    },
    { link: '/messages', name: 'Сообщения', icon: MessageOutlined },
    { link: '/notifications', name: 'Нотификация', icon: NotificationOutlined },
    { link: '/awards', name: 'Награды', icon: RadarChartOutlined },
    {
      link: '/treasureChests',
      name: 'Сундуки',
      icon: DropboxOutlined,
    },
    {
      link: '/allDroppedRewards',
      name: 'Выпадения с сундуков',
      icon: GiftOutlined,
    },
    {
      link: '/marathons',
      name: 'Марафоны',
      icon: TrophyOutlined,
    },
    {
      link: '/heroPathes',
      name: 'Пути Марафона',
      icon: NodeIndexOutlined,
    },
    {
      link: '/specialBookings',
      name: 'Акции',
      icon: DashboardOutlined,
    },
    {
      link: '/autoNotifications',
      name: 'Автоматические нотификации',
      icon: NotificationOutlined,
    },
    {
      link: '/coefficientsCompress',
      name: 'Настройки приложения',
      icon: SettingOutlined,
    },
    {
      link: '/salesReport',
      name: 'Отчёт по продажам',
      icon: DollarOutlined,
    },
    {
      link: '/newsModals',
      name: 'Новости',
      icon: PicCenterOutlined,
    },
    {
      link: '/products',
      name: 'Товары',
      icon: CodeSandboxOutlined,
    },
    {
      link: '/salesPayments',
      name: 'Платежи',
      icon: MoneyCollectOutlined,
    },
    {
      link: '/heroPasses',
      name: 'HeroPasses',
      icon: StarOutlined,
    },
    {
      link: '/promptDescriptions',
      name: 'Параметры бота',
      icon: RobotOutlined,
    },
    {
      link: '/conversionContainer',
      name: 'Конверсия ПШ',
      icon: TeamOutlined,
    },
    {
      link: '/faqContainer',
      name: 'FAQ',
      icon:MessageOutlined,
    },
    {
      link: '/support',
      name: 'Тех. Поддержка',
      icon: AlertOutlined,
    },
    {
      link: '/tickets',
      name: 'Служба поддержки',
      icon: MessageOutlined,
    },
    {
      link: '/supportCategories',
      name: 'Категории тех.поддержки',
      icon: ProfileOutlined
    },
        { 
     link: '/herosWeek', 
     name: 'Hero`s week', 
     icon: InsertRowAboveOutlined
    },
  ]

  const doctorMenu = []

  const trainerMenu = [
    {
      link: '/trainerEvents',
      name: 'События',
      icon: CalendarOutlined,
    },
    {
      link: '/trainerReviews',
      name: 'Оценки',
      icon: StarOutlined,
    },
  ]

  const photographerMenu = [
    // {
    //   link: '/userPhotos',
    //   name: 'Трансформация',
    //   icon: PictureOutlined
    // }
  ]

  const receptionMenu = [
    {
      link: '/receptionEvents',
      name: 'События',
      icon: CalendarOutlined,
    },
  ]

  const salespersonMenu = [
    {
      link: '/receptionEvents',
      name: 'События',
      icon: CalendarOutlined,
    },
    { link: '/events', name: 'Расписание', icon: CalendarOutlined },
    {
      link: '/salesPayments',
      name: 'Платежи клиентов',
      icon: MoneyCollectOutlined,
    },
    {
      link: '/clientsContainer',
      name: 'Клиенты',
      icon: TeamOutlined,
    },
    {
      link: '/marathons',
      name: 'Марафоны',
      icon: TrophyOutlined,
    },
    {
      link: '/conversionContainer',
      name: 'Конверсия ПШ',
      icon: TeamOutlined,
    },
    { 
     link: '/herosWeek', 
     name: 'Hero`s week', 
     icon: InsertRowAboveOutlined
    },
  ]

  const accountantnMenu = [
    {
      link: '/salesReport',
      name: 'Отчёт по продажам',
      icon: DollarOutlined,
    },
  ]

  const userToMenu = {
    admin: adminMenu,
    doctor: doctorMenu,
    trainer: trainerMenu,
    photographer: photographerMenu,
    reception: receptionMenu,
    salesperson: salespersonMenu,
    salesadmin: salespersonMenu,
    accountant: accountantnMenu,
  }

  if (!user) {
    return <Loading />
  }

  const currentMenu = userToMenu[user.role] || []

  const onLogoutClick = () => {
    logout()
    navigate('/login')
  }

  if (user) {
    return (
      <Menu selectedKeys={[currentUrl]} mode="inline" theme="light">
        {currentMenu.map((item) => (
          <Menu.Item key={item.link} icon={<item.icon />}>
            <Link to={item.link} key={item.link}>
              {item.name}
            </Link>
          </Menu.Item>
        ))}

        <Menu.Item
          key="logout"
          icon={<LogoutOutlined />}
          onClick={onLogoutClick}
          danger
        >
          Выйти
        </Menu.Item>
      </Menu>
    )
  }
  return <React.Fragment />
}

MainMenu.propTypes = {
  currentUrl: PropTypes.string.isRequired,
}

export default MainMenu
