import React, { useState, useEffect } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import HeroPathes from './HeroPathes'
import Loading from '../shared/Loading'
import { useLoading } from '../../context/useLoading'
import withMainLayout from '../../hocs/withMainLayout'

const GET_HEROPATH = gql`
  query getHeroPath {
    heroPathes {
      id
      name
      identifier
    }
  }
`

const DELETE_HEROPATH = gql`
  mutation deleteHeroPath($id: ID!) {
    deleteHeroPath(id: $id)
  }
`

const ADD_HEROPATH = gql`
  mutation addHeroPath($input: HeroPathInput) {
    addHeroPath(input: $input) {
      id
      name
      identifier
    }
  }
`

const UPDATE_HEROPATH = gql`
  mutation updateHeroPath($id: ID!, $input: HeroPathInput) {
    updateHeroPath(id: $id, input: $input) {
      id
      name
      identifier
    }
  }
`

const HeroPathesContainer = () => {
  const [dataHeroPathes, setDataHeroPathes] = useState(null)
  const { showLoading, hideLoading } = useLoading()

  const [updateHeroPath] = useMutation(UPDATE_HEROPATH)

  const { data, loading, error, refetch } = useQuery(GET_HEROPATH)

  const [deleteHeroPath] = useMutation(DELETE_HEROPATH, {
    update(cache, { data: { deleteHeroPath: id } }) {
      const { heroPathes } = cache.readQuery({ query: GET_HEROPATH })
      cache.writeQuery({
        query: GET_HEROPATH,
        data: {
          heroPathes: heroPathes.filter(heroPath => heroPath.id !== id)
        }
      })
      hideLoading()
      toast.success('Цена успешно удалена')
    },
    onCompleted: () => {
      refetch()
    }
  })

  const [addHeroPath] = useMutation(ADD_HEROPATH, {
    update(cache, { data: { addHeroPath: heroPath } }) {
      const { heroPathes } = cache.readQuery({ query: GET_HEROPATH })
      cache.writeQuery({
        query: GET_HEROPATH,
        data: { heroPathes: heroPathes.concat([heroPath]) }
      })
      hideLoading()
      toast.success('Цена успешно создана')
    }
  })

  useEffect(() => {
    if (data && !loading && !error) {
      setDataHeroPathes(data.heroPathes)
    }
  }, [data, loading, error])

  const handleDeleteHeroPath = heroPathId => {
    showLoading()
    deleteHeroPath({ variables: { id: heroPathId } })
  }

  const handleAddHeroPath = values => {
    showLoading()
    addHeroPath({
      variables: {
        input: values
      }
    })
  }

  const handleUpdateHeroPath = (id, values) => {
    updateHeroPath({ variables: { id, input: values } })
  }

  if (loading && !dataHeroPathes) {
    return <Loading />
  }

  return (
    <HeroPathes
      heroPathes={dataHeroPathes}
      deleteHeroPathClick={handleDeleteHeroPath}
      addHeroPathClick={handleAddHeroPath}
      updateHeroPathClick={handleUpdateHeroPath}
    />
  )
}

export default withMainLayout(HeroPathesContainer)
