import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { Button, Modal, Form, Input, Avatar, Select } from 'antd'

import ImageUpload from '../shared/ImageUpload'
import StyledTooltip from '../shared/StyledTooltip'
import showConfirm from '../shared/DeleteConfirm'
import { useAuth } from '../../context/useAuth'

const FormItem = Form.Item

const ImageUploadContainer = styled.div`
  display: flex;
  flex-direction: row;
`
const StyledAvatar = styled(Avatar)`
  margin-left: 5px;
`

const EditUserPhoto = ({
  visible,
  onCancel,
  title,
  editUserPhoto,
  onUpdate,
  onDelete,
  bodyTests,
}) => {
  const { user } = useAuth()
  const [imageURL, setImageURL] = React.useState(null)
  const [compressedImageURL, setCompressedImageURL] = React.useState(null)

  const [form] = Form.useForm()

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />
  }

  return (
    <Modal
      open={visible}
      title={title}
      okText="Создать"
      cancelText="Закрыть"
      onCancel={onCancel}
      footer={[
        user.role === 'admin' && (
          <Button
            danger
            style={{ float: 'left' }}
            onClick={() => {
              showConfirm(() => {
                onDelete()
              })
            }}
          >
            Удалить
          </Button>
        ),
        [
          <Button key="submit" onClick={onCancel}>
            Отмена
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  form.resetFields()
                  if (imageURL) {
                    onUpdate({ ...values, imageURL, compressedImageURL })
                  } else {
                    onUpdate(values)
                  }
                })
                .catch((info) => {
                  console.log('Validate Failed:', info)
                })
            }}
          >
            Сохранить
          </Button>,
        ],
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          imageURL: editUserPhoto.imageURL,
          bodyMeasurement: editUserPhoto.bodyMeasurement,
          bodyTest: editUserPhoto.bodyTest?.id,
        }}
      >
        <FormItem
          key="imageURL"
          label={getTooltip('Фотка', 'Фотка')}
          name="imageURL"
          rules={[
            {
              required: false,
              message: `Пожалуйста, загрузите обложку класса`,
            },
          ]}
        >
          <ImageUploadContainer>
            <ImageUpload
              onUpdate={(value) => {
                setImageURL(value)
              }}
              onUpdateCompressed={(value) => {
                setCompressedImageURL(value)
              }}
              uploadLink="/api/uploadImageWithCompressed"
            />
            <StyledAvatar
              size="96"
              shape="square"
              src={imageURL || editUserPhoto.imageURL}
            />
          </ImageUploadContainer>
        </FormItem>
        <FormItem
          key="bodyTest"
          label={getTooltip(
            'Фитнес тест',
            'Выберите фитнес тест вручную, если требуется'
          )}
          name="bodyTest"
        >
          <Select placeholder="Фитнес тест">
            {bodyTests.map((bodyTest) => (
              <Select.Option value={bodyTest.id}>
                {dayjs(bodyTest.testDate).format('Do MMMM, YYYY')} | Процент
                жира = {bodyTest.fatPercent}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          key="bodyMeasurement"
          label={getTooltip('Замеры', 'Замеры пользователя')}
          name="bodyMeasurement"
        >
          <FormItem
            key={['bodyMeasurement', 'waist']}
            label={getTooltip('Талия', 'Талия')}
            name={['bodyMeasurement', 'waist']}
          >
            <Input type="number" placeholder="Талия" />
          </FormItem>
        </FormItem>
      </Form>
    </Modal>
  )
}

EditUserPhoto.propTypes = {
  editUserPhoto: PropTypes.objectOf().isRequired,
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  bodyTests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      testDate: PropTypes.string,
      fatPercent: PropTypes.number,
    })
  ).isRequired,
}

export default EditUserPhoto
