import React from 'react'
import styled from 'styled-components'
import { Table, Button, Form, Modal, Input } from 'antd'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

import CommentModal from '../conversionClients/CommentModal'
import Loading from '../shared/Loading'

const GuestsClients = ({ guestUsers, guestUsersPage, setGuestUsersPage }) => {
  const columns = [
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray',
            }}
          >
            Имя
          </div>
        )
      },
      dataIndex: ['user', 'firstName'],
      width: '25%',
      render: (text, item) => <>{item?.userRecipient?.firstName}</>,
    },
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray',
            }}
          >
            Номер
          </div>
        )
      },
      dataIndex: ['user', 'phoneNumber'],
      width: '25%',
      render: (text, item) => <>{item?.userRecipient?.phoneNumber}</>,
    },
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray',
            }}
          >
            Дата получения
          </div>
        )
      },
      dataIndex: 'endTime',
      width: '25%',
      render: (text, item) => {
        const endTime = dayjs(item?.created_at)
        return (
          <div>
            <span>{endTime.format('DD.MM.YY')}</span>
          </div>
        )
      },
    },
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray',
            }}
          >
            Дата окончания
          </div>
        )
      },
      dataIndex: 'endTime',
      width: '25%',
      render: (text, item) => {
        const endTime = dayjs(item?.expiryDate)
        return (
          <div>
            <span>{endTime.format('DD.MM.YY')}</span>
          </div>
        )
      },
    },
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray',
            }}
          >
            Количество занятий
          </div>
        )
      },
      dataIndex: ['user', 'phoneNumber'],
      width: '25%',
      render: (text, item) => (
        <>
          {item?.ticketsLeft}/{item?.guestTickets}
        </>
      ),
    },
  ]
  if (!guestUsers) {
    return <Loading />
  }

  return (
    <>
      <Table
        dataSource={guestUsers?.userGuestTicket}
        columns={columns}
        rowKey={(item) => item?.id}
        pagination={{
          defaultPageSize: 10,
          total: guestUsers?.total,
          current: guestUsersPage,
          onChange: (guestUsersPage, pageSize) => {
            setGuestUsersPage(guestUsersPage)
          },
          showSizeChanger: false,
        }}
      />
    </>
  )
}

export default GuestsClients
