import React, { useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { Input, Modal, DatePicker, Checkbox, Form, Select } from 'antd'
import StyledTooltip from '../shared/StyledTooltip'

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const AddTicketsModal = ({
  handleAddTickets,
  modalVisible,
  isAddModal,
  onCancel,
  currentTickets,
}) => {
  const [isExpiry, setIsExpiry] = useState(false)
  const [values, setValues] = useState({
    expiryDate: dayjs().format(),
    changeValue: 0,
    comment: '',
  })

  const handleInputChange = (field, value) => {
    setValues({
      ...values,
      [field]: value,
    })
  }

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />
  }

  const handleOkClick = () => {
    if (isAddModal) {
      console.log('values', values)
      handleAddTickets({
        values,
        isExpiry,
      })
    } else {
      const negativeTickets = -values.changeValue
      const newValues = {
        ...values,
        changeValue: negativeTickets,
      }
      handleAddTickets({
        values: newValues,

        isExpiry,
      })
    }
    onCancel()
  }

  return (
    <Modal
      open={modalVisible}
      onCancel={onCancel}
      onOk={handleOkClick}
      cancelText="Отмена"
      okText={isAddModal ? 'Пополнить' : 'Убрать'}
      okButtonProps={{
        danger: !isAddModal,
      }}
    >
      <MainContainer>
        <h2>Баланс: {currentTickets}</h2>
        <Form layout="vertical">
          <Form.Item>
            <label>
              {getTooltip(
                'Количество билетов',
                'Сколько билетов добавится пользователю'
              )}
            </label>
            <Input
              type="number"
              value={values?.changeValue}
              onChange={(e) => handleInputChange('changeValue', e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <label>
              {getTooltip(
                'Тип Билета',
                'Какой тип у билетов которые вы добавляете'
              )}
            </label>
            <Select
              mode="single"
              onChange={(value) => handleInputChange('ticketType', value)}
              placeholder="Choose type"
            >
              <Select.Option key="tickets" value="tickets">
                tickets
              </Select.Option>
              <Select.Option key="marathon" value="marathon">
                marathon
              </Select.Option>
              <Select.Option key="assessment" value="assessment">
                assessment
              </Select.Option>
              <Select.Option key="education" value="education">
                education
              </Select.Option>
              <Select.Option key="endGame" value="endGame">
                endGame
              </Select.Option>
              <Select.Option key="guest" value="guest">
                Гостевые
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <label>{getTooltip('Комментарий', 'Зачем добавили билеты?')}</label>
            <Input
              value={values?.comment}
              onChange={(e) => handleInputChange('comment', e.target.value)}
            />
          </Form.Item>
          {isAddModal && (
            <Form.Item>
              <Checkbox
                checked={isExpiry}
                onChange={(e) => setIsExpiry(e.target.checked)}
              >
                Билеты со сроком годности?
              </Checkbox>
            </Form.Item>
          )}
          {isExpiry && (
            <Form.Item>
              <DatePicker
                onChange={(value) =>
                  handleInputChange('expiryDate', value?.format())
                }
                value={dayjs(values?.expiryDate)}
              />
            </Form.Item>
          )}
        </Form>
      </MainContainer>
    </Modal>
  )
}

AddTicketsModal.propTypes = {
  handleAddTickets: PropTypes.func.isRequired,
  isAddModal: PropTypes.bool.isRequired,
  currentTickets: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  modalVisible: PropTypes.bool.isRequired,
}

export default AddTicketsModal
