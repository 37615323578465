import React from 'react'
import { MenuItem } from '@mui/material'
import { FormControl } from '@mui/material'
import { Select } from '@mui/material'
import styled from 'styled-components'
import { Input, Button } from 'antd'
import { styled as styledMui } from '@mui/system'

import SearchLogo from '../../images/search.svg'
import PlusIcon from '../../images/plusIcon.svg'

const Container = styled.div`
  padding: 8px;
  justify-content: space-between;
  display: flex;
  align-items: center;
`
const Body = styled.div`
  flex-direction: column;
`

const Search = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`

const RightContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`

const Btn = styled(Button)`
  border-radius: 8px;
  margin-left: 8px;
  height: 40px;
  font-family: 'GraphikLCGMedium';
  background-color: #6f2cff;
  color: #ffffff;
  font-size: 14px;
  display: flex;
  align-items: center;
  border: none;
  &:focus {
    background-color: #6f2cff;
    border: none;
  }
  &:hover {
    background-color: #6f2cff;
    opacity: 0.5;
    border: none;
  }
`

const StyledFormControl = styledMui(FormControl)(({ theme }) => ({
  minWidth: 165,
  border: '1px solid rgba(0, 0, 0, 0.1)',
  borderRadius: 8,
  backgroundColor: 'transparent',
  padding: 3,
  justifyContent: 'center',
  marginLeft: 20,
}))

const StyledInput = styled(Input)`
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.04);
  height: 40px;
  width: 240px;
  background: rgba(0, 0, 0, 0.04);
  background-image: url(${SearchLogo});
  background-repeat: no-repeat;
  background-position: 5%;
  padding: 0 44px;
  border: none;
  color: rgba(0, 0, 0, 0.2);
  &:focus {
    border: none;
    outline: none;
    background: rgba(0, 0, 0, 0.08);
    background-image: url(${SearchLogo});
    background-repeat: no-repeat;
    background-position: left center;
    background-position: 5%;
    color: #000;
  }
`
const SelectUsers = styled.div`
  padding-top: 30px;
  justify-content: row;
  margin-left: 20px;
  display: flex;
  padding-bottom: 6px;
  margin-bottom: 8px;
`
const Text = styled.button`
  font-size: 14px;
  line-height: 16px;
  color: black;
  cursor: pointer;
  border: 0px;
  font-family: 'GraphikLCGMedium';
  background: white;
`
const Slider = styled.div`
  border-top: 2px solid black;
  margin-left: ${(props) =>
    props.all
      ? '32%'
      : props.installment
      ? '5%'
      : props.reccurent
      ? '60%'
      : props.guest
      ? '82%'
      : '0'};
  width: 64px;
  border-radius: 10px;
  transition: all 0.3s;
`

const ClientsHeader = ({
  setSearchPhoneNumber,
  abonement,
  setAbonement,
  setNewUserModal,
  clients,
  setClients,
  setSearchClientsWithHeroPass,
  setSearchRecurrentUsers,
  setFilter,
  heroPasses,
  setMonth,
  setSearchGuestUsers,
  setPayment,
  setDate,
  setSalesPerson
}) => {
  const [value, setValue] = React.useState('')
  const [selectMonth, setSelectMonth] = React.useState('')
  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const handleChangeMonth = (item) => {
    setSelectMonth(item.target.value)
  }

  function getLast12Months() {
    const currentDate = new Date()
    const last12Months = []

    for (let i = 0; i < 12; i++) {
      const year = currentDate.getFullYear()
      const month = currentDate.getMonth() - i

      if (month >= 0) {
        const date = new Date(year, month, 1)
        const monthName = date.toLocaleString('default', { month: 'long' })
        const months = `${month < 9 ? '0' + (month + 1) : month + 1}`
        const years = `${year}`

        last12Months.push({
          year: years,
          month: months,
          label: monthName,
        })
      } else {
        break // Stop the loop once we reach months before the current month
      }
    }
    return last12Months.reverse()
  }

  const last12Months = getLast12Months()

  return (
    <Container>
      <Body>
        <SelectUsers>
          <div style={{ justifyContent: 'column' }}>
            <Text
              onClick={() => setClients(0)}
              style={{
                marginRight: 8,
                color: clients === 0 ? 'black' : 'rgba(0, 0, 0, 0.50)',
              }}
            >
              Все клиенты
            </Text>
            <Text
              onClick={() => setClients(1)}
              style={{
                marginRight: 8,
                color: clients === 1 ? 'black' : 'rgba(0, 0, 0, 0.50)',
              }}
            >
              Продление HP
            </Text>
            <Text
              onClick={() => setClients(2)}
              style={{ color: clients === 2 ? 'black' : 'rgba(0, 0, 0, 0.50)' }}
            >
              Рекуренты
            </Text>
            <Text
              onClick={() => setClients(3)}
              style={{
                color: clients === 3 ? 'black' : 'rgba(0, 0, 0, 0.50)',
                marginLeft: 5,
              }}
            >
              Гостевые
            </Text>
            <Slider
              all={clients === 1}
              installment={clients === 0}
              reccurent={clients === 2}
              guest={clients === 3}
            />
          </div>
        </SelectUsers>
        {clients === 1 && (
          <div>
            <StyledFormControl variant="standard">
              <Select
                MenuProps={{
                  disableScrollLock: true,
                }}
                value={value}
                onChange={handleChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                disableUnderline
                style={{
                  paddingLeft: 10,
                  fontFamily: 'GraphikLCGMedium',
                  fontSize: 14,
                }}
              >
                <MenuItem
                  style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
                  value=""
                  onClick={() => setFilter(0)}
                >
                  Все абонементы
                </MenuItem>
                {heroPasses?.map((item, index) => (
                  <MenuItem
                    key={item.id}
                    style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
                    value={item}
                    onClick={() => {
                      setFilter(item)
                    }}
                  >
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
            <StyledFormControl variant="standard">
              <Select
                MenuProps={{
                  disableScrollLock: true,
                }}
                value={selectMonth}
                onChange={handleChangeMonth}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                disableUnderline
                style={{
                  paddingLeft: 10,
                  fontFamily: 'GraphikLCGMedium',
                  fontSize: 14,
                }}
              >
                <MenuItem
                  style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
                  value=""
                  onClick={() => setMonth(null)}
                >
                  Любой месяц
                </MenuItem>
                {last12Months?.map((item, index) => (
                  <MenuItem
                    key={item.month}
                    style={{
                      fontFamily: 'GraphikLCGMedium',
                      fontSize: 14,
                      textTransform: 'capitalize',
                    }}
                    value={item.month}
                    onClick={() => {
                      setMonth(item)
                    }}
                  >
                    {item?.label}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </div>
        )}
      </Body>
      <RightContainer>
        <Search>
          <StyledInput
            placeholder="Поиск"
            onChange={(e) => {
              const inputValue = e.target.value.toLowerCase()
              switch (clients) {
                case 0:
                  setSearchPhoneNumber(inputValue)
                  break
                case 1:
                  setSearchClientsWithHeroPass(inputValue)
                case 2:
                  setSearchRecurrentUsers(inputValue)
                  break
                case 3:
                  setSearchGuestUsers(inputValue)
                default:
                  break
              }
            }}
          />
        </Search>
        <Btn
          type="primary"
          icon={<img src={PlusIcon} />}
          onClick={() => {
            setAbonement('')
            setNewUserModal(true)
            setPayment()
            setDate()
            setSalesPerson()
            }}
        >
          Новый клиент
        </Btn>
      </RightContainer>
    </Container>
  )
}

export default ClientsHeader
