import React, { useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import { Table, Button, Divider } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import EditForm from '../shared/EditFormModal'
import CreateForm from '../shared/CreateForm'

const SpecialBookings = ({
  specialBookings,
  deleteClick,
  addClick,
  updateClick,
}) => {
  const [display, setDisplay] = useState('default')
  const [editValue, setEditValue] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)

  const columns = [
    {
      title: 'Название',
      dataIndex: 'name',
    },
    {
      title: 'Описание',
      dataIndex: 'description',
    },
    {
      title: 'Тип',
      dataIndex: 'type',
    },
    {
      title: 'Начало акции',
      render: (text, item) => {
        const eventDate = dayjs(item?.startTime).format('Do MMMM, YYYY')
        return <span>{eventDate}</span>
      },
      width: '20%',
    },
    {
      title: 'Оканчание акции',
      render: (text, item) => {
        const eventDate = dayjs(item?.endTime).format('Do MMMM, YYYY')
        return <span>{eventDate}</span>
      },
      width: '20%',
    },
    {
      title: 'Действие',
      key: 'action',
      render: (text, item) => (
        <span>
          <Button
            type="link"
            onClick={() => {
              setDisplay('edit')
              setEditValue(item)
            }}
          >
            Редактировать
          </Button>
          <Divider type="vertical" />
        </span>
      ),
    },
  ]

  const fields = [
    {
      key: 'name',
      label: 'Название',
      tooltipText: 'Название акции',
    },
    {
      key: 'description',
      label: 'Описание',
      textArea: true,
      tooltipText: 'Описание акции',
    },
    {
      key: 'type',
      label: 'Тип акции',

      options: [{ value: 'skipAssessment', label: 'skipAssessment' }],
      tooltipText: 'Тип акции',
    },
    {
      key: 'startTime',
      label: 'Начало акции',
      inputType: 'date',
      isRequired: true,
      tooltipText: 'Начало акции',
    },
    {
      key: 'endTime',
      label: 'Оканчание акции',
      inputType: 'date',
      isRequired: true,
      tooltipText: 'Оканчание акции',
    },
  ]

  const editFields = [
    {
      key: 'name',
      label: 'Название',
      value: editValue && editValue.name,
      tooltipText: 'Название акции',
    },
    {
      key: 'description',
      label: 'Описание',
      value: editValue && editValue.name,
      tooltipText: 'Описание акции',
    },
    {
      key: 'type',
      label: 'Тип акции',
      value: editValue && editValue.type,

      options: [{ value: 'skipAssessment', label: 'skipAssessment' }],
      tooltipText: 'Тип акции',
    },
    {
      key: 'startTime',
      label: 'Начало акции',
      value: editValue && dayjs(editValue.startTime),
      inputType: 'date',
      isRequired: true,
      tooltipText: 'Начало акции',
    },
    {
      key: 'endTime',
      label: 'Оканчание акции',
      value: editValue && dayjs(editValue.endTime),
      inputType: 'date',
      isRequired: true,
      tooltipText: 'Оканчание акции',
    },
  ]

  const handleCreate = (values) => {
    addClick(values)
    setModalVisible(false)
  }

  const handleUpdate = (values) => {
    updateClick(editValue.id, values)
    setDisplay('default')
    setEditValue(null)
  }

  const handleDelete = () => {
    deleteClick(editValue.id)
    setDisplay('default')
    setEditValue(null)
  }

  return (
    <>
      <Table
        dataSource={specialBookings}
        columns={columns}
        title={() => (
          <div>
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новая акция
            </Button>
          </div>
        )}
      />
      <CreateForm
        title="Добавить новую акцию"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onCreate={handleCreate}
        fields={fields}
      />
      {display === 'edit' && editValue && (
        <EditForm
          title="Редактировать акцию"
          visible={display === 'edit'}
          onCancel={() => setDisplay('default')}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          fields={editFields}
        />
      )}
    </>
  )
}

SpecialBookings.propTypes = {
  specialBookings: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.number,
    startTime: PropTypes.number,
    endTime: PropTypes.number,
  }).isRequired,
  deleteClick: PropTypes.func.isRequired,
  addClick: PropTypes.func.isRequired,
  updateClick: PropTypes.func.isRequired,
}

export default SpecialBookings
