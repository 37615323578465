import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import styled from 'styled-components'
import { MenuItem } from '@mui/material'
import { FormControl } from '@mui/material'
import { Select } from '@mui/material'
// import { makeStyles } from "@material-ui/core/styles";
import Loading from '../shared/Loading'

import { Table } from 'antd'
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Th = styled.div`
  color: rgba(0, 0, 0, 0.5);
  font-family: 'GraphikLCGMedium';
  font-size: 14px;
  line-height: 16px;
  text-align: left;
`

const Text = styled.span`
  color: #000000;
  font-family: 'GraphikLCGRegular';
  font-size: 14px;
  line-height: 16px;
  text-align: left;
`

const Title = styled.div`
  color: rgba(0, 0, 0, 1);
  font-family: 'GraphikLCGRegular';
  font-size: 14px;
  line-height: 16px;
`

const Hour = styled.div`
  color: #00000080;
  font-family: 'GraphikLCGRegular';
  font-size: 14px;
  line-height: 16px;
`

const Time = styled.div`
  display: flex;
  gap: 4px;
`
const SalesPayments = ({
  salesPayments,
  total,
  setPage,
  page,
  setShowInfo,
  setSale,
  filter,
  setFilter,
  loading,
  user
}) => {
  const [value, setValue] = React.useState('')

  dayjs.locale('ru')

  // const useStyles = makeStyles((theme) => ({
  //   formControl: {
  //     width: 200,
  //     border: "1px solid rgba(0, 0, 0, 0.1)",
  //     borderRadius: 8,
  //     backgroundColor: "#fff",
  //     padding: 5,
  //     justifyContent: "center",
  //     marginBottom: 20
  //   }
  // }));
  const handleChange = (event) => {
    setValue(event.target.value)
  }

  // const classes = useStyles();

  const columns = [
    {
      title: () => {
        return <Th>Платеж</Th>
      },
      render: (_, item) => {
        let text = ''
        if (item?.payment) {
          text = `Покупка ${item?.payment?.countClasses} билетов ${
            item?.payment?.ticketType === 'regular'
              ? 'для клиентов Invictus'
              : 'для всех'
          }`
        }
        if (item?.heroPass) {
          text = `Покупка HeroPass "${item?.heroPass?.name}"`
        }
        if (item?.marathonEvent && item?.type !== 'addedFromHeroPass') {
          text = `Покупка Марафона "${
            item?.marathonEvent?.marathon?.name
          }" c ${dayjs(item?.marathonEvent?.startTime).format(
            'Do MMMM'
          )} до ${dayjs(item?.marathonEvent?.endTime).format('Do MMMM, YYYY')}`
        }
        if (item?.type === 'addedFromHeroPass') {
          text = `Приобретение Марафона через HeroPass "${
            item?.marathonEvent?.marathon?.name
          }" c ${dayjs(item?.marathonEvent?.startTime).format(
            'Do MMMM'
          )} до ${dayjs(item?.marathonEvent?.endTime).format('Do MMMM, YYYY')}`
        }
        return <Text>{text}</Text>
      },
      width: '20%',
      ellipsis: true,
    },
    {
      title: 'Клиент',
      width: '20%',
      render: (text, item) => (
        <span>
          <Link to={`/users/${item?.user?.id}`}>{item?.user?.nickname}</Link>
        </span>
      ),
    },
    {
      title: () => {
        return <Th>Дата и время</Th>
      },
      dataIndex: 'created_at',
      render: (text, item) => (
        <Time>
          <Title>{dayjs(text).format('DD.MM.YY')}</Title>
          <Hour>{dayjs(text).format('HH:mm')}</Hour>
        </Time>
      ),
      width: '20%',
    },
    {
      title: () => {
        return <Th>Сумма</Th>
      },
      // dataIndex: 'paidAmount',
      width: '20%',
      render: (text, item) => {
        let cost = 0
        if (!item?.paidAmount) {
          if (item?.payment) {
            cost = item?.payment?.cost
          }
          if (item?.heroPass) {
            cost = item?.heroPass?.price
          }
          if (item?.marathonEvent && item?.type !== 'addedFromHeroPass') {
            cost = item?.marathonEvent?.marathon?.price
          }
        } else {
          cost = item?.paidAmount
        }

        return (
          <Text>
            {item?.type === 'addedFromHeroPass' ? 'Через HeroPass' : cost}₸
          </Text>
        )
      },
    },
  ]
  if (loading) {
    return <Loading />
  }

  return (
    <MainContainer>
      <FormControl
      // className={classes.formControl}
      >
        <Select
          MenuProps={{
            disableScrollLock: true,
          }}
          value={value}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          disableUnderline
          style={{
            fontFamily: 'GraphikLCGMedium',
            fontSize: 14,
            paddingLeft: 10,
          }}
        >
          <MenuItem
            style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
            value=""
            onClick={() => setFilter(null)}
          >
            Мои продажи
          </MenuItem>
          <MenuItem
            style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
            value={1}
            onClick={() => setFilter('added')}
          >
            Через приложение
          </MenuItem>
          {user?.role === 'salesadmin' && 
            <MenuItem
              style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
              value={2}
              onClick={() => setFilter('salesman')}
            >
              Все продажи
            </MenuItem>
          }
        </Select>
      </FormControl>
      <Table
        dataSource={salesPayments}
        columns={columns}
        rowKey={(item) => item.id}
        pagination={{
          defaultPageSize: 10,
          total: total,
          current: page,
          onChange: (page, pageSize) => {
            setPage(page)
          },

          showSizeChanger: false,
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              setShowInfo(true)
              setSale(record)
            },
          }
        }}
      />
    </MainContainer>
  )
}

SalesPayments.propTypes = {
  salesPayments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      userNickname: PropTypes.string,
      countClasses: PropTypes.number,
      cost: PropTypes.number,
      ticketType: PropTypes.string,
    })
  ).isRequired,
}

export default SalesPayments
