import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Table, Button, Divider } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { gql, useMutation, useLazyQuery } from '@apollo/client'
import { useParams } from "react-router-dom"
import { toast } from 'react-toastify'

import withMainLayout from '../../hocs/withMainLayout'
import EditForm from '../shared/EditFormModal'
import CreateForm from '../shared/CreateForm'


const GET_SUBCATEGORY_BY_CATEGORY = gql`
  query findSubcategories($categoryId: ID) {
		findSubcategories(categoryId: $categoryId) {
          _id
					name
      }
 }
`

const CREATE_SUBCATEGORY = gql `
  mutation createRequestSubcategory($input: RequestSubcategoryInput) {
    createRequestSubcategory(input: $input) {
			_id
    }
 }
`
const UPDATE_SUBCATEGORY = gql `
  mutation updateRequestSubcategory($id: ID! $input: RequestSubcategoryInput) {
    updateRequestSubcategory(id: $id input: $input ) {
          _id
      }
 }
`

const SupportSubcategory = () => {
	const { itemId } = useParams()
  const [subcategory, setSubcategory] = useState(null)
  const [display, setDisplay] = useState('default')
  const [editValue, setEditValue] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)
  const [image, setImage] = useState()
  const [secondImage, setSecondImage] = useState()

	const [getSubcategory, { data, loading, error, refetch }] = useLazyQuery(GET_SUBCATEGORY_BY_CATEGORY)

	useEffect(()=> {
		if (itemId) {
			getSubcategory({
				variables: {
					categoryId: itemId
				}
			})
		}
	}, [getSubcategory, itemId])

  
  const [addSubcategory] = useMutation(CREATE_SUBCATEGORY,{
    fetchPolicy: "no-cache",
    onCompleted() {
      toast.success("Подкатегория успешно добавлена")
      refetch()
    }
  })
  const [updateSubcategory] = useMutation(UPDATE_SUBCATEGORY, {
    fetchPolicy: "no-cache",
    onCompleted() {
      toast.success("Подкатегория успешно изменена")
      refetch()
    }
  })

	useEffect(() => {
		if (data && !loading && !error) {
			setSubcategory(data.findSubcategories)
		}
	}, [data, loading, error])

  const handleAddSubcategory = (values, itemId) => {
    addSubcategory({ 
			variables: { 
				input: { 
					...values, 
					requestCategory: itemId 
				}
			}
		})
  }


  const handleUpdateSubcategory = (id, value) => {
    updateSubcategory({ 
			variables: {
				id: id, 
				input:{
					...value 
				}
			} 
		})
  }

  const handleDeleteSubcategory = (id) => {
    updateSubcategory({ 
    variables:
      { id: id, 
        input: {
          isDeleted: true
      }  
    } 
  })
  }

  const columns = [
    {
      title: 'Название',
      dataIndex: 'name'
    },
		{
      title: 'Действие',
      key: 'action',
      render: (text, item) => (
        <span>
          <Button
            type="link"
            onClick={() => {
              setDisplay('edit')
              setEditValue(item)
            }}
          >
            Редактировать
          </Button>
          <Divider type="vertical" />
        </span>
      )
    }
  ]

  const fields = [
    {
      key: 'name',
      label: 'Название',
      tooltipText: 'Название'
    },
  ]

  const editFields = [
    {
      key: 'name',
      label: 'Название',
      value: editValue && editValue.name,
      tooltipText: 'Название'
    }
  ]


  const handleCreate = values => {
    console.log(values)
    handleAddSubcategory(values, itemId)
    setModalVisible(false)
  }

  const handleUpdate = values => {
    handleUpdateSubcategory(editValue._id, values)
    setDisplay('default')
    setEditValue(null)
  }

  const handleDelete = () => {
    handleDeleteSubcategory(editValue._id)
    setDisplay('default')
    setEditValue(null)
  }

  return (
    <>
      <Table
        dataSource={subcategory}
        columns={columns}
        title={() => (
          <div>
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новая подкатегория
            </Button>
          </div>
        )}
      />
      <CreateForm
        title="Добавить новую подкатегорию"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onCreate={handleCreate}
        fields={fields}
      />
      {display === 'edit' && editValue && (
        <EditForm
          title="Редактировать вопрос"
          visible={display === 'edit'}
          onCancel={() => setDisplay('default')}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          fields={editFields}
          setImages={setImage}
          images={image}
          setSecondImage={setSecondImage}
          secondImage={secondImage}
        />
      )}
    </>
  )
}

SupportSubcategory.propTypes = {
  categories: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
}

export default withMainLayout(SupportSubcategory)
