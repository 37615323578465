import React from 'react'
import { Modal, Select, Form } from 'antd'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'

const FormItem = Form.Item

const TransferMarathonModal = ({
  modalVisible,
  setModalVisible,
  handleTransferMarathon,
  marathons,
  marathonEvents,
  handleGetMarathonEvents,
  clubs
}) => {
  const [form] = Form.useForm()
  const [marathonId, setMarathonId] = React.useState(null)
  const [marathonEventId, setMarathonEventId] = React.useState(null)

  return (
    <Modal
      open={modalVisible}
      title="Добавить в марафон"
      okText="Добавить в марафон"
      cancelText="Отмена"
      onCancel={() => {
        form.resetFields()
        setModalVisible(false)
      }}
      okButtonProps={{
        disabled: !marathonId || !marathonEventId,
        color: !marathonId || !marathonEventId ? 'gray' : 'blue',
      }}
      onOk={() => {
        if (marathonId && marathonEventId) {
          form
            .validateFields()
            .then((values) => {
              form.resetFields()
              const { marathon, marathonEvent, club } = values
              setModalVisible(false)
              handleTransferMarathon({
                marathonId: marathon,
                marathonEventId: marathonEvent,
                clubId: club,
              })
            })
            .catch((info) => {
              console.log('Validate Failed:', info)
            })
        }
      }}
    >
      <Form form={form} layout="vertical" hideRequiredMark>
        <FormItem key="marathon" label={'Выберите марафон'} name="marathon">
          <Select
            mode="single"
            onChange={(value) => {
              setMarathonId(value)
              handleGetMarathonEvents(value)
            }}
            placeholder="Выберите марафон"
          >
            {marathons.map((marathon) => (
              <Select.Option key={marathon.id} value={marathon.id}>
                {marathon?.name} {marathon?.price}
              </Select.Option>
            ))}
          </Select>
        </FormItem>

        {marathonEvents && marathonEvents.length > 0 && (
          <FormItem
            key="marathonEvent"
            label={'Выберите Дату'}
            name="marathonEvent"
          >
            <Select
              mode="single"
              onChange={(value) => setMarathonEventId(value)}
              placeholder="Выберите дату марафона"
            >
              {marathonEvents.map((marathon) => (
                <Select.Option key={marathon?.id} value={marathon?.id}>
                  Старт:{dayjs(marathon?.startTime).format('DD.MM.YYYY')} Конец:
                  {dayjs(marathon?.endTime).format('DD.MM.YYYY')}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
        )}
        <FormItem
          key="club"
          label="Клуб"
          name="club"
          rules={[
            {
              required: true,
              message: `Пожалуйста, выберите клуб`,
            },
          ]}
        >
          <Select placeholder="Выберите клуб" mode="single">
          {clubs.map((obj => (
            <Select.Option key={obj.id} value={obj.id}>
              {obj.name}
            </Select.Option>
          )))
          }
          </Select>
        </FormItem>
      </Form>
    </Modal>
  )
}

TransferMarathonModal.propTypes = {
  setModalVisible: PropTypes.func.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  getActiveMarathonEvent: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.any).isRequired,
  marathons: PropTypes.arrayOf(PropTypes.any),
  handleGetMarathonEvents: PropTypes.func.isRequired,
  marathonEvents: PropTypes.arrayOf(PropTypes.any),
}

TransferMarathonModal.defaultProps = {
  marathons: [],
  marathonEvents: [],
}

export default TransferMarathonModal
