import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Modal, Form, Input, DatePicker } from 'antd'
import StyledTooltip from '../shared/StyledTooltip'

const { TextArea } = Input
const FormItem = Form.Item

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`

const CreateSeason = ({ visible, onCancel, title, onCreate }) => {
  const [form] = Form.useForm()

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />
  }

  return (
    <Modal
      open={visible}
      title={title}
      okText="Создать"
      cancelText="Закрыть"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields()
            onCreate(values)
          })
          .catch((info) => {
            // eslint-disable-next-line no-console
            console.log('Validate Failed:', info)
          })
      }}
    >
      <Form form={form} layout="vertical">
        <FormItem
          key="name"
          label={getTooltip('Название Сезона', 'Название Сезона')}
          name="name"
        >
          <Input placeholder="Название Сезона" />
        </FormItem>
        <FormItem
          key="description"
          label={getTooltip('Описание Сезона', 'Описание Сезона')}
          name="description"
        >
          <TextArea row={4} />
        </FormItem>
        <FormItem
          key="subheading"
          label={getTooltip('Слоган Сезона', 'Слоган Сезона')}
          name="subheading"
        >
          <Input placeholder="Слоган Сезона" />
        </FormItem>

        <FormItem
          key="bannerURL"
          label={getTooltip('Баннер Сезона', 'Баннер Сезона')}
          name="bannerURL"
        >
          <Input placeholder="Баннер Сезона" />
        </FormItem>
        <FormItem
          key="attendancePoints"
          label={getTooltip('Баллы', 'Баллы за визит в этом сезона')}
          name="attendancePoints"
        >
          <Input type="number" placeholder="Баллы" />
        </FormItem>
        <FormItem
          key="perfectWeekPoints"
          label={getTooltip(
            'Количество бонусных очков за "Идеальную неделю"',
            'Кол-во бонусных очков получаемых за выполнение “Идеальной недели”'
          )}
          name="perfectWeekPoints"
        >
          <Input type="number" placeholder="Название Сезона" />
        </FormItem>
        <FormItem
          key="visitsForPerfectWeek"
          label={getTooltip(
            'Количество посещений для "Идеальной недели" клана',
            'Требуемое кол-во посещений в неделю для выполнения “Идеальной недели” клана'
          )}
          name="visitsForPerfectWeek"
        >
          <Input
            type="visitsForPerfectWeek"
            placeholder='Количество посещений для "Идеальной недели" клана'
          />
        </FormItem>

        <FormItem
          key="startTime"
          label={getTooltip('Дата сезона', 'Дата начала сезона')}
          name="startTime"
          rules={[
            {
              required: true,
              message: `Пожалуйста, выберите дату`,
            },
          ]}
        >
          <StyledDatePicker placeholder="Выберите дату" />
        </FormItem>
        <FormItem
          key="endTime"
          label={getTooltip('Дата сезона', 'Дата конца сезона')}
          name="endTime"
          rules={[
            {
              required: true,
              message: `Пожалуйста, выберите дату`,
            },
          ]}
        >
          <StyledDatePicker placeholder="Выберите дату" />
        </FormItem>
      </Form>
    </Modal>
  )
}

CreateSeason.propTypes = {
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
}

export default CreateSeason
