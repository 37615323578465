import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import Recurrents from './Recurrents'
import Loading from '../shared/Loading'
import { useLoading } from '../../context/useLoading'

const GET_DATA = gql`
  query getData($heroPassId: ID) {
    recurrentsByHeroPassId(heroPassId: $heroPassId) {
      id
      name
      totalAmount
      freezingCount
      freezingDays
      isActive
      monthPayments {
        monthNumber
        amount
      }
    }
  }
`

const ADD_RECURRENT = gql`
  mutation addRecurrentPayment($input: RecurrentPaymentInput) {
    addRecurrentPayment(input: $input) {
      id
      name
      totalAmount
      isActive
      freezingCount
      freezingDays
      monthPayments {
        monthNumber
        amount
      }
    }
  }
`

const UPDATE_RECURRENT = gql`
  mutation updateRecurrentPayment($id: ID!, $input: RecurrentPaymentInput) {
    updateRecurrentPayment(id: $id, input: $input) {
      id
      name
      totalAmount
      isActive
      freezingCount
      freezingDays
      monthPayments {
        monthNumber
        amount
      }
    }
  }
`

const DELETE_RECURRENT = gql`
  mutation deleteRecurrentPayment($id: ID!) {
    deleteRecurrentPayment(id: $id)
  }
`

const RecurrentContainer = ({ heroPassId }) => {
  const [dataRecurrents, setDataRecurrents] = useState(null)
  const { showLoading, hideLoading } = useLoading()

  const { data, loading, error, refetch } = useQuery(GET_DATA, {
    variables: { heroPassId: heroPassId }
  })

  const [addRecurrent] = useMutation(ADD_RECURRENT, {
    onCompleted() {
      refetch()
      hideLoading()
      toast.success('Рекуррентный платеж успешно создан')
    }
  })

  const [updateRecurrent] = useMutation(UPDATE_RECURRENT)

  const [deleteRecurrent] = useMutation(DELETE_RECURRENT, {
    onCompleted() {
      refetch()
      hideLoading()
      toast.success('Рекуррентный платеж успешно удален')
    }
  })

  useEffect(() => {
    if (data && !loading && !error) {
      setDataRecurrents(data.recurrentsByHeroPassId)
    }
  }, [data, loading, error])

  const handleAddRecurrent = values => {
    showLoading()
    const monthPayments = values?.monthPayments?.map((monthPayment, index) => {
      return {
        amount: parseInt(monthPayment, 10),
        monthNumber: index + 1
      }
    })
    const totalAmount = monthPayments.reduce(
      (partialSum, a) => partialSum + a.amount,
      0
    )
    addRecurrent({
      variables: {
        input: {
          ...values,
          heroPass: heroPassId,
          freezingCount: parseInt(values?.freezingCount, 10),
          freezingDays: parseInt(values?.freezingDays, 10),
          totalAmount: totalAmount,
          monthPayments: monthPayments
        }
      }
    })
  }

  const handleUpdateRecurrent = (id, values) => {
    showLoading()
    const monthPayments = values?.monthPayments?.map((monthPayment, index) => {
      return {
        amount: parseInt(monthPayment, 10),
        monthNumber: index + 1
      }
    })
    const totalAmount = monthPayments.reduce(
      (partialSum, a) => partialSum + a.amount,
      0
    )
    updateRecurrent({
      variables: {
        id: id,
        input: {
          ...values,
          heroPass: heroPassId,
          freezingCount: parseInt(values?.freezingCount, 10),
          freezingDays: parseInt(values?.freezingDays, 10),
          totalAmount: totalAmount,
          monthPayments: monthPayments
        }
      }
    })
    hideLoading()
    toast.success('Рекуррентный платеж успешно отредактирован')
  }

  const handleDeleteRecurrent = id => {
    showLoading()
    deleteRecurrent({
      variables: {
        id: id
      }
    })
  }

  if (loading && !dataRecurrents) {
    return <Loading />
  }

  return (
    <>
      {dataRecurrents && (
        <Recurrents
          recurrents={dataRecurrents}
          heroPassId={heroPassId}
          addRecurrentClick={handleAddRecurrent}
          updateRecurrentClick={handleUpdateRecurrent}
          deleteRecurrentClick={handleDeleteRecurrent}
        />
      )}
    </>
  )
}

RecurrentContainer.propTypes = {
  heroPassId: PropTypes.string.isRequired
}

export default RecurrentContainer
