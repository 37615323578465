import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {
  Breadcrumb,
  Layout,
  Table,
  Modal,
  Form,
  Select,
  Button,
  Divider,
} from 'antd'

// import {
//   sortableContainer,
//   sortableElement,
//   sortableHandle
// } from "react-sortable-hoc";
import { MenuOutlined } from '@ant-design/icons'
import arrayMove from 'array-move'

import icons from '../shared/icons'

import ExercisesTable from './ExercisesTable'
import showConfirm from '../shared/DeleteConfirm'
import StyledTooltip from '../shared/StyledTooltip'

const { Content } = Layout

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledLayout = styled(Layout)`
  background: #fff;
`
const StyledTable = styled(Table)`
  margin-top: 24px;
`

const DetailsIcon = styled.img`
  width: 32px;
  height: 32px;
`

const StyledSelect = styled(Select)`
  width: 100%;
  margin-bottom: 24px;
  margin-top: 18px;
`

const StyledHeaderText = styled.h3`
  font-weight: 600;
`

// const DragHandle = sortableHandle(() => (
//   <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
// ));

const ProgramSetStations = ({
  programSet,
  exercises,
  handleUpdateStationExercise,
  handleAddStationExercise,
  handleDeleteStationExercise,
  updateStationExercisesOrder,
}) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [modalMode, setModalMode] = useState('edit')
  const [currentStation, setCurrentStation] = useState(null)
  const [currentExercise, setCurrentExercise] = useState(null)
  const [descriptiveStations, setDescriptiveStations] = useState([])

  const stations = programSet?.zone?.stations
  const { stationExercises } = programSet

  useEffect(() => {
    // select currently existing stationExercises and append them to the table
    const descriptiveStationsNew = stationExercises.map((stationExercise) => {
      const stationIndex = stations.findIndex((station) => {
        return stationExercise?.station?.id === station.id
      })
      const isPresent = stationIndex !== -1
      const station = isPresent && stations[stationIndex]
      return { ...station, isPresent, stationExercise }
    })
    // select stations that do not have a stationExerciseand add them to the table
    descriptiveStationsNew.push(
      ...stations.filter((station) => {
        const stationExerciseIndex = stationExercises.findIndex(
          (stationExercise) => {
            return stationExercise?.station?.id === station.id
          }
        )
        return stationExerciseIndex === -1
      })
    )
    setDescriptiveStations(descriptiveStationsNew)
  }, [stationExercises])

  useEffect(() => {
    if (currentStation?.exercise?.id) {
      setCurrentExercise(currentStation?.exercise?.id)
    }
  }, [currentStation])

  const emptyExerciseDetails = programSet?.levels.map((level) => {
    return {
      level,
      reps: 12,
      sets: 4,
      maxWeight: 30,
      minWeight: 0,
      levelName: level?.name,
      levelId: level?.id,
    }
  })
  // const SortableItem = sortableElement((props) => <tr {...props} />);

  // const SortableContainer = sortableContainer((props) => <tbody {...props} />);

  // const onSortEnd = ({ oldIndex, newIndex }) => {
  //   const descriptiveStationsNew = descriptiveStations;
  //   if (oldIndex !== newIndex) {
  //     const newData = arrayMove(
  //       [].concat(descriptiveStationsNew),
  //       oldIndex,
  //       newIndex
  //     ).filter((el) => !!el);

  //     setDescriptiveStations(newData);
  //     updateStationExercisesOrder(newData);
  //   }
  // };

  // const DraggableContainer = (props) => (
  //   <SortableContainer
  //     useDragHandle
  //     disableAutoscroll
  //     helperClass="row-dragging"
  //     onSortEnd={onSortEnd}
  //     {...props}
  //   />
  // );

  // const DraggableBodyRow = ({ className, style, ...restProps }) => {
  //   const dataSource = descriptiveStations;
  //   // function findIndex base on Table rowKey props and should always be a right array index
  //   const index = dataSource.findIndex(
  //     (x) => x.id === restProps["data-row-key"]
  //   );
  //   return <SortableItem index={index} {...restProps} />;
  // };

  const getExerciseDetails = ({ stationExercise }) => {
    console.log({
      stationExercise,
    })
    const newExerciseDetails = programSet?.levels.map((level) => {
      const oldExerciseDetail = stationExercise?.exerciseDetails.find(
        (exerciseDetail) =>
          exerciseDetail?.level?.id.toString() === level.id.toString()
      )

      if (oldExerciseDetail) {
        return {
          ...oldExerciseDetail,
          levelName: level?.name,
          levelId: level?.id,
        }
      }

      return {
        level,
        reps: 12,
        sets: 4,
        maxWeight: 30,
        minWeight: 0,
        levelName: level?.name,
        levelId: level?.id,
      }
    })
    return { ...stationExercise, exerciseDetails: newExerciseDetails }
  }

  const columns = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      // render: () => <DragHandle />
    },
    {
      title: 'Станция',
      dataIndex: 'number',
    },
    {
      title: 'Название',
      dataIndex: 'name',
    },
    {
      title: 'Иконка',
      render: (item) => {
        return (
          <>
            <DetailsIcon src={icons[item.icon]} />
            {item.icon}
          </>
        )
      },
    },
    {
      title: 'Упражнение',
      render: (item) => {
        if (item.isPresent) {
          return item.stationExercise?.exercise?.name
        }
        return null
      },
    },
    {
      title: 'Действие',
      key: 'action',
      render: (item, __, index) => {
        if (item.isPresent) {
          return (
            <span>
              {/* eslint-disable */}
              <Button
                type="link"
                onClick={() => {
                  setCurrentStation(
                    getExerciseDetails({
                      stationExercise:
                        descriptiveStations[index]?.stationExercise,
                    })
                  )
                  setModalVisible(true)
                  setModalMode('edit')
                }}
              >
                Редактировать
              </Button>
            </span>
          )
        }
        return (
          <span>
            {/* eslint-disable */}
            <Button
              type="link"
              onClick={() => {
                const curStation = stations.find(
                  (oneStation) => oneStation.id === item.id
                )
                setCurrentStation({
                  station: curStation,
                  exerciseDetails: emptyExerciseDetails,
                })
                setModalVisible(true)
                setModalMode('create')
              }}
            >
              Создать
            </Button>
          </span>
        )
      },
    },
  ]

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />
  }

  return (
    <MainContainer>
      <StyledLayout>
        <h2>Список Станции</h2>
        <Breadcrumb>
          <Breadcrumb.Item>{programSet?.club.name}</Breadcrumb.Item>
          <Breadcrumb.Item>{programSet?.zone.name}</Breadcrumb.Item>
        </Breadcrumb>
        <Content>
          <StyledTable
            dataSource={descriptiveStations}
            columns={columns}
            rowKey={(item) => item.id}
            pagination={false}
            // components={{
            //   body: {
            //     wrapper: DraggableContainer,
            //     row: DraggableBodyRow
            //   }
            // }}
          />
          <Modal
            open={modalVisible}
            title={currentStation?.name}
            okText={modalMode === 'edit' ? 'Сохранить' : 'Создать'}
            cancelText="Отмена"
            onCancel={() => {
              setModalVisible(false)
              setModalMode('edit')
              setCurrentStation(null)
              setCurrentExercise(null)
            }}
            onOk={() => {
              if (modalMode === 'edit') {
                handleUpdateStationExercise({
                  ...currentStation,
                  currentExercise,
                })
                setModalVisible(false)
                setCurrentExercise(null)
              } else {
                handleAddStationExercise({
                  ...currentStation,
                  currentExercise,
                })
                setModalVisible(false)
                setCurrentExercise(null)
              }
            }}
          >
            <StyledHeaderText>
              Станция{' '}
              {`${currentStation?.station?.number} |
              ${currentStation?.station?.name}`}
            </StyledHeaderText>
            <Divider type="horizontal" />
            <Form layout="vertical">
              <label>
                {getTooltip(
                  'Упражнение',
                  'Упражнение которое будет выполняться на этой станции'
                )}
              </label>

              <StyledSelect
                key={currentStation?.station?.id}
                mode="single"
                placeholder="Название упражнения"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={(value) => {
                  setCurrentExercise(value)
                }}
                defaultValue={currentStation?.exercise?.id}
              >
                {exercises.map((exercise) => (
                  <Select.Option key={exercise.id} value={exercise.id}>
                    {exercise.name}
                  </Select.Option>
                ))}
              </StyledSelect>
              <Form.Item
                label={getTooltip(
                  'Настройка упражнения',
                  'Требования для упражнения'
                )}
              >
                {currentStation && (
                  <ExercisesTable
                    key={currentStation?.station?.id}
                    dataSource={currentStation?.exerciseDetails}
                    setCurrentStation={setCurrentStation}
                    currentStation={currentStation}
                  />
                )}
              </Form.Item>
            </Form>
            {modalMode === 'edit' && (
              <Button
                danger
                onClick={() => {
                  showConfirm(() => {
                    console.log('deleting', currentStation?.id)
                    handleDeleteStationExercise(currentStation?.id)
                    setModalVisible(false)
                  })
                }}
              >
                Сбросить настройки станции
              </Button>
            )}
          </Modal>
        </Content>
      </StyledLayout>
    </MainContainer>
  )
}

ProgramSetStations.propTypes = {
  programSet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    club: PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
    zone: PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      stations: PropTypes.arrayOf(
        PropTypes.shape({
          number: PropTypes.number.isRequired,
          icon: PropTypes.string.isRequired,
        })
      ),
    }),
    levels: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      })
    ),
    stationExercises: PropTypes.arrayOf(
      PropTypes.shape({
        exercise: PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string,
        }),
      })
    ),
  }).isRequired,
  handleUpdateStationExercise: PropTypes.func.isRequired,
  handleAddStationExercise: PropTypes.func.isRequired,
  handleDeleteStationExercise: PropTypes.func.isRequired,
  updateStationExercisesOrder: PropTypes.func.isRequired,
  exercises: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default ProgramSetStations
