import React from 'react'
import { Table, Button, Form, Modal, Input, Tag } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

import styled from 'styled-components'

import PropTypes from 'prop-types'
import UserForm from './UserForm'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const Users = ({
  users,
  addUserHandler,
  levels,
  heroes,
  trainers,
  page,
  setPage,
  usersCnt,
  setSearchNickname,
  searchNickname,
  clubs
}) => {
  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = React.useState(false)

  const columns = [
    {
      title: 'Никнейм',
      dataIndex: 'nickname',
      width: '20%',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: '15%',
    },

    {
      title: 'Номер телефона',
      dataIndex: 'phoneNumber',
      width: '15%',
    },
    {
      title: 'Код',
      dataIndex: 'shortCode',
      width: '15%',
      render: (_, item) => (
        <span>{item?.sex === 'male' ? 'Мужской' : 'Женский'}</span>
      ),
    },
    {
      title: 'Уровень',
      render: (_, item) => {
        const allColors = [
          'green',
          'green',
          'green',
          'blue',
          'blue',
          'blue',
          'red',
          'red',
          'red',
        ]

        return (
          <Tag color={allColors[item?.level?.degree - 1]}>
            {item?.level?.name?.toUpperCase()}
          </Tag>
        )
      },
      width: '20%',
    },
    {
      title: 'Пол',
      render: (_, item) => (
        <span>{item?.sex === 'male' ? 'Мужской' : 'Женский'}</span>
      ),
      width: '10%',
    },
    {
      title: 'Действие',
      width: '20%',
      render: (text, item) => (
        <span>
          <Link to={`/users/${item.id}`}>Редактировать</Link>
        </span>
      ),
    },
  ]

  return (
    <>
      <Table
        dataSource={users}
        columns={columns}
        rowKey={(item) => item.id}
        pagination={{
          defaultPageSize: 10,
          total: usersCnt,
          current: page,
          onChange: (page, pageSize) => {
            setPage(page)
          },
          showSizeChanger: false,
        }}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Никнейм/Email/Номер телефона"
              onChange={(e) => {
                setSearchNickname(e.target.value.toLowerCase())
              }}
            />
            <div>
              <Button type="primary" onClick={() => setModalVisible(true)}>
                <PlusOutlined /> Новый пользователь
              </Button>
            </div>
          </StyledHeaderContainer>
        )}
      />
      {modalVisible && (
        <Modal
          open={modalVisible}
          title="Новый пользователь"
          okText="Создать"
          cancelText="Отмена"
          onCancel={() => {
            form.resetFields()
            setModalVisible(false)
          }}
          onOk={() => addUserHandler(form, setModalVisible)}
        >
          <UserForm
            form={form}
            withPassword
            levels={levels}
            heroes={heroes}
            trainers={trainers}
            users={users}
            searchNickname={searchNickname}
            setSearchNickname={setSearchNickname}
            clubs={clubs}
          />
        </Modal>
      )}
    </>
  )
}

Users.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  addUserHandler: PropTypes.func.isRequired,
  levels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  heroes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  trainers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  getActiveMarathonEvent: PropTypes.func.isRequired,
  handleTransferMarathon: PropTypes.func.isRequired,
  userActiveMarathon: PropTypes.objectOf(PropTypes.any),
  marathons: PropTypes.arrayOf(PropTypes.any),
  handleGetMarathonEvents: PropTypes.func.isRequired,
  marathonEvents: PropTypes.arrayOf(PropTypes.any),
}

Users.defaultProps = {
  userActiveMarathon: {},
  marathons: [],
  marathonEvents: [],
}

export default Users
