import React from 'react'
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import { Tabs, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'

import Loading from '../shared/Loading'
import withMainLayout from '../../hocs/withMainLayout'
import SalesReport from './SalesReport'
import { useLoading } from '../../context/useLoading'

const GET_PAYMENTS_BY_DATES = gql`
  query paymentsByDates($startDate: DateTime, $endDate: DateTime) {
    paymentsByDates(startDate: $startDate, endDate: $endDate) {
      id
      type
      created_at
      updated_at
      cost
      checkLink
      checkId
      name
      paidAmount
      paymentMethod
      status
      refundLetterTime
      comment
      salesman {
        id
        nickname
        phoneNumber
      }
      user {
        id
        nickname
        phoneNumber
      }
      toUser {
        id
        nickname
        phoneNumber
      }
      payment {
        id
        name
        cost
        isDemo
        ticketType
      }
      heroPass {
        id
        name
        price
      }
      marathonEvent {
        id
        startTime
        endTime
        marathon {
          name
          price
        }
      }
    }
    salesByRole {
      id
      username
      nickname
      role
    }
    webKassaReport(startDate: $startDate, endDate: $endDate) {
      created_at
      type
      totalAmount
      checkLink
      customer {
        email
        phone
      }
    }
  }
`

const REFUND_BY_USER_PAYMENT = gql`
  mutation refundByUserPayment(
    $userPaymentId: ID!
    $userId: ID!
    $imageFile: FileUpload!
    $clubId: ID
    $salesmanId: ID
    $receptionistId: ID
    $refundAmount: Float
    $changeRefundAmount: Float
    $comment: String
    $refundLetterTime: DateTime
  ) {
    refundByUserPayment(
      userPaymentId: $userPaymentId
      userId: $userId
      imageFile: $imageFile
      clubId: $clubId
      salesmanId: $salesmanId
      receptionistId: $receptionistId
      refundAmount: $refundAmount
      changeRefundAmount: $changeRefundAmount
      comment: $comment
      refundLetterTime: $refundLetterTime
    ) {
      status
      message
    }
  }
`

const { TabPane } = Tabs

const SalesReportContainer = () => {
  const [startDate, setStartDate] = React.useState(dayjs().startOf('month'))
  const [endDate, setEndDate] = React.useState(dayjs().endOf('month'))
  const [salesPersons, setSalesPersons] = React.useState([])
  const [webKassaPayments, setWebKassaPayments] = React.useState([])

  const [payments, setPayments] = React.useState([])
  const [getPayments, { data, loading, error, refetch }] = useLazyQuery(
    GET_PAYMENTS_BY_DATES,
    { fetchPolicy: 'no-cache' }
  )

  const [refundByUserPayment] = useMutation(REFUND_BY_USER_PAYMENT, {
    onError(err) {
      toast.error(`Error ${err.message}`)
    },
    onCompleted() {
      toast.success('возварт успешно оформлен')
      getPayments({
        variables: {
          startDate: dayjs(startDate),
          endDate: dayjs(endDate),
        },
      })
    },
  })

  React.useEffect(() => {
    getPayments({
      variables: {
        startDate: dayjs().startOf('month'),
        endDate: dayjs().endOf('month'),
      },
    })
  }, [])

  React.useEffect(() => {
    if (data && data.paymentsByDates) {
      setPayments(
        data.paymentsByDates.map((item) => {
          let type = item.type
          if (item?.type === 'salesman') {
            type = `ОП(${item?.salesman?.nickname})`
          } else if (item?.type === 'app') {
            type = `Через App`
          }
          if (item?.type === 'added') {
            type = `Добавил Админ`
          }

          let name = item.name
          if (!item?.name) {
            name = item?.payment?.name
            if (item?.payment?.name) {
              name = item?.payment?.name
            } else if (item.heroPass?.price) {
              name = item.heroPass?.name
            } else if (item?.marathonEvent) {
              name = item?.marathonEvent?.marathon?.name
            } else if (item?.payment?.name) {
              name = item?.payment?.name
            }
          }

          let price = item?.paidAmount || 0
          if (!item?.paidAmount) {
            name = item?.paidAmount
          }
          let paymentMetod = ''
          switch (item.paymentMethod) {
            case 'company':
              paymentMetod = 'Перевод от компании'
              break
            case 'card':
              paymentMetod = 'Картой'
              break
            case 'mixed':
              paymentMetod = 'Смешанное'
              break
            case 'cash':
              paymentMetod = 'Наличными'
              break
            case 'kaspiCredit':
              paymentMetod = 'Рассрочка'
              break
            case 'kaspiQR':
              paymentMetod = 'KaspiQR'
              break
            default:
              paymentMetod = ''
              break
          }

          let isCheck = false
          if (item?.checkId && item?.checkLink) {
            isCheck = true
          }
          return {
            id: item?.id,
            user: item?.user,
            toUser: item?.toUser,
            name: name,
            type: item.type,
            typeName: type,
            price: price,
            paymentMetod: paymentMetod,
            date: item?.created_at,
            isChecked: isCheck,
            status: item?.status,
            username: `${item?.user?.nickname} | ${item?.user?.phoneNumber}`,
            refundLetterTime: item?.refundLetterTime,
            comment: item?.comment,
          }
        })
      )
      setWebKassaPayments(data.webKassaReport)
      setSalesPersons(data?.salesByRole)
    }
  }, [data, loading, error])

  return (
    <div>
      <SalesReport
        payments={payments}
        webKassaPayments={webKassaPayments}
        loading={loading}
        setStartDate={setStartDate}
        startDate={startDate}
        setEndDate={setEndDate}
        endDate={endDate}
        refetch={refetch}
        getPayments={getPayments}
        salesPersons={salesPersons}
        refundByUserPayment={refundByUserPayment}
      />
    </div>
  )
}

export default withMainLayout(SalesReportContainer)
