import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, Table, Modal, Select, Form, Input, Divider } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import StyledTooltip from '../shared/StyledTooltip'
import showConfirm from '../shared/DeleteConfirm'
import PROGRAM_SET_TYPES from '../../utils/programSetTypes'

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledSelect = styled(Select)`
  width: 100%;
  margin-bottom: 24px;
  margin-top: 18px;
`

const StyledInput = styled(Input)`
  width: 100%;
  margin-bottom: 24px;
  margin-top: 18px;
`

const GOAL_TYPES = ['recomposition', 'muscleGain', 'fatLoss']

const EMPTY_PROGRAMSET_FREQUENCY = {
  proportion: 0,
  types: [],
}

const EMPTY_GOAL_PLAN = {
  goal: '',
  programSetFrequencies: [EMPTY_PROGRAMSET_FREQUENCY],
  programSetFrequenciesFemale: [EMPTY_PROGRAMSET_FREQUENCY],
}
const LevelPlanner = ({
  levelGoalPlans,
  handleAddLevelGoalPlan,
  handleUpdateLevelGoalPlan,
  handleDeleteLevelGoalPlan,
}) => {
  const [currentGoalPlan, setCurrentGoalPlan] = useState(EMPTY_GOAL_PLAN)
  const [modalVisible, setModalVisible] = useState(false)
  const [modalMode, setModalMode] = useState('edit') //'create'

  const columns = [
    {
      title: 'Цель',
      dataIndex: 'goal',
    },
    {
      title: 'Действие',
      render: (_, item) => {
        return (
          <Button
            onClick={() => {
              setCurrentGoalPlan(item)
              setModalMode('edit')
              setModalVisible(true)
            }}
          >
            Редактировать
          </Button>
        )
      },
    },
  ]

  const handleInputChange = (fieldName, value) => {
    setCurrentGoalPlan({
      ...currentGoalPlan,
      [fieldName]: value,
    })
  }

  const handleProgramSetChange = (index, fieldName, value, isFemale) => {
    if (isFemale) {
      const newProgramSetFrequencies = [
        ...currentGoalPlan?.programSetFrequenciesFemale,
      ]
      const oldValue = newProgramSetFrequencies[index]
      newProgramSetFrequencies[index] = {
        ...oldValue,
        [fieldName]: value,
      }
      setCurrentGoalPlan({
        ...currentGoalPlan,
        programSetFrequenciesFemale: newProgramSetFrequencies,
      })
      return
    }
    const newProgramSetFrequencies = [...currentGoalPlan?.programSetFrequencies]
    const oldValue = newProgramSetFrequencies[index]
    newProgramSetFrequencies[index] = {
      ...oldValue,
      [fieldName]: value,
    }
    setCurrentGoalPlan({
      ...currentGoalPlan,
      programSetFrequencies: newProgramSetFrequencies,
    })
  }

  const addProgramSetRow = (isFemale) => {
    if (isFemale) {
      const newProgramSetFrequencies = [
        ...currentGoalPlan?.programSetFrequenciesFemale,
      ]
      newProgramSetFrequencies.push(EMPTY_PROGRAMSET_FREQUENCY)
      setCurrentGoalPlan({
        ...currentGoalPlan,
        programSetFrequenciesFemale: newProgramSetFrequencies,
      })
      return
    }
    const newProgramSetFrequencies = [...currentGoalPlan?.programSetFrequencies]
    newProgramSetFrequencies.push(EMPTY_PROGRAMSET_FREQUENCY)
    setCurrentGoalPlan({
      ...currentGoalPlan,
      programSetFrequencies: newProgramSetFrequencies,
    })
  }

  const deleteProgramSetRow = (index, isFemale) => {
    if (isFemale) {
      const newProgramSetFrequencies = [
        ...currentGoalPlan?.programSetFrequenciesFemale,
      ]
      newProgramSetFrequencies.splice(index, 1)
      setCurrentGoalPlan({
        ...currentGoalPlan,
        programSetFrequenciesFemale: newProgramSetFrequencies,
      })
      return
    }
    const newProgramSetFrequencies = [...currentGoalPlan?.programSetFrequencies]
    newProgramSetFrequencies.splice(index, 1)
    setCurrentGoalPlan({
      ...currentGoalPlan,
      programSetFrequencies: newProgramSetFrequencies,
    })
  }

  const onCancel = () => {
    setModalVisible(false)
    setModalMode('edit')
    setCurrentGoalPlan(EMPTY_GOAL_PLAN)
  }

  const onDelete = () => {
    if (modalMode === 'edit') {
      showConfirm(() => {
        handleDeleteLevelGoalPlan(currentGoalPlan?.id)
        setModalVisible(false)
        setModalMode('edit')
      })
    }
  }

  const onOk = () => {
    const parsedProgramSets = currentGoalPlan?.programSetFrequencies.map(
      (programSetFrequency) => ({
        ...programSetFrequency,
        proportion: parseInt(programSetFrequency.proportion, 10),
      })
    )

    const parsedProgramSetsFemale =
      currentGoalPlan?.programSetFrequenciesFemale.map(
        (programSetFrequency) => ({
          ...programSetFrequency,
          proportion: parseInt(programSetFrequency.proportion, 10),
        })
      )
    if (modalMode === 'edit') {
      handleUpdateLevelGoalPlan(currentGoalPlan?.id, {
        ...currentGoalPlan,
        programSetFrequencies: parsedProgramSets,
        programSetFrequenciesFemale: parsedProgramSetsFemale,
      })
      setModalVisible(false)
    } else {
      handleAddLevelGoalPlan({
        ...currentGoalPlan,
        programSetFrequencies: parsedProgramSets,
        programSetFrequenciesFemale: parsedProgramSetsFemale,
      })
      setModalVisible(false)
    }
  }

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />
  }

  return (
    <MainContainer>
      <Table
        dataSource={levelGoalPlans}
        columns={columns}
        rowKey={(item) => item.id}
        title={() => (
          <div>
            <Button
              type="primary"
              onClick={() => {
                setCurrentGoalPlan(EMPTY_GOAL_PLAN)
                setModalMode('create')
                setModalVisible(true)
              }}
            >
              <PlusOutlined /> Новая цель
            </Button>
          </div>
        )}
      />
      <Modal
        open={modalVisible}
        title={currentGoalPlan?.goal}
        okText={modalMode === 'edit' ? 'Сохранить' : 'Создать'}
        cancelText="Отмена"
        onCancel={onCancel}
        onOk={onOk}
        footer={[
          <Button danger type="primary" onClick={onDelete}>
            {' '}
            Удалить цель{' '}
          </Button>,
          <>
            <Button key="back" onClick={onCancel}>
              Отмена
            </Button>
            <Button key="submit" type="primary" onClick={onOk}>
              {modalMode === 'edit' ? 'Сохранить' : 'Создать'}
            </Button>
          </>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item
            label={getTooltip('Цель', 'Цель человека: похудеть, набрать мышцы')}
          >
            <StyledSelect
              key={`${currentGoalPlan?.id}goal`}
              mode="single"
              placeholder="Цель человека"
              onChange={(value) => {
                handleInputChange('goal', value)
              }}
              value={currentGoalPlan?.goal}
            >
              {GOAL_TYPES.map((goal) => (
                <Select.Option key={goal} value={goal}>
                  {goal}
                </Select.Option>
              ))}
            </StyledSelect>
          </Form.Item>
          <Form.Item
            label={getTooltip('Программы', 'На какие программы надо ходить')}
          >
            {currentGoalPlan?.programSetFrequencies.map(
              (programSetFrequency, index) => {
                return (
                  <>
                    <h3>Тип программ #{index + 1}</h3>
                    <Select
                      key={`${currentGoalPlan?.id}programSetFrequencies${index}`}
                      mode="multiple"
                      value={programSetFrequency?.types}
                      onChange={(value) =>
                        handleProgramSetChange(index, 'types', value)
                      }
                    >
                      {PROGRAM_SET_TYPES.map((type) => (
                        <Select.Option key={type} value={type}>
                          {type}
                        </Select.Option>
                      ))}
                    </Select>
                    <StyledInput
                      value={programSetFrequency.proportion}
                      onChange={(e) =>
                        handleProgramSetChange(
                          index,
                          'proportion',
                          e.target.value
                        )
                      }
                    />
                    <Button
                      danger
                      onClick={() => {
                        deleteProgramSetRow(index)
                      }}
                    >
                      Delete
                    </Button>
                  </>
                )
              }
            )}
          </Form.Item>
          <Button onClick={() => addProgramSetRow(false)} type="primary">
            {' '}
            Добавить программы
          </Button>
          <Divider />
          <h1> Female specifications</h1>
          <Form.Item
            label={getTooltip('Программы', 'На какие программы надо ходить')}
          >
            {currentGoalPlan?.programSetFrequenciesFemale.map(
              (programSetFrequency, index) => {
                return (
                  <>
                    <h3>Тип программ #{index + 1}</h3>
                    <Select
                      key={`${currentGoalPlan?.id}programSetFrequenciesFemale${index}`}
                      mode="multiple"
                      value={programSetFrequency?.types}
                      onChange={(value) =>
                        handleProgramSetChange(index, 'types', value, true)
                      }
                    >
                      {PROGRAM_SET_TYPES.map((type) => (
                        <Select.Option key={type} value={type}>
                          {type}
                        </Select.Option>
                      ))}
                    </Select>
                    <StyledInput
                      value={programSetFrequency.proportion}
                      onChange={(e) =>
                        handleProgramSetChange(
                          index,
                          'proportion',
                          e.target.value,
                          true
                        )
                      }
                    />
                    <Button
                      danger
                      onClick={() => {
                        deleteProgramSetRow(index, true)
                      }}
                    >
                      Delete
                    </Button>
                  </>
                )
              }
            )}
          </Form.Item>
          <Button onClick={() => addProgramSetRow(true)} type="primary">
            {' '}
            Добавить программы
          </Button>
        </Form>
      </Modal>
    </MainContainer>
  )
}

LevelPlanner.propTypes = {
  levelGoalPlans: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      goal: PropTypes.string,
      programSetFrequencies: PropTypes.arrayOf(
        PropTypes.shape({
          proportion: PropTypes.number,
          types: PropTypes.arrayOf(PropTypes.string),
        })
      ),
    })
  ).isRequired,
  handleAddLevelGoalPlan: PropTypes.func.isRequired,
  handleUpdateLevelGoalPlan: PropTypes.func.isRequired,
  handleDeleteLevelGoalPlan: PropTypes.func.isRequired,
}

export default LevelPlanner
