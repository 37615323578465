import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Table, Button, Divider } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { useNavigate } from "react-router-dom"


import EditForm from '../shared/EditFormModal'
import CreateForm from '../shared/CreateForm'

const StyledImg = styled.img`
  width: 50px;
  height: 50px;
`

const Faq = ({
	categories,
	handleAddCategory,
	handleDeleteCategory,
	handleUpdateCategory
}) => {
  const navigate = useNavigate()

  const [display, setDisplay] = useState('default')
  const [editValue, setEditValue] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)

  const columns = [
    {
      title: 'Название категории',
      dataIndex: 'name'
    },
		{
			title: 'Иконка',
			dataIndex: 'iconUrl',
      render: item => {
        return <StyledImg src={item} alt="No Icon" />
      }
		},
		{
      title: 'Действие',
      key: 'action',
      render: (text, item) => (
        <span>
          <Button
            type="link"
            onClick={() => {
              setDisplay('edit')
              setEditValue(item)
            }}
          >
            Редактировать
          </Button>
          <Divider type="vertical" />
        </span>
      )
    },
    {
      title: 'Действие',
      key: 'action',
      render: (text, item) => (
        <span>
          <Button
            type="link"
            onClick={() => {
              navigate(`/faqQuestions/${item.id}`)
            }}
          >
            Перейти к вопросам
          </Button>
          <Divider type="vertical" />
        </span>
      )
    }
  ]

  const fields = [
    {
      key: 'name',
      label: 'Название',
      tooltipText: 'Название категории'
    },
		{
			key: 'iconUrl',
			label: 'Иконка',
			tooltipText: 'Иконка'
		}
  ]

  const editFields = [
    {
      key: 'name',
      label: 'Название',
      value: editValue && editValue.name,
      tooltipText: 'Название категории'
    },
    {
      key: 'iconUrl',
      label: 'Иконка',
      value: editValue && editValue.iconUrl,
      tooltipText: 'Иконка'
    }
  ]

  const handleCreate = values => {
    handleAddCategory(values)
    setModalVisible(false)
  }

  const handleUpdate = values => {
    handleUpdateCategory(editValue.id, values)
    setDisplay('default')
    setEditValue(null)
  }

  const handleDelete = () => {
    handleDeleteCategory(editValue.id)
    setDisplay('default')
    setEditValue(null)
  }

  return (
    <>
      <Table
        dataSource={categories}
        columns={columns}
        title={() => (
          <div>
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новая категория
            </Button>
          </div>
        )}
      />
      <CreateForm
        title="Добавить новую категорию"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onCreate={handleCreate}
        fields={fields}
      />
      {display === 'edit' && editValue && (
        <EditForm
          title="Редактировать категорию"
          visible={display === 'edit'}
          onCancel={() => setDisplay('default')}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          fields={editFields}
        />
      )}
    </>
  )
}

Faq.propTypes = {
  categories: PropTypes.shape({
    name: PropTypes.string,
		iconUrl: PropTypes.string
  }).isRequired,
  handleDeleteCategory: PropTypes.func.isRequired,
  handleAddCategory: PropTypes.func.isRequired,
  handleUpdateCategory: PropTypes.func.isRequired
}

export default Faq
