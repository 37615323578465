import React from 'react'
import { Layout, Menu, Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {
  InfoCircleOutlined,
  CalendarOutlined,
  HeartOutlined
} from '@ant-design/icons'
import LevelGeneral from './LevelGeneral'
import HurdleModal from './LevelHurdle'
import LevelPlanner from './LevelPlanner'

const { Sider, Content } = Layout

const StyledBreadcrumb = styled(Breadcrumb)`
  margin: 16px 0;
`

const StyledHeader = styled.div`
  background: #fff;
  border-bottom: 1px solid #d9d9d9;
  padding: 16px 24px 0;
  display: flex;
  justify-content: space-between;
`

const StyledMenu = styled(Menu)`
  height: 100%;
  padding-top: 16px;
`

const StyledLayout = styled(Layout)`
  padding: 0 24px 24px;
  background: #fff;
`

const StyledContent = styled(Content)`
  padding: 24px;
  margin: 0px;
  min-height: 280px;
`

const Level = ({
  level,
  handleUpdateLevel,
  handleUpdateHurdle,
  handleAddLevelGoalPlan,
  handleUpdateLevelGoalPlan,
  handleDeleteLevelGoalPlan
}) => {
  const [currentTab, setCurrentTab] = React.useState(1)

  const handleTabChange = activeKey => {
    setCurrentTab(activeKey)
  }

  return (
    <Layout>
      <StyledBreadcrumb>
        <Breadcrumb.Item>
          <Link to="/levels" href="/">
            Уровни
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item> {level?.name}</Breadcrumb.Item>
      </StyledBreadcrumb>

      <StyledHeader>
        <h2>Уровень</h2>
      </StyledHeader>

      <Layout>
        <Sider>
          <StyledMenu mode="inline" defaultSelectedKeys={['1']}>
            <Menu.Item
              icon={<InfoCircleOutlined />}
              key="1"
              onClick={() => handleTabChange(1)}
            >
              Основные
            </Menu.Item>
            <Menu.Item
              icon={<HeartOutlined />}
              key="2"
              onClick={() => handleTabChange(2)}
            >
              Hurdle
            </Menu.Item>
            <Menu.Item
              icon={<CalendarOutlined />}
              key="3"
              onClick={() => handleTabChange(3)}
            >
              Планер
            </Menu.Item>
          </StyledMenu>
        </Sider>
        <StyledLayout>
          <StyledContent className="site-layout-background">
            {currentTab === 1 && (
              <LevelGeneral
                {...{
                  level,
                  handleUpdateLevel
                }}
              />
            )}
            {currentTab === 2 && (
              <HurdleModal
                handleUpdateHurdle={handleUpdateHurdle}
                hurdle={level?.hurdle}
              />
            )}
            {currentTab === 3 && (
              <LevelPlanner
                {...{
                  levelGoalPlans: level?.levelGoalPlans,
                  handleAddLevelGoalPlan,
                  handleUpdateLevelGoalPlan,
                  handleDeleteLevelGoalPlan
                }}
              />
            )}
          </StyledContent>
        </StyledLayout>
      </Layout>
    </Layout>
  )
}

Level.propTypes = {
  level: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    maxPoints: PropTypes.number,
    attendancePoints: PropTypes.number,
    perfectWeekPoints: PropTypes.number,
    punishmentPoints: PropTypes.number,
    enterWeightBonusPoints: PropTypes.number,
    visitsForPerfectWeek: PropTypes.number,
    hurdle: PropTypes.shape({
      hurdleType: PropTypes.string
    }),
    levelGoalPlans: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        goal: PropTypes.string,
        programSetFrequencies: PropTypes.shape({
          proportion: PropTypes.number,
          types: PropTypes.arrayOf(PropTypes.string)
        })
      })
    ).isRequired
  }).isRequired,
  handleUpdateLevel: PropTypes.func.isRequired,
  handleUpdateHurdle: PropTypes.func.isRequired,
  handleAddLevelGoalPlan: PropTypes.func.isRequired,
  handleUpdateLevelGoalPlan: PropTypes.func.isRequired,
  handleDeleteLevelGoalPlan: PropTypes.func.isRequired
}

export default Level
