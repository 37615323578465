import React from 'react'
import { Layout, Menu, Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { InfoCircleOutlined, CheckOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

import MarathonEventGeneral from './MarathonEventGeneral'
import MarathonEventMembers from './MarathonEventMembers'

const { Sider, Content } = Layout

const StyledBreadcrumb = styled(Breadcrumb)`
  margin: 16px 0;
`

const StyledHeader = styled.div`
  background: #fff;
  border-bottom: 1px solid #d9d9d9;
  padding: 16px 24px 0;
  display: flex;
  justify-content: space-between;
`

const StyledMenu = styled(Menu)`
  height: 100%;
  padding-top: 16px;
`

const StyledLayout = styled(Layout)`
  padding: 0 24px 24px;
  background: #fff;
`

const StyledContent = styled(Content)`
  padding: 24px;
  margin: 0px;
  min-height: 280px;
`

const MarathonEvent = ({
  marathon,
  marathonEvent,
  userMarathonEvents,
  handleUpdateMarathonEvent,
  handleDeleteMarathonEvent,
  trainers,
  users,
  getUsers,
  refetchUsers,
  handleParticipateUser,
  clubs
}) => {
  const [currentTab, setCurrentTab] = React.useState(1)
  dayjs.locale('ru')

  const handleTabChange = (activeKey) => {
    setCurrentTab(activeKey)
  }

  return (
    <Layout>
      <StyledBreadcrumb>
        <Breadcrumb.Item>
          <Link to="/marathons" href="/">
            Марафоны
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item> {marathon?.name}</Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/marathons" href="/">
            {dayjs(marathonEvent?.startTime).format('Do MMMM, YYYY')}
          </Link>
        </Breadcrumb.Item>
      </StyledBreadcrumb>

      <StyledHeader>
        <h2>{dayjs(marathonEvent?.startTime).format('Do MMMM, YYYY')}</h2>
      </StyledHeader>

      <Layout>
        <Sider>
          <StyledMenu mode="inline" defaultSelectedKeys={['1']}>
            <Menu.Item
              icon={<InfoCircleOutlined />}
              key="1"
              onClick={() => handleTabChange(1)}
            >
              Основные
            </Menu.Item>
            <Menu.Item
              icon={<CheckOutlined />}
              key="2"
              onClick={() => handleTabChange(2)}
            >
              Участники
            </Menu.Item>
          </StyledMenu>
        </Sider>
        <StyledLayout>
          <StyledContent className="site-layout-background">
            {currentTab === 1 && (
              <MarathonEventGeneral
                {...{
                  trainers,
                  marathonEvent,
                  handleUpdateMarathonEvent,
                  handleDeleteMarathonEvent,
                }}
              />
            )}
            {currentTab === 2 && (
              <MarathonEventMembers
                userMarathonEvents={userMarathonEvents}
                users={users}
                getUsers={getUsers}
                refetchUsers={refetchUsers}
                handleParticipateUser={handleParticipateUser}
                clubs={clubs}
              />
            )}
          </StyledContent>
        </StyledLayout>
      </Layout>
    </Layout>
  )
}

MarathonEvent.propTypes = {
  marathon: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  marathonEvent: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  handleUpdateMarathonEvent: PropTypes.func.isRequired,
  handleDeleteMarathonEvent: PropTypes.func.isRequired,
  trainers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      nickname: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default MarathonEvent
