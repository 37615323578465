import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import {
  Table,
  DatePicker,
  Form,
  Button,
  Tag,
  Tooltip,
  Modal,
  Select,
  InputNumber,
  Input,
} from 'antd'
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  ReloadOutlined,
} from '@ant-design/icons'
import { Chart } from 'react-google-charts'
import { Card, Col, Row, Statistic } from 'antd'
import * as XLSX from 'xlsx'
import * as FileSaver from 'file-saver'
import datePickerLocale from 'antd/es/date-picker/locale/ru_RU'

import Loading from '../shared/Loading'
import { useAuth } from '../../context/useAuth'

const { TextArea } = Input
const { RangePicker } = DatePicker
const FormItem = Form.Item

const confirmModal = Modal.confirm

const showConfirm = (func) => {
  confirmModal({
    title: 'Вы уверены, что хотите сделать возврат?',
    content: 'По этой записи будет сделан возврат',
    okType: 'primary',
    onOk: () => func(),
    // eslint-disable-next-line no-console
    onCancel: () => console.log('Cancel'),
    cancelText: 'Отменить',
    okText: 'Удалить',
  })
}

const StyleTable = styled(Table)`
  margin-top: 8px;
`

const Title = styled.div`
  color: rgba(0, 0, 0, 1);
  font-family: 'GraphikLCGRegular';
  font-size: 14px;
  line-height: 16px;
`

const Hour = styled.div`
  color: #00000080;
  font-family: 'GraphikLCGRegular';
  font-size: 14px;
  line-height: 16px;
`

const Time = styled.div`
  display: flex;
  gap: 4px;
`

const SalesReport = ({
  payments,
  webKassaPayments,
  loading,
  setStartDate,
  startDate,
  setEndDate,
  endDate,
  refetch,
  getPayments,
  salesPersons,
  refundByUserPayment,
}) => {
  const [form] = Form.useForm()
  const { user } = useAuth()
  const [refundModal, setRefundModal] = React.useState(false)
  const imageFileRef = React.useRef()
  const [imageFile, setImageFile] = React.useState(null)
  const [selectUserPayment, setSelectUserPayment] = React.useState(null)

  const sum = payments.reduce(function (prev, curr) {
    if (curr?.status !== 'refunded') {
      return prev + curr?.price
    }
    return prev + 0
  }, 0)

  const refundSum = payments.reduce(function (prev, curr) {
    if (curr?.status === 'refunded') {
      return prev + curr?.price
    }
    return prev + 0
  }, 0)

  const sumWithChecks = payments.reduce(function (prev, curr) {
    if (curr?.status !== 'refunded' && curr?.isChecked) {
      return prev + curr?.price
    }

    return prev + 0
  }, 0)

  const webKassaSum = webKassaPayments.reduce(function (prev, curr) {
    if (curr?.type !== 'refunded') {
      return prev + curr?.totalAmount
    }
    return prev + 0
  }, 0)

  const webKassaRefundSum = webKassaPayments.reduce(function (prev, curr) {
    if (curr?.type === 'refunded') {
      return prev + curr?.totalAmount
    }
    return prev + 0
  }, 0)

  const columns = [
    {
      title: 'Название',
      render: (item) => {
        return <span>{item.name}</span>
      },
    },
    {
      title: 'Тип',
      render: (item) => {
        return <span>{item.typeName}</span>
      },
    },

    {
      title: 'Цена',
      render: (item) => {
        return <span>{item?.price}</span>
      },
    },
    {
      title: 'Способ оплаты',
      render: (item) => {
        return <span>{item.paymentMetod}</span>
      },
    },
    {
      title: 'Дата и время',
      render: (item) => {
        return (
          <Time>
            <Title>{dayjs(item?.date).format('DD.MM.YY')}</Title>
            <Hour>{dayjs(item?.date).format('HH:mm')}</Hour>
          </Time>
        )
      },
    },
    {
      title: 'Есть Чек?',
      render: (item) => {
        if (item.isChecked) {
          return <span style={{ color: 'green' }}>Да</span>
        }
        return <span style={{ color: 'red' }}>Нет</span>
      },
    },
    {
      title: 'Коммент',
      render: (item) => {
        return <span>{item?.comment}</span>
      },
      width: '20%',
    },
    {
      title: 'Статус',
      render: (item) => {
        if (item?.status === 'refunded') {
          return <Tag color={'red'}>ВОЗВРАТ</Tag>
        }
        return <Tag color={'green'}>ОПЛАТА</Tag>
      },
    },
    {
      title: 'Пользователь',
      render: (item) => <span>{item?.username}</span>,
    },
    {
      title: 'Действие',
      render: (_, item) => {
        return (
          <span>
            {item.status !== 'refunded' &&
            item.isChecked &&
            !item.refundLetterTime &&
            item?.type !== 'added' &&
            item?.user?.id !== item?.toUser?.id ? (
              <Button
                type="link"
                onClick={() => {
                  setRefundModal(true)
                  setSelectUserPayment(item)
                }}
              >
                Оформить возврат
              </Button>
            ) : (
              <div style={{ color: 'red' }}>Нельзя сделать возврат</div>
            )}
          </span>
        )
      },
    },
  ]
  const StyledTooltip = ({ text, fullText }) => (
    <>
      <Tooltip title={fullText} placement="right">
        {text}
      </Tooltip>
    </>
  )

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />
  }

  return (
    <>
      <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 16 }}>
        <div style={{ width: '30%', paddingRight: 8 }}>
          <div style={{ paddingRight: 8, marginBottom: 16 }}>
            <div style={{ fontFamily: 'GraphikLCGMedium', fontSize: 20 }}>
              Все платежи
            </div>
            <Row gutter={16}>
              <Col span={12}>
                <Card bordered={false}>
                  <Statistic
                    title="Оплата"
                    value={sum}
                    valueStyle={{
                      color: '#3f8600',
                    }}
                    prefix={<ArrowUpOutlined />}
                    suffix="₸"
                  />
                </Card>
              </Col>
              <Col span={12}>
                <Card bordered={false}>
                  <Statistic
                    title="Возварт"
                    value={refundSum}
                    valueStyle={{
                      color: '#cf1322',
                    }}
                    prefix={<ArrowDownOutlined />}
                    suffix="₸"
                  />
                </Card>
              </Col>
            </Row>
          </div>
          <div style={{ paddingRight: 8, marginBottom: 16 }}>
            <div style={{ fontFamily: 'GraphikLCGMedium', fontSize: 20 }}>
              Все платежи с чеками
            </div>
            <Row gutter={16}>
              <Col span={12}>
                <Card bordered={false}>
                  <Statistic
                    title="Оплата"
                    value={sumWithChecks}
                    valueStyle={{
                      color: '#3f8600',
                    }}
                    prefix={<ArrowUpOutlined />}
                    suffix="₸"
                  />
                </Card>
              </Col>
              <Col span={12}>
                <Card bordered={false}>
                  <Statistic
                    title="Возварт"
                    value={refundSum}
                    valueStyle={{
                      color: '#cf1322',
                    }}
                    prefix={<ArrowDownOutlined />}
                    suffix="₸"
                  />
                </Card>
              </Col>
            </Row>
          </div>
          <div style={{ paddingRight: 8 }}>
            <div style={{ fontFamily: 'GraphikLCGMedium', fontSize: 20 }}>
              Все платежи из Ukassa.kz
            </div>
            <Row gutter={16}>
              <Col span={12}>
                <Card bordered={false}>
                  <Statistic
                    title="Оплата"
                    value={webKassaSum}
                    valueStyle={{
                      color: '#3f8600',
                    }}
                    prefix={<ArrowUpOutlined />}
                    suffix="₸"
                  />
                </Card>
              </Col>
              <Col span={12}>
                <Card bordered={false}>
                  <Statistic
                    title="Возварт"
                    value={webKassaRefundSum}
                    valueStyle={{
                      color: '#cf1322',
                    }}
                    prefix={<ArrowDownOutlined />}
                    suffix="₸"
                  />
                </Card>
              </Col>
            </Row>
          </div>
        </div>

        <div style={{ width: '70%' }}>
          <Chart
            chartType="PieChart"
            width="100%"
            height="100%"
            data={[
              ['Task', 'Hours per Day'],
              [
                'Платежи c чека',
                payments?.filter(
                  (payment) =>
                    !!payment?.isChecked && payment.status !== 'refunded'
                ).length,
              ],
              [
                'Платежи без чека',
                payments?.filter(
                  (payment) =>
                    !payment?.isChecked && payment.status !== 'refunded'
                ).length,
              ],
              [
                'Возвраты с чека',
                payments?.filter(
                  (payment) =>
                    !!payment?.isChecked && payment.status === 'refunded'
                ).length,
              ],
              [
                'Возвраты без чека',
                payments?.filter(
                  (payment) =>
                    !payment?.isChecked && payment.status === 'refunded'
                ).length,
              ],
            ]}
            options={{
              title: 'Количество платежей',
              pieHole: 0.4,
              is3D: false,
            }}
          />
        </div>
      </div>

      <div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <RangePicker
              showTime
              locale={datePickerLocale}
              defaultValue={[dayjs(startDate), dayjs(endDate)]}
              onOk={(value) => {
                if (value.length > 1) {
                  setStartDate(dayjs(value[0]).startOf('day'))
                  setEndDate(dayjs(value[1]).endOf('day'))
                  getPayments({
                    variables: {
                      startDate: dayjs(value[0]).startOf('day'),
                      endDate: dayjs(value[1]).endOf('day'),
                    },
                  })
                }
              }}
            />

            <Button
              style={{ marginLeft: 8 }}
              onClick={() => {
                getPayments({
                  variables: {
                    startDate: dayjs(startDate),
                    endDate: dayjs(endDate),
                  },
                })
              }}
            >
              <ReloadOutlined />
            </Button>
          </div>
          {!loading && (
            <div>
              <Button
                type="dashed"
                style={{ marginLeft: 8 }}
                onClick={() => {
                  const convPayments = webKassaPayments.map((item) => {
                    return {
                      Цена: item?.totalAmount,
                      Дата: dayjs(item?.created_at).format('HH:mm DD.MM.YYYY'),
                      'Чек URL': item?.checkLink,
                      Статус: item?.type === 'refunded' ? 'ВОЗВРАТ' : 'ОПЛАТА',
                      Пользователь: `${item?.customer?.email} | ${item?.customer?.phone}`,
                      Коммент: item?.comment,
                    }
                  })

                  const ws = XLSX.utils.json_to_sheet(convPayments)
                  const fileType =
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
                  /* custom headers */
                  XLSX.utils.sheet_add_aoa(
                    ws,
                    [
                      [
                        'Цена',
                        'Дата',
                        'Чек URL',
                        'Статус',
                        'Пользователь',
                        'Коммент',
                      ],
                    ],
                    { origin: 'A1' }
                  )
                  const wb = {
                    Sheets: { data: ws },
                    SheetNames: ['data'],
                  }
                  const excelBuffer = XLSX.write(wb, {
                    bookType: 'xlsx',
                    type: 'array',
                  })
                  const data = new Blob([excelBuffer], { type: fileType })
                  FileSaver.saveAs(
                    data,
                    `Отчет Ukassa(${dayjs(startDate).format(
                      'DD.MM.YYYY'
                    )} - ${dayjs(endDate).format('DD.MM.YYYY')}).xlsx`
                  )
                }}
              >
                Отчет с Ukassa.kz
              </Button>
              <Button
                type="primary"
                style={{ marginLeft: 8 }}
                onClick={() => {
                  const convPayments = payments.map((item) => {
                    return {
                      Название: item?.name,
                      Тип: item?.typeName,
                      Цена: item?.price,
                      'Способ оплаты': item?.paymentMetod,
                      Дата: dayjs(item?.date).format('HH:mm DD.MM.YYYY'),
                      'Есть Чек?': item?.isChecked ? 'Да' : 'Нет',
                      Статус:
                        item?.status === 'refunded' ? 'ВОЗВРАТ' : 'ОПЛАТА',
                      Пользователь: item?.username,
                      Коммент: item?.comment,
                    }
                  })

                  const ws = XLSX.utils.json_to_sheet(convPayments)
                  const fileType =
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
                  /* custom headers */
                  XLSX.utils.sheet_add_aoa(
                    ws,
                    [
                      [
                        'Название',
                        'Тип',
                        'Цена',
                        'Способ оплаты',
                        'Дата',
                        'Есть Чек?',
                        'Статус',
                        'Пользователь',
                        'Коммент',
                      ],
                    ],
                    { origin: 'A1' }
                  )
                  const wb = {
                    Sheets: { data: ws },
                    SheetNames: ['data'],
                  }
                  const excelBuffer = XLSX.write(wb, {
                    bookType: 'xlsx',
                    type: 'array',
                  })
                  const data = new Blob([excelBuffer], { type: fileType })
                  FileSaver.saveAs(
                    data,
                    `Отчет HJ(${dayjs(startDate).format(
                      'DD.MM.YYYY'
                    )} - ${dayjs(endDate).format('DD.MM.YYYY')}).xlsx`
                  )
                }}
              >
                Отчет HJ
              </Button>
            </div>
          )}
        </div>
        <StyleTable loading={loading} dataSource={payments} columns={columns} />
      </div>
      {refundModal && (
        <Modal
          open={refundModal}
          title="Оформить возврат"
          okText="Оформить"
          cancelText="Отмена"
          onCancel={() => {
            form.resetFields()
            setImageFile(null)
            setSelectUserPayment(null)
            setRefundModal(false)
          }}
          onOk={() => {
            if (imageFile) {
              form
                .validateFields()
                .then((values) => {
                  form.resetFields()
                  confirmModal({
                    title: 'Вы уверены, что хотите сделать возврат?',
                    content: 'По этой записи будет сделан возврат',
                    okType: 'primary',
                    onOk: () => {
                      refundByUserPayment({
                        variables: {
                          userId: selectUserPayment?.user?.id,
                          userPaymentId: selectUserPayment?.id,
                          imageFile: imageFile,
                          clubId: user?.club?.id,
                          salesmanId: values.salesPerson,
                          refundAmount: selectUserPayment?.price,
                          changeRefundAmount: values.amount,
                          comment: values.comment,
                          refundLetterTime: values.refundLetterTime,
                        },
                      })
                      setImageFile(null)
                      setSelectUserPayment(null)
                      setRefundModal(false)
                    },
                    // eslint-disable-next-line no-console
                    onCancel: () => {
                      setImageFile(null)
                      setSelectUserPayment(null)
                      setRefundModal(false)
                    },
                    cancelText: 'Отменить',
                    okText: 'Оформить',
                  })
                })
                .catch((info) => {
                  // eslint-disable-next-line no-console
                  console.log('Validate Failed:', info)
                })
            } else {
              toast.error('Заявление не загружено')
            }
          }}
        >
          <Form form={form} layout="vertical">
            <FormItem
              key="refundLetterTime"
              label={getTooltip('Дата заявления', 'Дата начала события')}
              name="refundLetterTime"
              rules={[
                {
                  required: true,
                  message: `Пожалуйста, выберите дату`,
                },
              ]}
              initialValue={dayjs()}
            >
              <DatePicker
                locale={datePickerLocale}
                placeholder="Выберите дату"
                style={{ width: '100%' }}
              />
            </FormItem>
            <FormItem
              key="salesPerson"
              label={getTooltip('Менеджер', 'Менеджер')}
              name="salesPerson"
              rules={[
                {
                  required: true,
                  message: `Пожалуйста, выберите менеджера`,
                },
              ]}
            >
              <Select placeholder="Выберите менеджера">
                {salesPersons.map((salesPerson) => (
                  <Select.Option key={salesPerson.id} value={salesPerson.id}>
                    {salesPerson?.username || salesPerson?.nickname}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
            <FormItem
              key="amount"
              label={getTooltip('Сумма возрата', 'Сумма возрата')}
              name="amount"
              initialValue={selectUserPayment.price || 400000}
              rules={[
                {
                  required: true,
                  message: `Пожалуйста, введите сумму`,
                },
              ]}
            >
              <InputNumber
                min={0}
                max={selectUserPayment.price || 400000}
                type="number"
                placeholder="Сумма возрата"
                style={{ width: '100%' }}
              />
            </FormItem>
            <FormItem
              key=""
              label={getTooltip('Заявление на возрата', 'Заявление на возрата')}
              name=""
            >
              <input
                type="file"
                accept="image/*"
                ref={imageFileRef}
                onChange={(event) => setImageFile(event.target.files[0])}
              />
            </FormItem>

            <FormItem
              key="comment"
              label={getTooltip('Комментарий', 'Комментарий')}
              name="comment"
            >
              <TextArea
                rows={4}
                placeholder="Комментарий"
                style={{ width: '100%' }}
              />
            </FormItem>
          </Form>
        </Modal>
      )}
    </>
  )
}

SalesReport.propTypes = {
  paymentsByMonth: PropTypes.arrayOf(PropTypes.any).isRequired,
  isRefund: PropTypes.bool,
}

SalesReport.defaultProps = {
  isRefund: false,
}

export default SalesReport
