import React, { useState, useEffect } from 'react'
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

import { useAuth } from '../../context/useAuth'
import Events from './Events'
import Loading from '../shared/Loading'
import { useLoading } from '../../context/useLoading'
import withMainLayout from '../../hocs/withMainLayout'

const GET_DATA = gql`
  query getData($startTime: DateTime, $endTime: DateTime, $clubId: ID) {
    eventsByDates(startTime: $startTime, endTime: $endTime, clubId: $clubId) {
      id
      programSet {
        id
        name
        type
        zone {
          id
          name
        }
        club {
          id
          name
          zones {
            id
            name
          }
        }
      }
      trainer {
        id
        role
        nickname
      }
      status
      startTime
      endTime
    }
  }
`
const GET_CLUBS = gql`
  query getClubs {
    clubs {
      id
      name
      zones {
        id
        name
      }
    }
  }
`
const GET_PROGRAMSETS = gql`
  query programSetsByClubId($clubId: ID) {
    programSetsByClubId(clubId: $clubId) {
      id
      name
      zone {
        id
        name
      }
      club {
        id
        name
      }
    }
  }
`

const GET_TRAINERS = gql`
  query getTrainers($role: String!) {
    usersByRole(role: $role) {
      id
      role
      nickname
    }
  }
`

const ADD_EVENT = gql`
  mutation addEvent($input: EventInput) {
    addEvent(input: $input) {
      id
      programSet {
        id
        name
        type
        zone {
          id
          name
        }
        club {
          id
          name
          zones {
            id
            name
          }
        }
      }
      trainer {
        id
        role
        nickname
      }
      status
      startTime
      endTime
    }
  }
`

const UPDATE_EVENT = gql`
  mutation updateEvent($id: ID!, $input: EventInput) {
    updateEvent(id: $id, input: $input) {
      id
      programSet {
        id
        name
        type
        zone {
          id
          name
        }
        club {
          id
          name
          zones {
            id
            name
          }
        }
      }
      trainer {
        id
        role
        nickname
      }
      status
      startTime
      endTime
    }
  }
`

const ADD_MULTIPLE_EVENTS = gql`
  mutation addMultipleEvents($input: [EventInput]) {
    addMultipleEvents(input: $input) {
      message
      status
    }
  }
`

const UPDATE_MULTIPLE_EVENTS = gql`
  mutation updateMultipleEvents($input: [EventUpdateInput]) {
    updateMultipleEvents(input: $input) {
      message
      status
    }
  }
`

const DELETE_MULTIPLE_EVENTS = gql`
  mutation deleteMultipleEvents($ids: [ID]) {
    deleteMultipleEvents(ids: $ids) {
      message
      status
    }
  }
`
const NOTIFICATION_CHANGE_TRAINER = gql`
  mutation addNotificationEvent($eventId: ID) {
    addNotificationEvent(eventId: $eventId) {
      message
    }
  }
`

const EventsContainer = () => {
  const { user } = useAuth()
  const [dataEvents, setDataEvents] = useState(null)
  const [programSets, setProgramSets] = useState(null)
  const [clubs, setClubs] = useState(null)
  const [trainers, setTrainers] = useState(null)
  const { showLoading, hideLoading } = useLoading()
  const [date, setDate] = React.useState(new Date())
  const [currentClubId, setCurrentClubId] = React.useState(user?.club?.id)
  const [currentClub, setCurrentClub] = React.useState(user?.club)
  const [startTime, setStartTime] = React.useState(dayjs().startOf('week'))
  const [endTime, setEndTime] = React.useState(dayjs().endOf('week'))

  const [getEvents, { data, loading, error, refetch }] = useLazyQuery(
    GET_DATA,
    { fetchPolicy: 'no-cache' }
  )
  const [getProgramSets, { data: dataProgramSets }] =
    useLazyQuery(GET_PROGRAMSETS)

  const { data: dataClubs } = useQuery(GET_CLUBS)

  const { data: dataTrainers } = useQuery(GET_TRAINERS, {
    variables: {
      role: 'trainer',
    },
  })

  const [addEvent] = useMutation(ADD_EVENT, {
    onError(err) {
      hideLoading()
      toast.error(`Error ${err.message}`)
    },
    onCompleted() {
      refetch()
      hideLoading()
      toast.success('Событие успешно создано')
    },
  })

  const [updateEvent] = useMutation(UPDATE_EVENT, {
    onError(err) {
      hideLoading()
      toast.error(`Error ${err.message}`)
    },
    onCompleted() {
      refetch()
      hideLoading()
      toast.success('Событие успешно отредактировано')
    },
  })

  const [addMultipleEvents] = useMutation(ADD_MULTIPLE_EVENTS, {
    onError(err) {
      hideLoading()
      toast.error(`Error ${err.message}`)
    },
    onCompleted: () => {
      refetch()
      toast.success('Событие успешно загружено')
      hideLoading()
    },
  })

  const [updateMultipleEvents] = useMutation(UPDATE_MULTIPLE_EVENTS, {
    onError(err) {
      hideLoading()
      toast.error(`Error ${err.message}`)
    },
    onCompleted: () => {
      refetch()
      toast.success('Событие успешно  загружено')
      hideLoading()
    },
  })

  const [deleteMultipleEvents] = useMutation(DELETE_MULTIPLE_EVENTS, {
    onError(err) {
      hideLoading()
      toast.error(`Error ${err.message}`)
    },
    onCompleted: () => {
      refetch()
      toast.success('Событие успешно удалено')
      hideLoading()
    },
  })

  const [notificationChangeTrainer] = useMutation(NOTIFICATION_CHANGE_TRAINER)


  useEffect(() => {
    if (currentClubId) {
      getEvents({
        variables: {
          startTime: startTime,
          endTime: endTime,
          clubId: currentClubId,
        },
      })
      getProgramSets({
        variables: {
          clubId: currentClubId,
        },
      })
    }
  }, [currentClubId, startTime, endTime])

  useEffect(() => {
    getEvents({
      variables: {
        startTime: startTime,
        endTime: dayjs(startTime).endOf('week'),
        clubId: currentClubId,
      },
    })
  }, [startTime])

  useEffect(() => {
    if (data && !loading && !error) {
      setDataEvents(data.eventsByDates)
    }
  }, [data, loading, error])


  useEffect(()=> {
    if (dataClubs) {
      setClubs(dataClubs.clubs)
    }
  }, [dataClubs])

  useEffect(() => {
  if (user?.role === 'schedule') {
    const findClub = dataClubs?.clubs?.find(
      (club) => club.id === user.club.id
    );
    setCurrentClubId(user.club.id);
    setCurrentClub(findClub);
  } else {
    const findClub = dataClubs?.clubs?.find(
      (club) => club.name === `Hero's Journey`
    );
    setCurrentClubId(findClub?.id || dataClubs?.clubs[0]?.id);
    setCurrentClub(findClub || dataClubs?.clubs[0]);
  }
}, [dataClubs, user]);

  useEffect(() => {
    if (dataProgramSets) {
      setProgramSets(dataProgramSets.programSetsByClubId)
    }
  }, [dataProgramSets])

  useEffect(() => {
    if (dataTrainers) {
      setTrainers(dataTrainers.usersByRole)
    }
  }, [dataTrainers])

  const handleAddEvent = (values) => {
    showLoading()
    addEvent({
      variables: {
        input: values,
      },
    })
  }
  const handleUpdateEvent = (id, values) => {
    showLoading()
    updateEvent({
      variables: {
        id: id,
        input: values,
      },
    })
  }

  const handleAddMultipleEvents = (values) => {
    addMultipleEvents({
      variables: {
        input: values,
      },
    })
  }

  const handleUpdateMultipleEvents = (values) => {
    updateMultipleEvents({
      variables: {
        input: values,
      },
    })
  }

  const handleDeleteMultipleEvents = (ids) => {
    deleteMultipleEvents({
      variables: {
        ids: ids,
      },
    })
  }

  const getNextWeekValues = (values) => {
    const startTime = dayjs(values.startTime).add(7, 'days')
    const endTime = dayjs(values.endTime).add(7, 'days')

    return {
      ...values,
      startTime,
      endTime,
    }
  }

  const handleCopyEvent = ({ event }) => {
    showLoading()
    if (event) {
      let values = {
        startTime: event.startTime,
        endTime: event.endTime,
        programSet: event?.programSet?.id,
        trainer: event?.trainer?.id,
      }
      values = getNextWeekValues(values)

      addEvent({
        variables: {
          input: values,
        },
      })
    }
  }

  return (
    <>
      <Events
        events={dataEvents}
        programSets={programSets}
        clubs={clubs}
        trainers={trainers}
        handleAddEvent={handleAddEvent}
        handleUpdateEvent={handleUpdateEvent}
        handleCopyEvent={handleCopyEvent}
        handleAddMultipleEvents={handleAddMultipleEvents}
        handleUpdateMultipleEvents={handleUpdateMultipleEvents}
        handleDeleteMultipleEvents={handleDeleteMultipleEvents}
        // new fields
        user={user}
        currentClub={currentClub}
        setCurrentClub={setCurrentClub}
        currentClubId={currentClubId}
        setCurrentClubId={setCurrentClubId}
        date={date}
        setDate={setDate}
        startTime={startTime}
        setStartTime={setStartTime}
        endTime={endTime}
        setEndTime={setEndTime}
        loading={loading}
        notificationChangeTrainer={notificationChangeTrainer}
      />
    </>
  )
}

export default withMainLayout(EventsContainer)
