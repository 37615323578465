import React, { useState } from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { Table, Button, Modal, AutoComplete } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { useAuth } from '../../context/useAuth'

const { Option } = AutoComplete

const StyledText = styled.div`
  height: 28px;
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #262626;
`
const StyledId = styled.div`
  height: 12px;
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  color: #262626;
`

const StyledUser = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledNickname = styled.div`
  height: 24;
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #262626;
  padding-left: 10px;
`

const MarathonEventMembers = ({
  userMarathonEvents,
  users,
  getUsers,
  refetchUsers,
  handleParticipateUser,
  clubs
}) => {
  const { user } = useAuth()
  const [modalVisible, setModalVisible] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedClub, setSelectedClub] = useState(null) 
  const columns = [
    {
      title: 'Пользователь',
      render: (text, item) => {
        return (
          <span>
            <Link to={`/users/${item?.user?.id}`}>{item?.user?.nickname}</Link>
          </span>
        )
      },
    },
    {
      title: 'Телефон',
      render: (text, item) => {
        return <span>{item?.user?.phoneNumber}</span>
      },
    },
    {
      title: 'Email',
      render: (text, item) => {
        return <span>{item?.user?.email}</span>
      },
    },
    {
      title: 'Дата записи',
      render: (text, item) => {
        const eventDate = dayjs(item?.created_at).format(
          'Do MMMM, YYYY | hh:mm'
        )
        return <span>{eventDate}</span>
      },
    },
  ]

  const handleSearch = (value) => {
    refetchUsers({
      query: {
        phoneNumber: value,
      },
      pagination: {
        page: 1,
        limit: 20,
      },
    })
  }

  const onSelect = (value) => {
    setSelectedUser(value)
  }

  const handleClubSelect = (value) => {
    setSelectedClub(value)
  }

  return (
    <>
      <StyledText>Кол-во участников: {userMarathonEvents.length}</StyledText>
      <Table
        dataSource={userMarathonEvents}
        columns={columns}
        rowKey={(item) => item?.id}
        title={() => (
          <div>
            {user?.role === 'admin' && (
              <Button type="primary" onClick={() => setModalVisible(true)}>
                <PlusOutlined /> Добавить участника
              </Button>
            )}
          </div>
        )}
      />
      <Modal
        open={modalVisible}
        title="Добавить участника"
        okText="Добавить"
        cancelText="Отмена"
        onCancel={() => {
          setModalVisible(false)
        }}
        onOk={() => {
          if (selectedUser && selectedClub) {
            handleParticipateUser(selectedUser, selectedClub) 
            setModalVisible(false)
          }
        }}
      >
        <AutoComplete
          dropdownMatchSelectWidth={252}
          style={{ width: 300 }}
          onSelect={onSelect}
          onSearch={handleSearch}
          placeholder="Введите номер телефона"
        >
          {users.map((user) => (
            <Option key={user.id} value={user.id}>
              <StyledUser>
                {user.phoneNumber}
                <StyledNickname> {user.email}</StyledNickname>
              </StyledUser>
              <StyledId>{user.id}</StyledId>
            </Option>
          ))}
        </AutoComplete>
        <AutoComplete
          dropdownMatchSelectWidth={252}
          style={{ width: 300, marginTop: 20 }}
          onSelect={handleClubSelect}
          placeholder="Выберите клуб"
        >
          {clubs.map((club) => (
            <Option key={club.id} value={club.id}>
              {club.name}
            </Option>
          ))}
        </AutoComplete>
      </Modal>
    </>
  )
}
export default MarathonEventMembers
