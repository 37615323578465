import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import WeeklyTasks from './WeeklyTasks'
import Loading from '../shared/Loading'
import { useLoading } from '../../context/useLoading'

const GET_DATA = gql`
  query getData($id: ID!) {
    season(id: $id) {
      id
      weeklyTasks {
        id
        name
        type
        weekNumber
        changeValue
        typeOfClass
        userVisits
        visitsCount
      }
    }
  }
`

const GET_WEEKLY_TASKS = gql`
  query getWeeklyTasks($id: ID!) {
    season(id: $id) {
      id
      weeklyTasks {
        id
        name
        type
        weekNumber
        changeValue
        typeOfClass
        userVisits
        visitsCount
      }
    }
  }
`

const ADD_WEEKLY_TASK = gql`
  mutation addWeeklyTask($input: WeeklyTaskInput) {
    addWeeklyTask(input: $input) {
      id
      name
      type
      weekNumber
      changeValue
      typeOfClass
      userVisits
      visitsCount
    }
  }
`

const UPDATE_SEASON = gql`
  mutation updateSeason($id: ID!, $input: SeasonInput) {
    updateSeason(id: $id, input: $input) {
      id
      weeklyTasks {
        id
        name
        type
        weekNumber
        changeValue
        typeOfClass
        userVisits
        visitsCount
      }
    }
  }
`

const DELETE_WEEKLY_TASK = gql`
  mutation deleteWeeklyTask($id: ID!) {
    deleteWeeklyTask(id: $id)
  }
`

const UPDATE_WEEKLY_TASK = gql`
  mutation updateWeeklyTask($id: ID!, $input: WeeklyTaskInput) {
    updateWeeklyTask(id: $id, input: $input) {
      id
      name
      type
      weekNumber
      changeValue
      typeOfClass
      userVisits
      visitsCount
    }
  }
`

const WeeklyTasksContainer = ({ seasonId }) => {
  const [weeklyTasksData, setWeeklyTasksData] = useState(null)
  const { showLoading, hideLoading } = useLoading()
  const [updateSeason] = useMutation(UPDATE_SEASON)
  const [updateWeeklyTask] = useMutation(UPDATE_WEEKLY_TASK)

  const { data, loading, error } = useQuery(GET_DATA, {
    variables: { id: seasonId }
  })

  const [addWeeklyTask] = useMutation(ADD_WEEKLY_TASK, {
    update(cache, { data: { addWeeklyTask: weeklyTask } }) {
      let { season } = cache.readQuery({
        query: GET_WEEKLY_TASKS,
        variables: { id: seasonId }
      })
      season = {
        id: season.id,
        weeklyTasks: season.weeklyTasks
          ? season.weeklyTasks.concat([weeklyTask])
          : [weeklyTask]
      }
      const weeklyTasks =
        season && season.weeklyTasks
          ? season.weeklyTasks.map(weeklyTaskData => weeklyTaskData.id)
          : []
      updateSeason({
        variables: { id: seasonId, input: { weeklyTasks } }
      })
      cache.writeQuery({
        query: GET_WEEKLY_TASKS,
        variables: { id: seasonId },
        data: { season }
      })
      hideLoading()
      toast.success('Сезон успешно создано')
    }
  })

  const [deleteWeeklyTask] = useMutation(DELETE_WEEKLY_TASK, {
    update(cache, { data: { deleteWeeklyTask: id } }) {
      let { season } = cache.readQuery({
        query: GET_WEEKLY_TASKS,
        variables: { id: seasonId }
      })
      season = {
        id: season.id,
        weeklyTasks: season.weeklyTasks.filter(
          weeklyTaskData => weeklyTaskData.id !== id
        )
      }
      const weeklyTasks =
        season && season.weeklyTasks
          ? season.weeklyTasks.map(weeklyTaskData => weeklyTaskData.id)
          : []

      updateSeason({ variables: { id: season.id, input: { weeklyTasks } } })
      cache.writeQuery({
        query: GET_WEEKLY_TASKS,
        variables: { id: seasonId },
        data: {
          season
        }
      })
      hideLoading()
      toast.success('Зона успешно удален')
    }
  })

  useEffect(() => {
    if (data && !loading && !error) {
      setWeeklyTasksData(data.season.weeklyTasks)
    }
  }, [data, loading, error])

  const handleAddWeeklyTask = values => {
    const changeValue = parseInt(values.changeValue, 10)
    const userVisits = parseInt(values?.userVisits, 10)
    const visitsCount = parseInt(values?.visitsCount, 10)
    delete values.changeValue
    delete values.userVisits
    delete values.userVisits
    showLoading()
    addWeeklyTask({
      variables: {
        input: { ...values, changeValue, userVisits, visitsCount }
      }
    })
  }

  const handleUpdateClick = ({ id, values }) => {
    const changeValue = parseInt(values.changeValue, 10)
    const userVisits = parseInt(values?.userVisits, 10)
    const visitsCount = parseInt(values?.visitsCount, 10)
    delete values.changeValue
    delete values.userVisits
    delete values.userVisits
    updateWeeklyTask({
      variables: {
        id,
        input: { ...values, changeValue, userVisits, visitsCount }
      }
    })
  }

  const handleDeleteClick = ({ id }) => {
    showLoading()
    deleteWeeklyTask({ variables: { id } })
  }

  if (loading && !weeklyTasksData) {
    return <Loading />
  }

  return (
    <>
      {weeklyTasksData && (
        <WeeklyTasks
          weeklyTasks={weeklyTasksData}
          addWeeklyTaskClick={handleAddWeeklyTask}
          updateWeeklyTaskClick={handleUpdateClick}
          deleteWeeklyTaskClick={handleDeleteClick}
        />
      )}
    </>
  )
}

WeeklyTasksContainer.propTypes = {
  seasonId: PropTypes.string.isRequired
}

export default WeeklyTasksContainer
