import styled from 'styled-components'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import React from 'react'
import { MenuItem, Select, FormControl } from '@mui/material'
import { DatePicker } from 'antd'
import { styled as styledMui } from '@mui/system'
import datePickerLocale from 'antd/es/date-picker/locale/ru_RU'

import CancelLogo from '../../images/cancel.svg'
import MaskInput from './MaskInput'

const Wrapper = styled.div`
  background-color: #fff;
  position: fixed;
  transition: all 0.3s;
  top: 0;
  right: ${(props) => (props.show ? '0' : '-30%')};
  width: 368px;
  height: 100%;
  z-index: 1;
  overflow-y: auto;
`

const Cancel = styled.img`
  cursor: pointer;
  margin-left: auto;
`

const Header = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 20px 24px;
  position: sticky;
  top: 0;
`

const Title = styled.div`
  font-family: 'GraphikLCGMedium';
  font-size: 20px;
  line-height: 24px;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
`
// const BodyTitle = styled.div`
//   font-family: "GraphikLCGMedium";
//   font-size: 16px;
//   line-height: 16px;
//   margin-top: 3%;
// `;

const Field = styled.div`
  margin-right: auto;
  font-family: 'GraphikLCGMedium';
  font-size: 16px;
  line-height: 16px;
  color: #0000000;
  margin-bottom: 16px;
`

// const Value = styled.div`
//   font-family: "GraphikLCGMedium";
//   font-size: 14px;
//   line-height: 16px;
// `;

// const Content = styled.div`
//   display: flex;
//   margin-top: 7%;
// `;

const Section = styled.div`
  padding: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`

const Input = styled.input`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 100%;
  padding: 10px;
  font-family: 'GraphikLCGMedium';
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 8px;
  &:focus {
    outline: none;
    border: 1px solid #6f2cff;
  }
`

const Form = styled.form`
  padding: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`

const PriceText = styled.p`
  font-family: 'GraphikLCGMedium';
  font-size: 14px;
  color: #00000080;
`
const OpenButton = styled.button`
  font-family: 'GraphikLCGMedium';
  font-size: 20px;
  line-height: 24px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  width: 320px;
  height: 56px;
  background: #6f2cff;
  color: #fff;
  align-self: center;
`
const StyledFormControl = styledMui(FormControl)(({ theme }) => ({
  width: '100%',
  borderRadius: 8,
  backgroundColor: 'transparent',
  border: '1px solid rgba(0, 0, 0, 0.1)',
  padding: 3,
  marginBottom: 8,
}))

const StyledSumContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
const StyledChangeSumButton = styled.button`
  font-size: 14px;
  color: #6f2cff;
  background: #fff;
  border: #fff;
  cursor: pointer;
  margin-bottom: 10px;
  width: 70px;
`
const CustomPrice = styled.p`
  font-family: 'GraphikLCGMedium';
  font-size: 14px;
  height: 10px;
  text-align: right;
`


const disabledDate = (currentDate) => {
  const today = new Date();
  today.setDate(today.getDate() - 1)
  const nextMonth = new Date();
  nextMonth.setMonth(nextMonth.getMonth() + 1);
  return currentDate < today || currentDate > nextMonth;
};

const NewClient = ({
  newUserModal,
  setNewUserModal,
  heroPasses,
  marathon,
  setNewAbonement,
  newAbonement,
  setPayment,
  payment,
  name,
  setName,
  setPaymentModal,
  setTotalPrice,
  payments,
  setTypeAbonement,
  newUserPhoneNumber,
  setNewUserPhoneNumber,
  newUserEmail,
  setNewUserEmail,
  date,
  setDate,
  setMarathonEvent,
  totalPrice,
  salesUsers,
  setSalesPerson,
  salesPerson,
  setComment,
  comment,
  setMixedPayment,
  mixedPayment,
  cashAmount,
  setCashAmount,
  cardAmount,
  setCardAmount,
  creditAmount,
  setCreditAmount,
  setCompanyBin,
  companyBin,
  marathonSexyLegs,
  marathonSummerCamp,
  marathonEvent,
  typeAbonement
}) => {
  // const [mail, setMail] = React.useState("");
  const [value, setValue] = React.useState('')
  const [valueDate, setValueDate] = React.useState('')
  const [valueSalesPerson, setValueSalesPerson] =
    React.useState('Выберите менеджера')
  const [company, setCompany] = React.useState(false)
  const [mixedFirstType, setMixedFirstType] = React.useState('')
  const [mixedSecondType, setMixedSecondType] = React.useState('')
  const [changeSum, setChangeSum] = React.useState(false)
  const [disabled, setDisabled] = React.useState(true)


  React.useEffect(() => {
    if (typeAbonement === 'marathon') {
      if (marathonEvent && payment && salesPerson && name && newUserEmail && newUserPhoneNumber) {
        setDisabled(false)
      } else if (!marathonEvent && !payment && !salesPerson && !name && !newUserEmail && !newUserPhoneNumber) {
        setDisabled(true)
      } else {
        setDisabled(true)
      }
    } else if (typeAbonement === 'heropass') {
      if (date && payment && salesPerson && name && newUserEmail && newUserPhoneNumber) {
        setDisabled(false)
      } else if (!date && !payment && !salesPerson && !name && !newUserEmail && !newUserPhoneNumber) {
        setDisabled(true)
      } else {
        setDisabled(true)
      }
    } else if (typeAbonement === 'payments') {
      if (newAbonement && payment && salesPerson && name && newUserEmail && newUserPhoneNumber) {
        setDisabled(false)
      } else if (!date && !payment && !salesPerson && !name && !newUserEmail && !newUserPhoneNumber) {
        setDisabled(true)
      } else {
        setDisabled(true)
      }
    } else {
      setDisabled(true)
    }
  }, [date, payment, salesPerson, marathonEvent, typeAbonement, newAbonement]);


  React.useEffect(() => {
    setChangeSum(false)
  }, [newAbonement])

  const handleChange = (event) => {
    setValue(event.target.value)
  }
  const handleChangeDate = (event) => {
    setValueDate(event.target.value)
  }

  const handleChangePhoneNumber = (event) => {
    setNewUserPhoneNumber(event.target.value)
  }

  const handleChangePayment = (event) => {
    setPayment(event.target.value)
  }
  const handleMail = (event) => {
    setNewUserEmail(event.target.value)
  }
  const handlePrice = (event) => {
    setTotalPrice(event.target.value)
  }
  const handleSalesPerson = (event) => {
    setValueSalesPerson(event.target.value)
  }
  const handleComment = (event) => {
    setComment(event.target.value)
  }
  const handleCashAmount = (event) => {
    setCashAmount(event.target.value)
    if (
      cardAmount &&
      (mixedFirstType === 'card' || mixedSecondType === 'card')
    ) {
      setTotalPrice(parseInt(cardAmount) + parseInt(event.target.value))
    } else if (
      creditAmount &&
      (mixedFirstType === 'credit' || mixedSecondType === 'credit')
    ) {
      setTotalPrice(parseInt(creditAmount) + parseInt(event.target.value))
    } else {
      setTotalPrice(parseInt(event.target.value))
    }
  }
  const handleCardAmount = (event) => {
    setCardAmount(event.target.value)
    if (
      cashAmount &&
      (mixedFirstType === 'cash' || mixedSecondType === 'cash')
    ) {
      setTotalPrice(parseInt(cashAmount) + parseInt(event.target.value))
    }
    if (
      creditAmount &&
      (mixedFirstType === 'credit' || mixedSecondType === 'credit')
    ) {
      setTotalPrice(parseInt(creditAmount) + parseInt(event.target.value))
    } else {
      setTotalPrice(parseInt(event.target.value))
    }
  }
  const handleCreditAmount = (event) => {
    setCreditAmount(event.target.value)
    if (
      cashAmount &&
      (mixedFirstType === 'cash' || mixedSecondType === 'cash')
    ) {
      setTotalPrice(parseInt(cashAmount) + parseInt(event.target.value))
    } else if (
      cardAmount &&
      (mixedFirstType === 'card' || mixedSecondType === 'card')
    ) {
      setTotalPrice(parseInt(cardAmount) + parseInt(event.target.value))
    } else {
      setTotalPrice(parseInt(event.target.value))
    }
  }
  const handleCompanyBin = (event) => {
    setCompanyBin(event.target.value)
  }

  const handleChangeMixedFirst = (event) => {
    setMixedFirstType(event.target.value)
  }

  const handleChangeMixedSecond = (event) => {
    setMixedSecondType(event.target.value)
  }
  const renderMixedType = (value) => {
    if (value === 'card') {
      return (
        <Input
          type="text"
          value={cardAmount}
          onChange={handleCardAmount}
          placeholder={'Картой'}
        />
      )
    } else if (value === 'cash') {
      return (
        <Input
          type="text"
          value={cashAmount}
          onChange={handleCashAmount}
          placeholder={'Наличные'}
        />
      )
    } else if (value === 'credit') {
      return (
        <Input
          type="text"
          value={creditAmount}
          onChange={handleCreditAmount}
          placeholder={'Кредит'}
        />
      )
    }
  }

  React.useEffect(() => {
    setTotalPrice(findPayments ? newAbonement?.cost : newAbonement?.price)
  }, [newAbonement])

  const findPayments = payments?.find((el) => el?.id === newAbonement?.id)

  return (
    <Wrapper show={newUserModal}>
      <Header>
        <Title>Новый клиент</Title>
        <Cancel src={CancelLogo} onClick={() => setNewUserModal(false)} />
      </Header>
      <Body>
        <Form>
          <Field>Клиент</Field>
          <Input
            type="text"
            value={name}
            onChange={(event) => {
              setName(event.target.value)
            }}
            placeholder={'Имя'}
          />
          <Input
            type="text"
            value={newUserEmail}
            onChange={handleMail}
            placeholder={'Почта'}
          />
          <MaskInput
            mask="+8 (708) 777-68-25"
            value={newUserPhoneNumber}
            onChange={handleChangePhoneNumber}
            placeholder="Номер телефона"
            // className={classes.mask}
          />
        </Form>
        <Section>
          <Field>Абонемент</Field>
          <StyledFormControl variant="standard">
            <Select
              value={value}
              MenuProps={{
                disableScrollLock: true,
              }}
              onChange={handleChange}
              displayEmpty
              disableUnderline
              style={{
                fontFamily: 'GraphikLCGMedium',
                fontSize: 14,
                marginLeft: 12,
              }}
            >
              <MenuItem
                value=""
                style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
                onClick={() => setNewAbonement()}
              >
                Выберите тариф
              </MenuItem>
              <MenuItem
                value="-2"
                style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
                onClick={() => {
                  setNewAbonement(marathon)
                  setTypeAbonement('marathon')
                }}
              >
                {marathon?.name}
              </MenuItem>
              <MenuItem
                value="-3"
                style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
                onClick={() => {
                  setNewAbonement(marathonSummerCamp)
                  setTypeAbonement('marathon')
                }}
              >
                {marathonSummerCamp?.name}
              </MenuItem>
              {heroPasses?.map((item, index) => {
                return (
                  <MenuItem
                    key={index}
                    style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
                    value={index}
                    onClick={() => {
                      setNewAbonement(item)
                      setTypeAbonement('heropass')
                    }}
                  >
                    {item?.name}
                  </MenuItem>
                )
              })}
              {payments?.map((itm) => {
                return (
                  <MenuItem
                    key={itm.id}
                    style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
                    value={itm.id}
                    onClick={() => {
                      setNewAbonement(itm)
                      setTypeAbonement('payments')
                    }}
                  >
                    {itm?.name}
                  </MenuItem>
                )
              })}
            </Select>
          </StyledFormControl>
          {newAbonement && (
            <div>
              {newAbonement?.id === marathon?.id && (
                <StyledFormControl variant="standard">
                  <Select
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    value={valueDate}
                    onChange={handleChangeDate}
                    displayEmpty
                    disableUnderline
                    style={{
                      fontFamily: 'GraphikLCGMedium',
                      fontSize: 14,
                      marginLeft: 12,
                    }}
                  >
                    <MenuItem
                      value=""
                      style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
                    >
                      Выберите дату начала
                    </MenuItem>
                    {marathon?.marathonEvents?.map((item, index) => {
                      if (dayjs().weekday(-7) <= dayjs(item?.startTime)) {
                        return (
                          <MenuItem
                            value={index}
                            key={index}
                            style={{
                              fontFamily: 'GraphikLCGMedium',
                              fontSize: 14,
                            }}
                            onClick={() => setMarathonEvent(item?.id)}
                          >
                            {dayjs(item?.startTime).format('DD.MM.YYYY')}
                          </MenuItem>
                        )
                      }
                    })}
                  </Select>
                </StyledFormControl>
              )}
              {newAbonement?.id === marathonSummerCamp?.id && (
                <StyledFormControl variant="standard">
                  <Select
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    value={valueDate}
                    onChange={handleChangeDate}
                    displayEmpty
                    disableUnderline
                    style={{
                      fontFamily: 'GraphikLCGMedium',
                      fontSize: 14,
                      marginLeft: 12,
                    }}
                  >
                    <MenuItem
                      value=""
                      style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
                    >
                      Выберите дату начала
                    </MenuItem>
                    {marathonSummerCamp?.marathonEvents?.map((item, index) => {
                      if (dayjs().weekday(-7) <= dayjs(item?.startTime)) {
                        return (
                          <MenuItem
                            value={index}
                            key={index}
                            style={{
                              fontFamily: 'GraphikLCGMedium',
                              fontSize: 14,
                            }}
                            onClick={() => setMarathonEvent(item?.id)}
                          >
                            {dayjs(item?.startTime).format('DD.MM.YYYY')}
                          </MenuItem>
                        )
                      }
                    })}
                  </Select>
                </StyledFormControl>
              )}
              {newAbonement?.id === marathonSexyLegs?.id && (
                <StyledFormControl variant="standard">
                  <Select
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    value={valueDate}
                    onChange={handleChangeDate}
                    displayEmpty
                    disableUnderline
                    style={{
                      fontFamily: 'GraphikLCGMedium',
                      fontSize: 14,
                      marginLeft: 12,
                    }}
                  >
                    <MenuItem
                      value=""
                      style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
                    >
                      Выберите дату начала
                    </MenuItem>
                    {marathonSexyLegs?.marathonEvents?.map((item, index) => {
                      if (dayjs().startOf('week') <= dayjs(item?.startTime)) {
                        return (
                          <MenuItem
                            value={index}
                            key={index}
                            style={{
                              fontFamily: 'GraphikLCGMedium',
                              fontSize: 14,
                            }}
                            onClick={() => setMarathonEvent(item?.id)}
                          >
                            {dayjs(item?.startTime).format('DD.MM.YYYY')}
                          </MenuItem>
                        )
                      }
                    })}
                  </Select>
                </StyledFormControl>
              )}
              {newAbonement?.id !== marathon?.id && !findPayments && newAbonement?.id !== marathonSummerCamp?.id && (
                <DatePicker
                  locale={datePickerLocale}
                  placeholder="Выберите дату начала"
                  format="DD.MM.YYYY"
                  onChange={(date) => setDate(date)}
                  style={{
                    width: '100%',
                    color: 'black',
                    borderRadius: 8,
                  }}
                  disabledDate={disabledDate}
                />
              )}
              {!changeSum && (
                <StyledSumContainer>
                  <div
                    style={{
                      justifyContent: 'space-between',
                      display: 'flex',
                      width: '100%',
                    }}
                  >
                    <PriceText>Итоговая сумма к оплате</PriceText>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                      <CustomPrice>
                        {findPayments
                          ? newAbonement?.cost
                          : newAbonement?.price}
                        ₸
                      </CustomPrice>
                      <StyledChangeSumButton
                        onClick={() => {
                          setChangeSum(true)
                        }}
                      >
                        Изменить
                      </StyledChangeSumButton>
                    </div>
                  </div>
                </StyledSumContainer>
              )}
              {!mixedPayment && changeSum && (
                <Input
                  type="text"
                  value={totalPrice}
                  onChange={handlePrice}
                  placeholder={
                    findPayments
                      ? `${newAbonement?.cost}₸`
                      : `${newAbonement?.price}₸`
                  }
                  style={{ marginTop: 10 }}
                />
              )}
              <StyledFormControl variant="standard">
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  value={valueSalesPerson}
                  onChange={handleSalesPerson}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  disableUnderline
                  style={{
                    fontFamily: 'GraphikLCGMedium',
                    fontSize: 14,
                    marginLeft: 12,
                  }}
                >
                  <MenuItem
                    value="Выберите менеджера"
                    style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
                    onClick={() => setSalesPerson()}
                  >
                    Выберите менеджера
                  </MenuItem>
                  {salesUsers?.map((item, index) => {
                    return (
                      <MenuItem
                        key={item?.id}
                        value={index}
                        style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
                        onClick={() => setSalesPerson(item?.id)}
                      >
                        {item?.username || item?.nickname}
                      </MenuItem>
                    )
                  })}
                </Select>
              </StyledFormControl>
              <StyledFormControl variant="standard">
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  value={payment}
                  onChange={handleChangePayment}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  disableUnderline
                  style={{
                    fontFamily: 'GraphikLCGMedium',
                    fontSize: 14,
                    marginLeft: 12,
                  }}
                >
                  <MenuItem
                    value=""
                    style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
                    onClick={() => {
                      setCompany(false)
                      setMixedPayment(false)
                    }}
                  >
                    Выберите способ оплаты
                  </MenuItem>
                  <MenuItem
                    style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
                    value="card"
                    onClick={() => {
                      setCompany(false)
                      setMixedPayment(false)
                    }}
                  >
                    Картой
                  </MenuItem>
                  <MenuItem
                    style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
                    value="cash"
                    onClick={() => {
                      setCompany(false)
                      setMixedPayment(false)
                    }}
                  >
                    Наличными
                  </MenuItem>
                  <MenuItem
                    style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
                    value="kaspiCredit"
                    onClick={() => {
                      setCompany(false)
                      setMixedPayment(false)
                    }}
                  >
                    Каспи кредит
                  </MenuItem>
                  <MenuItem
                    style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
                    value="mixed"
                    onClick={() => {
                      setCompany(false)
                      setMixedPayment(true)
                    }}
                  >
                    Смешанный вид оплаты
                  </MenuItem>
                  <MenuItem
                    style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
                    value="company"
                    onClick={() => {
                      setCompany(true)
                      setMixedPayment(false)
                    }}
                  >
                    Оплата перечислением
                  </MenuItem>
                </Select>
              </StyledFormControl>
              {mixedPayment && (
                <>
                  <div
                    style={{
                      border: '1px solid rgba(0, 0, 0, 0.1)',
                      padding: 10,
                      borderRadius: 8,
                      marginBottom: 8,
                    }}
                  >
                    <StyledFormControl variant="standard">
                      <Select
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                        value={mixedFirstType}
                        onChange={handleChangeMixedFirst}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        disableUnderline
                        style={{
                          fontFamily: 'GraphikLCGMedium',
                          fontSize: 14,
                          marginLeft: 12,
                        }}
                      >
                        <MenuItem
                          value=""
                          style={{
                            fontFamily: 'GraphikLCGMedium',
                            fontSize: 14,
                          }}
                        >
                          Выберите первый способ оплаты
                        </MenuItem>
                        <MenuItem
                          style={{
                            fontFamily: 'GraphikLCGMedium',
                            fontSize: 14,
                          }}
                          value="card"
                          disabled={mixedSecondType === 'card'}
                        >
                          Картой
                        </MenuItem>
                        <MenuItem
                          style={{
                            fontFamily: 'GraphikLCGMedium',
                            fontSize: 14,
                          }}
                          value="cash"
                          disabled={mixedSecondType === 'cash'}
                        >
                          Наличными
                        </MenuItem>
                        <MenuItem
                          style={{
                            fontFamily: 'GraphikLCGMedium',
                            fontSize: 14,
                          }}
                          value="credit"
                          disabled={mixedSecondType === 'credit'}
                        >
                          Кредит
                        </MenuItem>
                      </Select>
                    </StyledFormControl>
                    {renderMixedType(mixedFirstType)}
                  </div>
                  <div
                    style={{
                      border: '1px solid rgba(0, 0, 0, 0.1)',
                      padding: 10,
                      borderRadius: 8,
                      marginBottom: 8,
                    }}
                  >
                    <StyledFormControl variant="standard">
                      <Select
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                        value={mixedSecondType}
                        onChange={handleChangeMixedSecond}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        disableUnderline
                        style={{
                          fontFamily: 'GraphikLCGMedium',
                          fontSize: 14,
                          marginLeft: 12,
                        }}
                      >
                        <MenuItem
                          value=""
                          style={{
                            fontFamily: 'GraphikLCGMedium',
                            fontSize: 14,
                          }}
                        >
                          Выберите второй способ оплаты
                        </MenuItem>
                        <MenuItem
                          style={{
                            fontFamily: 'GraphikLCGMedium',
                            fontSize: 14,
                          }}
                          value="card"
                          disabled={mixedFirstType === 'card'}
                        >
                          Картой
                        </MenuItem>
                        <MenuItem
                          style={{
                            fontFamily: 'GraphikLCGMedium',
                            fontSize: 14,
                          }}
                          value="cash"
                          disabled={mixedFirstType === 'cash'}
                        >
                          Наличными
                        </MenuItem>
                        <MenuItem
                          style={{
                            fontFamily: 'GraphikLCGMedium',
                            fontSize: 14,
                          }}
                          value="credit"
                          disabled={mixedFirstType === 'credit'}
                        >
                          Кредит
                        </MenuItem>
                      </Select>
                    </StyledFormControl>
                    {renderMixedType(mixedSecondType)}
                  </div>
                </>
              )}
              {company && (
                <Input
                  type="text"
                  value={companyBin}
                  onChange={handleCompanyBin}
                  placeholder={'БИН компании'}
                />
              )}
              <Input
                type="text"
                style={{ height: 80 }}
                value={comment}
                onChange={handleComment}
                placeholder={'Комментарий'}
              />
            </div>
          )}
        </Section>
        <OpenButton
          disabled={disabled}
          style={{ opacity: !disabled ? 1 : 0.5 }}
          onClick={() => {
            setPaymentModal(true)
          }}
        >
          Провести оплату
        </OpenButton>
      </Body>
    </Wrapper>
  )
}

export default NewClient
