import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Form, Input, Select, Button } from 'antd'

import showConfirm from '../shared/DeleteConfirm'
import StyledTooltip from '../shared/StyledTooltip'
import PROGRAM_SET_TYPES from '../../utils/programSetTypes'

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const StyledInput = styled(Input)`
  width: 320px;
`

const FormItem = Form.Item

const ProgramSetGeneral = ({
  programSet,
  levels,
  clubs,
  zones,
  setZones,
  form,
  onFinish,
  deleteClick
}) => {
  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />
  }

  return (
    <MainContainer>
      <h2> Основные </h2>
      <Form form={form} layout="vertical" hideRequiredMark onFinish={onFinish}>
        <FormItem
          key="name"
          label={getTooltip('Название программы', 'Название программы')}
          name="name"
          rules={[
            {
              required: true,
              message: `Пожалуйста, напишите название программы`
            }
          ]}
          initialValue={programSet?.name}
        >
          <StyledInput placeholder="Название программы" />
        </FormItem>
        <FormItem
          key="shortDescription"
          label={getTooltip('Название программы', 'Название программы')}
          name="shortDescription"
          rules={[
            {
              required: true,
              message: `Пожалуйста, напишите краткое описание программы`
            }
          ]}
          initialValue={programSet?.shortDescription}
        >
          <StyledInput placeholder="Краткое описание программы" />
        </FormItem>
        <FormItem
          key="imageURL"
          label={getTooltip('Картинка программы', 'Картинка программы')}
          name="imageURL"
          rules={[
            {
              required: true,
              message: `Пожалуйста, укажите картинку программы`
            }
          ]}
          initialValue={programSet?.imageURL}
        >
          <StyledInput placeholder="Картинка программы" />
        </FormItem>
        <FormItem
          key="levels"
          label={getTooltip(
            'Список классов',
            'Список классов в которые будет включена эта программа'
          )}
          name="levels"
          rules={[
            {
              required: true,
              message: `Выберите классы`
            }
          ]}
          initialValue={programSet.levels.map(level => level.id)}
        >
          <Select
            mode="multiple"
            placeholder="Выберите класс"
            showSearch
            style={{ width: '320px' }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {levels.map(level => (
              <Select.Option key={level?.id} value={level?.id}>
                {level?.name}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          key="type"
          label={getTooltip(
            'Тип програмсета, боди тест или тренировка',
            'Боди тест или тренировка'
          )}
          name="type"
          rules={[
            {
              required: true,
              message: `Выберите тип`
            }
          ]}
          initialValue={programSet.type}
        >
          <Select
            mode="single"
            placeholder="Выберите класс"
            showSearch
            style={{ width: '320px' }}
          >
            {PROGRAM_SET_TYPES.map(programSetType => (
              <Select.Option value={programSetType} key={programSetType}>
                {programSetType}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          key="club"
          label={getTooltip(
            'Клуб',
            'Клуб в котором будет проходить данная программа'
          )}
          name="club"
          rules={[
            {
              required: true,
              message: `Пожалуйста, выберите клуб`
            }
          ]}
          initialValue={programSet.club?.id}
        >
          <Select
            placeholder="Выберите клуб"
            showSearch
            onChange={value => {
              const club = clubs.find(clubData => clubData.id === value)
              setZones(club.zones)
            }}
            style={{ width: '320px' }}
          >
            {clubs.map(club => (
              <Select.Option key={club.id} value={club.id}>
                {club.name}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          key="zone"
          label={getTooltip(
            'Зона',
            'Зона в которой будет проходить данная программа'
          )}
          name="zone"
          rules={[
            {
              required: true,
              message: `Пожалуйста, выберите зону`
            }
          ]}
          initialValue={programSet.zone.id}
        >
          <Select
            mode="single"
            placeholder="Выберите зону"
            showSearch
            style={{ width: '320px' }}
          >
            {zones && zones.length> 0 && 
              zones.map(zone => (
                <Select.Option key={zone?.id} value={zone?.id}>
                  {zone?.name}
                </Select.Option>
              ))}
          </Select>
        </FormItem>
        <FormItem
          key="setDescriptions"
          label={getTooltip(
            'Подходы и повторения',
            'Напишите через пробел количество повторений в каждом подходе'
          )}
          name="setDescriptions"
          rules={[
            {
              required: true,
              message: `Пожалуйста, заполните данные подходов и повторений `
            }
          ]}
          initialValue={programSet?.setDescriptions?.join(' ')}
        >
          <StyledInput placeholder="Подходы и повторения" />
        </FormItem>
        {programSet.type === 'endGame' && (
          <FormItem
            key="secondSetDescriptions"
            label={getTooltip(
              'Подходы и повторения для второй части',
              'Напишите через пробел количество повторений в каждом подходе для второй части ендгейм'
            )}
            name="secondSetDescriptions"
            rules={[
              {
                required: true,
                message: `Пожалуйста, заполните данные подходов и повторений для второй части ендгейм`
              }
            ]}
            initialValue={programSet?.secondSetDescriptions?.join(' ')}
          >
            <StyledInput placeholder="Подходы и повторения" />
          </FormItem>
        )}

        <FormItem>
          <ButtonContainer>
            <Button
              danger
              onClick={() => {
                showConfirm(() => {
                  deleteClick()
                })
              }}
            >
              Удалить
            </Button>
            <Button type="primary" htmlType="submit">
              Сохранить изменения
            </Button>
          </ButtonContainer>
        </FormItem>
      </Form>
    </MainContainer>
  )
}

ProgramSetGeneral.propTypes = {
  programSet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    club: PropTypes.shape({
      id: PropTypes.string.isRequired
    }),
    zone: PropTypes.shape({
      id: PropTypes.string.isRequired
    }),
    levels: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired
      })
    ),
    setDescriptions: PropTypes.arrayOf(PropTypes.number).isRequired
  }).isRequired,
  levels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  clubs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      zones: PropTypes.arrayOf(PropTypes.shape({}))
    })
  ).isRequired,
  zones: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  setZones: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  deleteClick: PropTypes.func.isRequired,
  form: PropTypes.shape({}).isRequired
}

export default ProgramSetGeneral
