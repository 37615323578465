import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Table, Button, Divider } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import EditForm from '../shared/EditFormModal'
import CreateForm from '../shared/CreateForm'

const HeroPathes = ({
  heroPathes,
  deleteHeroPathClick,
  addHeroPathClick,
  updateHeroPathClick
}) => {
  const [display, setDisplay] = useState('default')
  const [editHeroPath, setEditHeroPath] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)

  const columns = [
    {
      title: 'Название',
      dataIndex: 'name'
    },
    {
      title: 'Идентификатор',
      dataIndex: 'identifier'
    },
    {
      title: 'Действие',
      key: 'action',
      render: (text, item) => (
        <span>
          <Button
            type="link"
            onClick={() => {
              setDisplay('edit')
              setEditHeroPath(item)
            }}
          >
            Редактировать
          </Button>
          <Divider type="vertical" />
        </span>
      )
    }
  ]

  const fields = [
    {
      key: 'name',
      label: 'Название',
      tooltipText: 'Название пути'
    },
    {
      key: 'identifier',
      label: 'Идентификатор',
      tooltipText: 'Идентификатор пути'
    }
  ]

  const editFields = [
    {
      key: 'name',
      label: 'Название',
      value: editHeroPath && editHeroPath.name,
      tooltipText: 'Название пути'
    },
    {
      key: 'identifier',
      label: 'Идентификатор',
      value: editHeroPath && editHeroPath.identifier,
      tooltipText: 'Идентификатор пути'
    }
  ]

  const handleCreate = values => {
    addHeroPathClick(values)
    setModalVisible(false)
  }

  const handleUpdate = values => {
    updateHeroPathClick(editHeroPath.id, values)
    setDisplay('default')
    setEditHeroPath(null)
  }

  const handleDelete = () => {
    deleteHeroPathClick(editHeroPath.id)
    setDisplay('default')
    setEditHeroPath(null)
  }

  return (
    <>
      <Table
        dataSource={heroPathes}
        columns={columns}
        title={() => (
          <div>
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новый пути
            </Button>
          </div>
        )}
      />
      <CreateForm
        title="Добавить новый путь"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onCreate={handleCreate}
        fields={fields}
      />
      {display === 'edit' && editHeroPath && (
        <EditForm
          title="Редактировать путь"
          visible={display === 'edit'}
          onCancel={() => setDisplay('default')}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          fields={editFields}
        />
      )}
    </>
  )
}

HeroPathes.propTypes = {
  heroPathes: PropTypes.shape({
    name: PropTypes.string,
    identifier: PropTypes.string
  }).isRequired,
  deleteHeroPathClick: PropTypes.func.isRequired,
  addHeroPathClick: PropTypes.func.isRequired,
  updateHeroPathClick: PropTypes.func.isRequired
}

export default HeroPathes
