import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  Table,
  Button,
  Divider,
  Modal,
  Form,
  Input,
  Select,
  Avatar,
  DatePicker,
} from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'

import showConfirm from '../shared/DeleteConfirm'
import ImageUpload from '../shared/ImageUpload'
import StyledTooltip from '../shared/StyledTooltip'
import EditNewsModal from './EditNewsModal'

const FormItem = Form.Item
const { TextArea } = Input

const ImageUploadContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledAvatar = styled(Avatar)`
  margin-left: 5px;
`

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`

const NewsModals = ({
  dataNewsModals,
  levels,
  deleteNewsModalClick,
  addNewsModalClick,
  updateNewsModalClick,
}) => {
  const [form] = Form.useForm()
  const [imageUrl, setImageUrl] = useState(null)
  const [display, setDisplay] = useState('default')
  const [editNewsModal, setEditNewsModal] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)
  const [newsModals, setNewsModals] = useState([])
  const [searchInput, setSearchInput] = useState('')

  React.useEffect(() => {
    let descriptiveNewsModals = dataNewsModals.map((newsModal) => {
      return {
        ...newsModal,
      }
    })
    if (searchInput) {
      descriptiveNewsModals = descriptiveNewsModals.filter((newsModal) => {
        return newsModal.name?.toLowerCase().includes(searchInput)
      })
    }
    setNewsModals(descriptiveNewsModals)
  }, [dataNewsModals, searchInput])

  const columns = [
    {
      title: 'Новость',
      dataIndex: 'title',
      width: '30%',
    },
    {
      title: 'Начало',
      render: (text, item) => {
        const eventDate = dayjs(item?.startTime).format('Do MMMM, YYYY')
        return <span>{eventDate}</span>
      },
      width: '20%',
    },
    {
      title: 'Конец',
      render: (text, item) => {
        const eventDate = dayjs(item?.endTime).format('Do MMMM, YYYY')
        return <span>{eventDate}</span>
      },
      width: '20%',
    },
    {
      title: 'Действие',
      key: 'action',
      width: '30%',
      render: (text, item) => (
        <span>
          <Button
            onClick={() => {
              setDisplay('edit')
              setImageUrl(null)
              setEditNewsModal(item)
            }}
          >
            Редактировать
          </Button>
        </span>
      ),
    },
  ]

  const handleCreate = (values) => {
    addNewsModalClick(values)
    setModalVisible(false)
  }

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />
  }

  return (
    <>
      <Table
        dataSource={newsModals}
        columns={columns}
        rowKey={(item) => item.id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Поиск"
              onChange={(e) => {
                setSearchInput(e.target.value.toLowerCase())
              }}
            />
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новый класс
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новый класс"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          setImageUrl(null)
          setModalVisible(false)
        }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields()

              delete values.imageUrl
              console.log({ ...values, imageUrl })
              handleCreate({ ...values, imageUrl })
            })
            .catch((info) => {
              // eslint-disable-next-line no-console
              console.log('Validate Failed:', info)
            })
        }}
      >
        <Form form={form} layout="vertical" hideRequiredMark>
          <FormItem
            key="title"
            label={getTooltip('Название класса', 'Название класса')}
            name="title"
            rules={[
              {
                required: true,
                message: `Пожалуйста, напишите название класса`,
              },
            ]}
          >
            <Input placeholder="Название" />
          </FormItem>
          <FormItem
            key="StartTime"
            label="Дата события"
            name="startTime"
            rules={[
              {
                required: true,
                message: `Пожалуйста, выберите дату`,
              },
            ]}
          >
            <StyledDatePicker placeholder="Выберите дату" />
          </FormItem>
          <FormItem
            key="EndTime"
            label="Дата события"
            name="endTime"
            rules={[
              {
                required: true,
                message: `Пожалуйста, выберите дату`,
              },
            ]}
          >
            <StyledDatePicker placeholder="Выберите дату" />
          </FormItem>
          <FormItem
            key="imageUrl"
            label={getTooltip(
              'Обложка',
              'Обложка класса для отображения в общем списке'
            )}
            name="imageUrl"
            rules={[
              {
                required: false,
                message: `Пожалуйста, загрузите обложку класса`,
              },
            ]}
          >
            <ImageUploadContainer>
              <ImageUpload
                onUpdate={(value) => {
                  setImageUrl(value)
                }}
              />
              <StyledAvatar size="96" shape="square" src={imageUrl} />
            </ImageUploadContainer>
          </FormItem>
        </Form>
      </Modal>
      {display === 'edit' && editNewsModal && (
        <EditNewsModal
          visible={editNewsModal}
          onCancel={() => {
            setImageUrl(null)
            setEditNewsModal(null)
            setModalVisible(false)
          }}
          title="Редактировать"
          editNewsModal={editNewsModal}
          onUpdate={updateNewsModalClick}
          onDelete={deleteNewsModalClick}
        />
      )}
    </>
  )
}
NewsModals.propTypes = {
  dataNewsModals: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  levels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deleteNewsModalClick: PropTypes.func.isRequired,
  addNewsModalClick: PropTypes.func.isRequired,
  updateNewsModalClick: PropTypes.func.isRequired,
}

export default NewsModals
