import React from 'react'
import styled from 'styled-components'
import { Table, Button, Form, Modal, Input } from 'antd'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

import CommentModal from '../conversionClients/CommentModal'
import Loading from '../shared/Loading'

const ClientsUsersWithHeroPass = ({
  usersWithHeroPass,
  totalUsersWithHeroPass,
  thirdPage,
  setThirdPage,
  comment,
  setComment,
  setIdUserMarathonEvent,
  HandleAddNote,
  idUserMarathonEvent,
}) => {
  const [modalComment, setModalComment] = React.useState(false)

  const columns = [
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray',
            }}
          >
            Имя
          </div>
        )
      },
      dataIndex: ['user', 'firstName'],
      width: '25%',
      render: (text, item) => <>{text}</>,
    },
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray',
            }}
          >
            Номер
          </div>
        )
      },
      dataIndex: ['user', 'phoneNumber'],
      width: '25%',
      render: (text, item) => <>{text}</>,
    },
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray',
            }}
          >
            Абонемент
          </div>
        )
      },
      dataIndex: ['heroPass', 'name'],
      width: '25%',
      render: (text, item) => <>{text}</>,
    },
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray',
            }}
          >
            Окончание HP
          </div>
        )
      },
      dataIndex: 'endTime',
      width: '25%',
      render: (text, item) => {
        const endTime = dayjs(text)
        const timeDifference = endTime - Date.now()
        const daysDifference = Math.floor(
          timeDifference / (1000 * 60 * 60 * 24)
        )
        const getNoun = (days, one, two, five) => {
          let n = Math.abs(days)
          n %= 100
          if (n >= 5 && n <= 20) {
            return five
          }
          n %= 10
          if (n === 1) {
            return one
          }
          if (n >= 2 && n <= 4) {
            return two
          }
          return five
        }
        {
          getNoun(
            dayjs(item?.endTime).diff(dayjs(), 'days'),
            'день',
            'дня',
            'дней'
          )
        }

        const detection = () => {
          if (daysDifference >= 60) {
            const months = Math.floor(daysDifference / 30)
            return `${months} месяца`
          }
          if (daysDifference >= 30) {
            return <span style={{ color: 'red' }}>{'1 месяц'}</span>
          }
          if (daysDifference < 30) {
            return (
              <span style={{ color: 'red' }}>
                {`${daysDifference} ${getNoun(
                  daysDifference,
                  'день',
                  'дня',
                  'дней'
                )}`}
              </span>
            )
          }
        }
        return (
          <div>
            <span>{endTime.format('DD.MM.YY')}</span>
            <span style={{ paddingLeft: 5 }}>{detection()}</span>
          </div>
        )
      },
    },
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray',
            }}
          >
            Заметка
          </div>
        )
      },
      dataIndex: ['note', 'noteText', 'createdAt', 'id'],
      width: '25%',
      render: (text, item) => {
        if (!item.note?.noteText) {
          return (
            <button
              style={{
                color: 'gray',
                border: 0,
                backgroundColor: 'transparent',
                cursor: 'pointer',
              }}
              onClick={() => {
                {
                  setModalComment(true)
                  setIdUserMarathonEvent(item.id)
                }
              }}
            >
              Добавить
            </button>
          )
        }
        return (
          <button
            style={{
              color: 'black',
              border: 0,
              backgroundColor: 'transparent',
              cursor: 'pointer',
              display: 'flex',
            }}
            onClick={() => {
              {
                setModalComment(true)
                setIdUserMarathonEvent(item.id)
              }
            }}
          >
            <>{item.note.noteText}</>
            <div style={{ color: 'gray', paddingLeft: 5 }}>
              {dayjs(item.note.createdAt).format('DD.MM.YY')}
            </div>
          </button>
        )
      },
    },
  ]
  if (!usersWithHeroPass) {
    return <Loading />
  }

  return (
    <>
      <Table
        dataSource={usersWithHeroPass}
        columns={columns}
        rowKey={(item) => item.user.id}
        pagination={{
          defaultPageSize: 10,
          total: totalUsersWithHeroPass,
          current: thirdPage,
          onChange: (thirdPage, pageSize) => {
            setThirdPage(thirdPage)
          },
          showSizeChanger: false,
        }}
      />
      {modalComment && (
        <CommentModal
          modalComment={modalComment}
          setModalComment={setModalComment}
          comment={comment}
          setComment={setComment}
          HandleAddNote={HandleAddNote}
          idUserMarathonEvent={idUserMarathonEvent}
        />
      )}
    </>
  )
}

export default ClientsUsersWithHeroPass
