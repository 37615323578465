import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Form, Input, Select, Button } from 'antd'

import showConfirm from '../shared/DeleteConfirm'
import StyledTooltip from '../shared/StyledTooltip'

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const StyledInput = styled(Input)`
  width: 320px;
`

const FormItem = Form.Item

const ProgramSetСharacteristics = ({ programSet, form, onFinish }) => {
  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />
  }

  return (
    <MainContainer>
      <h2> Характеристики </h2>
      <Form form={form} layout="vertical" hideRequiredMark onFinish={onFinish}>
        <FormItem
          key="strength"
          label={getTooltip(
            'Сила за тренировку',
            'силу которую зарабатывает за посещения тренировки'
          )}
          name="strength"
          rules={[
            {
              required: true,
              message: `Пожалуйста, укажить сила за тренировку`
            }
          ]}
          initialValue={programSet?.strength}
        >
          <StyledInput placeholder="Сила за тренировку" />
        </FormItem>
        <FormItem
          key="ability"
          label={getTooltip(
            'Ловкость за тренировку',
            'Ловкость которую зарабатывает за посещения тренировки'
          )}
          name="ability"
          rules={[
            {
              required: true,
              message: `Пожалуйста, укажить ловкость за тренировку`
            }
          ]}
          initialValue={programSet?.ability}
        >
          <StyledInput placeholder="Ловкость за тренировку" />
        </FormItem>
        <FormItem
          key="endurance"
          label={getTooltip(
            'Выносливость за тренировку',
            'Выносливость которую зарабатывает за посещения тренировки'
          )}
          name="endurance"
          rules={[
            {
              required: true,
              message: `Пожалуйста, укажить выносливость за тренировку`
            }
          ]}
          initialValue={programSet?.endurance}
        >
          <StyledInput placeholder="Выносливость за тренировку" />
        </FormItem>
        <FormItem
          key="sexuality"
          label={getTooltip(
            'Сексуальность за тренировку',
            'Сексуальность которую зарабатывает за посещения тренировки'
          )}
          name="sexuality"
          rules={[
            {
              required: true,
              message: `Пожалуйста, укажить сексуальность за тренировку`
            }
          ]}
          initialValue={programSet?.sexuality}
        >
          <StyledInput placeholder="Сексуальность за тренировку" />
        </FormItem>
        <FormItem
          key="willPower"
          label={getTooltip(
            'Сила Воли за тренировку',
            'Сила Воли которую зарабатывает за посещения тренировки'
          )}
          name="willPower"
          rules={[
            {
              required: true,
              message: `Пожалуйста, укажить сила Воли за тренировку`
            }
          ]}
          initialValue={programSet?.willPower}
        >
          <StyledInput placeholder="Сила Воли за тренировку" />
        </FormItem>
        <FormItem
          key="biceps"
          label={getTooltip(
            'Бицепс за тренировку',
            'Бицепс которую зарабатывает за посещения тренировки'
          )}
          name="biceps"
          rules={[
            {
              required: true,
              message: `Пожалуйста, укажить Бицепс за тренировку`
            }
          ]}
          initialValue={programSet?.biceps}
        >
          <StyledInput placeholder="Бицепс за тренировку" />
        </FormItem>

        <FormItem
          key="pectoralMuscles"
          label={getTooltip(
            'Грудные мышцы за тренировку',
            'Грудные мышцы которую зарабатывает за посещения тренировки'
          )}
          name="pectoralMuscles"
          rules={[
            {
              required: true,
              message: `Пожалуйста, укажить Грудные мышцы
              за тренировку`
            }
          ]}
          initialValue={programSet?.pectoralMuscles}
        >
          <StyledInput placeholder="Бицепс за тренировку" />
        </FormItem>

        <FormItem
          key="backMuscles"
          label={getTooltip(
            'Мышцы спины  за тренировку',
            'Мышцы спины которую зарабатывает за посещения тренировки'
          )}
          name="backMuscles"
          rules={[
            {
              required: true,
              message: `Пожалуйста, укажить Мышцы спины за тренировку`
            }
          ]}
          initialValue={programSet?.backMuscles}
        >
          <StyledInput placeholder="Мышцы спины за тренировку" />
        </FormItem>

        <FormItem
          key="lumbarMuscles"
          label={getTooltip(
            'Мышцы Поясницы  за тренировку',
            'Мышцы Поясницы которую зарабатывает за посещения тренировки'
          )}
          name="lumbarMuscles"
          rules={[
            {
              required: true,
              message: `Пожалуйста, укажить Мышцы Поясницы за тренировку`
            }
          ]}
          initialValue={programSet?.lumbarMuscles}
        >
          <StyledInput placeholder="Мышцы Поясницы за тренировку" />
        </FormItem>

        <FormItem
          key="quadriceps"
          label={getTooltip(
            'Квадрицепсы  за тренировку',
            'Квадрицепсы которую зарабатывает за посещения тренировки'
          )}
          name="quadriceps"
          rules={[
            {
              required: true,
              message: `Пожалуйста, укажить Квадрицепсы за тренировку`
            }
          ]}
          initialValue={programSet?.quadriceps}
        >
          <StyledInput placeholder="Квадрицепсы за тренировку" />
        </FormItem>

        <FormItem
          key="buttocks"
          label={getTooltip(
            'Ягодицы  за тренировку',
            'Ягодицы которую зарабатывает за посещения тренировки'
          )}
          name="buttocks"
          rules={[
            {
              required: true,
              message: `Пожалуйста, укажить Ягодицы за тренировку`
            }
          ]}
          initialValue={programSet?.buttocks}
        >
          <StyledInput placeholder="Ягодицы за тренировку" />
        </FormItem>

        <FormItem
          key="legBiceps"
          label={getTooltip(
            'Бицепсы ног за тренировку',
            'Бицепсы ног которую зарабатывает за посещения тренировки'
          )}
          name="legBiceps"
          rules={[
            {
              required: true,
              message: `Пожалуйста, укажить Бицепсы ног за тренировку`
            }
          ]}
          initialValue={programSet?.legBiceps}
        >
          <StyledInput placeholder="Бицепсы ног за тренировку" />
        </FormItem>

        <FormItem
          key="caviar"
          label={getTooltip(
            'Икры  за тренировку',
            'Икры  которую зарабатывает за посещения тренировки'
          )}
          name="caviar"
          rules={[
            {
              required: true,
              message: `Пожалуйста, укажить Икры  за тренировку`
            }
          ]}
          initialValue={programSet?.caviar}
        >
          <StyledInput placeholder="Икры  за тренировку" />
        </FormItem>

        <FormItem
          key="delta"
          label={getTooltip(
            'Дельты  за тренировку',
            'Дельты  которую зарабатывает за посещения тренировки'
          )}
          name="delta"
          rules={[
            {
              required: true,
              message: `Пожалуйста, укажить Дельты  за тренировку`
            }
          ]}
          initialValue={programSet?.delta}
        >
          <StyledInput placeholder="Икры  за тренировку" />
        </FormItem>

        <FormItem
          key="triceps"
          label={getTooltip(
            'Трицепсы  за тренировку',
            'Трицепсы  которую зарабатывает за посещения тренировки'
          )}
          name="triceps"
          rules={[
            {
              required: true,
              message: `Пожалуйста, укажить Трицепсы за тренировку`
            }
          ]}
          initialValue={programSet?.triceps}
        >
          <StyledInput placeholder="Трицепсы  за тренировку" />
        </FormItem>

        <FormItem
          key="press"
          label={getTooltip(
            'Пресс  за тренировку',
            'Пресс  которую зарабатывает за посещения тренировки'
          )}
          name="press"
          rules={[
            {
              required: true,
              message: `Пожалуйста, укажить  Пресс за тренировку`
            }
          ]}
          initialValue={programSet?.press}
        >
          <StyledInput placeholder="Пресс  за тренировку" />
        </FormItem>

        <FormItem
          key="cardio"
          label={getTooltip(
            'Кардио  за тренировку',
            'Кардио  которую зарабатывает за посещения тренировки'
          )}
          name="cardio"
          rules={[
            {
              required: true,
              message: `Пожалуйста, укажить  Кардио за тренировку`
            }
          ]}
          initialValue={programSet?.cardio}
        >
          <StyledInput placeholder="Кардио  за тренировку" />
        </FormItem>

        <FormItem>
          <ButtonContainer>
            <Button type="primary" htmlType="submit">
              Сохранить изменения
            </Button>
          </ButtonContainer>
        </FormItem>
      </Form>
    </MainContainer>
  )
}

ProgramSetСharacteristics.propTypes = {
  programSet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    club: PropTypes.shape({
      id: PropTypes.string.isRequired
    }),
    zone: PropTypes.shape({
      id: PropTypes.string.isRequired
    }),
    setDescriptions: PropTypes.arrayOf(PropTypes.number).isRequired
  }).isRequired,
  onFinish: PropTypes.func.isRequired,
  form: PropTypes.shape({}).isRequired
}

export default ProgramSetСharacteristics
