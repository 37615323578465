import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Table, Button, Divider } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

import EditForm from '../shared/EditFormModal'
import CreateForm from '../shared/CreateForm'

const HeroPasses = ({ heroPasses, addHeroPassClick }) => {
  const [modalVisible, setModalVisible] = useState(false)

  const columns = [
    {
      title: 'Название',
      dataIndex: 'name'
    },
    {
      title: 'Описание',
      dataIndex: 'description'
    },
    {
      title: 'Статус',
      render: (_, item) => {
        if (item.isActive) {
          return <div style={{ color: 'green' }}>Активен</div>
        }
        return <div style={{ color: 'red' }}>Неактивен</div>
      }
    },
    {
      title: 'Действие',
      key: 'action',
      render: (text, item) => (
        <span>
          <Link to={`/heroPasses/${item.id}`}>Редактировать</Link>
        </span>
      )
    }
  ]

  const fields = [
    {
      key: 'name',
      label: 'Название',
      tooltipText: 'Название HeroPass'
    },
    {
      key: 'description',
      label: 'Описание',
      tooltipText: 'Описание пути'
    },
    {
      key: 'imageURL',
      label: 'Ссылка на изображение HeroPass',
      tooltipText: 'Ссылка на изображение HeroPass'
    },
    {
      key: 'price',
      label: 'Цена HeroPass',
      tooltipText: 'Цена HeroPass',
      number: true
    },
    {
      key: 'duration',
      label: 'Длительность HeroPass',
      tooltipText: 'Длительность HeroPass',
      number: true
    },
    {
      key: 'type',
      label: 'Выберите типа HeroPass',
      options: [
        { value: 'limited', label: 'Лимитом' },
        { value: 'unlimited', label: 'Безлимит' }
      ],
      tooltipText: 'Тип HeroPass'
    }
  ]

  const handleCreate = values => {
    addHeroPassClick(values)
    setModalVisible(false)
  }

  return (
    <>
      <Table
        dataSource={heroPasses}
        columns={columns}
        title={() => (
          <div>
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новый пути
            </Button>
          </div>
        )}
      />
      <CreateForm
        title="Добавить новый путь"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onCreate={handleCreate}
        fields={fields}
      />
    </>
  )
}

HeroPasses.propTypes = {
  heroPasses: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string
  }).isRequired,
  deleteHeroPassClick: PropTypes.func.isRequired,
  addHeroPassClick: PropTypes.func.isRequired,
  updateHeroPassClick: PropTypes.func.isRequired
}

export default HeroPasses
