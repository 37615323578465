import React from 'react'
import { Table, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

import styled from 'styled-components'

import PropTypes from 'prop-types'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const UserPhotos = ({ users }) => {
  const [searchNickname, setSearchNickname] = React.useState(null)

  const [listUsers, setListUsers] = React.useState(null)

  React.useEffect(() => {
    const descriptiveUsers = users.map(user => {
      return {
        id: user.id,
        nickname: user?.nickname || 'Нет никнейма',
        levelName: user.level?.name,
        sex: user.sex,
        sexName:
          (user.sex === 'male' && 'Мужской') ||
          (user.sex === 'female' && 'Женский'),
        phoneNumber: user.phoneNumber,
        birthday: user.birthday,
        email: user.username,
        hero: user.hero?.id,
        level: user.level?.id,
        role: user.role
      }
    })
    let searchArray = descriptiveUsers

    if (searchNickname) {
      searchArray = searchArray.filter(user => {
        return (
          user?.nickname?.toLowerCase().includes(searchNickname) ||
          user.email?.toLowerCase().includes(searchNickname) ||
          user.phoneNumber?.toLowerCase().includes(searchNickname)
        )
      })
    }

    setListUsers(searchArray)
  }, [users, searchNickname])

  const columns = [
    {
      title: 'Никнейм',
      dataIndex: 'nickname',
      width: '25%'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: '25%'
    },
    {
      title: 'Уровень',
      dataIndex: 'levelName',
      width: '20%'
    },
    {
      title: 'Пол',
      dataIndex: 'sexName',
      width: '10%'
    },
    {
      title: 'Действие',
      width: '20%',
      render: (text, item) => (
        <span>
          <Link to={`/userPhotos/${item.id}`}>Добавить фото</Link>
        </span>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={listUsers}
        columns={columns}
        rowKey={item => item.id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Никнейм/Email/Номер телефона"
              onChange={e => {
                setSearchNickname(e.target.value.toLowerCase())
              }}
            />
          </StyledHeaderContainer>
        )}
      />
    </>
  )
}

UserPhotos.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default UserPhotos
