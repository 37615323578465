import React from 'react'
import PropTypes from 'prop-types'
import { UploadOutlined } from '@ant-design/icons'
import { Upload, message, Button } from 'antd'

const ImageUpload = props => {
  const onChange = info => {
    if (info.file.status === 'done') {
      console.log('image name is ', info)
      message.success(`${info.file.name} file uploaded successfully`)
      props.onUpdate(info.file.response.file.url)
      if (props.onUpdateCompressed) {
        console.log('we entered here', props.onUpdateCompressed)
        props.onUpdateCompressed(info.file.response.compressedFile?.url)
      }
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`)
    }
  }

  const uploadProps = {
    accept: 'image/*',
    name: 'image',
    action: props.uploadLink || '/api/uploadImageByFile',
    multiple: false
  }

  return (
    <Upload {...uploadProps} onChange={onChange}>
      <Button>
        <UploadOutlined /> Загрузить
      </Button>
    </Upload>
  )
}

ImageUpload.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onUpdateCompressed: PropTypes.func.isRequired,
  uploadLink: PropTypes.string
}

ImageUpload.defaultProps = {
  uploadLink: ''
}

export default ImageUpload
