import React, { useState, useEffect } from 'react'
import { gql, useQuery } from '@apollo/client'

import ReceptionEvents from './ReceptionEvents'
import Loading from '../../shared/Loading'
import withMainLayout from '../../../hocs/withMainLayout'
import { useAuth } from '../../../context/useAuth'

const GET_DATA = gql`
  query getData {
    events {
      id
      programSet {
        id
        name
        club {
          id
          name
        }
      }
      status
      startTime
      endTime
    }
  }
`

const ReceptionEventsContainer = () => {
  const [dataEvents, setDataEvents] = useState(null)

  const { data, loading, error } = useQuery(GET_DATA)
  const { user } = useAuth()

  useEffect(() => {
    if (user && data && !loading && !error) {
      const { events } = data
      setDataEvents(events)
    }
  }, [data, loading, error, user])

  if (loading && !dataEvents) {
    return <Loading />
  }

  return <>{dataEvents && <ReceptionEvents events={dataEvents} />}</>
}

export default withMainLayout(ReceptionEventsContainer)
