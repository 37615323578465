import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, Form, Input, Select } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import StyledTooltip from '../shared/StyledTooltip'

const { TextArea } = Input
const FormItem = Form.Item

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
}

const CreateHero = ({ visible, onCancel, title, levels, onCreate }) => {
  const [type, setType] = React.useState(null)
  const [form] = Form.useForm()

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />
  }

  return (
    <Modal
      open={visible}
      title={title}
      okText="Создать"
      cancelText="Закрыть"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields()
            onCreate(values)
          })
          .catch((info) => {
            // eslint-disable-next-line no-console
            console.log('Validate Failed:', info)
          })
      }}
    >
      <Form form={form} layout="vertical">
        <FormItem
          key="name"
          label={getTooltip('Имя Персонажа', 'Имя Персонажа')}
          name="name"
        >
          <Input placeholder="Имя Персонажа" />
        </FormItem>
        <FormItem
          key="type"
          label={getTooltip('Тип Персонажа', 'Тип Персонажа')}
          name="type"
        >
          <Select
            mode="single"
            placeholder="Choose type"
            showSearch
            onChange={(value) => setType(value)}
          >
            <Select.Option key="simple" value="simple">
              Обычный
            </Select.Option>
            <Select.Option key="epic" value="epic">
              Эпический
            </Select.Option>
          </Select>
        </FormItem>
        {type === 'epic' && (
          <FormItem
            key="countSales"
            label={getTooltip('Количество Персонажа', 'Количество Персонажа')}
            name="countSales"
          >
            <Input type="number" placeholder="Количество Персонажа" />
          </FormItem>
        )}

        {type === 'epic' && (
          <FormItem
            key="tickets"
            label={getTooltip('Стоимость Персонажа', 'Стоимость Персонажа')}
            name="tickets"
          >
            <Input type="number" placeholder="Стоимость Персонажа" />
          </FormItem>
        )}

        {type === 'epic' && (
          <FormItem
            key="backgroundURL"
            label={getTooltip('Фон Персонажа', 'Фон Персонажа')}
            name="backgroundURL"
          >
            <Input placeholder="Фон Персонажа" />
          </FormItem>
        )}
        <FormItem
          key="identifier"
          label={getTooltip(
            'Идентификатор Персонажа',
            'Идентификатор Персонажа'
          )}
          name="identifier"
        >
          <Input placeholder="Идентификатор Персонажа" />
        </FormItem>
        <FormItem
          key="history"
          label={getTooltip('Описание Персонажа', 'Описание Персонажа')}
          name="history"
        >
          <TextArea row={4} />
        </FormItem>
        <FormItem
          key="sex"
          label={getTooltip('Пол Персонажа', 'Пол Персонажа')}
          name="sex"
        >
          <Select mode="single" placeholder="Choose type" showSearch>
            <Select.Option key="male" value="male">
              Мужской
            </Select.Option>
            <Select.Option key="female" value="female">
              Женский
            </Select.Option>
          </Select>
        </FormItem>
        <FormItem
          key="imageURL"
          label={getTooltip('imageURL Персонажа', 'imageURL Персонажа')}
          name="imageURL"
        >
          <Input placeholder="imageURL Персонажа" />
        </FormItem>
        <FormItem
          key="avatarURL"
          label={getTooltip('avatarURL Персонажа', 'avatarURL Персонажа')}
          name="avatarURL"
        >
          <Input placeholder="avatarURL Персонажа" />
        </FormItem>
        <FormItem
          key="bwAvatarURL"
          label={getTooltip('bwAvatarURL Персонажа', 'bwAvatarURL Персонажа')}
          name="bwAvatarURL"
        >
          <Input placeholder="bwAvatarURL Персонажа" />
        </FormItem>
        <Form.List
          name="heroLevels"
          rules={[
            {
              validator: async (_, names) => {
                if (!names || names.length < 1) {
                  return Promise.reject(new Error('At least 2 passengers'))
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields &&
                fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0 ? formItemLayout : formItemLayout)}
                    label={index === 0 ? 'Passengers' : ''}
                    required={false}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      name={[field.name, 'level']}
                      fieldKey={[field.fieldKey, 'level']}
                      rules={[
                        { required: true, message: 'Missing level name' },
                      ]}
                    >
                      <Select
                        mode="single"
                        placeholder="Choose type"
                        showSearch
                      >
                        {levels.map((level) => (
                          <Select.Option key={level.id} value={level.id}>
                            {level.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'history']}
                      fieldKey={[field.fieldKey, 'history']}
                      rules={[
                        { required: true, message: 'Missing history name' },
                      ]}
                    >
                      <TextArea row={4} />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'avatarURL']}
                      fieldKey={[field.fieldKey, 'avatarURL']}
                      rules={[
                        { required: true, message: 'Missing avatarURL name' },
                      ]}
                    >
                      <Input placeholder="AvatarURL Name" />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'imageURL']}
                      fieldKey={[field.fieldKey, 'imageURL']}
                      rules={[
                        { required: true, message: 'Missing imageURL name' },
                      ]}
                    >
                      <Input placeholder="ImageURL Name" />
                    </Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => remove(field.name)}
                      style={{ width: '60%' }}
                      icon={<PlusOutlined />}
                    >
                      Remove field
                    </Button>
                  </Form.Item>
                ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: '60%' }}
                  icon={<PlusOutlined />}
                >
                  Add field
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  )
}

CreateHero.propTypes = {
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  levels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default CreateHero
