import React from 'react'
import PropTypes from 'prop-types'
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Table, Tag, Button, Divider, Popconfirm } from 'antd'
import styled from 'styled-components'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

import { useLoading } from '../../context/useLoading'
import TransferMarathonModal from './TransferMarathonModal'
import ParticipateMarathonModal from './ParticipateMarathonModal'
import UpdateUserMarathonEventModal from './UpdateUserMarathonEventModal'

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledButtonHeroPass = styled(Button)`
  margin-right: 10px;
`

const GET_DATA = gql`
  query getData($userId: ID, $query: PaginationQuery) {
    userMarathonEventWithPaginate(userId: $userId, query: $query) {
      userMarathonEvents {
        id
        name
        status

        marathonEvent {
          id
          endTime
          startTime
          marathon {
            id
            name
          }
        }
        basicTime
        tasksProgress {
          task {
            id
            name
            type
            typeOfClass
            typeOfClassMultiple
            visits
            countReads
          }
          completedCnt
          status
        }
        created_at
      }
      total
      limit
      page
      pages
    }
  }
`

const GET_MARATHONS = gql`
  query getMarathons {
    marathons {
      id
      name
      price
    }
  }
`

const GET_MARATHON_EVENTS = gql`
  query unfinishedMarathonEventsByMarathonId($marathonId: ID) {
    unfinishedMarathonEventsByMarathonId(marathonId: $marathonId) {
      id
      startTime
      endTime
    }
  }
`

const FINISH_MARATHON_EVENT = gql`
  mutation finishMarathonEvent($userId: ID, $marathonEventId: ID) {
    finishMarathonEvent(userId: $userId, marathonEventId: $marathonEventId) {
      status
      message
    }
  }
`

const TRANSFER_USER_MARATHON = gql`
  mutation transferMarathon($userId: ID, $marathon: ID, $marathonEventId: ID) {
    transferMarathon(
      userId: $userId
      marathon: $marathon
      marathonEventId: $marathonEventId
    ) {
      status
      message
    }
  }
`

const PARTICIPATE_USER = gql`
  mutation participateInMarathonEvent($userId: ID!, $marathonEventId: ID!, $clubId: ID!) {
    participateInMarathonEvent(
      userId: $userId
      marathonEventId: $marathonEventId
      clubId: $clubId
    ) {
      id
    }
  }
`

const UPDATE_PROGRESS = gql`
  mutation updateUserMarathonEventProgress(
    $userMarathonId: ID
    $input: UserMarathonEventInput
  ) {
    updateUserMarathonEventProgress(
      userMarathonId: $userMarathonId
      input: $input
    ) {
      status
      message
    }
  }
`

const GET_CLUBS = gql`
  query clubs {
    clubs {
      id
      name
    }
  }
  `

const UserMarathonEvents = ({ userId }) => {
  const { showLoading, hideLoading } = useLoading()
  const [transferModal, setTransferModal] = React.useState(false)
  const [userMarathonEvents, setUserMarathonEvents] = React.useState([])
  const [marathons, setMarathons] = React.useState([])
  const [marathonEvents, setMarathonEvents] = React.useState([])
  const [participateModal, setParticipateModal] = React.useState(false)
  const [updateModal, setUpdateModal] = React.useState(false)
  const [selectedUserMarathonEvent, setSelectedUserMarathonEvent] =
    React.useState(null)
  const [total, setTotal] = React.useState(0)
  const [page, setPage] = React.useState(1)
  const [clubs, setClubs] = React.useState()
  const { data: clubsData, loading: clubsLoading } = useQuery(GET_CLUBS)
  dayjs.locale('ru')

  const [getData, { data, error, loading, refetch }] = useLazyQuery(GET_DATA, {
    fetchPolicy: 'no-cache',
  })

  const {
    data: marathonsData,
    loading: marathonsLoading,
    error: marathonsError,
  } = useQuery(GET_MARATHONS, {
    fetchPolicy: 'no-cache',
  })

  const [
    getMarathonEvents,
    {
      data: marathonEventsData,
      loading: marathonEventsLoading,
      error: marathonEventsError,
    },
  ] = useLazyQuery(GET_MARATHON_EVENTS, {
    fetchPolicy: 'no-cache',
  })

  const [finishMarathonEvent] = useMutation(FINISH_MARATHON_EVENT, {
    onError(err) {
      hideLoading()
      toast.error(`Error ${err.message}`)
    },
    onCompleted() {
      toast.success(`Марафон успешно завершен`)
      hideLoading()
      refetch()
    },
  })

  const [transferUserMarathonEvent] = useMutation(TRANSFER_USER_MARATHON, {
    onError(err) {
      hideLoading()
      toast.error(`Error ${err.message}`)
    },
    onCompleted() {
      toast.success(`Марафон успешно перенесен`)
      hideLoading()
      refetch()
    },
  })

  const [participateUser] = useMutation(PARTICIPATE_USER, {
    onCompleted() {
      hideLoading()
      refetch()
      toast.success('Пользователь успешно добавлен в марафон')
    },
    onError(error) {
      hideLoading()
      toast.error(`${error.message}`)
    },
  })

  const [updateProgress] = useMutation(UPDATE_PROGRESS, {
    onCompleted() {
      hideLoading()
      refetch()
      toast.success('Прогресс отредактирован')
    },
    onError(error) {
      hideLoading()
      toast.error(`${error.message}`)
    },
  })

  React.useEffect(() => {
    if (marathonsData && !marathonsLoading && !marathonsError) {
      setMarathons(marathonsData.marathons)
    }
  }, [marathonsData, marathonsLoading, marathonsError])

  React.useEffect(() => {
    getData({
      variables: {
        userId: userId,
        query: {
          page: page,
          limit: 10,
        },
      },
    })
  }, [])

  React.useEffect(() => {
    refetch({
      userId: userId,
      query: {
        page: page,
        limit: 10,
      },
    })
  }, [page])

  React.useEffect(() => {
    if (data && data.userMarathonEventWithPaginate && !loading) {
      setUserMarathonEvents(
        data.userMarathonEventWithPaginate.userMarathonEvents
      )
      setTotal(data.userMarathonEventWithPaginate.total)
    } else if (error) {
      console.log(`error `, error)
    }
  }, [data, loading, error])

  React.useEffect(() => {
    if (marathonEventsData && !marathonEventsLoading && !marathonEventsError) {
      setMarathonEvents(marathonEventsData.unfinishedMarathonEventsByMarathonId)
    }
  }, [marathonEventsData, marathonEventsLoading, marathonEventsError])

  React.useEffect(() => {
    if (clubsData && !clubsLoading) {  
      setClubs(clubsData.clubs)
    }
  }, [clubsData, clubsLoading])

  const userPaymentColumns = [
    {
      title: 'Марафон',
      key: 'marathon',
      render: (item) => {
        return (
          <span>
            {' '}
            <Link
              to={`/marathonEvent/${item?.marathonEvent?.marathon?.id}/${item?.marathonEvent?.id}`}
            >{`"${item?.marathonEvent?.marathon?.name}" c ${dayjs(
              item?.marathonEvent?.startTime
            ).format('Do MMM YYYY')} до ${dayjs(
              item?.marathonEvent?.endTime
            ).format('Do MMM YYYY')}`}</Link>
          </span>
        )
      },
    },
    {
      title: 'Дата записи на Марфон',
      key: 'date',
      render: (item) => {
        const paymentDate = dayjs(item.created_at).format('Do MMMM, YYYY')
        return <span>{paymentDate} </span>
      },
    },
    {
      title: 'Статус',
      key: 'status',
      render: (item) => {
        if (
          item?.status === 'finished' ||
          dayjs(item?.marathonEvent?.endTime) < dayjs()
        ) {
          return <Tag color={'red'}>НЕАКТИВЕН</Tag>
        }
        return <Tag color={'green'}>АКТИВЕН</Tag>
      },
    },
    {
      title: 'Действие',
      key: 'action',
      render: (item) => (
        <span>
          <Button
            onClick={() => {
              setUpdateModal(true)
              setSelectedUserMarathonEvent(item)
            }}
          >
            Редактировать
          </Button>

          {item?.status !== 'finished' &&
            dayjs(item?.marathonEvent?.endTime) > dayjs() && (
              <>
                <Divider type="vertical" />
                <Button
                  type="dashed"
                  onClick={() => {
                    setTransferModal(true)
                  }}
                >
                  Перенести
                </Button>
                <Divider type="vertical" />
                <Popconfirm
                  title="Завершить марафон ?"
                  cancelText="Отмена"
                  onConfirm={() => {
                    showLoading()
                    finishMarathonEvent({
                      variables: {
                        userId: userId,
                        marathonEventId: item?.marathonEvent?.id,
                      },
                    })
                  }}
                >
                  <Button danger>Завершить</Button>
                </Popconfirm>
              </>
            )}
        </span>
      ),
    },
  ]

  return (
    <MainContainer>
      <Table
        dataSource={userMarathonEvents}
        columns={userPaymentColumns}
        rowKey={(item) => item.id}
        loading={loading}
        pagination={{
          defaultPageSize: 10,
          total: total,
          current: page,
          onChange: (page, pageSize) => {
            setPage(page)
          },
          showSizeChanger: false,
        }}
        title={() => (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h2> Марафоны </h2>
            <StyledButtonHeroPass
              type="primary"
              onClick={() => setParticipateModal(true)}
            >
              Добавить в марафон
            </StyledButtonHeroPass>
          </div>
        )}
      />
      {transferModal && (
        <TransferMarathonModal
          modalVisible={transferModal}
          setModalVisible={setTransferModal}
          marathons={marathons}
          marathonEvents={marathonEvents}
          handleTransferMarathon={({ marathonId, marathonEventId }) => {
            showLoading()
            transferUserMarathonEvent({
              variables: {
                userId: userId,
                marathon: marathonId,
                marathonEventId,
              },
            })
          }}
          handleGetMarathonEvents={(value) => {
            getMarathonEvents({
              variables: {
                marathonId: value,
              },
            })
          }}
        />
      )}

      {participateModal && (
        <ParticipateMarathonModal
          modalVisible={participateModal}
          setModalVisible={setParticipateModal}
          marathons={marathons}
          marathonEvents={marathonEvents}
          clubs={clubs}
          handleTransferMarathon={({ marathonId, marathonEventId, clubId }) => {
            showLoading()
            participateUser({
              variables: {
                userId: userId,
                marathonEventId,
                clubId,
              },
            })
          }}
          handleGetMarathonEvents={(value) => {
            getMarathonEvents({
              variables: {
                marathonId: value,
              },
            })
          }}
        />
      )}

      {selectedUserMarathonEvent && (
        <UpdateUserMarathonEventModal
          modalVisible={selectedUserMarathonEvent}
          userMarathonEvent={selectedUserMarathonEvent}
          onCancel={() => {
            setUpdateModal(false)
            setSelectedUserMarathonEvent(null)
          }}
          handleUpdateUser={({ id, basicTime, tasksProgress }) => {
            showLoading()
            updateProgress({
              variables: {
                userMarathonId: id,
                input: {
                  basicTime: basicTime,
                  tasksProgress: tasksProgress.map((data) => {
                    return {
                      task: data.taskId,
                      completedCnt: parseInt(data.completedCnt),
                    }
                  }),
                },
              },
            })
          }}
        />
      )}
    </MainContainer>
  )
}

UserMarathonEvents.propTypes = {
  userId: PropTypes.string.isRequired,
}

export default UserMarathonEvents
