import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { gql, useLazyQuery, useQuery, useMutation } from '@apollo/client'
import { Link } from 'react-router-dom'
import { Button, Table } from 'antd'
import styled from 'styled-components'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import { toast } from 'react-toastify'

import { useLoading } from '../../context/useLoading'

import AddTicketsModal from './AddTicketsModal'
import { useAuth } from '../../context/useAuth'
import AddPackageModal from './AddPackageModal'

const ColorfulText = styled.div`
  color: ${(p) => p.color};
`

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const StyledButton = styled(Button)`
  margin-left: 20px;
`
const StyledButtonPackage = styled(Button)`
  margin-right: 10px;
`

const GET_DATA = gql`
  query getData($userId: ID!, $query: PaginationQuery) {
    userTransactionsWithPaginate(userId: $userId, query: $query) {
      transactions {
        id
        changeValue
        type
        ticketType
        created_at
        expiryDate
        comment
        booking {
          id
          event {
            id
            startTime
            programSet {
              id
              name
            }
          }
        }
        addedBy {
          id
          username
        }
      }

      total
      limit
      page
      pages
    }
  }
`

const GET_PAYMENTS = gql`
  query payments {
    payments {
      id
      name
      cost
      isActive
      ticketType
      countClasses
    }
  }
`

const ADD_TICKETS_BY_SALES = gql`
  mutation addTicketsBySales($input: TransactionInput) {
    addTicketsBySales(input: $input) {
      id
      tickets
      outsiderTickets
    }
  }
`
const ADD_TICKETS = gql`
  mutation addtickets($input: TransactionInput) {
    addTicketsByAdmin(input: $input) {
      id
      tickets
      outsiderTickets
    }
  }
`

const UserTransactions = ({ user }) => {
  const { showLoading, hideLoading } = useLoading()
  const [userTransactions, setUserTransactions] = React.useState([])
  const [total, setTotal] = React.useState(0)
  const [page, setPage] = React.useState(1)
  dayjs.locale('ru')

  const [payments, setPayments] = React.useState([])
  const { user: currentUser } = useAuth()

  const [modalVisible, setModalVisible] = useState(false)
  const [isAddModoal, setIsAddModoal] = useState(false)
  const [packageModalVisible, setPackageModalVisible] = useState(false)

  const [getData, { data, error, loading, refetch }] = useLazyQuery(GET_DATA, {
    fetchPolicy: 'no-cache',
  })

  const {
    data: paymentsData,
    error: paymentsError,
    loading: paymentsLoading,
  } = useQuery(GET_PAYMENTS)

  const [addTicketsBySales, { error: addTicketsBySalesError }] = useMutation(
    ADD_TICKETS_BY_SALES,
    {
      onCompleted: () => {
        hideLoading()
        if (page === 1) {
          refetch({
            userId: user.id,
            query: {
              page: 1,
              limit: 10,
            },
          })
        }
        toast.success('Пакет успешно добавлен')
      },
    }
  )

  const [addTickets, { error: addTicketsError }] = useMutation(ADD_TICKETS, {
    onCompleted: () => {
      hideLoading()
      if (page === 1) {
        refetch({
          userId: user.id,
          query: {
            page: 1,
            limit: 10,
          },
        })
      }
      toast.success('Билеты успешно добавлены')
    },
    onError: (error) => {
      hideLoading()
      toast.error(error?.graphQLErrors?.[0]?.message)
    },
  })

  React.useEffect(() => {
    if (paymentsData && !paymentsLoading && paymentsData.payments) {
      setPayments(paymentsData.payments)
    }
  }, [paymentsData, paymentsLoading, paymentsError])

  React.useEffect(() => {
    getData({
      variables: {
        userId: user.id,
        query: {
          page: page,
          limit: 10,
        },
      },
    })
  }, [])

  React.useEffect(() => {
    refetch({
      userId: user.id,
      query: {
        page: page,
        limit: 10,
      },
    })
  }, [page])

  React.useEffect(() => {
    if (data && data.userTransactionsWithPaginate && !loading) {
      setUserTransactions(data.userTransactionsWithPaginate.transactions)
      setTotal(data.userTransactionsWithPaginate.total)
    } else if (error) {
      console.log(`error `, error)
    }
  }, [data, loading, error])

  const openAddTicketsModal = () => {
    if (
      currentUser?.role === 'salesperson' ||
      currentUser?.role === 'salesadmin'
    ) {
      setPackageModalVisible(true)
    } else {
      setModalVisible(true)
      setIsAddModoal(true)
    }
  }

  const openRemoveTicketsModal = () => {
    setModalVisible(true)
    setIsAddModoal(false)
  }

  const onCancel = () => {
    if (
      currentUser?.role === 'salesperson' ||
      currentUser?.role === 'salesadmin'
    ) {
      setPackageModalVisible(false)
    } else {
      setModalVisible(false)
    }
  }

  const renderChangeValue = (changeValue) => {
    if (changeValue > 0) {
      return <ColorfulText color="#00c000"> +{changeValue}</ColorfulText>
    } else if (changeValue < 0) {
      return <ColorfulText color="#FF4D4F"> {changeValue}</ColorfulText>
    }
    return <ColorfulText color="#000000"> {changeValue}</ColorfulText>
  }

  const renderDescription = (transaction) => {
    if (transaction.type === 'payment') {
      return (
        <span>{`Покупка пакета на ${transaction.changeValue} билетов`} </span>
      )
    }
    if (
      transaction.type === 'adminAdd' ||
      transaction.type === 'addExpiryTickets'
    ) {
      return (
        <span>
          {`${
            transaction.changeValue >= 0 ? 'Добавление' : 'Штраф'
          } админом. Коммент: ${transaction.comment}`}{' '}
        </span>
      )
    }
    if (transaction.type === 'penaltyForMissing') {
      return <span>{`${transaction.changeValue} Штраф за пропуск `} </span>
    }
    if (transaction.type === 'attentionForMissing') {
      return (
        <span>{`${transaction.changeValue} Предупреждение за пропуск `} </span>
      )
    }
    if (
      transaction.type === 'booking' ||
      transaction.type === 'expiryBooking'
    ) {
      const eventDate = dayjs(transaction?.booking?.event?.startTime).format(
        'Do MMMM, YYYY, HH:mm'
      )
      return (
        <span>
          <Link to={`/events/${transaction?.booking?.event?.id}`}>
            {`Запись на "${transaction?.booking?.event?.programSet?.name}" ${eventDate}`}
          </Link>
        </span>
      )
    }
    if (transaction.type === 'epicHero') {
      return <span> Покупка эпик героя </span>
    }

    if (
      transaction.type === 'bookingCancel' ||
      transaction.type === 'expiryBookingCancel'
    ) {
      const eventDate = dayjs(transaction?.booking?.event?.startTime).format(
        'Do MMMM, YYYY, HH:mm'
      )
      return (
        <span>
          <Link to={`/events/${transaction?.booking?.event?.id}`}>
            {`Отмена записи на "${transaction?.booking?.event?.programSet?.name}" ${eventDate}`}
          </Link>
        </span>
      )
    }
    if (transaction.type === 'demoVisitsExpired') {
      return <span> Демо билеты сгорели </span>
    }
    if (transaction.type === 'penalty') {
      return <span> Штраф за поздний выход </span>
    }
    if (transaction.type === 'ticketsExpired') {
      return <span> Билеты просрочились </span>
    }
    if (transaction.type === 'bonus') {
      return (
        <span>
          {`${transaction?.changeValue >= 0 ? 'Добавление бонуса' : 'Штраф'} `}
        </span>
      )
    }
    if (transaction.type === 'marathonExpired') {
      return <span> Марафон просрочился </span>
    }
    return <span> {transaction.changeValue} </span>
  }

  const renderSource = (transaction) => {
    if (
      transaction.type === 'adminAdd' ||
      transaction.type === 'addExpiryTickets'
    ) {
      return <span> Админ: {transaction?.addedBy?.username} </span>
    }
    return <span> Пользователь</span>
  }

  const renderType = (item) => {
    if (item?.ticketType === 'marathon') {
      return <span> Марафонские билеты</span>
    }
    if (item?.ticketType === 'assessment') {
      return <span>Билеты для ассессмент</span>
    }
    if (item?.ticketType === 'education') {
      return <span>Билеты для обучающей</span>
    }
    if (item?.ticketType === 'golden') {
      return <span> Билеты для EndGame</span>
    }
    if (item?.ticketType === 'guest') {
      return <span>Гостевой билет</span>
    }
    return <span>Обычный билет</span>
  }

  const userTransactionColumns = [
    {
      title: 'Билеты',
      width: '15%',
      dataIndex: 'changeValue',
      render: (text, item) => renderChangeValue(item?.changeValue),
    },
    {
      title: 'Тип билета',
      width: '20%',
      render: (text, item) => renderType(item),
    },
    {
      title: 'Причина',
      width: '20%',
      render: (text, item) => renderDescription(item),
    },
    {
      title: 'Источник',
      width: '20%',
      render: (text, item) => renderSource(item),
    },
    {
      title: 'Дата',
      width: '20%',
      render: (text, item) => {
        const dateReceived = dayjs(item.created_at).format(
          'Do MMMM, YYYY, HH:mm'
        )
        return <span>{dateReceived} </span>
      },
    },
  ]

  const handleAddTicketsBySales = ({ values }) => {
    showLoading()
    addTicketsBySales({
      variables: {
        input: {
          user: user.id,
          changeValue: parseInt(values?.changeValue, 10),
          comment: values?.comment,
          payment: values?.payment,
        },
      },
    })
  }

  const handleAddTickets = ({ values, isExpiry }) => {
    showLoading()
    if (isExpiry) {
      addTickets({
        variables: {
          input: {
            user: user.id,
            changeValue: parseInt(values?.changeValue, 10),
            comment: values?.comment,
            ticketType: values?.ticketType,
            expiryDate: values?.expiryDate || null,
          },
        },
      })
    } else {
      addTickets({
        variables: {
          input: {
            user: user.id,
            changeValue: parseInt(values?.changeValue, 10),
            ticketType: values?.ticketType,
            comment: values?.comment,
          },
        },
      })
    }
  }
  // console.log('userTransactions', userTransactions)
  return (
    <MainContainer>
      {/* <h2> История баланса</h2> */}
      <Table
        dataSource={userTransactions}
        columns={userTransactionColumns}
        rowKey={(item) => item.id}
        loading={loading}
        pagination={{
          defaultPageSize: 10,
          total: total,
          current: page,
          onChange: (page, pageSize) => {
            setPage(page)
          },
          showSizeChanger: false,
        }}
        title={() => (
          <TableHeader>
            <h2> Билеты: {user?.tickets + user?.outsiderTickets}</h2>
            <h2> Марафонские: {user?.marathonTickets}</h2>
            <h2> Ассессмент: {user?.assessmentTickets}</h2>
            <h2> Обучающая: {user?.educationTickets}</h2>
            <h2> Гостевые: {user?.guestTickets}</h2>
            <h2>
              {' '}
              Общий баланс:{' '}
              {user?.tickets +
                user?.outsiderTickets +
                user?.marathonTickets +
                user?.assessmentTickets}
            </h2>
            <div>
              {currentUser?.role === 'admin' && (
                <StyledButtonPackage
                  type="primary"
                  onClick={() => setPackageModalVisible(true)}
                >
                  Добавить пакет
                </StyledButtonPackage>
              )}
              <Button type="primary" onClick={openAddTicketsModal}>
                {currentUser?.role === 'salesperson' ||
                currentUser?.role === 'salesadmin'
                  ? 'Добавить пакет'
                  : 'Пополнить'}
              </Button>
              {currentUser?.role === 'admin' && (
                <StyledButton onClick={openRemoveTicketsModal}>
                  Убрать
                </StyledButton>
              )}
            </div>
          </TableHeader>
        )}
      />
      <AddTicketsModal
        handleAddTickets={handleAddTickets}
        isAddModal={isAddModoal}
        modalVisible={modalVisible}
        onCancel={onCancel}
        currentTickets={
          user?.tickets +
          user?.outsiderTickets +
          user?.marathonTickets +
          user?.assessmentTickets
        }
      />
      {packageModalVisible && (
        <AddPackageModal
          modalVisible={packageModalVisible}
          payments={payments}
          onCancel={onCancel}
          onCancel={setPackageModalVisible}
          userIsInvictusClient={user?.isInvictusClient}
          currentTickets={
            user?.tickets +
            user?.outsiderTickets +
            user?.marathonTickets +
            user?.assessmentTickets
          }
          handleAddTicketsBySales={handleAddTicketsBySales}
        />
      )}
    </MainContainer>
  )
}

UserTransactions.propTypes = {
  userTransactions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      changeValue: PropTypes.number,
      created_at: PropTypes.string,
      type: PropTypes.string,
    })
  ).isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    tickets: PropTypes.number.isRequired,
    outsiderTickets: PropTypes.string.isRequired,
  }).isRequired,
  handleAddTickets: PropTypes.func.isRequired,
  payments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      cost: PropTypes.number.isRequired,
      isActive: PropTypes.bool.isRequired,
      ticketType: PropTypes.string.isRequired,
      countClasses: PropTypes.number.isRequired,
    })
  ).isRequired,
  handleAddTicketsBySales: PropTypes.func.isRequired,
}

export default UserTransactions
