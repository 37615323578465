import React from 'react'
import { Modal, Button, Select, AutoComplete } from 'antd'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import showConfirm from '../shared/DeleteConfirm'

const StyledButton = styled(Button)`
  position: absolute;
  bottom: 10px;
  left: 15px;
  border: 2px solid red;
  color: red;
`
const StyledUser = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledNickname = styled.div`
  height: 24;
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #262626;
  padding-left: 10px;
`
const StyledId = styled.div`
  height: 12px;
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  color: #262626;
`

const { Option } = AutoComplete

const EditEventModal = ({
  visible,
  setVisible,
  users,
  isEdit,
  stationExercise,
  okHandler,
  deleteButtonHandler,
  refetchUsers,
}) => {
  const [selectedUser, setSelectedUser] = React.useState(null)

  React.useEffect(() => {
    if (!stationExercise?.isEmpty && stationExercise?.phoneNumber) {
      refetchUsers({
        query: {
          phoneNumber: stationExercise?.phoneNumber,
        },
        pagination: {
          page: 1,
          limit: 20,
        },
      })
    }
  }, [])

  const handleSearch = (value) => {
    refetchUsers({
      query: {
        phoneNumber: value,
      },
      pagination: {
        page: 1,
        limit: 20,
      },
    })
  }

  let footer = isEdit
    ? [
        <Button
          danger
          style={{ float: 'left' }}
          onClick={() => {
            showConfirm(() => {
              deleteButtonHandler({
                stationExercise: stationExercise,
              })
            })
          }}
        >
          Удалить
        </Button>,
        <Button
          key="submit"
          onClick={() => {
            setVisible(false)
          }}
        >
          Отмена
        </Button>,
      ]
    : [
        <Button
          key="submit"
          onClick={() => {
            setVisible(false)
          }}
        >
          Отмена
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            const user = users.find((userData) => userData?.id === selectedUser)
            okHandler({
              user: user,
              stationExercise: stationExercise,
            })
          }}
        >
          Сохранить
        </Button>,
      ]

  return (
    <Modal
      open={visible}
      footer={footer}
      onCancel={() => {
        setVisible(false)
      }}
    >
      <h3>
        <b>Станция: {stationExercise?.station}</b>
      </h3>
      <Select
        filterOption={false}
        placeholder="Введите номер телефона"
        showSearch
        style={{ width: '100%' }}
        onSearch={handleSearch}
        onChange={setSelectedUser}
        value={stationExercise?.userId}
      >
        {users.map((user) => (
          <Option key={user.id} value={user.id}>
            {user.email} | {user.phoneNumber}
          </Option>
        ))}
      </Select>
    </Modal>
  )
}

EditEventModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      nickname: PropTypes.string,
    })
  ).isRequired,
  okHandler: PropTypes.func.isRequired,
  stationName: PropTypes.string,
  deleteButtonHandler: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
}

EditEventModal.defaultProps = {
  stationName: '',
}

export default EditEventModal
