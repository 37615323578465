import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Table, Button, Divider, Select, Input, Modal, Avatar } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import styled from 'styled-components'

import showConfirm from '../shared/DeleteConfirm'
import ImageUpload from '../shared/ImageUpload'
import { removeTypenameField } from '../../utils/apollo'

const StyledImg = styled.img`
  width: 50px;
  height: 50px;
`

const StyledAvatar = styled(Avatar)`
  margin-left: 5px;
`

const StyledSelect = styled(Select)`
  width: 100%;
`
const ImageUploadContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const emptyAwardInput = {
  name: '',
  description: '',
  imageURL: '',
  type: '',
  requiredAmount: 0,
}

const Awards = ({
  awards,
  deleteAwardClick,
  addAwardClick,
  updateAwardClick,
}) => {
  const [display, setDisplay] = useState('default')
  const [editAward, setEditAward] = useState(emptyAwardInput)

  const columns = [
    {
      title: 'Название',
      dataIndex: 'name',
    },
    {
      title: 'Описание',
      dataIndex: 'description',
    },
    {
      title: 'Тип награды',
      dataIndex: 'type',
    },
    {
      title: 'Количество',
      dataIndex: 'requiredAmount',
    },
    {
      title: 'Иконка',
      dataIndex: 'imageURL',
      render: (item) => {
        return <StyledImg src={item} alt="No Icon" />
      },
    },
    {
      title: 'Действие',
      key: 'action',
      render: (text, item) => (
        <span>
          <Button
            onClick={() => {
              setDisplay('edit')
              setEditAward(item)
            }}
          >
            Редактировать
          </Button>
          <Divider type="vertical" />
          <Button
            type="danger"
            onClick={() => {
              showConfirm(() => {
                deleteAwardClick(item.id)
              })
            }}
          >
            Удалить
          </Button>
        </span>
      ),
    },
  ]

  const AWARD_TYPES = [
    { value: 'time', label: 'Проведенное время' },
    { value: 'visit', label: 'Количество посещений' },
    { value: 'weight', label: 'Веса' },
    { value: 'fitnessTest', label: 'Фитнес тесты' },
    { value: 'perfectWeek', label: 'Идеальная неделя' },
    { value: 'visitsForPerfectWeek', label: 'Количество посещений в неделю' },
    { value: 'marathon', label: 'Марафон' },
    { value: 'visitsToClass', label: 'Количество посещений по классам' },
    { value: 'post', label: 'Количество постов' },
  ]

  const handleInputChange = (key, value) => {
    setEditAward({ ...editAward, [key]: value })
  }

  const handleCreate = (values) => {
    addAwardClick(values)
    setDisplay('default')
  }

  const handleUpdate = (values) => {
    updateAwardClick(editAward.id, values)
    setDisplay('default')
  }

  const handleSubmit = () => {
    if (display === 'edit') {
      handleUpdate(removeTypenameField(editAward))
    } else if (display === 'create') {
      handleCreate(removeTypenameField(editAward))
    }
  }

  return (
    <>
      <Table
        dataSource={awards}
        columns={columns}
        title={() => (
          <div>
            <Button
              type="primary"
              onClick={() => {
                setDisplay('create')
                setEditAward(emptyAwardInput)
              }}
            >
              <PlusOutlined /> Новая награда
            </Button>
          </div>
        )}
      />
      <Modal
        okText={display === 'edit' ? 'Обновить' : 'Создать'}
        title={display === 'edit' ? 'Обновить' : 'Создать'}
        cancelText="Отмена"
        open={display === 'edit' || display === 'create'}
        onOk={handleSubmit}
        onCancel={() => setDisplay('default')}
      >
        <h3> Название награды </h3>
        <Input
          value={editAward.name}
          onChange={(e) => handleInputChange('name', e.target.value)}
        />
        <h3> Описание награды</h3>
        <Input
          value={editAward.description}
          onChange={(e) => handleInputChange('description', e.target.value)}
        />
        <h3> Тип награды</h3>
        <StyledSelect
          value={editAward.type}
          onChange={(value) => handleInputChange('type', value)}
        >
          {AWARD_TYPES.map((awardType) => (
            <Select.Option key={awardType.value} value={awardType.value}>
              {awardType.label}
            </Select.Option>
          ))}
        </StyledSelect>
        <h3> Количество этого типа действий </h3>
        <Input
          type="number"
          value={editAward.requiredAmount}
          onChange={(e) => handleInputChange('requiredAmount', e.target.value)}
        />
        <h3> Иконка награды </h3>
        <ImageUploadContainer>
          <ImageUpload
            onUpdate={(value) => {
              handleInputChange('imageURL', value)
            }}
          />
          <StyledAvatar size="96" shape="square" src={editAward.imageURL} />
        </ImageUploadContainer>
      </Modal>
    </>
  )
}

Awards.propTypes = {
  awards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      description: PropTypes.string,
      type: PropTypes.string,
      imageURL: PropTypes.string,
      requiredAmount: PropTypes.number,
    })
  ).isRequired,
  deleteAwardClick: PropTypes.func.isRequired,
  addAwardClick: PropTypes.func.isRequired,
  updateAwardClick: PropTypes.func.isRequired,
}

export default Awards
